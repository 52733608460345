import { Box } from '@mui/material';
import logo from 'assets/images/logo.svg';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

type TLogoButtonProps = {
  size: number;
};

const LogoButton: FC<TLogoButtonProps> = ({ size }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
  };

  return (
    <Box
      component="img"
      src={logo}
      height={size}
      width={size}
      alt="file icon"
      sx={{ cursor: 'pointer' }}
      onClick={handleClick}
    />
  );
};

export default LogoButton;
