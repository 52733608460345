import { IBaseModelDTO } from './general.model';

export enum EMessageTypes {
  TEXT = 'TEXT',
}

export interface IMessageSender {
  id: string;
  name: string;
  email: string;
}

interface IMessageCommonFieldsDTO extends IBaseModelDTO {
  chatId: string;
  sender: IMessageSender;
  seenBy: string[];
}

export type IMessageDTO =
  | IMessageCommonFieldsDTO & {
      contentType: EMessageTypes.TEXT;
      content: string;
    };
