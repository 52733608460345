import {
  pink,
  orange,
  yellow,
  green,
  teal,
  blue,
  purple,
  blueGrey,
} from '@mui/material/colors';

type TProjectColor = {
  light: string;
  dark: string;
};
type TBoardColor = {
  light: string;
  dark: string;
};

const palleteColors = [
  pink,
  orange,
  yellow,
  green,
  teal,
  blue,
  purple,
  blueGrey,
];

export const projectColors: TProjectColor[] = palleteColors.map(color => ({
  light: color[50],
  dark: color[900],
}));

export const boardColors: TBoardColor[] = palleteColors.map(color => ({
  light: color[50],
  dark: color[900],
}));

export const dateFormat = 'MMMM D, YYYY';
export const timeFormat = 'HH:mm A';
export const dateTimeFormat = `${dateFormat} HH:MM`;
