import { useParams, useSearchParams } from 'react-router-dom';
import BoardsDetail from 'components/BoardsDetail';
import {
  useGetAuthUserQuery,
  useUpdateUserShortcutsMutation,
} from 'redux/apiSlices/authUser.slice';
import { EShortcutTypes } from 'shared/types/generic.types';
import { useGetProjectBoardsQuery } from 'redux/apiSlices/board.slice';
import CreateShortcutButton from 'components/CreateShortcutButton';
import { generateUid } from 'firebaseServices/firebase.config';
import { Box } from '@mui/material';
import DetailsPageWrapper from 'components/DetailsPageWrapper';
import useBoardDetailsBreadcrumb from 'hooks/BreadcrumbItems/useBoardsBreadcrumb';
import { useGetProjectByIdQuery } from 'redux/apiSlices/project.slice';
import BoardFilter from 'components/BoardsDetail/components/BoardFilter';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { setBoardFilter } from '../../../redux/appSlices/boardFilter.slice';

const BoardDetailsPage = () => {
  const dispatch = useAppDispatch();
  const [queryParams, setQueryParams] = useSearchParams();

  const { data } = useGetAuthUserQuery();
  const [updateShortcuts] = useUpdateUserShortcutsMutation();

  const params = useParams<{ projectId: string; boardId: string }>();
  const projectId = params.projectId || '';
  const boardId = params.boardId || '';

  const { data: projectData } = useGetProjectByIdQuery(projectId);
  const { data: boards, isLoading } = useGetProjectBoardsQuery(projectId);

  const filters = useAppSelector(state => state.boardFilterSlice[boardId]);
  useEffect(() => {
    const labels = queryParams.getAll('labels');
    const assignees = queryParams.getAll('assignees');
    if (labels.length || assignees.length)
      dispatch(
        setBoardFilter({
          boardId,
          filters: {
            assignees,
            labels,
          },
        }),
      );
  }, []);
  useEffect(() => {
    setQueryParams({
      labels: filters?.labels || [],
      assignees: filters?.assignees || [],
    });
  }, [filters]);
  const boardName =
    boards?.find(item => item.documentId === boardId)?.name || '';
  const shortcuts = data?.settings?.shortcuts || [];

  const inShortcuts = shortcuts.find(
    item =>
      item.type === EShortcutTypes.BOARD &&
      item.projectId === projectId &&
      item.boardGroupId === boardId,
  );

  const onAddShortcut = async () => {
    await updateShortcuts([
      ...shortcuts,
      {
        type: EShortcutTypes.BOARD,
        projectId,
        boardGroupId: boardId,
        pinned: false,
        name: boardName,
        open: false,
        id: generateUid(),
      },
    ]);
  };
  const breadcrumbItems = useBoardDetailsBreadcrumb({
    boardId,
    projectId,
  });

  return (
    <DetailsPageWrapper
      header={{
        breadcrumbItems,
        rightAction: (
          <Box width={1} display="flex">
            <BoardFilter projectId={projectId} boardId={boardId} />
            {!inShortcuts && <CreateShortcutButton onClick={onAddShortcut} />}
          </Box>
        ),
      }}
      loading={isLoading}
      bodyBgColor={projectData?.color}
    >
      <BoardsDetail projectId={projectId} boardId={boardId} />
    </DetailsPageWrapper>
  );
};

export default BoardDetailsPage;
