import React, { FC, ReactElement } from 'react';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { DialogActions, Popover, PopoverProps } from '@mui/material';
import { Close } from '@mui/icons-material';

type TPopoverWithStateProps = {
  popoverContent: React.ReactNode;
  children: ReactElement;
  popoverProps?: Omit<PopoverProps, 'open'>;
};

const PopoverWithState: FC<TPopoverWithStateProps> = ({
  popoverContent,
  children,
  popoverProps,
}) => {
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {popupState => (
        <>
          {React.cloneElement(children, bindTrigger(popupState))}
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            {...popoverProps}
          >
            <DialogActions>
              <Close
                onClick={bindPopover(popupState).onClose}
                sx={{ cursor: 'pointer' }}
              />
            </DialogActions>
            {popoverContent}
          </Popover>
        </>
      )}
    </PopupState>
  );
};

export default PopoverWithState;
