import { EmailOutlined } from '@mui/icons-material';
import { Avatar, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { FC } from 'react';
import { getFirstLetters, stringToColor } from 'utils/utils';

type TMemberOptionItemProps = {
  name?: string;
  email: string;
  inviteMember?: {
    projectName: string;
  };
};

const MemberOptionItem: FC<TMemberOptionItemProps> = ({
  name,
  email,
  inviteMember,
}) => {
  const avatar = inviteMember ? (
    <EmailOutlined />
  ) : (
    <Avatar
      sx={{
        bgcolor: stringToColor(name),
        fontSize: '14px',
        width: '28px',
        height: '28px',
      }}
    >
      {getFirstLetters(name)}
    </Avatar>
  );

  const nameToShow = inviteMember
    ? `Invite to ${inviteMember.projectName}`
    : name;

  return (
    <Grid container direction="row" columnSpacing={1} alignItems="center">
      <Grid>{avatar}</Grid>
      <Grid>
        <Stack spacing={0} direction="column">
          <Typography variant="body2" fontWeight={600}>
            {email}
          </Typography>
          <Typography variant="caption">{nameToShow}</Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default MemberOptionItem;
