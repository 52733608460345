import { useProjectEventsListener } from 'firebaseServices/listeners/Events/useProjectEventsListener';
import ChangeLogList from 'pages/privatePages/ChangelogDetailsPage/ChangeLogList';
import { FC } from 'react';

type TChangelogPreviewProps = {
  projectId: string;
};

const ChangelogPreview: FC<TChangelogPreviewProps> = ({ projectId }) => {
  const { data } = useProjectEventsListener({ projectId, limitCount: 5 });
  return <ChangeLogList eventsData={data} previewMode />;
};

export default ChangelogPreview;
