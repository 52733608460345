import * as Yup from 'yup';
import { validationMessages } from '../messages/messages';

export const AddBoardGroupSchema = Yup.object().shape({
  name: Yup.string().required(validationMessages.required),
});

export const AddBoardColumnSchema = Yup.object().shape({
  title: Yup.string().required(validationMessages.required),
});
