import { FC } from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { Delete } from '@mui/icons-material';

type TRemoveMenuItemProps = {
  onClick: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
};

const RemoveMenuItem: FC<TRemoveMenuItemProps> = ({ onClick }) => {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <Delete fontSize="small" />
      </ListItemIcon>
      <ListItemText>Remove</ListItemText>
    </MenuItem>
  );
};

export default RemoveMenuItem;
