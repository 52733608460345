import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FormControl, Stack, TextField } from '@mui/material';

type TAddDocModalFormProps = Pick<
  UseFormReturn<{ name: string }>,
  'formState' | 'register'
> & {
  onSubmit: () => void;
};

const AddDocModalForm: FC<TAddDocModalFormProps> = ({
  formState,
  register,
  onSubmit,
}) => {
  const { errors } = formState;
  return (
    <FormControl component="form" fullWidth onSubmit={onSubmit}>
      <Stack spacing={1} direction="column">
        <TextField
          label="Name"
          variant="standard"
          error={!!errors.name}
          helperText={errors.name && errors.name.message}
          required
          autoFocus
          autoComplete="off"
          {...register('name')}
        />
      </Stack>
    </FormControl>
  );
};

export default AddDocModalForm;
