import React, { FC, ReactElement, useState } from 'react';
import AlertDialog, { TAlertDialogProps } from '..';

type TAlertDialogWithStateProps = Omit<
  TAlertDialogProps,
  'open' | 'onCancel'
> & {
  children: ReactElement;
};

const AlertDialogWithState: FC<TAlertDialogWithStateProps> = ({
  children,
  title,
  text,
  submitDisabled,
  submitLoading,
  onConfirm,
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    await onConfirm();
    handleClose();
  };

  return (
    <>
      {React.cloneElement(children, { onClick: handleClickOpen })}
      <AlertDialog
        onCancel={handleClose}
        onConfirm={handleSubmit}
        open={open}
        text={text}
        title={title}
        submitDisabled={submitDisabled}
        submitLoading={submitLoading}
      />
    </>
  );
};

export default AlertDialogWithState;
