import React, { FC, useRef, useState } from 'react';
import { Box, IconButton, TextField } from '@mui/material';
import { Add, Check } from '@mui/icons-material';
import { FirebaseError } from 'firebase/app';
import { useSnackbar } from 'notistack';
import { errorMessages } from 'data/messages/messages';
import { boardColors } from 'data/static';
import Button from '../../../Base/Button';
import { useAddTaskMutation } from '../../../../redux/apiSlices/tasks.slice';

const AddTask: FC<{
  columnId: string;
  order: number;
  boardId: string;
  projectId: string;
  color: string;
}> = ({ columnId, order, projectId, boardId, color }) => {
  const { enqueueSnackbar } = useSnackbar();
  const inputRef = useRef<HTMLInputElement>();
  const [editMode, setEditMode] = useState(false);
  const [addTask, { isLoading }] = useAddTaskMutation();

  const openEditMode = () => {
    setEditMode(true);
  };
  const closeEditMode = () => {
    setEditMode(false);
  };
  const onInputBlur = () => {
    if (!inputRef.current?.value) closeEditMode();
  };
  const onAddTask = async () => {
    try {
      const title = inputRef.current?.value;
      if (title) {
        await addTask({
          task: { title, columnId, order, projectId, boardId },
        });
      }
    } catch (err) {
      const error = err as FirebaseError;
      enqueueSnackbar(error.message || errorMessages.default, {
        variant: 'error',
      });
    } finally {
      closeEditMode();
    }
  };
  const onEnter = async (event: React.KeyboardEvent<HTMLDivElement>)=>{
    if (event.key === 'Enter') {
      await onAddTask();
    }
  }
  return (
    <>
      {editMode ? (
        <Box display="flex" justifyContent="space-between" onKeyDown={onEnter} onBlur={onInputBlur}>
          <TextField size="small" autoFocus inputRef={inputRef} fullWidth />
          <IconButton onClick={onAddTask}>
            <Check />
          </IconButton>
        </Box>
      ) : (
        <Button
          loading={isLoading}
          startIcon={<Add />}
          fullWidth
          onClick={openEditMode}
          sx={{ color: boardColors.find(c => c.light === color)?.dark }}
        >
          Add a task
        </Button>
      )}
    </>
  );
};

export default AddTask;
