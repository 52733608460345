import {
  collection,
  documentId,
  firestore,
  getDocs,
  query,
  where,
} from '../firebase.config';
import {
  EFirestoreCollectionPaths,
  EProjectSubCollectionPaths,
} from '../../shared/types/FirestoreCollections';
import FirestoreOperations from '../FirestoreOperations';
import { ILabelDTO } from '../../shared/models/label.model';
import {
  ICreateLabelUI,
  IGetLabelsByIds,
  ILabelUI,
} from '../../data/types/label.type';
import { getLabelConverter } from '../../data/converters/label.converter';

export const getProjectLabels = async (projectId: string) => {
  const labelsRef = collection(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    projectId,
    EProjectSubCollectionPaths.LABELS,
  );
  const labelsSnapshot = await getDocs(labelsRef);

  const labels: ILabelUI[] = [];
  labelsSnapshot.forEach(doc => {
    if (doc.exists()) {
      const label = getLabelConverter.fromDb({
        ...(doc.data() as ILabelDTO),
        documentId: doc.id,
      });
      labels.push(label);
    }
  });
  return labels;
};

export const getLabelsByIds = async ({
  labelIds,
  projectId,
}: IGetLabelsByIds) => {
  const labelsRef = collection(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    projectId,
    EProjectSubCollectionPaths.LABELS,
  );
  const labelsQuery = query(labelsRef, where(documentId(), 'in', labelIds));
  const labelsSnapshot = await getDocs(labelsQuery);

  const labels: ILabelUI[] = [];
  labelsSnapshot.forEach(doc => {
    if (doc.exists()) {
      const label = getLabelConverter.fromDb({
        ...(doc.data() as ILabelDTO),
        documentId: doc.id,
      });
      labels.push(label);
    }
  });
  return labels;
};

export const createLabel = async ({ title, projectId }: ICreateLabelUI) => {
  const labelRef = collection(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    projectId,
    EProjectSubCollectionPaths.LABELS,
  );
  const newLabel: ILabelDTO = {
    title,
  };

  await FirestoreOperations.addDoc(labelRef, newLabel);
  return newLabel;
};
