import { Circle } from '@mui/icons-material';
import { MenuItem, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { boardColors } from 'data/static';
import { IColumnUI } from 'data/types/board.type';
import { FC } from 'react';
import { useUpdateColumnColorMutation } from 'redux/apiSlices/board.slice';

type TChangeColorProps = {
  projectId: string;
  boardId: string;
  column: IColumnUI;
};

const ChangeColor: FC<TChangeColorProps> = ({ projectId, boardId, column }) => {
  const [updateColor] = useUpdateColumnColorMutation();

  const onColorChange = async (color: string) => {
    await updateColor({
      color,
      projectId,
      boardId,
      columnId: column.documentId,
    });
  };
  return (
    <MenuItem>
      <ToggleButtonGroup
        value={column.color || ''}
        exclusive
        onChange={async (e, value) => {
          await onColorChange(value);
        }}
      >
        {boardColors.map(color => (
          <ToggleButton key={color.light} value={color.light} size="small">
            <Circle fontSize="large" style={{ fill: color.dark }} />
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </MenuItem>
  );
};

export default ChangeColor;
