import { Circle } from '@mui/icons-material';
import { FC } from 'react';

type TColorCircleInContentProps = {
  color: string | undefined | null;
  previewMode?: boolean;
};

const ColorCircleInContent: FC<TColorCircleInContentProps> = ({
  color,
  previewMode,
}) => {
  if (!color) {
    return null;
  }
  const colorSize = previewMode ? 'small' : 'large';
  return <Circle fontSize={colorSize} style={{ fill: color }} />;
};

export default ColorCircleInContent;
