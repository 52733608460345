import { FC } from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { Download } from '@mui/icons-material';
import { downloadFileData } from 'utils/utils';

type TDownloadMenuItemProps = {
  downloadUrl: string;
  fileName: string;
};

const DownloadMenuItem: FC<TDownloadMenuItemProps> = ({
  downloadUrl,
  fileName,
}) => {
  return (
    <MenuItem onClick={() => downloadFileData(downloadUrl, fileName)}>
      <ListItemIcon>
        <Download fontSize="small" />
      </ListItemIcon>
      <ListItemText>Download</ListItemText>
    </MenuItem>
  );
};

export default DownloadMenuItem;
