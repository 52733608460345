import { Badge, BadgeProps } from '@mui/material';
import { FC } from 'react';
import { EUserRole } from 'shared/types/generic.types';

type TBadgeOwnerProps = BadgeProps & {
  role?: EUserRole;
  children: React.ReactNode;
  badgeSize?: 'small' | 'large';
};

const BadgeOwner: FC<TBadgeOwnerProps> = ({
  role,
  children,
  badgeSize = 'small',
  ...badgeProps
}) => {
  const badgeStyleOveride =
    badgeSize === 'small'
      ? {
          left: '40%',
          height: '15px',
          lineHeight: '0',
        }
      : {
          left: '50%',
          height: '25px',
          fontSize: '18px',
        };

  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      sx={{
        '& .MuiBadge-badge': badgeStyleOveride,
      }}
      badgeContent="Owner"
      color="warning"
      invisible={role !== EUserRole.OWNER}
      {...badgeProps}
    >
      {children}
    </Badge>
  );
};

export default BadgeOwner;
