import { functions } from 'firebaseServices/firebase.config';
import {
  addNewProjectConverter,
  addProjectMemberConverter,
  getProjectConverter,
} from 'data/converters/project.converter';
import {
  IAddNewProjectUI,
  IProjectUI,
  IEditProjectUI,
  IAddProjectMemberUI,
} from 'data/types/project.types';
import {
  deleteField,
  documentId,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { IProjectDTO, IProjectMemberDTO } from 'shared/models/project.model';
import { firestoreReference } from 'firebaseServices/FirestoreReferenceManager';
import FirestoreOperations from 'firebaseServices/FirestoreOperations';

export const addNewProject = async (data: IAddNewProjectUI) => {
  const projectData = addNewProjectConverter.toDb(data);
  await httpsCallable(functions, 'project-createProject')(projectData);
};

export const getProjectsByIds = async (
  projectIds: string[],
): Promise<IProjectUI[] | null> => {
  if (!projectIds.length) {
    return null;
  }
  const projectsRef = firestoreReference.projects_CollectionRef();

  const q = query(
    projectsRef,
    where(documentId(), 'in', projectIds),
    orderBy('name', 'desc'),
  );
  const docSnapshot = await getDocs(q);

  if (docSnapshot.empty || docSnapshot.docs.every(d => !d.exists())) {
    return null;
  }
  const projects = docSnapshot.docs.map(d => {
    const convertedProject = getProjectConverter.fromDb({
      ...(d.data() as IProjectDTO),
      documentId: d.id,
    });
    return convertedProject;
  });
  return projects;
};

export const getProjectById = async (
  projectId: string,
): Promise<IProjectUI | null> => {
  const projectRef = firestoreReference.project_DocRef({
    projectId,
  });
  const projectDoc = await getDoc(projectRef);

  if (!projectDoc.exists()) {
    throw new Error('Project not found');
  }

  const project = getProjectConverter.fromDb({
    ...(projectDoc.data() as IProjectDTO),
    documentId: projectDoc.id,
  });

  return project;
};

export const updateProjectById = async (
  data: IEditProjectUI & { projectId: string },
): Promise<void> => {
  const projectRef = firestoreReference.project_DocRef({
    projectId: data.projectId,
  });
  await FirestoreOperations.setDoc(
    projectRef,
    { color: data.color, name: data.name, description: data.description },
    { merge: true },
  );
};

export type TAddProjectMemberProps = {
  projectId: string;
  newMembers: IAddProjectMemberUI[];
};

export const addProjectMembers = async (
  data: TAddProjectMemberProps,
): Promise<void> => {
  const { newMembers, projectId } = data;
  const projectRef = firestoreReference.project_DocRef({
    projectId,
  });

  const membersToUpdate: IProjectMemberDTO = {};

  newMembers.forEach(member => {
    const convertedMember = addProjectMemberConverter.toDb(member);
    Object.assign(membersToUpdate, convertedMember);
  });
  await FirestoreOperations.setDoc(
    projectRef,
    { members: membersToUpdate },
    { merge: true },
  );
};

export const removeProjectMember = async (data: {
  projectId: string;
  userId: string;
}): Promise<void> => {
  const { projectId, userId } = data;
  const projectRef = firestoreReference.project_DocRef({
    projectId,
  });

  await FirestoreOperations.updateDoc(projectRef, {
    [`members.${userId}`]: deleteField(),
  });
};

export const removeProject = async (projectId: string) => {
  await httpsCallable(functions, 'project-removeProject')(projectId);
};
