import { FC } from 'react';
import { Card } from '@mui/material';
import TextMessageArea from './TextMessageArea';

const NewMessageArea: FC<{
  chatId: string;
  projectId: string;
  onMessageSend: () => void;
}> = ({ chatId, projectId,onMessageSend }) => {
  return (
    <Card>
      <TextMessageArea projectId={projectId} chatId={chatId} onMessageSend={onMessageSend} />
    </Card>
  );
};
export default NewMessageArea;
