import { FC, useRef } from 'react';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useGetProjectBoardsQuery } from 'redux/apiSlices/board.slice';
import { useGetProjectDocsQuery } from 'redux/apiSlices/doc.slice';
import toolsEmptyImage from 'assets/images/tools_empty.svg';
import { Box, CircularProgress, Stack } from '@mui/material';
import { useGetProjectEventsQuery } from 'redux/apiSlices/events.slice';
import { IEventResourceType } from 'shared/models/event.model';
import AllBoards from './AllBoards';
import AllDocs from './AllDocs';
import AddTool from '../AddTool';
import { useGetProjectChatsQuery } from '../../../../../redux/apiSlices/chats.slice';
import AllChats from './AllChats';
import Changelog from './Changelog';
import { useGetCustomToolsQuery } from '../../../../../redux/apiSlices/customTool.slice';
import AllCustomTools from './AllCustomTools';

const ActiveTools: FC<{ projectId: string }> = ({ projectId }) => {
  const gridRef = useRef<HTMLDivElement>(null);

  const { data: boards, isLoading: boardsLoading } =
    useGetProjectBoardsQuery(projectId);
  const { data: docs, isLoading: docsLoading } =
    useGetProjectDocsQuery(projectId);
  const { data: chats, isLoading: chatsLoading } =
    useGetProjectChatsQuery(projectId);
  const { data: customTools, isLoading: customToolsLoading } =
    useGetCustomToolsQuery(projectId);
  const { data: events, isLoading: eventsLoading } = useGetProjectEventsQuery(
    { projectId, limitCount: 3 },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const emptyBoards = !boardsLoading && !boards?.length;
  const emptyDocs = !docsLoading && !docs?.length;
  const emptyChats = !chatsLoading && !chats?.length;
  const emptyCustomTools = !customToolsLoading && !customTools?.length;
  const isInitialEvent =
    (events?.totalCount || 0) < 2 &&
    events?.data.every(
      event => event.eventResourceType === IEventResourceType.PROJECT,
    );
  const isLoading =
    boardsLoading ||
    docsLoading ||
    chatsLoading ||
    eventsLoading ||
    customToolsLoading;

  if (isLoading) {
    return (
      <Stack justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Grid
      ref={gridRef}
      container
      height={1}
      alignItems="stretch"
      justifyContent="center"
    >
      {emptyBoards &&
      emptyDocs &&
      emptyChats &&
      emptyCustomTools &&
      isInitialEvent ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={1}
        >
          <img src={toolsEmptyImage} alt="empty tools" />
          <AddTool projectId={projectId} isMobileView={false} />
        </Box>
      ) : (
        <>
          {boards?.length ? <AllBoards boards={boards} /> : null}
          {docs?.length ? <AllDocs projectId={projectId} docs={docs} /> : null}
          {chats?.length ? <AllChats chats={chats} /> : null}
          {customTools?.length ? (
            <AllCustomTools customTools={customTools} />
          ) : null}
          <Changelog projectId={projectId} />
        </>
      )}
    </Grid>
  );
};

export default ActiveTools;
