import { firestore } from 'firebaseServices/firebase.config';
import { getColumnConverter } from 'data/converters/board.converter';
import { IColumnUI } from 'data/types/board.type';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { IColumnDTO } from 'shared/models/board.model';
import {
  EFirestoreCollectionPaths,
  EProjectSubCollectionPaths,
} from 'shared/types/FirestoreCollections';

// TODO: lets create a directory for firebase listeners
interface IProps {
  projectId: string;
  boardId: string;
}
export type IColumnsState = IColumnUI[];
const useListenBoardColumns = ({ boardId, projectId }: IProps) => {
  const [columns, setColumns] = useState<IColumnsState>([]);

  useEffect(() => {
    const ref = collection(
      firestore,
      EFirestoreCollectionPaths.PROJECTS,
      projectId,
      EProjectSubCollectionPaths.BOARDS,
      boardId,
      EProjectSubCollectionPaths.COLUMNS,
    );
    const q = query(ref, orderBy('order', 'asc'));
    const unsubscribe = onSnapshot(q, querySnapshot => {
      if (!querySnapshot.empty) {
        setColumns(
          querySnapshot.docs.map(snapshot =>
            getColumnConverter.fromDb({
              ...(snapshot.data() as IColumnDTO),
              documentId: snapshot.id,
            }),
          )
        );
      }
    });
    return () => {
      unsubscribe();
    };
  }, [projectId, boardId]);

  return { columns, setColumns };
};

export default useListenBoardColumns;
