import { useEffect, useState, useRef } from 'react';
import { TextField, Typography } from '@mui/material';
import { typographyEllipsisStyles } from 'components/Base/Typography/styles';
import { useSnackbar } from 'notistack';
import { TBreadcrumbItem } from './BreadcrumbNavigation.types';

type TEditableLastItemProps = {
  initialValue: string;
  onUpdate?: TBreadcrumbItem['onUpdateText'];
};

const EditableLastItem = ({
  initialValue,
  onUpdate,
}: TEditableLastItemProps) => {
  const isEditable = !!onUpdate;
  const { enqueueSnackbar } = useSnackbar();

  const [editMode, setEditMode] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [width, setWidth] = useState<number | undefined>();
  const typographyRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (typographyRef.current) {
      setWidth(typographyRef.current.offsetWidth);
    }
  }, [inputValue, editMode]);

  const openEditMode = () => {
    if (isEditable) {
      setEditMode(true);
    }
  };

  const closeEditMode = () => {
    setEditMode(false);
  };

  const onUpdateTitle = async () => {
    const newTitle = inputValue.trim();
    if (newTitle === initialValue || !newTitle) {
      closeEditMode();
      setInputValue(initialValue);
    } else {
      if (onUpdate) {
        try {
          await onUpdate(newTitle);
        } catch (error) {
          setInputValue(initialValue);
          enqueueSnackbar('Something went wrong', {
            variant: 'error',
          });
        }
      }
      closeEditMode();
    }
  };

  return editMode && isEditable ? (
    <TextField
      fullWidth
      variant="standard"
      value={inputValue}
      onChange={e => {
        setInputValue(e.target.value);
      }}
      autoFocus
      onBlur={onUpdateTitle}
      onKeyDown={async event => {
        if (event.key === 'Enter') {
          await onUpdateTitle();
        }
      }}
      sx={{
        '.MuiInputBase-root': {
          fontSize: 'inherit',
          width: width ? `${width}px` : 'auto',
        },
      }}
    />
  ) : (
    <Typography
      ref={typographyRef}
      sx={{
        ...typographyEllipsisStyles,
        minWidth: '100px',
      }}
      fontSize="inherit"
      color="text.primary"
      onClick={openEditMode}
    >
      {inputValue}
    </Typography>
  );
};

export default EditableLastItem;
