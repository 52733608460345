import { firebaseErrorMapper } from 'data/messages/messages';
import { TRtkErrorType } from 'data/types/general.types';
import { FirebaseError } from 'firebase/app';

type AsyncFn = (...args: any[]) => Promise<any>;

export const queryFnWrapper = async (fn: AsyncFn, ...params: any[]) => {
  try {
    const result = await fn(...params);
    return { data: result };
  } catch (error) {
    const err = error as FirebaseError;
    console.log(err, 'error from firebase');

    const returnError = {
      data: {
        message: firebaseErrorMapper(err),
      },
    } as TRtkErrorType;

    return {
      error: returnError || 'An unknown error occurred',
    };
  }
};
