import { FC, useRef, useState } from 'react';
import { Add, Done, Close } from '@mui/icons-material';
import { Box, IconButton, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FirebaseError } from 'firebase/app';
import { useCreateNewBoardColumnMutation } from 'redux/apiSlices/board.slice';
import { errorMessages, successMessages } from 'data/messages/messages';

const AddColumn: FC<{
  projectId: string;
  boardId: string;
  order: number;
  afterAdd?: () => void;
}> = ({ projectId, boardId, order, afterAdd }) => {
  const { enqueueSnackbar } = useSnackbar();
  const inputRef = useRef<HTMLInputElement>(null);
  const [editMode, setEditMode] = useState(false);
  const [createColumn, { isLoading }] = useCreateNewBoardColumnMutation();

  const openEditMode = () => {
    setEditMode(true);
  };
  const closeEditMode = () => {
    setEditMode(false);
  };

  const onColumnAdd = async () => {
    try {
      const inputValue = inputRef.current?.value;
      if (inputValue) {
        await createColumn({
          title: inputValue,
          boardId,
          order,
          projectId,
        });
        enqueueSnackbar(successMessages.toast, {
          variant: 'success',
        });
      }
      closeEditMode();
      if (afterAdd) afterAdd();
    } catch (err) {
      const error = err as FirebaseError;
      enqueueSnackbar(error.message || errorMessages.default, {
        variant: 'error',
      });
    }
  };

  return (
    <>
      {editMode ? (
        <Box paddingTop={1}>
          <TextField
            inputRef={inputRef}
            placeholder="Type"
            autoFocus
            label="Name"
            size="medium"
            sx={{ minWidth: '120px' }}
          />
          <Box display="flex" justifyContent="end" gap="5px">
            <IconButton onClick={onColumnAdd} disabled={isLoading}>
              <Done />
            </IconButton>
            <IconButton onClick={closeEditMode}>
              <Close />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <IconButton onClick={openEditMode} sx={{ height: 'fit-content' }}>
          <Add />
        </IconButton>
      )}
    </>
  );
};

export default AddColumn;
