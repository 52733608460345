import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import DetailsPageWrapper from 'components/DetailsPageWrapper';
import { useParams, useSearchParams } from 'react-router-dom';
import { useGetAuthUserQuery } from 'redux/apiSlices/authUser.slice';
import { EDocTypes } from 'shared/models/doc.model';
import { useDropzone } from 'react-dropzone';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import useDocDetailsBreadcrumb from 'hooks/BreadcrumbItems/useDocDetailsBreadcrumb';
import UploadFilesInfo from 'components/UploadFiles/UploadFilesInfo';
import { useProjectDocItemsListener } from 'firebaseServices/listeners/Docs/useProjectDocItemsListener';
import { useGetProjectByIdQuery } from 'redux/apiSlices/project.slice';
import { uploadFileConfig } from 'components/UploadFiles/uploadFile.config';
import useUploadFileInDocOnDrop from 'components/UploadFiles/useUploadFileInDocOnDrop';
import { useMoveDocItemMutation } from 'redux/apiSlices/doc.slice';
import { useSnackbar } from 'notistack';
import { TRtkErrorType } from 'data/types/general.types';
import { errorMessages } from 'data/messages/messages';
import { Box, Stack } from '@mui/material';
import docsEmptyImage from 'assets/images/docs_empty.svg';
import AddDocItem from './AddDocItem';
import FileCard from './FileCard';
import FolderCard from './FolderCard';
import CustomDocCard from './CustomDocCard';
import UploadFileOnDrop from './components/UploadFileOnDrop.tsx';
import DragDropDocItem from './components/DragDropDocItem';

const DocCardMapper: Record<EDocTypes, any> = {
  [EDocTypes.FILE]: FileCard,
  [EDocTypes.FOLDER]: FolderCard,
  [EDocTypes.CUSTOM]: CustomDocCard,
};

const DocsDetailsPage = () => {
  const { data: authData } = useGetAuthUserQuery();
  const params = useParams<{ projectId: string; docId: string }>();
  const projectId = params.projectId || '';
  const docId = params.docId || '';

  const { enqueueSnackbar } = useSnackbar();

  const [searchParams, setSearchParams] = useSearchParams();
  const foldersLocations = searchParams.get('location') || 'root';
  const currentfolderId = foldersLocations.split('/').pop() || 'root';

  const { data: projectData } = useGetProjectByIdQuery(projectId, {
    skip: !projectId,
  });

  const {
    data: docItems,
    isLoading,
    moveDocItemInState,
  } = useProjectDocItemsListener({
    docId,
    projectId,
    parentFolderId: currentfolderId,
    currentUserId: authData?.id || '',
  });

  const enterInFolder = (folderId: string) => {
    const newLocation = `${foldersLocations}/${folderId}`;
    setSearchParams({ location: newLocation });
  };

  const breadcrumbItems = useDocDetailsBreadcrumb({
    projectId,
    docId,
  });

  const { onDrop } = useUploadFileInDocOnDrop({
    projectId,
    docId,
    currentLocation: currentfolderId,
  });

  const { getInputProps, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    ...uploadFileConfig,
    onDrop,
  });

  const [moveDocItem] = useMoveDocItemMutation();

  const onDragEnd = async (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) return;
    if (destination.droppableId === source.droppableId) return;

    const destionationFolder = docItems?.find(
      item => item.documentId === destination.droppableId,
    );

    if (!destionationFolder) return;
    const docItemId = source.droppableId;
    const destinationFolderId = destionationFolder.documentId;
    try {
      moveDocItemInState({
        destinationFolderId,
        docItemId,
      });
      await moveDocItem({
        projectId,
        docId,
        docItemId,
        destinationFolderId,
      }).unwrap();
    } catch (err) {
      const error = err as TRtkErrorType;
      enqueueSnackbar(error?.data.message || errorMessages.default, {
        variant: 'error',
      });
    }
  };

  return (
    <DetailsPageWrapper
      header={{
        breadcrumbItems,
        leftAction: (
          <AddDocItem
            projectId={projectId}
            docId={docId}
            currentLocation={currentfolderId}
          />
        ),
      }}
      loading={isLoading}
      bodyBgColor={projectData?.color}
    >
      <Stack
        direction="column"
        justifyContent="space-between"
        height="100%"
        width="100%"
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Grid
            container
            alignItems="stretch"
            justifyContent="center"
            height={1}
          >
            {docItems?.length && !isLoading ? (
              docItems.map((item, index) => {
                const DocCard = DocCardMapper[item.type];
                return (
                  <Grid
                    minWidth={300}
                    minHeight={300}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={2.4}
                    key={item.documentId}
                    padding={1}
                  >
                    <DragDropDocItem docItem={item} index={index}>
                      <DocCard
                        docItem={item}
                        docId={docId}
                        projectId={projectId}
                        onContentClick={() => enterInFolder(item.documentId)}
                      />
                    </DragDropDocItem>
                  </Grid>
                );
              })
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                gap={2}
              >
                <img src={docsEmptyImage} alt="empty docs" />
                <AddDocItem
                  projectId={projectId}
                  docId={docId}
                  currentLocation={currentfolderId}
                  addText="Add New Folder or Blank"
                />
              </Box>
            )}
          </Grid>
        </DragDropContext>

        <UploadFileOnDrop
          isDragActive={isDragActive}
          getInputProps={getInputProps}
        />
      </Stack>
      <UploadFilesInfo />
    </DetailsPageWrapper>
  );
};

export default DocsDetailsPage;
