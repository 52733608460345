import { firestore, limitToLast } from 'firebaseServices/firebase.config';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import {
  EFirestoreCollectionPaths,
  EProjectSubCollectionPaths,
} from 'shared/types/FirestoreCollections';
import { IMessageUi } from 'data/types/message.types';
import { getMessageConverter } from 'data/converters/message.converter';
import { IMessageDTO } from 'shared/models/message.model';

interface IProps {
  projectId: string;
  chatId: string;
  limitSize?: number;
}

const useListenChatMessages = ({ chatId, projectId, limitSize }: IProps) => {
  const ref = collection(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    projectId,
    EProjectSubCollectionPaths.CHATS,
    chatId,
    EProjectSubCollectionPaths.MESSAGES,
  );
  const q = limitSize
    ? query(ref, orderBy('creationDate', 'asc'), limitToLast(limitSize))
    : query(ref, orderBy('creationDate', 'asc'));

  const [messages, setMessages] = useState<IMessageUi[] | null>(null);

  useEffect(() => {
    const unsubscribe = onSnapshot(q, querySnapshot => {
      if (!querySnapshot.empty) {
        setMessages(
          querySnapshot.docs.map(snapshot => {
            return getMessageConverter.fromDb({
              ...(snapshot.data() as IMessageDTO),
              documentId: snapshot.id,
            });
          }),
        );
      } else setMessages([]);
    });
    return () => {
      unsubscribe();
    };
  }, [chatId, projectId]);

  return { messages };
};

export default useListenChatMessages;
