import { forwardRef, useState } from 'react';
import { IconButton, InputAdornment, TextFieldProps } from '@mui/material';
import TextFieldMui from '@mui/material/TextField';
import { Visibility, VisibilityOff } from '@mui/icons-material';

type TPasswordTextFieldProps = Omit<TextFieldProps, 'type'>;

const PasswordTextField = forwardRef((props: TPasswordTextFieldProps, ref) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <TextFieldMui
      {...props}
      inputRef={ref}
      type={passwordVisible ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setPasswordVisible(!passwordVisible)}
              onMouseDown={e => e.preventDefault()}
            >
              {passwordVisible ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
        ...props.InputProps,
      }}
    />
  );
});

export default PasswordTextField;
