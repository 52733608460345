import { FC } from 'react';
import {
  IBoardEvent,
  IChatUpdateData,
  IEventActionType,
} from 'shared/models/event.model';
import OldNewValueContent from '../../components/OldNewValueContent';

type TChatEventsProps = {
  eventData: IBoardEvent;
  previewMode?: boolean;
};

const ChatEvents: FC<TChatEventsProps> = ({ eventData, previewMode }) => {
  if (eventData.eventActionType === IEventActionType.UPDATE) {
    const updateColumnData = eventData.data as IChatUpdateData;
    const { name } = updateColumnData;
    return (
      <>
        {name && (
          <OldNewValueContent
            prefixText="Title"
            previewMode={previewMode}
            {...updateColumnData.name}
          />
        )}
      </>
    );
  }
  return <></>;
};

export default ChatEvents;
