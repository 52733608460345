import { FC } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';

type TCreateShortcutButtonProps = {
  onClick: () => void;
};

const CreateShortcutButton: FC<TCreateShortcutButtonProps> = ({ onClick }) => {
  return (
    <Tooltip title="Create shortcut">
      <IconButton onClick={onClick}>
        <BookmarkAddIcon />
      </IconButton>
    </Tooltip>
  );
};

export default CreateShortcutButton;
