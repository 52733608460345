import { FormControl, TextField } from '@mui/material';
import React, { FC } from 'react';
import { TRtkErrorType } from 'data/types/general.types';
import { errorMessages, successMessages } from 'data/messages/messages';
import { SubmitHandler, useForm } from 'react-hook-form';
import { isEqual } from 'lodash';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import Modal from 'components/Base/Modal';
import { LinkSchema } from '../../../data/validation/ToolsSchema';

type TLinkModalProps = {
  initialLink?: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalTitle: string;
  submitButtonTitle?: string;
  onSubmitModal: (name: string) => Promise<void>;
  validationSchema?: any;
};

const LinkModal: FC<TLinkModalProps> = ({
  initialLink,
  setOpen,
  open,
  modalTitle,
  submitButtonTitle = 'Save',
  onSubmitModal,
  validationSchema = LinkSchema,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    handleSubmit,
    formState: { errors, defaultValues, isSubmitting },
    register,
  } = useForm<{
    link: string;
  }>({
    defaultValues: {
      link: initialLink || '',
    },
    resolver: yupResolver(validationSchema),
  });

  const onClose = () => {
    setOpen(false);
  };

  const onSubmit: SubmitHandler<{ link: string }> = async values => {
    if (isEqual(values, defaultValues)) {
      onClose();
      return;
    }
    try {
      await onSubmitModal(values.link);
      enqueueSnackbar(successMessages.toast, {
        variant: 'success',
      });
      onClose();
    } catch (err) {
      const error = err as TRtkErrorType;
      enqueueSnackbar(error?.data?.message || errorMessages.default, {
        variant: 'error',
      });
    }
  };
  return (
    <Modal
      open={open}
      handleClose={onClose}
      title={modalTitle}
      submitButtonProps={{
        disabled: isSubmitting,
        onClick: handleSubmit(onSubmit),
        text: submitButtonTitle,
      }}
      contentProps={{ sx: { minWidth: '300px' } }}
    >
      <FormControl component="form" fullWidth onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Link"
          variant="standard"
          error={!!errors.link}
          helperText={errors.link && errors.link.message}
          required
          autoFocus
          {...register('link')}
        />
      </FormControl>
    </Modal>
  );
};

export default LinkModal;
