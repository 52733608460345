import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddBoardGroupSchema } from 'data/validation/BoardSchemas';
import { useSnackbar } from 'notistack';
import { errorMessages, successMessages } from 'data/messages/messages';
import { useAddBoardMutation } from 'redux/apiSlices/board.slice';
import { TRtkErrorType } from 'data/types/general.types';
import Modal from 'components/Base/Modal';
import AddBoardModalForm from './AddBoardModalForm';

const AddBoardModal: FC<{
  open: boolean;
  closeModal: () => void;
  projectId: string;
}> = ({ open, closeModal, projectId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [addBoard, { isLoading }] = useAddBoardMutation();
  const { handleSubmit, formState, register } = useForm<{
    name: string;
  }>({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(AddBoardGroupSchema),
  });

  const onClose = () => {
    closeModal();
  };

  const onSubmit: SubmitHandler<{ name: string }> = async values => {
    try {
      await addBoard({ projectId, name: values.name }).unwrap();
      enqueueSnackbar(successMessages.toast, {
        variant: 'success',
      });
      closeModal();
    } catch (err) {
      const error = err as TRtkErrorType;
      enqueueSnackbar(error?.data?.message || errorMessages.default, {
        variant: 'error',
      });
    }
  };
  return (
    <Modal
      open={open}
      handleClose={onClose}
      title="New Card Board"
      submitButtonProps={{
        disabled: formState.isSubmitting,
        loading: isLoading,
        text: 'Create',
        onClick: handleSubmit(onSubmit),
      }}
      contentProps={{ sx: { minWidth: '300px' } }}
    >
      <AddBoardModalForm
        onSubmit={handleSubmit(onSubmit)}
        formState={formState}
        register={register}
      />
    </Modal>
  );
};

export default AddBoardModal;
