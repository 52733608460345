import { Avatar, Menu, MenuItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useGetAuthUserQuery } from 'redux/apiSlices/authUser.slice';
import React, { FC, useState } from 'react';
import { clearAllCachedData } from 'redux/store';
import { getFirstLetters, stringToColor, isDemo } from 'utils/utils';
import { auth } from 'firebaseServices/firebase.config';

type TProfileMenuProps = {
  size: number;
};

const ProfileMenu: FC<TProfileMenuProps> = ({ size }) => {
  const { data: userData } = useGetAuthUserQuery();

  const profileMenu: {
    name: string;
    onClick: () => void;
  }[] = [
    {
      name: 'Logout',
      onClick: () => {
        auth.signOut();
        clearAllCachedData();
      },
    },
  ];
  const shortName = getFirstLetters(userData?.name);
  const avatarColor = stringToColor(userData?.name);

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (isDemo) return;
    setAnchorElUser(event.currentTarget);
  };

  return (
    <>
      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
        <Avatar
          alt={userData?.name}
          sx={{
            bgcolor: avatarColor,
            width: size,
            height: size,
            fontSize: size / 2,
          }}
        >
          {shortName}
        </Avatar>
      </IconButton>
      <Menu
        // sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={() => {
          setAnchorElUser(null);
        }}
      >
        {profileMenu.map(item => (
          <MenuItem
            key={item.name}
            onClick={() => {
              item.onClick();
              setAnchorElUser(null);
            }}
          >
            <Typography textAlign="center">{item.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ProfileMenu;
