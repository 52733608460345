import { useState } from 'react';
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from 'firebase/auth';
import { auth } from 'firebaseServices/firebase.config';
import { FirebaseError } from 'firebase/app';
import { errorMessages, firebaseErrorMapper } from 'data/messages/messages';
import { TRtkErrorType } from 'data/types/general.types';
import { useCreateUserDocumentMutation } from '../../redux/apiSlices/authUser.slice';

const useCreateUserWithEmailAndPassword = () => {
  const [createUserDocument] = useCreateUserDocumentMutation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const createUser = async (
    email: string,
    password: string,
    userInfo: { name: string },
  ) => {
    setLoading(true);
    setError(null);
    setIsSuccess(false);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );
      await sendEmailVerification(userCredential.user);
      try {
        await createUserDocument({
          email,
          name: userInfo.name,
          id: userCredential.user.uid,
        }).unwrap();
        setIsSuccess(true);
      } catch (err) {
        const e = error as TRtkErrorType;
        setError(e?.data?.message || errorMessages.default);
      }
    } catch (e) {
      const mappedError = firebaseErrorMapper(e as FirebaseError);
      setError(mappedError);
    } finally {
      setLoading(false);
    }
  };
  return { createUser, loading, errorMessage: error, isSuccess };
};

export default useCreateUserWithEmailAndPassword;
