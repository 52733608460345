import { Grid } from '@mui/material';
import { FC } from 'react';
import EventContentPrefix from './EventContentPrefix';

type TOneValueContentProps = {
  prefixText?: string;
  children: React.ReactNode;
};

const OneValueContent: FC<TOneValueContentProps> = ({
  prefixText,
  children,
}) => {
  return (
    <Grid
      container
      wrap="nowrap"
      width="100%"
      direction="row"
      alignItems="center"
      spacing={2}
    >
      {prefixText && (
        <Grid item>
          <EventContentPrefix prefixText={prefixText} />
        </Grid>
      )}
      <Grid item>{children}</Grid>
    </Grid>
  );
};

export default OneValueContent;
