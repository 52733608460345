import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#B4154A',
      light: '#C61752',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FFFFFF',
      contrastText: '#120208',
    },
    text: {
      primary: '#120208',
      secondary: '#12020899',
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      'Inter',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiChip: {
      styleOverrides: {
        avatar: {
          color: 'white',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0 0 8px 0 rgb(19, 2, 7,0.16)',
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        PopperProps: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -8],
              },
            },
          ],
        },
        arrow: true,
      },
    },
  },
});

export default theme;
