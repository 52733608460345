import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  firestore,
  functions,
  getDoc,
  runTransaction,
} from 'firebaseServices/firebase.config';
import {
  EFirestoreCollectionPaths,
  EProjectSubCollectionPaths,
} from 'shared/types/FirestoreCollections';
import FirestoreOperations from 'firebaseServices/FirestoreOperations';
import { httpsCallable } from 'firebase/functions';
import { ITaskDTO, ITaskDeleteDTO } from '../../shared/models/task.model';
import {
  IAddAndUpdateTaskLabelsUI,
  IAddTaskUI,
  IUpdateTaskAssigneeUI,
  IUpdateTaskDescriptionUI,
  IUpdateTaskDueDateUI,
  IUpdateTaskEstimateUI,
  IUpdateTaskLabelsUI,
  IUpdateTaskOrder,
  IUpdateTaskStatusUI,
  IUpdateTaskTitleUI,
} from '../../data/types/task.type';
import {
  getTaskConverter,
  memberToTaskAssigneeConverter,
} from '../../data/converters/task.converter';
import { IColumnDTO } from '../../shared/models/board.model';
import { ILabelDTO } from '../../shared/models/label.model';
import { ILabelUI } from '../../data/types/label.type';

export const addTask = async ({ task }: IAddTaskUI) => {
  const tasksRef = collection(firestore, EFirestoreCollectionPaths.TASKS);
  const columnRef = doc(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    task.projectId,
    EProjectSubCollectionPaths.BOARDS,
    task.boardId,
    EProjectSubCollectionPaths.COLUMNS,
    task.columnId,
  );
  const newTask: ITaskDTO = {
    title: task.title,
    columnId: task.columnId,
    boardId: task.boardId,
    projectId: task.projectId,
  };
  const docRef = await FirestoreOperations.addDoc(tasksRef, newTask);
  await FirestoreOperations.updateDoc(columnRef, {
    tasks: arrayUnion(docRef.id),
  });
  return newTask;
};

export const updateTaskTitle = async ({
  taskId,
  title,
}: IUpdateTaskTitleUI) => {
  const taskRef = doc(firestore, EFirestoreCollectionPaths.TASKS, taskId);
  await FirestoreOperations.updateDoc(taskRef, {
    title,
  });
};

export const updateTaskAssignee = async ({
  member,
  taskId,
}: IUpdateTaskAssigneeUI) => {
  const taskRef = doc(firestore, EFirestoreCollectionPaths.TASKS, taskId);
  const assignee = member ? memberToTaskAssigneeConverter.toDb(member) : member;
  await FirestoreOperations.updateDoc(taskRef, {
    assignee,
  });
};

export const getTask = async (id: string) => {
  const taskRef = doc(firestore, EFirestoreCollectionPaths.TASKS, id);
  const snapshot = await getDoc(taskRef);
  if (snapshot.exists()) {
    return getTaskConverter.fromDb({
      ...(snapshot.data() as ITaskDTO),
      documentId: id,
    });
  }
  return undefined;
};

export const updateTaskDescription = async ({
  description,
  taskId,
}: IUpdateTaskDescriptionUI) => {
  const taskRef = doc(firestore, EFirestoreCollectionPaths.TASKS, taskId);
  await FirestoreOperations.updateDoc(taskRef, {
    description,
  });
};

export const updateTaskDueDate = async ({
  taskId,
  dueDate,
}: IUpdateTaskDueDateUI) => {
  const taskRef = doc(firestore, EFirestoreCollectionPaths.TASKS, taskId);
  await FirestoreOperations.updateDoc(taskRef, {
    dueDate,
  });
};

export const updateTaskEstimate = async ({
  taskId,
  estimate,
}: IUpdateTaskEstimateUI) => {
  const taskRef = doc(firestore, EFirestoreCollectionPaths.TASKS, taskId);
  await FirestoreOperations.updateDoc(taskRef, {
    estimate,
  });
};

export const deleteTask = async (data: ITaskDeleteDTO) => {
  // const taskRef = doc(firestore, EFirestoreCollectionPaths.TASKS, task.taskId);
  // const columnRef = doc(
  //   firestore,
  //   EFirestoreCollectionPaths.PROJECTS,
  //   task.projectId,
  //   EProjectSubCollectionPaths.BOARDS,
  //   task.boardId,
  //   EProjectSubCollectionPaths.COLUMNS,
  //   task.columnId,
  // );
  // const batch = writeBatch(firestore);
  // batch.update(columnRef, {
  //   tasks: arrayRemove(task.taskId),
  // });
  // batch.delete(taskRef);
  // await batch.commit();

  await httpsCallable(functions, 'board-deleteTask')(data);
};

export const updateTaskStatus = async ({
  destinationColumnId,
  destinationIndex,
  task,
}: IUpdateTaskStatusUI) => {
  const sourceColumnRef = doc(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    task.projectId,
    EProjectSubCollectionPaths.BOARDS,
    task.boardId,
    EProjectSubCollectionPaths.COLUMNS,
    task.columnId,
  );
  const destinationColumnRef = doc(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    task.projectId,
    EProjectSubCollectionPaths.BOARDS,
    task.boardId,
    EProjectSubCollectionPaths.COLUMNS,
    destinationColumnId,
  );
  const taskRef = doc(firestore, EFirestoreCollectionPaths.TASKS, task.taskId);
  await runTransaction(firestore, async transaction => {
    const destinationSnapshot = await transaction.get(destinationColumnRef);
    const sourceSnapshot = await transaction.get(sourceColumnRef);

    const sourceData = sourceSnapshot.data() as IColumnDTO;
    if (!sourceData?.tasks.includes(task.taskId)) {
      return;
    }

    const destinationColumnData = destinationSnapshot.data() as IColumnDTO;
    const newDestinationTasks = destinationColumnData.tasks;
    newDestinationTasks.splice(destinationIndex, 0, task.taskId);

    transaction.update(sourceColumnRef, { tasks: arrayRemove(task.taskId) });
    transaction.update(destinationColumnRef, { tasks: newDestinationTasks });
    transaction.update(taskRef, {
      columnId: destinationColumnId,
      lastModifiedBy: FirestoreOperations.getUserId(),
      lastModifiedDate: FirestoreOperations.getTimestamp(),
    });
  });
};

export const updateTaskOrder = async ({
  task,
  destinationIndex,
}: IUpdateTaskOrder) => {
  const columnRef = doc(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    task.projectId,
    EProjectSubCollectionPaths.BOARDS,
    task.boardId,
    EProjectSubCollectionPaths.COLUMNS,
    task.columnId,
  );
  const taskRef = doc(firestore, EFirestoreCollectionPaths.TASKS, task.taskId);

  await runTransaction(firestore, async transaction => {
    const columnSnapshot = await transaction.get(columnRef);

    const columnData = columnSnapshot.data() as IColumnDTO;
    const newTasks = [...columnData.tasks];
    const sourceIndex = newTasks.indexOf(task.taskId);

    newTasks.splice(sourceIndex, 1);
    newTasks.splice(destinationIndex, 0, task.taskId);

    transaction.update(columnRef, { tasks: newTasks });
  });
  // update metadata
  await FirestoreOperations.updateDoc(taskRef, {});
};

export const updateTaskLabels = async ({
  taskId,
  labels,
}: IUpdateTaskLabelsUI) => {
  const taskRef = doc(firestore, EFirestoreCollectionPaths.TASKS, taskId);
  await FirestoreOperations.updateDoc(taskRef, {
    labels,
  });
};

export const addAndUpdateTaskLabels = async ({
  taskId,
  projectId,
  newLabel,
  labelIds,
}: IAddAndUpdateTaskLabelsUI): Promise<ILabelUI> => {
  const taskRef = doc(firestore, EFirestoreCollectionPaths.TASKS, taskId);
  const labelsRef = collection(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    projectId,
    EProjectSubCollectionPaths.LABELS,
  );
  const { id: newLabelId } = await FirestoreOperations.addDoc(labelsRef, {
    title: newLabel,
  } as ILabelDTO);
  await FirestoreOperations.updateDoc(taskRef, {
    labels: [...labelIds, newLabelId],
  });
  return {
    title: newLabel,
    id: newLabelId,
  };
};
