import { Avatar, Stack, Typography } from '@mui/material';
import BadgeOwner from 'components/BadgeOwner';
import { FC } from 'react';
import { EUserRole } from 'shared/types/generic.types';

type TMemberPopoverContentProps = {
  name: string;
  email: string;
  avatar: {
    bgColor: string;
    firstLetters: string;
  };
  role?: EUserRole;
};

const MemberPopoverContent: FC<TMemberPopoverContentProps> = ({
  avatar,
  name,
  email,
  role,
}) => {
  const { bgColor, firstLetters } = avatar;

  return (
    <Stack
      spacing={1}
      direction="column"
      alignItems="center"
      padding={3}
      paddingTop={0}
    >
      <BadgeOwner
        {...(!role && {
          invisible: true,
        })}
        role={role}
        badgeSize="large"
      >
        <Avatar
          sx={theme => ({
            bgcolor: bgColor,
            width: 80,
            height: 80,
            fontSize: theme.typography.h4.fontSize,
          })}
        >
          {firstLetters}
        </Avatar>
      </BadgeOwner>
      <Typography variant="h5">{name}</Typography>
      <Typography variant="subtitle1">{email}</Typography>
    </Stack>
  );
};

export default MemberPopoverContent;
