import {
  ProjectEventsCollectionQuery,
  TGetAllEventsQueryProps,
} from 'firebaseServices/queryFunctions/eventQueryFunctions';
import { useMemo } from 'react';
import { TProjectEventDTO } from 'shared/models/event.model';
import { useFirebaseCollectionListener } from '../useFirebaseCollectionListener';

export const useProjectEventsListener = (props: TGetAllEventsQueryProps) => {
  const { projectId } = props;

  const documentQuery = useMemo(
    () => ProjectEventsCollectionQuery(props),
    [projectId],
  );
  const { snapshotData, isLoading, error } =
    useFirebaseCollectionListener<TProjectEventDTO>(documentQuery);

  const data: TProjectEventDTO[] | null = snapshotData
    ? snapshotData.map(item => ({
        ...item,
        documentId: item.documentId,
      }))
    : null;

  return { data, isLoading, error };
};
