import React, { FC, useCallback, useEffect, useState } from 'react';
import EditorJS, { OutputData } from '@editorjs/editorjs';
import { isEqual } from 'lodash';
import { Grid } from '@mui/material';
import { createReactEditorJS } from 'react-editor-js';
import { EDITOR_JS_TOOLS, styleOverides } from './tools';

interface TEditorProps {
  initialData: OutputData;
  readOnly?: boolean;
  editorRef: React.MutableRefObject<EditorJS | null>;
  onSave: () => Promise<void>;
}

const Editor: FC<TEditorProps> = ({
  initialData,
  readOnly,
  editorRef,
  onSave,
}) => {
  const ReactEditorJS = createReactEditorJS();
  const [editorData, setEditorData] = useState<OutputData>(initialData);

  const handleInitialize = useCallback((instance: any) => {
    editorRef.current = instance;
  }, []);

  useEffect(() => {
    const checkAndUpdateData = async () => {
      if (!editorRef.current) {
        setEditorData(initialData);
        return;
      }
      try {
        const currentData = await editorRef.current.save();
        if (!isEqual(currentData.blocks, initialData.blocks)) {
          setEditorData(initialData);
        }
      } catch (error) {
        console.error('Failed to save data:', error);
      }
    };
    checkAndUpdateData();
  }, [initialData, editorRef]);

  useEffect(() => {
    const renderEditorData = async () => {
      if (editorRef.current && editorRef.current.render) {
        try {
          await editorRef.current.render(editorData);
        } catch (error) {
          console.error('EditorJS render error:', error);
        }
      }
    };
    renderEditorData();
  }, [editorData, editorRef]);

  return (
    <Grid sx={styleOverides}>
      <ReactEditorJS
        onChange={async () => {
          await onSave();
        }}
        placeholder="Let's write an awesome document!"
        minHeight={50}
        readOnly={readOnly}
        onInitialize={handleInitialize}
        defaultValue={editorData}
        tools={EDITOR_JS_TOOLS}
      />
    </Grid>
  );
};

export default Editor;
