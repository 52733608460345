import { InsertDriveFileOutlined } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { errorMessages } from 'data/messages/messages';
import { TRtkErrorType } from 'data/types/general.types';
import { useSnackbar } from 'notistack';
import { FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetAuthUserQuery } from 'redux/apiSlices/authUser.slice';
import { useAddBlankCustomDocMutation } from 'redux/apiSlices/doc.slice';
import { PrivateRoutesPaths } from 'routes/Routes.types';

type TAddCustomDocProps = {
  projectId: string;
  docId: string;
  currentLocation: string;
};

const AddCustomDoc: FC<TAddCustomDocProps> = ({
  projectId,
  docId,
  currentLocation,
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { data: userData } = useGetAuthUserQuery();
  const [searchParams] = useSearchParams();
  const foldersLocations = searchParams.get('location') || 'root';

  const [addBlankCustomDoc, { isLoading }] = useAddBlankCustomDocMutation();

  const handleAddBlankCustomDoc = async () => {
    if (!userData) {
      // TODO: navigate to login page
      return;
    }
    try {
      const customDocId = await addBlankCustomDoc({
        projectId,
        docId,
        location: currentLocation,
        creatorId: userData?.id,
      }).unwrap();

      const customDocDetailPagePath =
        PrivateRoutesPaths.CustomDocDetails.replace(':projectId', projectId)
          .replace(':docId', docId)
          .replace(':customDocId', customDocId);
      navigate(`/${customDocDetailPagePath}?location=${foldersLocations}`);
    } catch (error) {
      const e = error as TRtkErrorType;
      enqueueSnackbar(e?.data?.message || errorMessages.default, {
        variant: 'error',
      });
    }
  };

  return (
    <MenuItem onClick={handleAddBlankCustomDoc} disabled={isLoading}>
      <ListItemIcon>
        <InsertDriveFileOutlined fontSize="small" />
      </ListItemIcon>
      <ListItemText>Blank Document</ListItemText>
    </MenuItem>
  );
};

export default AddCustomDoc;
