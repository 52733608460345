import { firestoreReference } from 'firebaseServices/FirestoreReferenceManager';
import {
  addAddCustomDocViewerConverter,
  getDocConverter,
  getDocItemConverter,
} from 'data/converters/doc.converter';
import {
  IDocUI,
  ICreateNewDocUI,
  TDocItemUI,
  IAddCustomDocViewerUI,
} from 'data/types/doc.types';
import {
  deleteField,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import {
  defaultTitle,
  stringifyTableData,
} from 'pages/privatePages/CustomDocDetailsPage/CustomDoc/helpers';
import {
  EDocTypes,
  ICustomDocDTO,
  IDocDTO,
  IDocDeleteDTO,
  IDocItemDeleteDTO,
  TDocItemDTO,
} from 'shared/models/doc.model';
import FirestoreOperations from 'firebaseServices/FirestoreOperations';
import { httpsCallable } from 'firebase/functions';
import { functions } from 'firebaseServices/firebase.config';
import OpenAI from 'openai';

// const checkFolderNameExists = async (
//   name: string,
//   location: string,
//   collectionRef: CollectionReference<DocumentData>,
// ) => {
//   const q = query(
//     collectionRef,
//     where('name', '==', name),
//     where('location', '==', location),
//     where('type', '==', EDocTypes.FOLDER),
//   );
//   const querySnapshot = await getDocs(q);

//   if (!querySnapshot.empty) {
//     throw new Error(errorMessages.folderNameExists);
//   }
// };

export const isAllowedDoc = (docData: TDocItemUI, userId: string): boolean => {
  // Filter out unpublished custom documents where the creator is not the current user
  if (docData.type === EDocTypes.CUSTOM) {
    return docData.isPublished || docData.creatorId === userId;
  }
  return true;
};

export const addNewDoc = async ({ projectId, name }: ICreateNewDocUI) => {
  const docsRef = firestoreReference.projectDocs_CollectionRef({ projectId });

  const newDoc: IDocDTO = {
    name,
    projectId,
  };
  await FirestoreOperations.addDoc(docsRef, newDoc);
  return newDoc;
};

export const getProjectAllDocs = async (
  projectId: string,
): Promise<IDocUI[] | null> => {
  const docsRef = firestoreReference.projectDocs_CollectionRef({ projectId });

  const querySorted = query(docsRef, orderBy('creationDate', 'desc'));
  const docSnapshot = await getDocs(querySorted);

  if (docSnapshot.empty || docSnapshot.docs.every(d => !d.exists)) {
    return null;
  }
  const docs = docSnapshot.docs.map(document => {
    return getDocConverter.fromDb({
      ...(document.data() as IDocDTO),
      documentId: document.id,
    });
  });
  return docs;
};

export type TGetDocByIdProps = {
  projectId: string;
  docId: string;
};

export const getDocById = async (
  data: TGetDocByIdProps,
): Promise<IDocUI | null> => {
  const { projectId, docId } = data;
  const docRef = firestoreReference.projectDoc_DocRef({
    projectId,
    docId,
  });

  const docDocument = await getDoc(docRef);

  if (!docDocument.exists()) {
    throw new Error('Document not found');
  }

  const docData = getDocConverter.fromDb({
    ...(docDocument.data() as IDocDTO),
    documentId: docDocument.id,
  });

  return docData;
};

export type TAddFileDocProps = {
  projectId: string;
  docId: string;
  file: File;
  location: string;
  storagePath: string;
  url: string;
};

export const addFileDoc = async (data: TAddFileDocProps) => {
  const { projectId, docId, file, location, storagePath, url } = data;

  const collectionRef = firestoreReference.projectDocItems_CollectionRef({
    docId,
    projectId,
  });

  const name = file.name.split('.').shift() || '';
  const extension = file.name.split('.').pop() || '';

  const newDoc: TDocItemDTO = {
    name,
    extension,
    url,
    storagePath,
    type: EDocTypes.FILE,
    parentFolderId: location,
  };
  await FirestoreOperations.addDoc(collectionRef, newDoc);
};

export type TAddFolderDocProps = {
  projectId: string;
  docId: string;
  name: string;
  location: string;
};

export const addFolderToDoc = async (data: TAddFolderDocProps) => {
  const { projectId, docId, name, location } = data;
  const collectionRef = firestoreReference.projectDocItems_CollectionRef({
    docId,
    projectId,
  });

  // await checkFolderNameExists(name, location, collectionRef);

  const newFolder: TDocItemDTO = {
    name,
    type: EDocTypes.FOLDER,
    parentFolderId: location,
    creationDate: Date.now(),
  };
  await FirestoreOperations.addDoc(collectionRef, newFolder);
};

export type TAddBlankCustomDocProps = {
  projectId: string;
  docId: string;
  location: string;
  creatorId: string;
};

export const addBlankCustomDoc = async (
  data: TAddBlankCustomDocProps,
): Promise<string> => {
  const { projectId, docId, location, creatorId } = data;
  const collectionRef = firestoreReference.projectDocItems_CollectionRef({
    docId,
    projectId,
  });

  const newBlankCustomDoc: ICustomDocDTO = {
    name: defaultTitle,
    type: EDocTypes.CUSTOM,
    content: {
      time: Date.now(),
      blocks: [],
    },
    isPublished: false,
    creatorId,
    parentFolderId: location,
    creationDate: Date.now(),
    viewers: {},
  };

  const newCreatedDoc = await FirestoreOperations.addDoc(
    collectionRef,
    newBlankCustomDoc,
  );
  return newCreatedDoc.id;
};

export type TUpdateCustomDocContentProps = {
  projectId: string;
  docId: string;
  customDocId: string;
  content: ICustomDocDTO['content'];
};

export const updateCustomDocContent = async (
  data: TUpdateCustomDocContentProps,
) => {
  const { projectId, docId, customDocId, content } = data;
  const docRef = firestoreReference.projectDocItem_DocRef({
    projectId,
    docId,
    docItemId: customDocId,
  });

  const stringifiedContent = stringifyTableData(content);
  const updateData: Partial<Pick<ICustomDocDTO, 'content' | 'name'>> = {
    content: stringifiedContent,
  };
  await FirestoreOperations.updateDoc(docRef, updateData);
};

export type TUpdateCustomDocPublishFlagProps = {
  projectId: string;
  docId: string;
  customDocId: string;
  isPublished: boolean;
};

export const updateCustomDocPublishFlag = async (
  data: TUpdateCustomDocPublishFlagProps,
) => {
  const { projectId, docId, customDocId, isPublished } = data;
  const docRef = firestoreReference.projectDocItem_DocRef({
    projectId,
    docId,
    docItemId: customDocId,
  });
  await FirestoreOperations.updateDoc(docRef, { isPublished });
};

export type TGetDocItemsProps = {
  projectId: string;
  docId: string;
  parentFolderId?: string;
  limit?: number;
  onlyFolders?: boolean;
  currentUserId: string;
};

export const getAllDocItems = async (
  data: TGetDocItemsProps,
): Promise<TDocItemUI[] | null> => {
  const { projectId, docId, parentFolderId, onlyFolders } = data;
  const docsRef = firestoreReference.projectDocItems_CollectionRef({
    projectId,
    docId,
  });

  let documentQuery = query(docsRef, orderBy('name'));

  if (data.limit) {
    documentQuery = query(documentQuery, limit(data.limit));
  }
  if (parentFolderId) {
    documentQuery = query(
      docsRef,
      where('parentFolderId', '==', parentFolderId),
    );
  }
  if (onlyFolders) {
    documentQuery = query(docsRef, where('type', '==', EDocTypes.FOLDER));
  }

  const docSnapshot = await getDocs(documentQuery);

  if (docSnapshot.empty || docSnapshot.docs.every(d => !d.exists)) {
    return null;
  }

  const docItems = docSnapshot.docs.map(document => {
    return getDocItemConverter.fromDb({
      ...(document.data() as TDocItemDTO),
      documentId: document.id,
    });
  });

  return docItems.filter(docItem => isAllowedDoc(docItem, data.currentUserId));
};

export type TGetDocItemByIdProps = {
  projectId: string;
  docId: string;
  docItemId: string;
};

export const getDocItemById = async (data: TGetDocItemByIdProps) => {
  const { projectId, docId, docItemId } = data;
  const docItemRef = firestoreReference.projectDocItem_DocRef({
    projectId,
    docId,
    docItemId,
  });

  const docItemDocument = await getDoc(docItemRef);

  if (!docItemDocument.exists()) {
    throw new Error('Doc item not found');
  }

  const docItemData = getDocItemConverter.fromDb({
    ...(docItemDocument.data() as TDocItemDTO),
    documentId: docItemDocument.id,
  });

  return docItemData;
};

export type TRemoveDocItemProps = IDocItemDeleteDTO;
// {
//   projectId: string;
//   docId: string;
//   docItemId: string;
// };

export const removeDocItem = async (data: TRemoveDocItemProps) => {
  // const { projectId, docId, docItemId } = data;
  // const docItemRef = firestoreReference.projectDocItem_DocRef({
  //   docId,
  //   projectId,
  //   docItemId,
  // });
  // await FirestoreOperations.deleteDoc(docItemRef);
  await httpsCallable(functions, 'docs-deleteDocsItem')(data);
};

export type TRenameDocItemProps = {
  projectId: string;
  docId: string;
  docItemId: string;
  newName: string;
};

export const renameDocItem = async (data: TRenameDocItemProps) => {
  const { projectId, docId, docItemId, newName } = data;
  const docItemRef = firestoreReference.projectDocItem_DocRef({
    docId,
    projectId,
    docItemId,
  });
  await FirestoreOperations.updateDoc(docItemRef, { name: newName });
};

export type TMoveDocItemProps = {
  projectId: string;
  docId: string;
  docItemId: string;
  destinationFolderId: string;
};

export const moveDocItem = async (data: TMoveDocItemProps) => {
  const { projectId, docId, docItemId, destinationFolderId } = data;
  const docItemRef = firestoreReference.projectDocItem_DocRef({
    projectId,
    docId,
    docItemId,
  });
  await FirestoreOperations.updateDoc(docItemRef, {
    parentFolderId: destinationFolderId,
  });
};

export type TRenameDocProps = {
  projectId: string;
  docId: string;
  newName: string;
};

export const renameDoc = async (data: TRenameDocProps) => {
  const { projectId, docId, newName } = data;
  const docRef = firestoreReference.projectDoc_DocRef({
    projectId,
    docId,
  });
  await FirestoreOperations.updateDoc(docRef, { name: newName });
};

export type TRemoveDocProps = IDocDeleteDTO;
//  {
//   projectId: string;
//   docId: string;
// };

export const removeDoc = async (data: TRemoveDocProps) => {
  // const { projectId, docId } = data;
  // const docRef = firestoreReference.projectDoc_DocRef({
  //   projectId,
  //   docId,
  // });
  // await FirestoreOperations.deleteDoc(docRef);
  await httpsCallable(functions, 'docs-deleteDocs')(data);
};

export type TAddViewerToCustomDocProps = {
  projectId: string;
  docId: string;
  customDocId: string;
  viewer: IAddCustomDocViewerUI;
};

export const addViewerToCustomDoc = async (
  data: TAddViewerToCustomDocProps,
) => {
  const { projectId, docId, customDocId, viewer } = data;
  const docRef = firestoreReference.projectDocItem_DocRef({
    projectId,
    docId,
    docItemId: customDocId,
  });

  const viewerToAdd: ICustomDocDTO['viewers'] =
    addAddCustomDocViewerConverter.toDb(viewer);
  await FirestoreOperations.setDoc(
    docRef,
    { viewers: viewerToAdd },
    { merge: true },
    false,
  );
};

export type TRemoveViewerFromCustomDocProps = {
  projectId: string;
  docId: string;
  customDocId: string;
  viewerId: string;
};

export const removeViewerFromCustomDoc = async (
  data: TRemoveViewerFromCustomDocProps,
) => {
  const { projectId, docId, customDocId, viewerId } = data;
  const docRef = firestoreReference.projectDocItem_DocRef({
    projectId,
    docId,
    docItemId: customDocId,
  });
  await FirestoreOperations.updateDoc(
    docRef,
    {
      [`viewers.${viewerId}`]: deleteField(),
    },
    false,
  );
};

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY || '',
  dangerouslyAllowBrowser: true,
});

export const fetchOpenAISuggestions = async (text: string)  => {
  if (!text) {
    return '';
  }
  try {
    const response = await openai.chat.completions.create({
      model: 'gpt-3.5-turbo',
      messages: [{ content: text, role: 'user' }],
      max_tokens: 100,
    });

    if (response?.choices && response?.choices?.length > 0) {
      return response.choices[0].message?.content?.trim() || '';
    }
    return '';
  } catch (error) {
    console.error('Error fetching suggestions:', error);
    return '';
  }
};
 
