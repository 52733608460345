import { FC } from 'react';
import { AvatarGroup } from '@mui/material';
import { IProjectUI } from 'data/types/project.types';
import MemberAvatar from 'components/MemberAvatar';

type TProjectMembersList = {
  members: IProjectUI['members'];
};

const ProjectMembersList: FC<TProjectMembersList> = ({ members }) => {
  return (
    <AvatarGroup spacing="medium" max={8}>
      {members.map(member => (
        <MemberAvatar {...member} key={member.userId} />
      ))}
    </AvatarGroup>
  );
};

export default ProjectMembersList;
