import { TGetAllEventsQueryProps } from 'firebaseServices/queryFunctions/eventQueryFunctions';
import { projectEventsIndex } from './algolia.config';

export const searchAlgoliaProjectEvents = async ({
  projectId,
  searchQuery = '',
  limitCount,
}: TGetAllEventsQueryProps) => {
  const algoliaData = await projectEventsIndex.search(searchQuery, {
    ...(limitCount && { hitsPerPage: limitCount }),
    filters: `projectId:${projectId}`,
  });

  const docItems = (algoliaData.hits || []).map(document => {
    return {
      ...document,
      documentId: document.objectID,
    };
  });

  const totalCount = algoliaData.nbHits;

  return {
    data: docItems,
    totalCount,
  };
};
