import { TFetchConverter } from '../types/general.types';
import { IMessageUi } from '../types/message.types';
import { IMessageDTO } from '../../shared/models/message.model';

export const getMessageConverter: TFetchConverter<
  IMessageUi,
  IMessageDTO & { documentId: string }
> = {
  fromDb: data => {
    return {
      ...data,
      documentId: data.documentId,
    };
  },
};
