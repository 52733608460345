export enum EFirestoreCollectionPaths {
  AUTH_USERS = 'authUsers',
  PROJECTS = 'projects',
  TASKS = 'tasks',
}
export enum EProjectSubCollectionPaths {
  BOARDS = 'boards',
  COLUMNS = 'columns',
  DOCS = 'docs',
  DOC_ITEMS = 'docItems',
  LABELS = 'labels',
  CHATS = 'chats',
  MESSAGES = 'messages',
  EVENTS = 'events',
  CUSTOM_TOOLS = 'customTools',
}

export class CollectionPaths {
  static Project = `${EFirestoreCollectionPaths.PROJECTS}`;

  static ProjectDocs(projectId: string) {
    return `${EFirestoreCollectionPaths.PROJECTS}/${projectId}/${EProjectSubCollectionPaths.DOCS}`;
  }

  static ProjectDocItems(projectId: string, docId: string) {
    return `${EFirestoreCollectionPaths.PROJECTS}/${projectId}/${EProjectSubCollectionPaths.DOCS}/${docId}/${EProjectSubCollectionPaths.DOC_ITEMS}`;
  }

  static ProjectEvents(projectId: string) {
    return `${EFirestoreCollectionPaths.PROJECTS}/${projectId}/${EProjectSubCollectionPaths.EVENTS}`;
  }
}
