import { TFetchConverter } from '../types/general.types';
import { ICustomToolUI } from '../types/customTools.type';
import { ICustomToolDTO } from '../../shared/models/customTool.model';

export const getCustomToolConverter: TFetchConverter<
  ICustomToolUI,
  ICustomToolDTO & { documentId: string }
> = {
  fromDb: data => {
    return {
      name: data.name,
      projectId: data.projectId,
      documentId: data.documentId,
      link: data.link,
      type: data.type,
    };
  },
};
