import { FC } from 'react';
import {
  IEventActionType,
  IProjectEvent,
  IProjectUpdatedData,
} from 'shared/models/event.model';
import ProjectUpdateEvent from './ProjectUpdateEvent';

type TProjectEventsProps = {
  eventData: IProjectEvent;
  previewMode?: boolean;
};

const ProjectEvents: FC<TProjectEventsProps> = ({ eventData, previewMode }) => {
  if (eventData.eventActionType === IEventActionType.UPDATE) {
    const updateEventData = eventData.data as IProjectUpdatedData;
    return (
      <ProjectUpdateEvent
        previewMode={previewMode}
        updateEventData={updateEventData}
      />
    );
  }

  return <></>;
};

export default ProjectEvents;
