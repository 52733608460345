import { TextField } from '@mui/material';
import { FC, useState } from 'react';
import Button from 'components/Base/Button';
import { sendTextMessage } from 'firebaseServices/queryFunctions/chatQueryFunctions';
import { useGetAuthUserQuery } from 'redux/apiSlices/authUser.slice';

const TextMessageArea: FC<{
  chatId: string;
  projectId: string;
  onMessageSend: () => void;
}> = ({ chatId, projectId, onMessageSend }) => {
  const { data: userData } = useGetAuthUserQuery();
  const [inputValue, setInputValue] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const sendMessage = async () => {
    if (userData) {
      setLoading(true);
      try {
        setInputValue('');
        await sendTextMessage({
          content: inputValue.trim(),
          chatId,
          projectId,
          sender: {
            name: userData.name,
            email: userData.email,
            id: userData.id,
          },
        });
        onMessageSend();
        setLoading(false);
      } catch {
        // TODO:HANDLE ERROR CASE
        setLoading(false);
      }
    }
  };

  return (
    <TextField
      fullWidth
      multiline
      maxRows={6}
      placeholder='Write message'
      onKeyDown={async event => {
        if (
          event.key === 'Enter' &&
          !event.shiftKey &&
          inputValue.trim() &&
          !loading
        ) {
          event.preventDefault();
          await sendMessage();
        }
      }}
      value={inputValue}
      onChange={event => {
        setInputValue(event.currentTarget.value);
      }}
      InputProps={{
        endAdornment: (
          <Button
            variant="contained"
            onClick={sendMessage}
            disabled={!inputValue.trim() || loading}
          >
            SEND
          </Button>
        ),
      }}
    />
  );
};

export default TextMessageArea;
