import { useEffect } from 'react';
import DetailsPageWrapper from 'components/DetailsPageWrapper';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetProjectByIdQuery } from 'redux/apiSlices/project.slice';
import useCustomDocDetailsBreadcrumb from 'hooks/BreadcrumbItems/useCustomDocDetailsBreadcrumb';
import CreateShortcutButton from 'components/CreateShortcutButton';
import {
  useGetAuthUserQuery,
  useUpdateUserShortcutsMutation,
} from 'redux/apiSlices/authUser.slice';
import { EShortcutTypes } from 'shared/types/generic.types';
import { generateUid } from 'firebaseServices/firebase.config';
import { useGetDocItemByIdQuery } from 'redux/apiSlices/doc.slice';
import { PrivateRoutesPaths } from 'routes/Routes.types';
import CustomDoc from './CustomDoc';

const CustomDocDetailsPage = () => {
  const params = useParams<{
    projectId: string;
    docId: string;
    customDocId: string;
  }>();
  const navigate = useNavigate()
  const projectId = params.projectId || '';
  const docId = params.docId || '';
  const customDocId = params.customDocId || '';

  const { data: projectData } = useGetProjectByIdQuery(projectId, {
    skip: !projectId,
  });

  const { data: customDocData, isError } = useGetDocItemByIdQuery(
    {
      projectId,
      docId,
      docItemId: customDocId,
    },
    {
      skip: !projectId || !docId || !customDocId,
    },
  );
 
  useEffect(() => {
    if (isError) navigate(`/${PrivateRoutesPaths.pageNotFound}`);
  }, [isError]);

  const { data: authData } = useGetAuthUserQuery();
  const [updateShortcuts] = useUpdateUserShortcutsMutation();
  const shortcuts = authData?.settings?.shortcuts || [];

  const inShortcuts = shortcuts.find(
    item =>
      item.type === EShortcutTypes.CUSTOM_DOC &&
      item.customDocId === customDocId,
  );

  const onAddShortcut = async () => {
    await updateShortcuts([
      ...shortcuts,
      {
        type: EShortcutTypes.CUSTOM_DOC,
        projectId,
        customDocId,
        docId,
        pinned: false,
        name: customDocData?.name || '',
        open: false,
        id: generateUid(),
      },
    ]);
  };

  const breadcrumbItems = useCustomDocDetailsBreadcrumb({
    projectId,
    docId,
    customDocId,
  });

  return (
    <DetailsPageWrapper
      header={{
        breadcrumbItems,
        rightAction: !inShortcuts && (
          <CreateShortcutButton onClick={onAddShortcut} />
        ),
      }}
      bodyBgColor={projectData?.color}
    >
      <CustomDoc
        projectId={projectId}
        docId={docId}
        customDocId={customDocId}
      />
    </DetailsPageWrapper>
  );
};

export default CustomDocDetailsPage;
