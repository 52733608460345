import { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from 'firebaseServices/firebase.config';
import { FirebaseError } from 'firebase/app';
import { errorMessages, firebaseErrorMapper } from 'data/messages/messages';

const useLoginWithEmailAndPassword = () => {
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const login = async (email: string, password: string) => {
    setError('');
    setLoading(true);
    try {
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      if (!user.emailVerified) {
        setError(errorMessages.verifyEmail);
        await auth.signOut();
      }
    } catch (err) {
      setError(firebaseErrorMapper(err as FirebaseError));
    } finally {
      setLoading(false);
    }
  };

  return { login, loading, error };
};

export default useLoginWithEmailAndPassword;
