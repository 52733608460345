import { createApi } from '@reduxjs/toolkit/query/react';
import { fakeBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { queryFnWrapper } from 'firebaseServices/queryFunctions/helper';
import {
  createChat,
  deleteChat,
  getChat,
  getProjectChats,
  renameChat,
} from '../../firebaseServices/queryFunctions/chatQueryFunctions';
import {
  IChatUi,
  ICreateChatUi,
  IDeleteChatUi,
  IRenameChatUi,
} from '../../data/types/chat.types';
import { eventsApi } from './events.slice';

export const chatApi = createApi({
  tagTypes: ['chats', 'chat', 'messages'],
  reducerPath: 'chat',
  baseQuery: fakeBaseQuery(),
  endpoints: build => ({
    getProjectChats: build.query<IChatUi[] | null, string>({
      queryFn: arg => queryFnWrapper(getProjectChats, arg),
      providesTags: ['chats'],
    }),
    getChat: build.query<IChatUi, { projectId: string; chatId: string }>({
      queryFn: arg => queryFnWrapper(getChat, arg),
      providesTags: ['chat'],
    }),
    renameChat: build.mutation<void, IRenameChatUi>({
      queryFn: arg => queryFnWrapper(renameChat, arg),
      invalidatesTags: ['chats', 'chat'],
    }),
    deleteChat: build.mutation<void, IDeleteChatUi>({
      queryFn: arg => queryFnWrapper(deleteChat, arg),
      invalidatesTags: ['chats', 'chat'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(eventsApi.util.invalidateTags(['events']));
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    createChat: build.mutation<void, ICreateChatUi>({
      queryFn: arg => queryFnWrapper(createChat, arg),
      invalidatesTags: ['chats'],
    }),
  }),
});

export const {
  useGetProjectChatsQuery,
  useRenameChatMutation,
  useDeleteChatMutation,
  useCreateChatMutation,
  useGetChatQuery,
} = chatApi;
