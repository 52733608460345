import { CheckCircle, ErrorOutlined } from '@mui/icons-material';
import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
} from '@mui/material';
import CircularProgressWithLabel from 'components/CircularProgressWithLabel';
import { FC } from 'react';

type TInfoItemProps = {
  fileName: string;
  isDone: boolean;
  isLoading: boolean;
  isError: boolean;
  errorMessage?: string;
  progress: number;
};

const InfoItem: FC<TInfoItemProps> = ({
  fileName,
  isDone,
  isLoading,
  isError,
  errorMessage,
  progress,
}) => {
  return (
    <ListItem
      sx={{
        paddingX: 0,
      }}
    >
      <ListItemText
        primaryTypographyProps={{
          width: '340px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          variant: 'body1',
        }}
      >
        {fileName}
      </ListItemText>
      <ListItemSecondaryAction>
        {isError && (
          <Tooltip title={errorMessage}>
            <ErrorOutlined color="error" fontSize="large" />
          </Tooltip>
        )}
        {isLoading && <CircularProgressWithLabel value={progress} />}
        {isDone && <CheckCircle color="success" fontSize="large" />}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default InfoItem;
