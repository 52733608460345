import {
  addDoc as firestoreAddDoc,
  updateDoc as firestoreUpdateDoc,
  deleteDoc as firestoreDeleteDoc,
  setDoc as firestoreSetDoc,
  SetOptions,
  DocumentReference,
  CollectionReference,
} from 'firebase/firestore';
import { IAuthUserUI } from 'data/types/user.types';
import { getStore } from 'redux/storeAccessor';

class FirestoreOperations {
  public static getUserId() {
    const state = getStore().getState();
    const authUser = state.authUser?.queries['getAuthUser(undefined)']
      ?.data as IAuthUserUI;
    return authUser?.id;
  }

  public static getTimestamp() {
    return Date.now();
  }

  static async addDoc(collectionRef: CollectionReference, data: any) {
    const timestamp = FirestoreOperations.getTimestamp();
    const dataWithMetadata = {
      ...data,
      creationDate: timestamp,
      lastModifiedBy: FirestoreOperations.getUserId(),
      lastModifiedDate: timestamp,
    };
    return firestoreAddDoc(collectionRef, dataWithMetadata);
  }

  static async updateDoc(
    docRef: DocumentReference,
    data: any,
    updateMetadata = true,
  ) {
    let dataWithMetadata = data;
    if (updateMetadata) {
      dataWithMetadata = {
        ...data,
        lastModifiedBy: this.getUserId(),
        lastModifiedDate: this.getTimestamp(),
      };
    }
    return firestoreUpdateDoc(docRef, dataWithMetadata);
  }

  static async deleteDoc(docRef: DocumentReference) {
    return firestoreDeleteDoc(docRef);
  }

  static async setDoc(
    docRef: DocumentReference,
    data: any,
    options: SetOptions,
    updateMetadata = true,
  ) {
    let dataWithMetadata = data;
    if (updateMetadata) {
      dataWithMetadata = {
        ...data,
        lastModifiedBy: FirestoreOperations.getUserId(),
        lastModifiedDate: FirestoreOperations.getTimestamp(),
      };
    }
    return firestoreSetDoc(docRef, dataWithMetadata, options);
  }
}

export default FirestoreOperations;
