import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FormControl, Stack, TextField } from '@mui/material';

type TAddBoardModalFormProps = Pick<
  UseFormReturn<{ chatName: string }>,
  'formState' | 'register'
> & {
  onSubmit: () => void;
};

const AddChatModalForm: FC<TAddBoardModalFormProps> = ({
  formState,
  register,
  onSubmit,
}) => {
  const { errors } = formState;
  return (
    <FormControl component="form" fullWidth onSubmit={onSubmit}>
      <Stack spacing={1} direction="column">
        <TextField
          autoFocus
          label="Name"
          variant="standard"
          error={!!errors.chatName}
          helperText={errors.chatName && errors.chatName.message}
          required
          {...register('chatName')}
        />
      </Stack>
    </FormControl>
  );
};

export default AddChatModalForm;
