import { TCreateConverter, TFetchConverter } from 'data/types/general.types';
import {
  IAddNewProjectUI,
  IAddProjectMemberUI,
  IEditProjectUI,
  IProjectMemberUI,
  IProjectUI,
} from 'data/types/project.types';
import { IProjectDTO, IProjectMemberDTO } from 'shared/models/project.model';
import { getFirstLetters, stringToColor } from 'utils/utils';
import { EUserRole } from 'shared/types/generic.types';
import {
  IAddNewProjectDB,
  IEditProjectNameColorDB,
} from '../../shared/types/project.types';

export const addNewProjectConverter: TCreateConverter<
  IAddNewProjectUI,
  IAddNewProjectDB
> = {
  toDb: data => {
    return {
      color: data.color,
      name: data.name,
      description: data.description?.trim() || null,
    };
  },
};

export const getProjectMembersConverter: TFetchConverter<
  IProjectMemberUI[],
  IProjectMemberDTO
> = {
  fromDb: data => {
    return Object.entries(data || {})
      .map(([userId, member]) => ({
        userId,
        role: member.role,
        name: member.name,
        firstLetters: getFirstLetters(member.name),
        avatarColor: stringToColor(member.name || ''),
        email: member.email,
      }))
      .sort((a, b) => {
        if (a.role === EUserRole.OWNER && b.role !== EUserRole.OWNER) {
          return -1;
        }
        if (a.role !== EUserRole.OWNER && b.role === EUserRole.OWNER) {
          return 1;
        }
        return a.name.localeCompare(b.name);
      });
  },
};

export const getProjectConverter: TFetchConverter<
  IProjectUI,
  IProjectDTO & { documentId: string }
> = {
  fromDb: data => {
    return {
      color: data.color,
      creationDate: data.creationDate,
      id: data.documentId,
      members: getProjectMembersConverter.fromDb(data.members),
      name: data.name,
      description: data.description,
    };
  },
};

export const editProjectNameColorConverter: TCreateConverter<
  IEditProjectUI,
  IEditProjectNameColorDB
> = {
  toDb: data => {
    return {
      color: data.color,
      name: data.name,
      description: data.description?.trim() || null,
    };
  },
};

export const addProjectMemberConverter: TCreateConverter<
  IAddProjectMemberUI,
  IProjectMemberDTO
> = {
  toDb: data => {
    return {
      [data.id]: {
        role: EUserRole.MEMBER,
        name: data.name,
        email: data.email,
      },
    };
  },
};
