import { dateFormat } from 'data/static';
import dayjs from 'dayjs';
import { PrivateRoutesPaths } from 'routes/Routes.types';
import {
  IBoardEvent,
  IChatEvent,
  IColumnEvent,
  IEventActionType,
  IEventResourceType,
  ITaskEvent,
  TProjectEventDTO,
} from 'shared/models/event.model';

export const eventActionTypeMapper: Record<IEventActionType, string> = {
  Create: 'created',
  Delete: 'deleted',
  Update: 'updated',
};

export const eventResourceTypeTextMapper: Record<IEventResourceType, string> = {
  Project: 'project',
  Board: 'board',
  Column: 'column',
  'Docs Item': 'docs item',
  Docs: 'docs',
  Task: 'task',
  Chat: 'chat',
  [IEventResourceType.CUSTOM_TOOL]: 'external tool',
};

export const convertDateToText = (date?: number) => {
  if (!date) {
    return '';
  }
  const today = dayjs().startOf('day');
  const yesterday = today.subtract(1, 'day').startOf('day');
  const eventDate = dayjs(date).startOf('day');

  if (eventDate.isSame(today, 'day')) {
    return 'Today';
  }
  if (eventDate.isSame(yesterday, 'day')) {
    return 'Yesterday';
  }
  return dayjs(date).format(dateFormat);
};

export const generatePathToNavigateByResourceType = (
  eventData: TProjectEventDTO,
): string | null => {
  if (!eventData.eventResourceType) {
    return null;
  }
  if (eventData.eventResourceType === IEventResourceType.PROJECT) {
    return `/${PrivateRoutesPaths.ProjectsDetails.replace(
      ':id',
      eventData.projectId,
    )}`;
  }
  if (eventData.eventResourceType === IEventResourceType.BOARD) {
    const boardEventData = eventData as IBoardEvent;
    return `/${PrivateRoutesPaths.BoardDetails.replace(
      ':boardId',
      boardEventData.boardId,
    ).replace(':projectId', boardEventData.projectId)}`;
  }
  if (eventData.eventResourceType === IEventResourceType.COLUMN) {
    const columnEventData = eventData as IColumnEvent;
    return `/${PrivateRoutesPaths.BoardDetails.replace(
      ':boardId',
      columnEventData.boardId,
    ).replace(':projectId', columnEventData.projectId)}`;
  }
  if (eventData.eventResourceType === IEventResourceType.TASK) {
    const taskEventData = eventData as ITaskEvent;
    return `/${PrivateRoutesPaths.TaskDetails.replace(
      ':taskId',
      taskEventData.taskId,
    )}`;
  }
  if (eventData.eventResourceType === IEventResourceType.CHAT) {
    const chatEventData = eventData as IChatEvent;
    return `/${PrivateRoutesPaths.Chat.replace(
      ':projectId',
      eventData.projectId,
    ).replace(':chatId', chatEventData.chatId)}`;
  }

  return null;
};
