import { FC } from 'react';
import {
  IEventActionType,
  ITaskEvent,
  ITaskUpdatedData,
} from 'shared/models/event.model';
import parse from 'html-react-parser';
import dayjs from 'dayjs';
import { dateFormat } from 'data/static';
import OldNewValueContent from '../../components/OldNewValueContent';
import MemberAvatarInContent from '../../components/MemberAvatarInContent';
import LabelUpdateEvent from './LabelUpdateEvent';

type TTaskEventsProps = {
  eventData: ITaskEvent;
  previewMode?: boolean;
};

const TaskEvents: FC<TTaskEventsProps> = ({ eventData, previewMode }) => {
  if (eventData.eventActionType === IEventActionType.UPDATE) {
    const updateTaskData = eventData.data as ITaskUpdatedData;
    const { title, estimate, description, dueDate, assignee, column, labels } =
      updateTaskData;

    return (
      <>
        {title && (
          <OldNewValueContent
            prefixText="Title"
            previewMode={previewMode}
            {...updateTaskData.title}
          />
        )}
        {estimate && (
          <OldNewValueContent
            prefixText="Estimate"
            previewMode={previewMode}
            {...updateTaskData.estimate}
          />
        )}
        {description && (
          <OldNewValueContent
            prefixText="Description"
            previewMode={previewMode}
            oldValue={description.oldValue ? parse(description.oldValue) : ''}
            newValue={description.newValue ? parse(description.newValue) : ''}
            alignItems="center"
          />
        )}
        {dueDate && (
          <OldNewValueContent
            prefixText="Due Date"
            previewMode={previewMode}
            oldValue={
              dueDate.oldValue ? dayjs(dueDate.oldValue).format(dateFormat) : ''
            }
            newValue={
              dueDate.newValue ? dayjs(dueDate.newValue).format(dateFormat) : ''
            }
          />
        )}
        {assignee && (
          <OldNewValueContent
            prefixText="Assignee"
            previewMode={previewMode}
            oldValue={
              assignee.oldValue ? (
                <MemberAvatarInContent
                  previewMode={previewMode}
                  member={assignee.oldValue}
                />
              ) : (
                'Unassigned'
              )
            }
            newValue={
              assignee.newValue ? (
                <MemberAvatarInContent
                  previewMode={previewMode}
                  member={assignee.newValue}
                />
              ) : (
                'Unassigned'
              )
            }
          />
        )}
        {column && (
          <OldNewValueContent
            prefixText="Status"
            previewMode={previewMode}
            oldValue={column.oldValue?.columnTitle}
            newValue={column.newValue?.columnTitle}
          />
        )}
        {labels && (
          <LabelUpdateEvent labels={labels} previewMode={previewMode} />
        )}
      </>
    );
  }
  return <></>;
};

export default TaskEvents;
