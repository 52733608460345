export const generateSpaceClassNames = (array: number[]) => {
  let classNames = ``;
  array.forEach((n, i) => {
    classNames += `
            .mx-${i} {
                margin: 0 ${n}px !important;
            }
            .my-${i} {
                margin: ${n}px 0 !important;
            }
            .m-${i} {
                margin: ${n}px !important;
            }
            .mt-${i} {
                margin-top: ${n}px !important;
            }
            .mb-${i} {
                margin-bottom: ${n}px !important;
            }
            .ml-${i} {
                margin-left: ${n}px !important;
            }
            .mr-${i} {
                margin-right: ${n}px !important;
            }
            .pt-${i} {
                padding-top: ${n}px !important;
            }
            .pb-${i} {
                padding-bottom: ${n}px !important;
            }
            .pl-${i} {
                padding-left: ${n}px !important;
            }
            .pr-${i} {
                padding-right: ${n}px !important;
            }
            .px-${i} {
                padding: 0 ${n}px !important;
            }
            .py-${i} {
                padding: ${n}px 0 !important;
            }
            .p-${i} {
                padding: ${n}px !important;
            }
        `;
  });

  return classNames;
};

export const checkFileType = (file: Blob, allowedTypes: string[]) => {
  const isAllowedType = allowedTypes.includes(file.type);
  if (!isAllowedType) {
    console.log('You can only upload SVG or PNG files!');
  }
  return isAllowedType;
};

export const checkImageDimensions = async (
  file: Blob,
  MAX_HEIGHT: number,
  MAX_WIDTH: number,
) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = event => {
      const loadedImageUrl = event?.target?.result || '';
      const image = document.createElement('img');
      image.src = loadedImageUrl as string;
      image.addEventListener('load', () => {
        const { width, height } = image;
        if (width > MAX_WIDTH || height > MAX_HEIGHT) {
          console.error(
            `Image dimensions must be ${MAX_WIDTH} x ${MAX_HEIGHT} or smaller!`,
          );
          reject();
        } else {
          resolve('success');
        }
      });
    };
  });
};

export const getFirstLetters = (name: string | undefined | null) => {
  if (!name) return '';
  return name
    .split(' ')
    .map(word => word[0].toLocaleUpperCase())
    .join('');
};

export const stringToColor = (string?: string) => {
  if (!string) return '#000000';
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const downloadFile = (file: Blob, fileName: string) => {
  const link = window.URL.createObjectURL(file);
  const anchorTag = document.createElement('a');
  anchorTag.setAttribute('href', link);
  anchorTag.setAttribute('download', fileName);
  anchorTag.style.display = 'none';
  document.body.appendChild(anchorTag);
  anchorTag.click();
  document.body.removeChild(anchorTag);
};

export const downloadFileData = (url: string, name: string) =>
  fetch(url).then(response =>
    response.blob().then(blob => downloadFile(blob, name)),
  );

 export const extractPathUpToFolder = (
    fullPath: string,
    folderId: string,
  ): string => {
    const segments = fullPath.split('/').map(segment => segment.trim());
    const targetIndex = segments.indexOf(folderId);
    if (targetIndex !== -1) {
      return segments.slice(0, targetIndex + 1).join('/');
    }
    return fullPath;
  };
export const isDemo = process.env.REACT_APP_ENVIROMENT === 'demo';
