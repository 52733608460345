import { initializeApp } from 'firebase/app';
import {
  getAuth,
  sendPasswordResetEmail,
  connectAuthEmulator,
} from 'firebase/auth';
import {
  collection,
  getFirestore,
  doc,
  setDoc,
  getDoc,
  getDocs,
  query,
  where,
  onSnapshot,
  addDoc,
  deleteDoc,
  writeBatch,
  updateDoc,
  arrayRemove,
  arrayUnion,
  runTransaction,
  documentId,
  orderBy,limit,
  limitToLast
} from 'firebase/firestore';
import { connectFirestoreEmulator } from '@firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from '@firebase/functions';
import { getStorage, connectStorageEmulator } from '@firebase/storage';
import { getAnalytics } from '@firebase/analytics';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MESURMENT_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const firestore = getFirestore(firebaseApp);
const functions = getFunctions(firebaseApp);
const storage = getStorage(firebaseApp);
const analytics = getAnalytics(firebaseApp);

if (process.env.REACT_APP_ENVIROMENT === 'local') {
  connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
  connectFirestoreEmulator(firestore, '127.0.0.1', 8080);
  connectFunctionsEmulator(functions, '127.0.0.1', 5001);
  connectStorageEmulator(storage, '127.0.0.1', 9199);
}

const generateUid = () => {
  return doc(collection(firestore, 'unused')).id;
};

export {
  firebaseApp,
  auth,
  firestore,
  functions,
  collection,
  doc,
  setDoc,
  getDoc,
  getDocs,
  query,
  where,
  sendPasswordResetEmail,
  onSnapshot,
  addDoc,
  deleteDoc,
  writeBatch,
  storage,
  generateUid,
  updateDoc,
  arrayRemove,
  arrayUnion,
  runTransaction,
  analytics,
  documentId,
  orderBy,
  limit,
  limitToLast
};
