import { FC } from 'react';
import {  Chip } from '@mui/material';
import { useGetLabelsByIdsQuery } from 'redux/apiSlices/labels.slice';

const TaskLabels: FC<{
  labels: string[];
  projectId: string;
}> = ({ labels, projectId }) => {
  const { data } = useGetLabelsByIdsQuery(
    {
      labelIds: labels,
      projectId,
    },
    {
      skip: !labels.length,
    },
  );

  return (
    <>
      {data &&
        data.map(label => (
          <Chip key={label.id} size="small" label={label.title} />
        ))}
    </>
  );
};

export default TaskLabels;
