import { createApi } from '@reduxjs/toolkit/query/react';
import { fakeBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { queryFnWrapper } from 'firebaseServices/queryFunctions/helper';
import {
  IAddAndUpdateTaskLabelsUI,
  IAddTaskUI,
  ITaskUI,
  IUpdateTaskAssigneeUI,
  IUpdateTaskDescriptionUI,
  IUpdateTaskDueDateUI,
  IUpdateTaskEstimateUI,
  IUpdateTaskLabelsUI,
  IUpdateTaskOrder,
  IUpdateTaskStatusUI,
  IUpdateTaskTitleUI,
} from 'data/types/task.type';
import { ITaskDeleteDTO } from 'shared/models/task.model';
import {
  addAndUpdateTaskLabels,
  addTask,
  deleteTask,
  getTask,
  updateTaskAssignee,
  updateTaskDescription,
  updateTaskDueDate,
  updateTaskEstimate,
  updateTaskLabels,
  updateTaskOrder,
  updateTaskStatus,
  updateTaskTitle,
} from '../../firebaseServices/queryFunctions/taskQueryFunctions';
import { ILabelUI } from '../../data/types/label.type';
import { labelsApi } from './labels.slice';

export const tasksApi = createApi({
  tagTypes: ['tasks', 'task'],
  reducerPath: 'tasks',
  baseQuery: fakeBaseQuery(),
  endpoints: build => ({
    addTask: build.mutation<void, IAddTaskUI>({
      queryFn: arg => queryFnWrapper(addTask, arg),
      invalidatesTags: ['tasks'],
    }),
    getTask: build.query<ITaskUI, string>({
      queryFn: arg => queryFnWrapper(getTask, arg),
      providesTags: (args, error, id) => [{ id, type: 'task' }],
    }),
    updateTaskTitle: build.mutation<void, IUpdateTaskTitleUI>({
      queryFn: arg => queryFnWrapper(updateTaskTitle, arg),
      invalidatesTags: (res, error, arg) => [{ id: arg.taskId, type: 'task' }],
    }),
    updateTaskAssigner: build.mutation<void, IUpdateTaskAssigneeUI>({
      queryFn: arg => queryFnWrapper(updateTaskAssignee, arg),
      invalidatesTags: (res, error, arg) => [{ id: arg.taskId, type: 'task' }],
    }),
    updateTaskStatus: build.mutation<void, IUpdateTaskStatusUI>({
      queryFn: arg => queryFnWrapper(updateTaskStatus, arg),
      invalidatesTags: (res, error, arg) => [
        { id: arg.task.taskId, type: 'task' },
      ],
    }),
    updateTaskDescription: build.mutation<void, IUpdateTaskDescriptionUI>({
      queryFn: arg => queryFnWrapper(updateTaskDescription, arg),
      invalidatesTags: (res, error, arg) => [{ id: arg.taskId, type: 'task' }],
    }),
    updateTaskDueDate: build.mutation<void, IUpdateTaskDueDateUI>({
      queryFn: arg => queryFnWrapper(updateTaskDueDate, arg),
      invalidatesTags: (res, error, arg) => [{ id: arg.taskId, type: 'task' }],
    }),
    updateTaskEstimate: build.mutation<void, IUpdateTaskEstimateUI>({
      queryFn: arg => queryFnWrapper(updateTaskEstimate, arg),
      invalidatesTags: (res, error, arg) => [{ id: arg.taskId, type: 'task' }],
    }),
    updateTaskLabels: build.mutation<void, IUpdateTaskLabelsUI>({
      queryFn: arg => queryFnWrapper(updateTaskLabels, arg),
      invalidatesTags: (res, error, arg) => [{ id: arg.taskId, type: 'task' }],
    }),
    updateTaskOrder: build.mutation<void, IUpdateTaskOrder>({
      queryFn: arg => queryFnWrapper(updateTaskOrder, arg),
      invalidatesTags: (res, error, arg) => [
        { id: arg.task.taskId, type: 'task' },
      ],
    }),
    addAndUpdateTaskLabels: build.mutation<ILabelUI, IAddAndUpdateTaskLabelsUI>(
      {
        queryFn: arg => queryFnWrapper(addAndUpdateTaskLabels, arg),
        invalidatesTags: (res, error, arg) => [
          { id: arg.taskId, type: 'task' },
        ],
        onQueryStarted(arg, { dispatch, queryFulfilled }) {
          queryFulfilled
            .then(() => {
              dispatch(labelsApi.util.invalidateTags(['labels',]));
            })
            .catch(() => {
              // do nothing
            });
        },
      },
    ),
    deleteTask: build.mutation<void, ITaskDeleteDTO>({
      queryFn: arg => queryFnWrapper(deleteTask, arg),
      invalidatesTags: (res, error, arg) => [{ id: arg.taskId, type: 'task' }],
    }),
  }),
});

export const {
  useAddTaskMutation,
  useUpdateTaskTitleMutation,
  useUpdateTaskAssignerMutation,
  useUpdateTaskStatusMutation,
  useUpdateTaskDescriptionMutation,
  useDeleteTaskMutation,
  useGetTaskQuery,
  useUpdateTaskDueDateMutation,
  useUpdateTaskEstimateMutation,
  useUpdateTaskLabelsMutation,
  useAddAndUpdateTaskLabelsMutation,
  useUpdateTaskOrderMutation
} = tasksApi;
