import { OutputData } from '@editorjs/editorjs';
import { cloneDeep } from 'lodash';

export const defaultTitle = 'Untitled';

export const generateDocTitle = (content: OutputData): string | null => {
  const firstTextBlock = content.blocks.find(block => block.data.text);
  if (!firstTextBlock) {
    return null;
  }
  const { text } = firstTextBlock.data;
  if (!text.trim()) {
    return null;
  }

  const textWithoutProbelSymbols = text
    .replace(/&nbsp;/g, ' ')
    .replace(/&nbsp/g, ' ');

  if (textWithoutProbelSymbols.length < 10) {
    return null;
  }
  const slicedText = textWithoutProbelSymbols.slice(0, 40);
  // const lastSpaceIndex = slicedText.lastIndexOf(' ');
  // const sliceUntillFirstProbel = slicedText.slice(0, lastSpaceIndex);
  return slicedText;
};

export const parseTableData = (data: OutputData): OutputData => {
  data.blocks.forEach(block => {
    if (block.type === 'table') {
      const oldContent = block.data.content;
      if (typeof oldContent === 'string') {
        block.data.content = JSON.parse(block.data.content);
      } else {
        block.data.content = cloneDeep(oldContent);
      }
    }
  });
  return data;
};
export const stringifyTableData = (data: OutputData) => {
  return {
    ...data,
    blocks: data.blocks.map(block => {
      if (block.type === 'table') {
        return {
          ...block,
          data: {
            ...block.data,
            content: JSON.stringify(block.data.content),
          },
        };
      }
      return block;
    }),
  };
};
