import { FC, cloneElement, useRef, useState } from 'react';
import { Box, Card, CardContent, Menu } from '@mui/material';
import { TActiveToolCardChildrenProps } from 'pages/privatePages/Projects/ProjectDetails/ActiveTools/ActiveToolCard';
import { EDocTypes } from 'shared/models/doc.model';
import useElementResize from 'hooks/useElementResize';
import CardHeader from 'components/Base/Card/CardHeader';
import MoveMenuItem from './MoveAction/MoveMenuItem';
import RemoveMenuItem from '../../../../../components/MenuDropdown/RemoveMenuItem';
import RenameDocItemModal from './RenameAction/RenameDocItemModal';
import RemoveDocItemModal from './RemoveAction/RemoveDocItemModal';
import { TDocItemCardProps } from './DocItemCard.types';
import MoveActionModal from './MoveAction/MoveActionModal';
import RenameMenuItem from '../../../../../components/MenuDropdown/RenameMenuItem';

const folderWrapperStyles = () => {
  const borderWith = 4;
  const tabHeight = 20;
  const folderColor = '#f6c141'; // folder icon color
  return {
    width: '100%',
    height: '100%',
    marginTop: `${tabHeight}px`,
    position: 'relative',
    border: `${borderWith}px solid ${folderColor}`,
    borderRadius: '0 6px 6px 6px',
    boxShadow: `1px 1px 2px ${folderColor}`,
    '&:before': {
      content: "''",
      width: '50%',
      height: `${tabHeight}px`,
      borderRadius: '0 20px 0 0',
      backgroundColor: folderColor,
      position: 'absolute',
      top: `-${tabHeight + borderWith}px`,
      left: `-${borderWith}px`,
      border: `${borderWith}px solid ${folderColor}`,
    },
    '&:after': {
      content: "''",
      width: '50%',
      height: `${tabHeight}px`,
      borderRadius: '0 20px 0 0',
      backgroundColor: folderColor,
      position: 'absolute',
      top: `-${tabHeight + borderWith}px`,
      left: `-${borderWith}px`,
      border: `${borderWith}px solid ${folderColor}`,
    },
  };
};

const DocItemCard: FC<TDocItemCardProps> = ({
  onActionAreaClick,
  children,
  title,
  docId,
  docItem,
  projectId,
  additionalActions,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [openMoveModal, setOpenMoveModal] = useState(false);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const isFolder = docItem.type === EDocTypes.FOLDER;

  const cardContentRef = useRef<HTMLDivElement>(null);
  const { width } = useElementResize({
    ref: cardContentRef,
  });

  const contentWidth = width;
  const contentHeight = isFolder ? 200 - 24 : 200;

  const childrenWithProps = cloneElement(children, {
    contentWidth,
    contentHeight,
  } as TActiveToolCardChildrenProps);


  return (
    <>
      <Card
        sx={{
          height: 'fit-content',
          // minWidth: 220,
          ...(isFolder && { boxShadow: 'none' }),
          ':hover': theme => ({
            backgroundColor: theme.palette.action.hover,
          }),
        }}
      >
        <Box sx={() => (isFolder ? folderWrapperStyles() : {})}>
          <CardContent
            ref={cardContentRef}
            onClick={onActionAreaClick}
            sx={theme => ({
              // width: contentWidth,
              height: contentHeight,
              maxHeight: contentHeight,
              cursor: 'pointer',
              padding: theme.spacing(1),
            })}
          >
            {childrenWithProps}
          </CardContent>
          <CardHeader
            title={title}
            hasaction={{
              onActionClick: handleOpenMenu,
            }}
            headerEllipsis
            border={{
              top: true,
            }}
          />
        </Box>
      </Card>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        onBlur={() => setAnchorEl(null)}
      >
        <RenameMenuItem onClick={() => setOpenRenameModal(true)} />
        <MoveMenuItem onClick={() => setOpenMoveModal(true)} />
        {additionalActions?.length && additionalActions.map(action => action)}
        <RemoveMenuItem onClick={() => setOpenRemoveModal(true)} />
      </Menu>
      <RenameDocItemModal
        open={openRenameModal}
        setOpen={setOpenRenameModal}
        docItemId={docItem.documentId}
        docId={docId}
        projectId={projectId}
        initialName={docItem.name}
        isFolder={isFolder}
      />
      <RemoveDocItemModal
        open={openRemoveModal}
        setOpen={setOpenRemoveModal}
        docItemId={docItem.documentId}
        docId={docId}
        projectId={projectId}
      />
      {openMoveModal && (
        <MoveActionModal
          docId={docId}
          docItem={docItem}
          projectId={projectId}
          open={openMoveModal}
          setOpen={setOpenMoveModal}
        />
      )}
    </>
  );
};

export default DocItemCard;
