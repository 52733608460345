import { FC } from 'react';
import ChatCard from './ChatCard';
import { IChatUi } from '../../../../../../data/types/chat.types';

const AllChats: FC<{ chats: IChatUi[] }> = ({ chats }) => {
  return (
    <>
      {chats.map(chat => (
        <ChatCard chat={chat} key={chat.documentId} />
      ))}
    </>
  );
};

export default AllChats;
