import { Chip } from '@mui/material';
import Button from 'components/Base/Button'; 
import { FC, useMemo } from 'react';
import { useUpdateCustomDocPublishFlagMutation } from 'redux/apiSlices/doc.slice';

type TDocStatusProps = {
  isPublished: boolean;
  currentUserId: string;
  creatorId: string;
  onSave: () => Promise<void>;
  projectId: string;
  docId: string;
  customDocId: string;
};

const DocStatus: FC<TDocStatusProps> = ({
  isPublished,
  currentUserId,
  creatorId,
  onSave,
  projectId,
  docId,
  customDocId,
}) => {
  const [updatePublishFlag, { isLoading: pulishLoading }] =
    useUpdateCustomDocPublishFlagMutation();

  const publishedStateChip = useMemo(
    () =>
      isPublished ? (
        <Chip label="Published" color="success" />
      ) : (
        <Chip label="Draft" color="default" />
      ),
    [isPublished],
  );

  const onPublish = async (publish: boolean) => {
    await updatePublishFlag({
      projectId,
      docId,
      customDocId,
      isPublished: publish,
    }).unwrap();
    await onSave();
  };

  const canUpdateDocState = currentUserId === creatorId;

  return (
    <>
      {canUpdateDocState && (
        <Button
          color={isPublished ? 'inherit' : 'success'}
          onClick={() => onPublish(!isPublished)}
          disabled={pulishLoading}
        >
          {isPublished ? 'Make Draft' : 'Publish'}
        </Button>
      )}
      {publishedStateChip}
    </>
  );
};

export default DocStatus;
