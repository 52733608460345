import { Typography } from '@mui/material';
import { FC } from 'react';

type TSaveStateProps = {
  isLoading: boolean;
  isSuccess: boolean;
};

const SaveState: FC<TSaveStateProps> = ({ isLoading, isSuccess }) => {
  return (
    <>
      {isLoading ? (
        <Typography variant="body2">Saving ...</Typography>
      ) : (
        <Typography variant="body2">{isSuccess ? 'Saved' : ''}</Typography>
      )}
    </>
  );
};

export default SaveState;
