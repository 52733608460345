import { useEffect, useState } from 'react';
import {
  onSnapshot,
  Query,
  FirestoreError,
  CollectionReference,
} from 'firebase/firestore';

export type WithDocumentId<T> = T & { documentId: string };

type FirebaseCollectionHook<T> = {
  snapshotData: WithDocumentId<T>[] | null;
  isLoading: boolean;
  error: Error | null;
  setSnapshotData: React.Dispatch<React.SetStateAction<WithDocumentId<T>[] | null>>
};

export const useFirebaseCollectionListener = <T>(
  queryOrRef: Query<T> | CollectionReference<T>,
): FirebaseCollectionHook<T> => {
  const [snapshotData, setSnapshotData] = useState<WithDocumentId<T>[] | null>(
    null,
  );
  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      queryOrRef,
      snapshot => {
        const docsData = snapshot.docs.map(doc => ({
          ...doc.data(),
          documentId: doc.id,
        }));
        setSnapshotData(docsData as WithDocumentId<T>[]);
        setError(null);
        setLoading(false);
      },
      (err: FirestoreError) => {
        setError(new Error(err.message));
        setLoading(false);
      },
    );

    return () => unsubscribe();
  }, [queryOrRef]);

  return { snapshotData, isLoading, error, setSnapshotData };
};
