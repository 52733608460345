import { FC } from 'react';
import { Typography } from '@mui/material';
import { IMessageUi } from 'data/types/message.types';
import MessageCard from '../MessageCard';

const Messages: FC<{
  messages: IMessageUi[] | null;
  projectId: string;
  userId: string;
}> = ({ messages, projectId, userId }) => {
  if (messages) {
    return messages.length ? (
      <>
        {messages.map(message => (
          <MessageCard
            message={message}
            key={message.documentId}
            projectId={projectId}
            userId={userId}
          />
        ))}
      </>
    ) : (
      <Typography>There is no messages</Typography>
    );
  }
  return null;
};

export default Messages;
