import { FC } from 'react';
import { useEditProjectNameColorMutation } from 'redux/apiSlices/project.slice';
import AddEditNewProjectModal from 'pages/privatePages/Projects/components/AddEditNewProjectModal';
import { IProjectUI } from 'data/types/project.types';

type TRenameProjectProps = {
  projectData: IProjectUI;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const RenameProject: FC<TRenameProjectProps> = ({
  projectData,
  open,
  setOpen,
}) => {
  const { color, description, id, name } = projectData;
  const [editProjectNameColor, { isLoading }] =
    useEditProjectNameColorMutation();
  if (!open) {
    return null;
  }
  return (
    <AddEditNewProjectModal
      submitLoading={isLoading}
      onSubmitClick={values =>
        editProjectNameColor({ ...values, projectId: id }).unwrap()
      }
      open={open}
      setOpen={setOpen}
      initialData={{ name, color, description }}
      modalTitlePrefix="Edit"
      submitButtonText="Save"
    />
  );
};

export default RenameProject;
