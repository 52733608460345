import { Navigate, useParams } from 'react-router-dom';
import DetailsPageWrapper from 'components/DetailsPageWrapper';
import { useGetTaskQuery } from 'redux/apiSlices/tasks.slice';
import useBoardDetailsBreadcrumb from 'hooks/BreadcrumbItems/useBoardsBreadcrumb';
import { Box } from '@mui/material';
import TaskTitle from 'components/BoardsDetail/components/ColumnContent/TaskInfoModal/TaskTitle';
import TaskDescription from 'components/BoardsDetail/components/ColumnContent/TaskInfoModal/TaskDescription';
import TaskDuedate from 'components/BoardsDetail/components/ColumnContent/TaskInfoModal/TaskDuedate';
import TaskEstimate from 'components/BoardsDetail/components/ColumnContent/TaskInfoModal/TaskEstimate';
import TaskStatus from 'components/BoardsDetail/components/ColumnContent/TaskInfoModal/TaskStatus';
import TaskAssignee from 'components/BoardsDetail/components/ColumnContent/TaskInfoModal/TaskAssignee';
import { PrivateRoutesPaths } from '../../../routes/Routes.types';
import TaskLabels from '../../../components/BoardsDetail/components/ColumnContent/TaskInfoModal/TaskLabels';
import { useGetProjectByIdQuery } from '../../../redux/apiSlices/project.slice';

const TaskDetailPage = () => {
  const params = useParams<{ taskId: string }>();
  const taskId = params.taskId || '';

  const { data: task, isLoading } = useGetTaskQuery(taskId, { skip: !taskId });

  const { data: projectData } = useGetProjectByIdQuery(task?.projectId || '', {
    skip: !task?.projectId,
  });
  const breadcrumbItems = useBoardDetailsBreadcrumb({
    boardId: task?.boardId || '',
    projectId: task?.projectId || '',
    taskId,
  });

  return (
    <DetailsPageWrapper
      loading={isLoading}
      header={{ breadcrumbItems }}
      bodyBgColor={projectData?.color}
    >
      {task ? (
        <Box
          display="flex"
          width={1}
          gap={2}
          flexDirection="column"
          height={1}
          padding={1}
          maxHeight={1}
          overflow="auto"
        >
          <Box>
            <TaskTitle title={task.title} taskId={task.taskId} wrap />
          </Box>
          <Box
            width={1}
            maxWidth={1}
            display="flex"
            gap={2}
            flexGrow={1}
            sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}
            overflow="auto"
          >
            <Box sx={{ height: { md: 1 }, flexGrow: 1 }}>
              <TaskDescription taskId={taskId} description={task.description} />
            </Box>
            <Box
              display="flex"
              gap={2}
              sx={{
                flexDirection: 'column',
                paddingTop: { md: 4 },
                width: { md: 0.2 },
                maxWidth: { md: 0.2 },
              }}
            >
              <TaskStatus task={task} />
              <TaskLabels task={task} />
              <TaskAssignee
                projectId={task.projectId}
                taskId={task.taskId}
                assignee={task.assignee}
              />
              <TaskDuedate taskId={task.taskId} dueDate={task.dueDate} />
              <TaskEstimate taskId={task.taskId} estimate={task.estimate} />
            </Box>
          </Box>
        </Box>
      ) : (
        <Navigate to={`/${PrivateRoutesPaths.pageNotFound}`} />
      )}
    </DetailsPageWrapper>
  );
};

export default TaskDetailPage;
