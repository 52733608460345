import { FirestoreError } from 'firebase/firestore';
import { IAuthUserDTO } from 'shared/models/user.model';
import { httpsCallable } from 'firebase/functions';
import {
  IInviteUserReturnDataDB,
  IUserBaseInfoDB,
} from 'shared/types/user.types';
import { EFirestoreCollectionPaths } from 'shared/types/FirestoreCollections';
import {
  auth,
  doc,
  firestore,
  functions,
  getDoc,
  setDoc,
} from '../firebase.config';
import {
  authUserConverter,
  getUserBaseInfoConverter,
  updateAuthUserConverter,
} from '../../data/converters/authUser.converter';
import {
  ICreateUserDocumentUI,
  IShortcutUi,
  IUserBaseInfoUI,
  TUpdateAuthUserUI,
} from '../../data/types/user.types'; 

export const getAuthUser = async () => {
  try {
    const { currentUser } = auth;
    const authUserId = currentUser?.uid;
    if (authUserId && currentUser?.emailVerified) {
      const userRef = doc(
        firestore,
        EFirestoreCollectionPaths.AUTH_USERS,
        authUserId,
      );
      const docSnapshot = await getDoc(userRef);
      if (docSnapshot.exists()) {
        const data = authUserConverter.fromDb({
          ...(docSnapshot.data() as IAuthUserDTO),
          documentId: docSnapshot.id,
        });
        return {
          data,
        };
      }
    }
    return {
      data: null,
    };
  } catch (error) {
    return {
      error: (error as FirestoreError).message,
    };
  }
};

export const getAllUsersBaseInfo = async (): Promise<
  IUserBaseInfoUI[] | null
> => {
  const response = await httpsCallable(functions, 'user-listSystemUsers')();
  const usersDB = response.data as IUserBaseInfoDB[];
  const convertedResponse = usersDB.map(user =>
    getUserBaseInfoConverter.fromDb(user),
  );
  return convertedResponse;
};

export const updateAuthUser = async (data: TUpdateAuthUserUI) => {
  try {
    const { currentUser } = auth;
    const authUserId = currentUser?.uid;
    const userRef = doc(
      firestore,
      EFirestoreCollectionPaths.AUTH_USERS,
      authUserId || '',
    );
    const body = updateAuthUserConverter.toDb(data);
    await setDoc(userRef, body, { merge: true });
    return {
      data: body,
    };
  } catch (error) {
    return {
      error: (error as FirestoreError).message,
    };
  }
};

export const createUserDocument = async (data: ICreateUserDocumentUI) => {
  try {
    const userRef = doc(
      firestore,
      EFirestoreCollectionPaths.AUTH_USERS,
      data.id,
    );
    await setDoc(userRef, { name: data.name, email: data.email });
    return {
      data: undefined,
    };
  } catch (error) {
    return {
      error: (error as FirestoreError).message,
    };
  }
};

export const updateUserShortcuts = async (data: IShortcutUi[]) => {
  const { currentUser } = auth;
  const authUserId = currentUser?.uid;
  const userRef = doc(
    firestore,
    EFirestoreCollectionPaths.AUTH_USERS,
    authUserId || '',
  );
  await setDoc(
    userRef,
    {
      settings: {
        shortcuts: data,
      },
    },
    { merge: true },
  );
};

export const inviteUserToSystem = async (data: {
  emails: string[];
}): Promise<IInviteUserReturnDataDB> => {
  const response = await httpsCallable(
    functions,
    'user-addUserToTheSystem',
  )(data);
  return response.data as IInviteUserReturnDataDB;
};
