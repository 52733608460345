import NameModal from 'components/Dialogs/NameModal';
import { FolderNameSchema } from 'data/validation/DocSchemas';
import { FC } from 'react';
import { useRenameDocItemMutation } from 'redux/apiSlices/doc.slice';

type TRenameDocItemModalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  initialName: string;
  projectId: string;
  docId: string;
  docItemId: string;
  isFolder?: boolean;
};

const RenameDocItemModal: FC<TRenameDocItemModalProps> = ({
  docId,
  docItemId,
  initialName,
  open,
  setOpen,
  projectId,
  isFolder,
}) => {
  const [renameDocItem] = useRenameDocItemMutation();
  if (!open) return null;
  return (
    <NameModal
      modalTitle="Rename"
      open={open}
      setOpen={setOpen}
      validationSchema={isFolder ? FolderNameSchema : undefined}
      initialName={initialName}
      onSubmitModal={value =>
        renameDocItem({
          projectId,
          newName: value,
          docId,
          docItemId,
        }).unwrap()
      }
    />
  );
};

export default RenameDocItemModal;
