import { FC, MouseEvent, useState } from 'react';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { Folder } from '@mui/icons-material';
import DetailsAddButton from 'components/DetailsPageWrapper/components/DetailsAddButton';
import AddFolderModal from './AddFolder';
import AddFile from './AddFile';
import AddCustomDoc from './AddCustomDoc';

type TAddDocItemProps = {
  projectId: string;
  docId: string;
  currentLocation: string;
  addText?:string
};

const AddDocItem: FC<TAddDocItemProps> = ({
  projectId,
  docId,
  currentLocation,
  addText='Add'
}) => {
  const [openFolderModal, setOpenFolderModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <DetailsAddButton
        onClick={handleOpenMenu}
        label={addText}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
      >
        <AddCustomDoc
          projectId={projectId}
          docId={docId}
          currentLocation={currentLocation}
        />

        <MenuItem
          onClick={() => {
            setOpenFolderModal(true);
            handleCloseMenu();
          }}
        >
          <ListItemIcon>
            <Folder fontSize="small" />
          </ListItemIcon>
          <ListItemText>New Folder</ListItemText>
        </MenuItem>

        <AddFile
          onCloseMenu={handleCloseMenu}
          projectId={projectId}
          docId={docId}
          currentLocation={currentLocation}
        />
      </Menu>
      {openFolderModal && (
        <AddFolderModal
          setOpen={setOpenFolderModal}
          open={openFolderModal}
          projectId={projectId}
          docId={docId}
          currentLocation={currentLocation}
        />
      )}
    </>
  );
};

export default AddDocItem;
