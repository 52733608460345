import { FC, useEffect, useRef } from 'react';
import { Box, CircularProgress, Paper, Stack } from '@mui/material';
import useElementResize from 'hooks/useElementResize';
import BreadcrumbNavigation from 'components/BreadcrumbNavigation';
import { TBreadcrumbItem } from 'components/BreadcrumbNavigation/BreadcrumbNavigation.types';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

type TDetailsPageWrapperProps = {
  header: {
    rightAction?: React.ReactNode;
    leftAction?: React.ReactNode;
    breadcrumbItems: TBreadcrumbItem[];
  };
  bodyBgColor?: string;
  children: React.ReactNode;
  loading?: boolean;
  setIsMobileView?: (value: boolean) => void;
  isEmptyView?: boolean;
  emptyView?: React.ReactNode;
};

const DetailsPageWrapper: FC<TDetailsPageWrapperProps> = ({
  header,
  bodyBgColor,
  children,
  loading,
  setIsMobileView,
  isEmptyView,
  emptyView,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { height: headerHeight, width } = useElementResize({
    ref: headerRef,
  });
  useEffect(() => {
    if (width < 765 && setIsMobileView) {
      setIsMobileView(true);
    } else if (setIsMobileView) setIsMobileView(false);
  }, [width]);
  const pageMainContentMargins = 32;
  const headerMarginBottom = 16;

  const bodyDistanceToScreenTop =
    headerHeight + pageMainContentMargins + headerMarginBottom;

  let content = children;
  if (loading) {
    content = (
      <Stack justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Stack>
    );
  }
  if (isEmptyView && !loading) {
    content = emptyView;
  }

  return (
    <Box
      margin={2}
      marginRight={1}
      height="-webkit-fill-available"
      maxWidth="100%"
    >
      <Box ref={headerRef}>
        <Paper
          elevation={3}
          sx={theme => ({
            paddingX: 2,
            paddingY: 2,
            marginBottom: 2,
            backgroundColor: bodyBgColor || theme.palette.background.paper,
            minHeight: 8,
          })}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            wrap="wrap"
            spacing={1}
            minHeight={62}
          >
            <Grid>{header.leftAction}</Grid>
            <Grid xs={12} sm="auto" sx={{ order: { xs: 3, sm: 2 } }}>
              <BreadcrumbNavigation items={header.breadcrumbItems} />
            </Grid>
            <Grid sx={{ order: { xs: 2, sm: 3 } }}>{header.rightAction}</Grid>
          </Grid>
        </Paper>
      </Box>
      <Box sx={{ height: `calc(100vh - ${bodyDistanceToScreenTop}px)` }}>
        <Paper
          sx={{
            padding: 1,
            height: '100%',
            overflowY: 'auto',
          }}
          elevation={3}
        >
          <Box
            sx={{
              height: 1,
              ...(loading && {
                justifyContent: 'center',
                alignItems: 'center',
              }),
            }}
          >
            {content}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default DetailsPageWrapper;
