import { FC, ReactNode } from 'react';
import Fab, { FabOwnProps } from '@mui/material/Fab';
import { Tooltip } from '@mui/material';
import { useGetProjectByIdQuery } from 'redux/apiSlices/project.slice';

const ShortcutItem: FC<{
  onClick: () => void;
  isOpened: boolean;
  itemSize: FabOwnProps['size'];
  icon: ReactNode;
  name: string;
  projectId: string;
}> = ({ name, onClick, isOpened, itemSize, projectId, icon }) => {
  const { data: projectData } = useGetProjectByIdQuery(projectId);

  return (
    <Tooltip title={name} placement="left">
      <Fab
        aria-label="add"
        onClick={onClick}
        sx={{
          background: projectData?.color || '',
          border: isOpened ? '1px solid black' : '',
        }}
        size={itemSize}
      >
        {icon}
      </Fab>
    </Tooltip>
  );
};

export default ShortcutItem;
