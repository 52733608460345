import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from 'firebaseServices/firebase.config';
import {
  useCreateUserDocumentMutation,
  useLazyGetAuthUserQuery,
} from '../../redux/apiSlices/authUser.slice';

const useSignInWithGoogle = () => {
  const [createUserDocument] = useCreateUserDocumentMutation();
  const [getAuthUser] = useLazyGetAuthUserQuery();

  const signInWithGoogle = async () => {
    const userCredential = await signInWithPopup(
      auth,
      new GoogleAuthProvider(),
    );

    if (
      userCredential &&
      userCredential.user.uid &&
      userCredential.user.email
    ) {
      const existingUser = await getAuthUser().unwrap();
      if (!existingUser) {
        await createUserDocument({
          email: userCredential.user.email,
          name: userCredential.user.displayName,
          id: userCredential.user.uid,
        }).unwrap();
        await getAuthUser().unwrap();
      }
    }
  };
  return { signInWithGoogle };
};

export default useSignInWithGoogle;
