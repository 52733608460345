import { useEffect, useState } from 'react';
import {
  collection,
  firestore,
  getDocs,
  query,
  where,
  onSnapshot,
} from '../../firebase.config';
import {
  EFirestoreCollectionPaths,
  EProjectSubCollectionPaths,
} from '../../../shared/types/FirestoreCollections';

interface IProps {
  projectId: string;
  chatId: string;
  userId: string;
}

const useListenUnreadMessages = ({ projectId, chatId, userId }: IProps) => {
  const [unreadMessages, setUnreadMessages] = useState(0);
  const ref = collection(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    projectId,
    EProjectSubCollectionPaths.CHATS,
    chatId,
    EProjectSubCollectionPaths.MESSAGES,
  );

  const seenQuery = query(ref, where('seenBy', 'array-contains', userId));

  useEffect(() => {
    const unsubscribe = onSnapshot(query(ref), allSnapshot => {
      getDocs(seenQuery).then(seenSnapshot => {
        const allMessagesLength = allSnapshot.size;
        const seenMessagesLength = seenSnapshot.size;
        setUnreadMessages(allMessagesLength - seenMessagesLength);
      });
    });
    return () => {
      unsubscribe();
    };
  }, [chatId, userId, projectId]);

  return { unreadMessages };
};

export default useListenUnreadMessages;
