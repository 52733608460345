import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { IChatUi } from 'data/types/chat.types';
import { EProjectSubCollectionPaths } from 'shared/types/FirestoreCollections';
import {
  useDeleteChatMutation,
  useRenameChatMutation,
} from 'redux/apiSlices/chats.slice';
import {
  useGetAuthUserQuery,
  useUpdateUserShortcutsMutation,
} from 'redux/apiSlices/authUser.slice';
import { EShortcutTypes } from 'shared/types/generic.types';
import ActiveToolCard from '../../ActiveToolCard';
import ChatPreview from './ChatPreview';

const ChatCard: FC<{ chat: IChatUi }> = ({ chat }) => {
  const [updateShortcuts] = useUpdateUserShortcutsMutation();
  const { data: userData } = useGetAuthUserQuery();
  const navigate = useNavigate();
  const [renameChat] = useRenameChatMutation();
  const [deleteChat, { isLoading: deleteLoading }] = useDeleteChatMutation();

  const onChatItemClick = () => {
    navigate(`${EProjectSubCollectionPaths.CHATS}/${chat.documentId}`);
  };
  const inShortcuts = !!(userData?.settings?.shortcuts || []).find(
    item =>
      item.type === EShortcutTypes.CHAT && item.chatId === chat.documentId,
  );
  const onPin = async () => {
    if (inShortcuts && userData?.settings?.shortcuts.length) {
      try {
        await updateShortcuts(
          userData.settings.shortcuts.filter(
            item =>
              !(
                item.type === EShortcutTypes.CHAT &&
                item.chatId === chat.documentId
              ),
          ),
        ).unwrap();
      } catch {
        // TODO handle error
      }
    } else if (!inShortcuts) {
      try {
        await updateShortcuts([
          ...(userData?.settings?.shortcuts || []),
          {
            projectId: chat.projectId,
            type: EShortcutTypes.CHAT,
            pinned: false,
            chatId: chat.documentId,
            name: chat.name,
            open: false,
            id: chat.documentId,
          },
        ]).unwrap();
      } catch {
        // TODO handle error
      }
    }
  };
  return (
    <ActiveToolCard
      key={chat.documentId}
      onClick={onChatItemClick}
      name={chat.name}
      pinProps={{
        inShortcuts,
        onClick: onPin,
      }}
      onRenameTool={async newName => {
        await renameChat({
          projectId: chat.projectId,
          chatId: chat.documentId,
          newChatName: newName,
        }).unwrap();
      }}
      onRemoveTool={async () => {
        await deleteChat({
          projectId: chat.projectId,
          chatId: chat.documentId,
        }).unwrap();
      }}
      removeLoading={deleteLoading}
    >
      <ChatPreview chat={chat} />
    </ActiveToolCard>
  );
};
export default ChatCard;
