import { Box, FabOwnProps, Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import { useGetAuthUserQuery } from 'redux/apiSlices/authUser.slice';
import PinnedContent from './PinnedContent/PinnedContent';
import Shortcuts from './Shortcuts/Shortcuts';
import DrawerContent from './DrawerContent/DrawerContent';
import ProfileMenu from './ProfileMenu';
import LogoButton from './LogoButton';

const itemsSizeMapper: Record<NonNullable<FabOwnProps['size']>, number> = {
  small: 40,
  medium: 48,
  large: 56,
};

const PinedLayout = () => {
  const { data } = useGetAuthUserQuery();
  const shortcuts = data?.settings?.shortcuts || [];

  const [openedShortcutId, setOpenedShortcutId] = useState<string>('');

  const itemsSize: FabOwnProps['size'] = 'medium';
  const iconSize: FabOwnProps['size'] = 'large';

  return (
    <Box display="flex" flex={1}>
      <Box sx={{ overflowX: 'auto', flexGrow: 1 }}>
        <Outlet />
      </Box>
      <DrawerContent
        shortcuts={shortcuts}
        openedShortcutId={openedShortcutId}
        setOpenedShortcutId={setOpenedShortcutId}
        userId={data?.id || ''}
      />
      <PinnedContent shortcuts={shortcuts} userId={data?.id || ''} />
      <Stack
        spacing={2}
        direction="column"
        justifyContent="space-between"
        alignItems="end"
        paddingTop={2}
        sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
        height="100vh"
      >
        <LogoButton size={itemsSizeMapper[iconSize]} />

        <Stack direction="column" spacing={2} padding={2} paddingLeft={1}>
          <Shortcuts
            shortcuts={shortcuts}
            openedShortcutId={openedShortcutId}
            setOpenedShortcut={setOpenedShortcutId}
            itemSize={itemsSize}
          />
          <Box paddingTop={2} />
          <ProfileMenu size={itemsSizeMapper[itemsSize]} />
        </Stack>
      </Stack>
    </Box>
  );
};

export default PinedLayout;
