import { FC, memo } from 'react';
import { IBoardUI } from 'data/types/board.type';
import BoardCard from './BoardCard';

const AllBoards: FC<{ boards: IBoardUI[] }> = memo(({ boards }) => {
  return (
    <>
      {boards.map(board => {
        return <BoardCard board={board} key={board.documentId} />;
      })}
    </>
  );
});

export default AllBoards;
