import { FC } from 'react';
import { OutputData } from '@editorjs/editorjs';
import { Box, Typography } from '@mui/material';
import PreviewIcon from 'components/Preview/FilePreview/PreviewIcon';
import { createReactEditorJS } from 'react-editor-js';
import { EDITOR_JS_TOOLS } from 'pages/privatePages/CustomDocDetailsPage/CustomDoc/Editor/tools';
import styled from 'styled-components';

type TCustomDocPreviewProps = {
  content: OutputData;
  label?: string;
};

const SPreviewHolder = styled.div`
  .codex-editor {
    .codex-editor__redactor {
      margin-right: 0px !important;
      .ce-block:first-of-type {
        display: none;
      }
    }
  }
`;

const CustomDocPreview: FC<TCustomDocPreviewProps> = ({ content, label }) => {
  const ReactEditorJS = createReactEditorJS();
  const firstBlocks = content.blocks.slice(0, 5);
  const emptyContent = !content.blocks.length;
  const showBoarder = !emptyContent && label;

  return (
    <>
      {emptyContent ? (
        <PreviewIcon height={1} />
      ) : (
        <Box
          sx={theme => ({
            maxHeight: 'inherit',
            overflow: 'hidden',
            border: showBoarder
              ? `1px solid ${theme.palette.grey[500]}`
              : 'none',
            borderRadius: theme.shape.borderRadius,
            paddingInline: theme.spacing(1),
            fontSize: theme.typography.caption.fontSize,
            pointerEvents: 'none',
            height: '100%',
          })}
        >
          <ReactEditorJS
            readOnly
            defaultValue={{
              time: content.time,
              blocks: firstBlocks,
              version: content.version,
            }}
            tools={EDITOR_JS_TOOLS}
            holder={`preview-holder ${content.time}`}
            key={content.time}
          >
            <SPreviewHolder id={`preview-holder ${content.time}`} />
          </ReactEditorJS>
        </Box>
      )}
      {label && (
        <Typography
          variant="body2"
          lineHeight={1.2}
          sx={{ wordBreak: 'break-all' }}
          mt={0.5}
        >
          {label.substring(0, 15)}
        </Typography>
      )}
    </>
  );
};

export default CustomDocPreview;
