import { FC, useMemo } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useGetProjectByIdQuery } from 'redux/apiSlices/project.slice';
import MemberAvatar from '../../../MemberAvatar';

const AssigneeFilter: FC<{
  projectId: string;
  value: string[];
  onChange: (value: string[]) => void;
}> = ({ projectId, onChange, value }) => {
  const { data, isLoading } = useGetProjectByIdQuery(projectId);
  const members = data?.members;

  const selectValue = useMemo(
    () => members?.filter(item => value.includes(item.userId)) || [],
    [data, value],
  );
  return (
    <Autocomplete
      onChange={(event, newValue) => {
        onChange(newValue.map(item => item.userId));
      }}
      multiple
      value={selectValue}
      getOptionLabel={option => option.name}
      fullWidth
      popupIcon={
        isLoading ? <CircularProgress color="inherit" size={20} /> : null
      }
      renderInput={params => (
        <TextField
          sx={{  width: 300, maxWidth: 300 }}
          {...params}
          size="small"
          label="Assignee"
        />
      )}
      options={members || []}
      renderOption={(props,option)=>(
        <li {...props} key={option.userId}>
          <MemberAvatar
            {...option}
            hideBadge
            avatarSize={24}
            avatarSx={{
              marginRight: 0.5,
            }}
          />
          {option.name}
        </li>
      )}
    />
  );
};

export default AssigneeFilter;
