import { Grid, Typography } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { FC, ReactNode } from 'react';
import EventContentPrefix from './EventContentPrefix';

type TOldNewValueContentProps = {
  oldValue?: string | ReactNode;
  newValue?: string | ReactNode;
  previewMode?: boolean;
  alignItems?: 'center' | 'stretch';
  prefixText: string;
};

const OldNewValueContent: FC<TOldNewValueContentProps> = ({
  oldValue,
  newValue,
  previewMode,
  alignItems = 'stretch',
  prefixText,
}) => {
  const typographyVariant = previewMode ? 'body2' : 'body1';
  const arrowSize = previewMode ? 'small' : 'medium';
  return (
    <Grid
      container
      wrap="nowrap"
      width="100%"
      direction="row"
      alignItems={alignItems}
      spacing={1}
    >
      {prefixText && (
        <Grid item>
          <EventContentPrefix prefixText={prefixText} />
        </Grid>
      )}

      <Grid item>
        <Typography variant={typographyVariant} color="textSecondary">
          {oldValue || 'None'}
        </Typography>
      </Grid>

      <Grid item>
        <ArrowRightAltIcon fontSize={arrowSize} />
      </Grid>

      <Grid item>
        <Typography variant={typographyVariant} color="textSecondary">
          {newValue || 'None'}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default OldNewValueContent;
