import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { FC } from 'react';
import { Close, CopyAll } from '@mui/icons-material';
import { useDeleteTaskMutation } from 'redux/apiSlices/tasks.slice';
import { ITaskUI } from 'data/types/task.type';
import { useSnackbar } from 'notistack';
import { PrivateRoutesPaths } from 'routes/Routes.types';
import DeleteIcon from '@mui/icons-material/Delete';
import { TRtkErrorType } from 'data/types/general.types';
import { errorMessages } from 'data/messages/messages';
import TaskTitle from './TaskTitle';
import TaskAssignee from './TaskAssignee';
import TaskStatus from './TaskStatus';
import TaskDescription from './TaskDescription';
import MenuDropdown from '../../../../MenuDropdown';
import TaskDuedate from './TaskDuedate';
import TaskEstimate from './TaskEstimate';
import TaskLabels from './TaskLabels';

interface IUpdateTaskModalProps {
  open: boolean;
  closeModal: () => void;
  task: ITaskUI;
}
const TaskInfoModal: FC<IUpdateTaskModalProps> = ({
  open,
  closeModal,
  task,
}) => {
  const [deleteTask, { isLoading }] = useDeleteTaskMutation();
  const { enqueueSnackbar } = useSnackbar();
  const onDelete = async () => {
    try {
      await deleteTask({
        taskId: task.taskId,
      }).unwrap();
    } catch (err) {
      const error = err as TRtkErrorType;
      enqueueSnackbar(error?.data.message || errorMessages.default, {
        variant: 'error',
      });
    }
    closeModal();
  };
  return (
    <Dialog open={open} fullWidth maxWidth="xl">
      <DialogTitle>
        <Box
          width={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          paddingBottom={0}
          flexGrow={0.1}
        >
          <TaskTitle taskId={task.taskId} title={task.title} />
          <Box display="flex" gap={1}>
            <Tooltip title="Copy Link">
              <IconButton
                onClick={async () => {
                  await navigator.clipboard.writeText(
                    `${
                      window.location.host
                    }/${PrivateRoutesPaths.TaskDetails.replace(
                      ':taskId',
                      task.taskId,
                    )}`,
                  );
                  enqueueSnackbar({ message: 'Link Copied To Clipboard' });
                }}
              >
                <CopyAll />
              </IconButton>
            </Tooltip>
            <MenuDropdown
              menuItems={[
                <MenuItem onClick={onDelete} disabled={isLoading}>
                  <DeleteIcon />
                  Delete
                </MenuItem>,
              ]}
            />
            <IconButton onClick={closeModal}>
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ height: 800 }}>
        <Box
          width={1}
          maxWidth={1}
          paddingTop={2}
          display="flex"
          gap={2}
          flexGrow={0.9}
          sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}
          height={1}
          overflow="auto"
        >
          <Box sx={{ height: { xs: 0.65, md: 1 }, flexGrow: 1 }}>
            <TaskDescription
              taskId={task.taskId}
              description={task.description}
            />
          </Box>
          <Box
            display="flex"
            gap={2}
            sx={{
              flexDirection: 'column',
              paddingTop: { md: 4 },
              width: { md: 0.2 },
              maxWidth: { md: 0.2 },
            }}
          >
            <TaskStatus task={task} />
            <TaskLabels task={task} />
            <TaskAssignee
              assignee={task.assignee}
              projectId={task.projectId}
              taskId={task.taskId}
            />
            <TaskDuedate taskId={task.taskId} dueDate={task.dueDate} />
            <TaskEstimate taskId={task.taskId} estimate={task.estimate} />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TaskInfoModal;
