import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBoardFilter } from '../../data/types/board.type';

type TBoardFilterSliceState = Record<string, IBoardFilter>;
const storagePath = 'boardsFilter';
const dataFromStorage = JSON.parse(localStorage.getItem(storagePath) || '{}');
const initialState: TBoardFilterSliceState = dataFromStorage;

const boardFilterSlice = createSlice({
  name: 'boardsFilter',
  initialState,
  reducers: {
    setBoardFilter: (
      state,
      action: PayloadAction<{
        boardId: string;
        filters: IBoardFilter;
      }>,
    ) => {
      state[action.payload.boardId] = action.payload.filters;
      localStorage.setItem(
        storagePath,
        JSON.stringify({
          ...state,
          [action.payload.boardId]: action.payload.filters,
        }),
      );
    },
    clearAllFilters: () => {
      localStorage.setItem(storagePath, JSON.stringify({}));
      return {};
    },
  },
});

export default boardFilterSlice;

export const { setBoardFilter,clearAllFilters } = boardFilterSlice.actions;
