import { collection, getDocs, orderBy } from 'firebase/firestore';
import {
  firestore,
  doc,
  writeBatch,
  getDoc,
  query,
  functions,
} from 'firebaseServices/firebase.config';
import {
  IBoardUI,
  IColumnUI,
  ICreateNewBoardUI,
  ICreateNewColumnUI,
  IDeleteColumnUI,
  IPutUpdateColumnTasksUI,
  IRemoveBoardUI,
  IUpdateBoardTitleUI,
  IUpdateColumnColorUI,
  IUpdateColumnsOrderUI,
  IUpdateColumnTitleUI,
} from 'data/types/board.type';
import {
  getBoardConverter,
  getColumnConverter,
} from 'data/converters/board.converter';
import { IBoardDTO, IColumnDTO } from 'shared/models/board.model';
import { grey } from '@mui/material/colors';
import {
  EFirestoreCollectionPaths,
  EProjectSubCollectionPaths,
} from 'shared/types/FirestoreCollections';
import FirestoreOperations from 'firebaseServices/FirestoreOperations';
import { httpsCallable } from 'firebase/functions';

export const getProjectBoards = async (
  projectId: string,
): Promise<IBoardUI[] | null> => {
  const boardsRef = collection(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    projectId,
    EProjectSubCollectionPaths.BOARDS,
  );

  const docSnapshot = await getDocs(boardsRef);

  if (docSnapshot.empty || docSnapshot.docs.every(d => !d.exists)) {
    return null;
  }
  const boards = docSnapshot.docs.map(document => {
    return getBoardConverter.fromDb({
      ...(document.data() as IBoardDTO),
      documentId: document.id,
    });
  });
  return boards.sort((a, b) => {
    if (a.creationDate && b.creationDate) {
      return b.creationDate - a.creationDate;
    }
    return 0;
  });
};

export const getBoardById = async ({
  boardId,
  projectId,
}: {
  projectId: string;
  boardId: string;
}) => {
  const boardRef = doc(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    projectId,
    EProjectSubCollectionPaths.BOARDS,
    boardId,
  );
  const snapshot = await getDoc(boardRef);
  if (snapshot.exists()) {
    return getBoardConverter.fromDb({
      ...(snapshot.data() as IBoardDTO),
      documentId: boardId,
    });
  }
  throw new Error('board not found');
};

export const createBoardColumn = async ({
  projectId,
  boardId,
  order,
  title,
  color,
  bySystem,
}: ICreateNewColumnUI) => {
  const columnRef = collection(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    projectId,
    EProjectSubCollectionPaths.BOARDS,
    boardId,
    EProjectSubCollectionPaths.COLUMNS,
  );
  const newColumn = {
    title,
    order,
    tasks: [],
    color: color || grey['50'],
    bySystem: bySystem || false,
  };
  await FirestoreOperations.addDoc(columnRef, newColumn);
  return newColumn;
};

export const addBoard = async ({ projectId, name }: ICreateNewBoardUI) => {
  const boardsRef = collection(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    projectId,
    EProjectSubCollectionPaths.BOARDS,
  );
  const newBoard: IBoardDTO = {
    name,
    projectId,
    creationDate: Date.now(),
  };
  const boardRef = await FirestoreOperations.addDoc(boardsRef, newBoard);
  await Promise.all([
    createBoardColumn({
      boardId: boardRef.id,
      order: 0,
      projectId,
      title: 'To Do',
      color: '#fafafa',
      bySystem: true,
    }),
    createBoardColumn({
      boardId: boardRef.id,
      order: 1,
      projectId,
      title: 'In Progress',
      color: '#fce4ec',
      bySystem: true,
    }),
    createBoardColumn({
      boardId: boardRef.id,
      order: 2,
      projectId,
      title: 'Done',
      color: '#e8f5e9',
      bySystem: true,
    }),
  ]);
  return newBoard;
};

export const deleteColumn = async (data: IDeleteColumnUI) => {
  await httpsCallable(functions, 'board-deleteColumn')(data);
};

export const updateColumnTasks = async ({
  columnId,
  boardId,
  tasks,
  projectId,
}: IPutUpdateColumnTasksUI) => {
  const boardRef = doc(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    projectId,
    EProjectSubCollectionPaths.BOARDS,
    boardId,
    EProjectSubCollectionPaths.COLUMNS,
    columnId,
  );
  await FirestoreOperations.updateDoc(boardRef, {
    tasks,
  });
};

export const updateColumnsOrder = async ({
  projectId,
  boardId,
  columns,
}: IUpdateColumnsOrderUI) => {
  const batch = writeBatch(firestore);
  columns.forEach(column => {
    const columnRef = doc(
      firestore,
      EFirestoreCollectionPaths.PROJECTS,
      projectId,
      EProjectSubCollectionPaths.BOARDS,
      boardId,
      EProjectSubCollectionPaths.COLUMNS,
      column.documentId,
    );
    batch.update(columnRef, { order: column.order });
  });
  await batch.commit();
};

export const updateColumnColor = async ({
  color,
  columnId,
  boardId,
  projectId,
}: IUpdateColumnColorUI) => {
  const columnRef = doc(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    projectId,
    EProjectSubCollectionPaths.BOARDS,
    boardId,
    EProjectSubCollectionPaths.COLUMNS,
    columnId,
  );
  await FirestoreOperations.updateDoc(columnRef, {
    color,
  });
};

export const updateColumnTitle = async ({
  columnId,
  boardId,
  projectId,
  title,
}: IUpdateColumnTitleUI) => {
  const columnRef = doc(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    projectId,
    EProjectSubCollectionPaths.BOARDS,
    boardId,
    EProjectSubCollectionPaths.COLUMNS,
    columnId,
  );

  await FirestoreOperations.updateDoc(columnRef, {
    title,
  });
};

export const getBoardColumns = async ({
  boardId,
  projectId,
}: {
  projectId: string;
  boardId: string;
}): Promise<IColumnUI[] | null> => {
  const collectionRef = collection(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    projectId,
    EProjectSubCollectionPaths.BOARDS,
    boardId,
    EProjectSubCollectionPaths.COLUMNS,
  );
  const q = query(collectionRef, orderBy('order', 'asc'));
  const snapshots = await getDocs(q);

  if (snapshots.empty || snapshots.docs.every(d => !d.exists())) {
    return null;
  }

  const data: IColumnUI[] = [];
  snapshots.forEach(snapshot => {
    const column = snapshot.data() as IColumnDTO;
    data.push(
      getColumnConverter.fromDb({ ...column, documentId: snapshot.id }),
    );
  });
  return data;
};

export const renameBoard = async ({
  projectId,
  boardId,
  name,
}: IUpdateBoardTitleUI) => {
  const boardRef = doc(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    projectId,
    EProjectSubCollectionPaths.BOARDS,
    boardId,
  );
  await FirestoreOperations.updateDoc(boardRef, {
    name,
  });
};

export const removeBoard = async (data: IRemoveBoardUI) => {
  // const boardRef = doc(
  //   firestore,
  //   EFirestoreCollectionPaths.PROJECTS,
  //   projectId,
  //   EProjectSubCollectionPaths.BOARDS,
  //   boardId,
  // );
  // await FirestoreOperations.deleteDoc(boardRef);
  await httpsCallable(functions, 'board-deleteBoard')(data);
};
