import { FC } from 'react';
import { Box, MenuItem, TextField, Typography } from '@mui/material';
import { useGetProjectByIdQuery } from 'redux/apiSlices/project.slice';
import { IProjectMemberUI } from 'data/types/project.types';
import { useUpdateTaskAssignerMutation } from 'redux/apiSlices/tasks.slice';
import { ITaskAssigneeUI } from 'data/types/task.type';
import MemberAvatar from '../../../../MemberAvatar';

const TaskAssignee: FC<{
  projectId: string;
  assignee?: ITaskAssigneeUI;
  taskId: string;
}> = ({ projectId, assignee, taskId }) => {
  const [updateAssignee] = useUpdateTaskAssignerMutation();
  const { data: projectData } = useGetProjectByIdQuery(projectId);
  const members: IProjectMemberUI[] = projectData?.members || [];

  const onUpdateAssignee = (member: IProjectMemberUI | null) => {
    updateAssignee({
      taskId,
      member,
    });
  };

  return (
    <>
      {!!members.length && (
        <TextField
          select
          fullWidth
          size="small"
          value={assignee?.userId || ''}
          label="Assignee"
          placeholder="Unassigned"
          sx={{
            '& .MuiSelect-select': {
              paddingY: 1.5,
            },
            '.MuiFormLabel-root': {
              lineHeight: '30px',
            },
          }}
        >
          <MenuItem
            key=""
            value=""
            onClick={() => {
              onUpdateAssignee(null);
            }}
          >
            Unassigned
          </MenuItem>
          {members.map(member => (
            <MenuItem
              key={member.userId}
              value={member.userId}
              onClick={() => {
                onUpdateAssignee(member);
              }}
            >
              <Box display="flex" flexWrap="nowrap">
                <MemberAvatar
                  {...member}
                  hideBadge
                  avatarSize={24}
                  avatarSx={{
                    marginLeft: 0.5,
                  }}
                />
                <Typography marginLeft={1} noWrap textOverflow="ellipsis">
                  {member.name}
                </Typography>
              </Box>
            </MenuItem>
          ))}
        </TextField>
      )}
    </>
  );
};
export default TaskAssignee;
