import { FC } from 'react';
import { Breadcrumbs } from '@mui/material';
import LinkRouter from 'components/Base/LinkRouter';
import { TBreadcrumbItem } from './BreadcrumbNavigation.types';
import EditableLastItem from './EditableLastItem';

type TBreadcrumbNavigationProps = {
  items: TBreadcrumbItem[];
};

const BreadcrumbNavigation: FC<TBreadcrumbNavigationProps> = ({ items }) => {
  // if (items.length === 0) {
  //   return <Skeleton variant="text" width={120} height={40} />;
  // }

  return (
    <Breadcrumbs
      sx={theme => ({
        fontSize: theme.typography.h6.fontSize,
        '& ol': {
          justifyContent: 'center',
          margin: 'auto',
        },
      })}
    >
      {items.map((item, index, arr) => {
        const last = index === arr.length - 1;
        return last ? (
          <EditableLastItem
            initialValue={item.text}
            key={item.text}
            onUpdate={item.onUpdateText}
          />
        ) : (
          <LinkRouter
            underline="hover"
            color="inherit"
            key={item.text}
            {...(item.onClick && { onClick: item.onClick })}
            to={{
              pathname: `/${item.link}`,
              ...(item.search && { search: item.search }),
            }}
          >
            {item.text}
          </LinkRouter>
        );
      })}
    </Breadcrumbs>
  );
};

export default BreadcrumbNavigation;
