import { useMemo } from 'react';
import { query, orderBy, Query } from 'firebase/firestore';
import {
  TGetDocItemsProps,
  TMoveDocItemProps,
  isAllowedDoc,
} from 'firebaseServices/queryFunctions/docsQueryFunctions';
import { TDocItemDTO } from 'shared/models/doc.model';
import { TDocItemUI } from 'data/types/doc.types';
import { getDocItemConverter } from 'data/converters/doc.converter';
import { firestoreReference } from 'firebaseServices/FirestoreReferenceManager';
import { useFirebaseCollectionListener } from '../useFirebaseCollectionListener';

const getDocItemsQuery = ({
  projectId,
  docId,
}:  
TGetDocItemsProps): Query<TDocItemDTO> => {
  const docsRef = firestoreReference.projectDocItems_CollectionRef({
    projectId,
    docId,
  });

  const documentQuery = query(
    docsRef,
    // where('location', '==', currentLocation),
    orderBy('name'),
  );

  return documentQuery as Query<TDocItemDTO>;
};

export const useProjectDocItemsListener = (props: TGetDocItemsProps) => {
  const { parentFolderId, projectId, docId, currentUserId, limit } = props;

  const documentQuery = useMemo(
    () => getDocItemsQuery(props),
    [projectId, docId],
  );
  const { snapshotData, isLoading, error, setSnapshotData } =
    useFirebaseCollectionListener<TDocItemDTO>(documentQuery);

  let data: TDocItemUI[] | null = snapshotData
    ? snapshotData
        .map(item =>
          getDocItemConverter.fromDb({
            ...item,
            documentId: item.documentId,
          }),
        )
        .filter(docItem => isAllowedDoc(docItem, currentUserId))
    : null;

  if (parentFolderId && data) {
    data = data.filter(docItem => docItem.parentFolderId === parentFolderId);
  }

  if (limit && data && data.length > limit) {
    data = data.slice(0, limit);
  }

  const moveDocItemInState = async ({
    destinationFolderId,
    docItemId,
  }: Omit<TMoveDocItemProps, 'projectId' | 'docId'>) => {
    setSnapshotData(prevData => {
      if (!prevData) return null;
      return prevData.map(item => {
        if (item.documentId === docItemId) {
          return {
            ...item,
            parentFolderId: destinationFolderId,
          };
        }
        return item;
      });
    });
  };

  return { data, isLoading, error, moveDocItemInState };
};
