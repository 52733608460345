import { dateFormat } from 'data/static';
import { TCreateConverter, TFetchConverter } from 'data/types/general.types';
import { IProjectMemberUI } from 'data/types/project.types';
import { ITaskAssigneeUI, ITaskUI } from 'data/types/task.type';
import dayjs from 'dayjs';
import { ITaskAssigneeDTO, ITaskDTO } from 'shared/models/task.model';
import { getFirstLetters, stringToColor } from 'utils/utils';

export const getTaskAssigneeConverter: TFetchConverter<
  ITaskAssigneeUI | undefined,
  ITaskAssigneeDTO | undefined
> = {
  fromDb: data => {
    if (data) {
      return {
        userId: data.userId,
        role: data.role,
        name: data.name,
        firstLetters: getFirstLetters(data.name),
        avatarColor: stringToColor(data.name || ''),
        email: data.email,
      };
    }
    return undefined;
  },
};

export const getTaskConverter: TFetchConverter<
  ITaskUI,
  ITaskDTO & {
    documentId: string;
  }
> = {
  fromDb: data => {
    return {
      taskId: data.documentId,
      boardId: data.boardId,
      projectId: data.projectId,
      columnId: data.columnId,
      title: data.title,
      assignee: getTaskAssigneeConverter.fromDb(data.assignee),
      description: data.description,
      dueDate: data.dueDate ? dayjs(data.dueDate).format(dateFormat) : '',
      estimate: data.estimate,
      labels: data.labels || [],
    };
  },
};

export const memberToTaskAssigneeConverter: TCreateConverter<
  IProjectMemberUI,
  ITaskAssigneeDTO
> = {
  toDb: data => {
    return {
      userId: data.userId,
      role: data.role,
      name: data.name,
      email: data.email,
    };
  },
};

export const updateTaskFromUiToFSConverter: TCreateConverter<
  ITaskUI,
  ITaskDTO
> = {
  toDb: data => {
    return {
      title: data.title,
      columnId: data.columnId,
      boardId: data.boardId,
      projectId: data.projectId,
      description: data.description,
      ...(data.assignee && {
        assignee: {
          name: data.assignee.name,
          email: data.assignee.email,
          userId: data.assignee.userId,
          role: data.assignee.role,
        },
      }),
      dueDate: data.dueDate,
      estimate: data.estimate,
    };
  },
};
