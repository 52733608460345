import { createApi } from '@reduxjs/toolkit/query/react';
import { fakeBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import {
  TAddProjectMemberProps,
  addNewProject,
  addProjectMembers,
  getProjectById,
  getProjectsByIds,
  removeProjectMember,
  updateProjectById,
  removeProject,
} from 'firebaseServices/queryFunctions/projectQueryFunctions';
import {
  IAddNewProjectUI,
  IEditProjectUI,
  IProjectUI,
} from 'data/types/project.types';
import { queryFnWrapper } from 'firebaseServices/queryFunctions/helper';
import { authUserApi } from './authUser.slice';

export const projectApi = createApi({
  tagTypes: ['projects', 'project'],
  reducerPath: 'project',
  baseQuery: fakeBaseQuery(),
  endpoints: build => ({
    addNewProject: build.mutation<void, IAddNewProjectUI>({
      queryFn: arg => queryFnWrapper(addNewProject, arg),
      invalidatesTags: ['projects'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(authUserApi.util.invalidateTags(['user']));
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    getUserProjects: build.query<IProjectUI[] | null, string[]>({
      queryFn: arg => queryFnWrapper(getProjectsByIds, arg),
      providesTags: ['projects'],
    }),
    getProjectById: build.query<IProjectUI | null, string>({
      queryFn: arg => queryFnWrapper(getProjectById, arg),
      providesTags: ['project'],
    }),
    editProjectNameColor: build.mutation<
      void,
      IEditProjectUI & { projectId: string }
    >({
      queryFn: arg => queryFnWrapper(updateProjectById, arg),
      invalidatesTags: ['project', 'projects'],
    }),
    addProjectMembers: build.mutation<void, TAddProjectMemberProps>({
      queryFn: arg => queryFnWrapper(addProjectMembers, arg),
      invalidatesTags: ['project'],
    }),
    removeProjectMember: build.mutation<
      void,
      { projectId: string; userId: string }
    >({
      queryFn: arg => queryFnWrapper(removeProjectMember, arg),
      invalidatesTags: ['project'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(authUserApi.util.invalidateTags(['user', 'all_users']));
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    removeProject: build.mutation<void, string>({
      queryFn: arg => queryFnWrapper(removeProject, arg),
      invalidatesTags: ['projects'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(authUserApi.util.invalidateTags(['user']));
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
  }),
});

export const {
  useAddNewProjectMutation,
  useGetUserProjectsQuery,
  useGetProjectByIdQuery,
  useEditProjectNameColorMutation,
  useAddProjectMembersMutation,
  useRemoveProjectMemberMutation,
  useRemoveProjectMutation,
} = projectApi;
