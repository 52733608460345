import { FC, ReactNode } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

type TAuthWrapperProps = {
  children: ReactNode;
  title?: string;
  description?: string;
  logo?: string;
};

const AuthWrapper: FC<TAuthWrapperProps> = ({
  children,
  description,
  logo,
  title,
}) => {
  return (
    <Grid alignItems="middle" container justifyContent="center">
      <Grid alignSelf="center">
        <Paper
          sx={theme => ({
            padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
            minWidth: '450px',
            borderRadius: `${theme.spacing(2)}px`,
          })}
          elevation={3}
        >
          <Grid container direction="column" rowSpacing={0} mb={3}>
            <Grid>{logo && <Box component="img" src="" />}</Grid>
            <Grid>
              {title && <Typography variant="h4">{title}</Typography>}
            </Grid>
            <Grid>
              {description && (
                <Typography variant="subtitle1">{description}</Typography>
              )}
            </Grid>
          </Grid>
          {children}
        </Paper>
      </Grid>
    </Grid>
  );
};
export default AuthWrapper;
