import React, { FC, useMemo } from 'react';
import { Box, Drawer } from '@mui/material';
import { useUpdateUserShortcutsMutation } from 'redux/apiSlices/authUser.slice';
import { IShortcutUi } from 'data/types/user.types';
import { EShortcutTypes } from 'shared/types/generic.types';
import BoardsDetail from 'components/BoardsDetail';
import CustomDoc from 'pages/privatePages/CustomDocDetailsPage/CustomDoc';
import DrawerHeader from './DrawerHeader';
import BoardFilter from '../../../BoardsDetail/components/BoardFilter';
import Chat from '../../../Chat';

const DrawerContent: FC<{
  openedShortcutId: string;
  setOpenedShortcutId: (value: string) => void;
  shortcuts: IShortcutUi[];
  userId: string;
}> = ({ setOpenedShortcutId, openedShortcutId, shortcuts, userId }) => {
  const [updateShortcuts] = useUpdateUserShortcutsMutation();

  const openedShortcut = useMemo(
    () => shortcuts.find(item => item.id === openedShortcutId),
    [openedShortcutId],
  );
  const onPin = async () => {
    await updateShortcuts(
      shortcuts.map(item => {
        if (item.id === openedShortcut?.id) {
          return {
            ...item,
            pinned: true,
            open: true,
          };
        }
        return {
          ...item,
          open: false,
        };
      }),
    );
    setOpenedShortcutId('');
  };
  const onRemoveShortcut = async () => {
    await updateShortcuts(
      shortcuts.filter(item => item.id !== openedShortcut?.id),
    );
    setOpenedShortcutId('');
  };
  const onClose = () => {
    setOpenedShortcutId('');
  };
  return (
    <Drawer
      open={!!openedShortcutId}
      anchor="right"
      onClose={() => {
        setOpenedShortcutId('');
      }}
    >
      <Box
        height={1}
        paddingLeft={1}
        paddingRight={11}
        maxWidth="50vw"
        width="50vw"
        display="flex"
        flexDirection="column"
        overflow="hidden"
      >
        <DrawerHeader
          onClose={onClose}
          onPin={onPin}
          onRemoveShortcut={onRemoveShortcut}
          boardFilter={
            openedShortcut?.type === EShortcutTypes.BOARD && (
              <BoardFilter
                projectId={openedShortcut.projectId}
                boardId={openedShortcut.boardGroupId}
              />
            )
          }
        />
        <Box flexGrow={1} overflow="auto">
          {openedShortcut?.type === EShortcutTypes.BOARD && (
            <BoardsDetail
              projectId={openedShortcut?.projectId}
              boardId={openedShortcut?.boardGroupId}
              headerActions
            />
          )}
          {openedShortcut?.type === EShortcutTypes.CUSTOM_DOC && (
            <CustomDoc
              projectId={openedShortcut.projectId}
              docId={openedShortcut.docId}
              customDocId={openedShortcut.customDocId}
            />
          )}
          {openedShortcut?.type === EShortcutTypes.CHAT && (
            <Chat
              projectId={openedShortcut.projectId}
              chatId={openedShortcut.chatId}
              userId={userId}
              fullWidth
            />
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default DrawerContent;
