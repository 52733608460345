import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import useChatBreadcrumbItems from 'hooks/BreadcrumbItems/useChatBreadcrumb';
import DetailsPageWrapper from 'components/DetailsPageWrapper';
import { useGetChatQuery } from 'redux/apiSlices/chats.slice';
import { useGetProjectByIdQuery } from 'redux/apiSlices/project.slice';
import {
  useGetAuthUserQuery,
  useUpdateUserShortcutsMutation,
} from 'redux/apiSlices/authUser.slice';
import { PrivateRoutesPaths } from 'routes/Routes.types';
import Chat from 'components/Chat';
import CreateShortcutButton from '../../../components/CreateShortcutButton';
import { EShortcutTypes } from '../../../shared/types/generic.types';
import { generateUid } from '../../../firebaseServices/firebase.config';
import { IChatShortcutDTO } from '../../../shared/models/user.model';

const ChatPage = () => {
  const [updateShortcuts] = useUpdateUserShortcutsMutation();
  const navigate = useNavigate();
  const { data: userData } = useGetAuthUserQuery();
  const params = useParams<{ chatId: string; projectId: string }>();
  const chatId = params.chatId || '';
  const projectId = params.projectId || '';
  const shortcuts = userData?.settings?.shortcuts || [];

  const { data: projectData } = useGetProjectByIdQuery(projectId, {
    skip: !projectId,
  });
  const {
    data: chat,
    isLoading,
    isError,
  } = useGetChatQuery(
    { chatId, projectId },
    {
      skip: !chatId || !projectId,
    },
  );
  useEffect(() => {
    if (isError) {
      navigate(`/${PrivateRoutesPaths.pageNotFound}`);
    }
  }, [isError]);
  const breadcrumbItems = useChatBreadcrumbItems({
    chatId,
    projectId,
    chatName: chat?.name,
  });

  const inShortcuts = shortcuts.find(
    item =>
      item.type === EShortcutTypes.CHAT &&
      item.projectId === projectId &&
      item.chatId === chatId,
  );
  const onAddShortcut = async () => {
    const newShortcut: IChatShortcutDTO = {
      type: EShortcutTypes.CHAT,
      projectId,
      pinned: false,
      name: chat?.name || '',
      open: false,
      id: generateUid(),
      chatId,
    };
    await updateShortcuts([...shortcuts, newShortcut]);
  };

  return (
    <DetailsPageWrapper
      header={{
        breadcrumbItems,
        rightAction: (
          <>
            {!inShortcuts && <CreateShortcutButton onClick={onAddShortcut} />}
          </>
        ),
      }}
      bodyBgColor={projectData?.color}
      loading={isLoading}
    >
      <Chat chatId={chatId} projectId={projectId} userId={userData?.id || ''} />
    </DetailsPageWrapper>
  );
};

export default ChatPage;
