import { FC } from 'react';
import {
  CardHeader as MuiCardHeader,
  IconButton,
  CardHeaderProps,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { typographyEllipsisStyles } from '../Typography/styles';

type TCardHeaderProps = CardHeaderProps & {
  hasaction?: {
    onActionClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  };
  headerEllipsis?: boolean;
  border?: {
    bottom?: boolean;
    top?: boolean;
  };
};

const CardHeader: FC<TCardHeaderProps> = props => {
  const {
    titleTypographyProps,
    hasaction,
    headerEllipsis: isHeaderEllipsis,
    border,
  } = props;
  return (
    <MuiCardHeader
      sx={theme => ({
        '.MuiCardHeader-content': {
          maxWidth: hasaction ? '88%' : '100%',
        },
        width: '100%',
        ...(border?.top && { borderTop: `1px solid ${theme.palette.divider}` }),
        ...(border?.bottom && {
          borderBottom: `1px solid ${theme.palette.divider}`,
        }),
      })}
      titleTypographyProps={{
        ...(isHeaderEllipsis ? typographyEllipsisStyles : {}),
        ...titleTypographyProps,
      }}
      action={
        hasaction && (
          <IconButton onClick={hasaction.onActionClick}>
            <MoreVertIcon />
          </IconButton>
        )
      }
      {...props}
    />
  );
};

export default CardHeader;
