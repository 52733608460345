import {
  Box,
  Card,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import { grey } from '@mui/material/colors';
import MemberAvatar from 'components/MemberAvatar';
import MenuDropdown from 'components/MenuDropdown';
import { timeFormat } from 'data/static';
import dayjs from 'dayjs';
import { IMessageUi } from 'data/types/message.types';
import {
  deleteMessage,
  readMessage,
} from 'firebaseServices/queryFunctions/chatQueryFunctions';
import { useInView } from 'react-intersection-observer';
import DeleteIcon from '@mui/icons-material/Delete';
import { EMessageTypes } from '../../../../shared/models/message.model';
import TextMessageContent from './TextMessageContent';

const MessageCard: FC<{
  message: IMessageUi;
  projectId: string;
  userId: string;
  forPreview?: boolean;
}> = ({ message, projectId, userId, forPreview }) => {
  const { ref, inView } = useInView({ threshold: 0.9 });

  const isMyMessage = userId === message.sender.id;
  const onDeleteMessage = async () => {
    try {
      await deleteMessage({
        messageId: message.documentId,
        chatId: message.chatId,
        projectId,
      });
    } catch {
      // TODO handle error
    }
  };

  useEffect(() => {
    if (!message.seenBy.includes(userId) && inView && !forPreview) {
      readMessage({
        userId,
        messageId: message.documentId,
        chatId: message.chatId,
        projectId,
      }).catch(() => {
        // TODO handle error
      });
    }
  }, [ref, inView]);

  const DropDownMenuItems = useMemo(() => {
    const menuItems = [];
    if (isMyMessage && !forPreview) {
      menuItems.push(
        <MenuItem key="Delete" onClick={onDeleteMessage}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>,
      );
    }
    return menuItems;
  }, [message]);

  return (
    <Box
      display="flex"
      flexWrap="nowrap"
      gap={1}
      id={forPreview ? '' : message.documentId}
    >
      {!isMyMessage && (
        <MemberAvatar
          name={message.sender.name}
          userId={message.sender.id}
          email={message.sender.email}
          avatarSize={forPreview ? 20 : 36}
          hideBadge
        />
      )}
      <Card
        ref={ref}
        sx={{
          padding: 1,
          display: 'flex',
          gap: 1,
          overflow: 'unset',
          width: 'fit-content',
          minWidth: 150,
          maxWidth: 0.9,
          marginLeft: isMyMessage ? 'auto' : '',
          borderRadius: isMyMessage
            ? '16px 0px 16px 16px '
            : '0px 16px 16px 16px ',
        }}
      >
        <Box width={1}>
          <Box display="flex" alignItems="start">
            {message.contentType === EMessageTypes.TEXT && (
              <TextMessageContent content={message.content} />
            )}
            {!!DropDownMenuItems.length && (
              <MenuDropdown
                menuItems={DropDownMenuItems}
                sx={{ marginLeft: 'auto', padding: 0.5 }}
              />
            )}
          </Box>
          <Box display="flex" justifyContent={isMyMessage ? 'start' : 'end'}>
            {!forPreview && (
              <Typography variant="body2" color={grey[500]}>
                {dayjs(message.creationDate).format(timeFormat)}
              </Typography>
            )}
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default MessageCard;
