import React, { FC } from 'react';
import { useAddFolderToDocMutation } from 'redux/apiSlices/doc.slice';
import NameModal from 'components/Dialogs/NameModal';
import { FolderNameSchema } from 'data/validation/DocSchemas';

type TAddFolderModalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  docId: string;
  projectId: string;
  currentLocation: string;
};

const AddFolderModal: FC<TAddFolderModalProps> = ({
  open,
  setOpen,
  projectId,
  docId,
  currentLocation,
}) => {
  const [addFolderToDoc] = useAddFolderToDocMutation();

  return (
    <NameModal
      open={open}
      setOpen={setOpen}
      modalTitle="New Folder"
      submitButtonTitle="Create"
      validationSchema={FolderNameSchema}
      onSubmitModal={value =>
        addFolderToDoc({
          docId,
          projectId,
          name: value,
          location: currentLocation,
        }).unwrap()
      }
    />
  );
};

export default AddFolderModal;
