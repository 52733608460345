import { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { ITaskUpdatedData } from 'shared/models/event.model';
import OneValueContent from '../../components/OneValueContent';

type TLabelUpdateEventProps = {
  labels: ITaskUpdatedData['labels'];
  previewMode?: boolean;
};

const LabelUpdateEvent: FC<TLabelUpdateEventProps> = ({
  labels,
  previewMode,
}) => {
  const prefixText = labels?.added?.length ? 'Added Label' : 'Removed Label';
  const labelsList = [...(labels?.added || []), ...(labels?.removed || [])];
  const typographyVariant = previewMode ? 'body2' : 'body1';
  return (
    <OneValueContent prefixText={prefixText}>
      <Grid container direction="row" spacing={2}>
        {labelsList.map(label => (
          <Grid item key={label.id}>
            <Typography variant={typographyVariant} color="textSecondary">
              {label.title}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </OneValueContent>
  );
};

export default LabelUpdateEvent;
