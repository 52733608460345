import { FC } from 'react';
import { Link as RouterLink, To } from 'react-router-dom';
import { Link, LinkProps } from '@mui/material';

type TLinkRouterProps = LinkProps & {
  to: To;
  replace?: boolean;
};

const LinkRouter: FC<TLinkRouterProps> = props => {
  return <Link {...props} component={RouterLink as any} />;
};

export default LinkRouter;
