import { FC, useMemo } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useGetProjectLabelsQuery } from 'redux/apiSlices/labels.slice';

const LabelFilter: FC<{
  projectId: string;
  value: string[];
  onChange: (value: string[]) => void;
}> = ({ projectId, onChange, value }) => {
  const { data, isLoading } = useGetProjectLabelsQuery(projectId);

  const selectValue = useMemo(
    () => data?.filter(item => value.includes(item.id)) || [],
    [data, value],
  );
  return (
    <Autocomplete
      onChange={(event, newValue) => {
        onChange(newValue.map(item => item.id));
      }}
      multiple
      value={selectValue}
      getOptionLabel={option => option.title}
      fullWidth
      popupIcon={
        isLoading ? <CircularProgress color="inherit" size={20} /> : null
      }
      renderInput={params => (
        <TextField
          sx={{ width: 300, maxWidth: 300 }}
          {...params}
          size="small"
          label="Label"
        />
      )}
      options={data || []}
    />
  );
};

export default LabelFilter;
