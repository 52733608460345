import { FC } from 'react';
import { Chip, Stack, Tooltip } from '@mui/material';
import { IProjectUI } from 'data/types/project.types';
import { useRemoveProjectMemberMutation } from 'redux/apiSlices/project.slice';
import { useSnackbar } from 'notistack';
import { errorMessages, successMessages } from 'data/messages/messages';
import { TRtkErrorType } from 'data/types/general.types';
import { PersonRemove } from '@mui/icons-material';
import { EUserRole } from 'shared/types/generic.types';
import MemberAvatar from 'components/MemberAvatar';
import AlertDialogWithState from 'components/Dialogs/AlertDialog/AlertDialogWithState';

type TManageProjectMembersListProps = {
  members: IProjectUI['members'];
  projectId: string;
  projectName: string;
};

const ManageProjectMembersList: FC<TManageProjectMembersListProps> = ({
  members,
  projectId,
  projectName,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [removeMember, { isLoading }] = useRemoveProjectMemberMutation();

  const handleDelete = async (userId: string) => {
    try {
      await removeMember({ projectId, userId }).unwrap();
      enqueueSnackbar(successMessages.toast, {
        variant: 'success',
      });
    } catch (error) {
      const e = error as TRtkErrorType;
      enqueueSnackbar(e?.data?.message || errorMessages.default, {
        variant: 'error',
      });
    }
  };
  return (
    <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
      {members.map(member => (
        <Chip
          key={member.userId}
          {...(member.role !== EUserRole.OWNER && {
            deleteIcon: (
              <AlertDialogWithState
                title="Remove person"
                text={`Are you sure you want to remove ${member.email} from ${projectName}?`}
                submitDisabled={isLoading}
                submitLoading={isLoading}
                onConfirm={() => handleDelete(member.userId)}
              >
                <Tooltip title={`Remove person from ${projectName}`}>
                  <PersonRemove className="MuiChip-deleteIcon" />
                </Tooltip>
              </AlertDialogWithState>
            ),
            onDelete: () => null,
          })}
          {...(member.role === EUserRole.OWNER && {
            sx: theme => ({
              border: `2px solid ${theme.palette.warning.main}`,
            }),
          })}
          avatar={
            <MemberAvatar
              {...member}
              hideBadge
              avatarSize={24}
              avatarSx={{
                marginLeft: 0.5,
              }}
            />
          }
          label={member.email}
          variant="outlined"
        />
      ))}
    </Stack>
  );
};

export default ManageProjectMembersList;
