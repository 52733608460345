import { FC } from 'react';
import { IChatUi } from 'data/types/chat.types';
import useListenChatMessages from 'firebaseServices/listeners/Chats/useListenChatMessages';
import { useGetAuthUserQuery } from 'redux/apiSlices/authUser.slice';
import { Box } from '@mui/material';
import MessageIcon from '@mui/icons-material/Message';
import useListenUnreadMessages from 'firebaseServices/listeners/Chats/useListenUnreadMessages';
import Alert from 'components/Base/Alert';
import MessageCard from 'components/Chat/components/MessageCard';
import ToolEmptyView from 'components/ToolEmptyView';

const ChatPreview: FC<{ chat: IChatUi }> = ({ chat }) => {
  const { data: userData } = useGetAuthUserQuery();
  const { unreadMessages } = useListenUnreadMessages({
    projectId: chat.projectId,
    chatId: chat.documentId,
    userId: userData?.id || '',
  });
  const { messages } = useListenChatMessages({
    chatId: chat.documentId,
    projectId: chat.projectId,
    limitSize: 5,
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      padding={1}
      height={1}
      justifyContent="end"
      position="relative"
    >
      {!!unreadMessages && (
        <Alert
          severity="info"
          variant="filled"
          sx={{
            position: 'absolute',
            top: 0,
            width: 'calc(100% - 16px)',
            zIndex: 300,
          }}
        >
          You have {unreadMessages} unread message
          {unreadMessages > 1 ? 's' : ''}.
        </Alert>
      )}
      {messages?.length === 0 && (
        <ToolEmptyView
          icon={<MessageIcon />}
          description="A centralized hub for group messages and real-time collaboration."
        />
      )}
      {!!messages?.length &&
        messages.map(message => {
          return (
            <MessageCard
              key={message.documentId}
              message={message}
              projectId={chat.projectId}
              userId={userData?.id || ''}
              forPreview
            />
          );
        })}
    </Box>
  );
};

export default ChatPreview;
