import { httpsCallable } from 'firebase/functions';
import {
  doc,
  firestore,
  getDoc,
  collection,
  getDocs,
  arrayUnion,
  functions,
} from '../firebase.config';
import {
  EFirestoreCollectionPaths,
  EProjectSubCollectionPaths,
} from '../../shared/types/FirestoreCollections';
import { IChatDTO } from '../../shared/models/chat.model';
import { getChatConverter } from '../../data/converters/chat.converter';
import {
  ICreateChatUi,
  IDeleteChatUi,
  IRenameChatUi,
} from '../../data/types/chat.types';
import FirestoreOperations from '../FirestoreOperations';
import {
  EMessageTypes,
  IMessageDTO,
  IMessageSender,
} from '../../shared/models/message.model';

export const getProjectChats = async (projectId: string) => {
  const chatsRef = collection(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    projectId,
    EProjectSubCollectionPaths.CHATS,
  );

  const docsSnapshot = await getDocs(chatsRef);

  if (docsSnapshot.empty || docsSnapshot.docs.every(d => !d.exists)) {
    return null;
  }
  return docsSnapshot.docs.map(document => {
    return getChatConverter.fromDb({
      ...(document.data() as IChatDTO),
      documentId: document.id,
    });
  });
};

export const renameChat = async ({
  chatId,
  newChatName,
  projectId,
}: IRenameChatUi) => {
  const chatRef = doc(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    projectId,
    EProjectSubCollectionPaths.CHATS,
    chatId,
  );
  await FirestoreOperations.updateDoc(chatRef, {
    name: newChatName,
  });
};

export const deleteChat = async (data: IDeleteChatUi) => {
  await httpsCallable(functions, 'chats-deleteChat')(data);
};

export const createChat = async ({ projectId, chatName }: ICreateChatUi) => {
  const chatCollectionRef = collection(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    projectId,
    EProjectSubCollectionPaths.CHATS,
  );
  await FirestoreOperations.addDoc(chatCollectionRef, {
    name: chatName,
    projectId,
  });
};

export const getChat = async ({
  chatId,
  projectId,
}: {
  projectId: string;
  chatId: string;
}) => {
  const chatRef = doc(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    projectId,
    EProjectSubCollectionPaths.CHATS,
    chatId,
  );
  const snapshot = await getDoc(chatRef);
  if (snapshot.exists()) {
    return getChatConverter.fromDb({
      ...(snapshot.data() as IChatDTO),
      documentId: snapshot.id,
    });
  }
  throw new Error('ChatPage not found');
};

export const sendTextMessage = async ({
  projectId,
  chatId,
  sender,
  content,
}: {
  projectId: string;
  chatId: string;
  content: string;
  sender: IMessageSender;
}) => {
  const messagesCollectionRef = collection(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    projectId,
    EProjectSubCollectionPaths.CHATS,
    chatId,
    EProjectSubCollectionPaths.MESSAGES,
  );
  await FirestoreOperations.addDoc(messagesCollectionRef, {
    sender,
    chatId,
    content,
    seenBy: [sender.id],
    contentType: EMessageTypes.TEXT,
  } as IMessageDTO);
};
export const deleteMessage = async ({
  projectId,
  chatId,
  messageId,
}: {
  projectId: string;
  chatId: string;
  messageId: string;
}) => {
  const messagesRef = doc(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    projectId,
    EProjectSubCollectionPaths.CHATS,
    chatId,
    EProjectSubCollectionPaths.MESSAGES,
    messageId,
  );
  await FirestoreOperations.deleteDoc(messagesRef);
};
export const readMessage = async ({
  projectId,
  chatId,
  messageId,
  userId,
}: {
  projectId: string;
  chatId: string;
  messageId: string;
  userId: string;
}) => {
  const messagesRef = doc(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    projectId,
    EProjectSubCollectionPaths.CHATS,
    chatId,
    EProjectSubCollectionPaths.MESSAGES,
    messageId,
  );
  await FirestoreOperations.updateDoc(messagesRef, {
    seenBy: arrayUnion(userId),
  });
};
