import { useState } from 'react';
import {
  Box,
  CircularProgress,
  Container,
  Paper,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { pink } from '@mui/material/colors';
import emptyProjectImage from 'assets/images/empty_projects.svg';
import Button from '../../../../../components/Base/Button';
import AddEditNewProjectModal from '../AddEditNewProjectModal';
import { useAddNewProjectMutation } from '../../../../../redux/apiSlices/project.slice';

type TProjectPageWrapperProps = {
  loading?: boolean;
  empty?: boolean;
  children: JSX.Element;
  createSection?: {
    title: string;
    element: JSX.Element;
  };
  mainSection: {
    title?: string;
  };
};

const ProjectPageWrapper = ({
  empty,
  loading,
  children,
  createSection,
  mainSection: { title },
}: TProjectPageWrapperProps) => {
  const [open, setOpen] = useState(false);
  const handleClickOpenModal = () => {
    setOpen(true);
  };

  const [addNewProject, { isLoading: createLoading }] =
    useAddNewProjectMutation();

  let content: JSX.Element;
  if (loading) {
    content = (
      <Container
        sx={{
          marginTop: '100px',
        }}
      >
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      </Container>
    );
  } else if (empty) {
    content = (
      <Container
        sx={{
          marginTop: '100px',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          justifyContent="center"
          alignItems="center"
        >
          <img
            src={emptyProjectImage}
            alt="empty projects"
            width={200}
            height={200}
          />
          <Button variant="contained" onClick={handleClickOpenModal}>
            + Add New Project
          </Button>
        </Box>
      </Container>
    );
  } else {
    content = children;
  }

  return (
    <Grid
      padding={2}
      paddingRight={1}
      container
      direction="column"
      sx={{
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          paddingX: 2,
          paddingY: 2,
          marginBottom: 2,
          backgroundColor: pink[50],
          minHeight: 8,
        }}
      >
        {createSection && (
          <Container>
            <Box textAlign="start" mb={2}>
              <Typography variant="h5">{createSection.title}</Typography>
            </Box>
            {createSection?.element}
          </Container>
        )}
      </Paper>

      <Container
        sx={{
          flex: 1,
          overflow: 'auto',
        }}
      >
        <Grid py={2}>
          {title && (
            <Box textAlign="start" mb={2}>
              <Typography variant="h5">{title}</Typography>
            </Box>
          )}
          {content}
        </Grid>
      </Container>
      <AddEditNewProjectModal
        submitLoading={createLoading}
        onSubmitClick={values => addNewProject(values).unwrap()}
        open={open}
        setOpen={setOpen}
        modalTitlePrefix="New"
        submitButtonText="Create"
      />
    </Grid>
  );
};

export default ProjectPageWrapper;
