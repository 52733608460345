import React, { FC } from 'react';
import { Menu, MenuItem } from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { setBoardFilter } from 'redux/appSlices/boardFilter.slice';
import { useAppDispatch, useAppSelector } from 'redux/store';
import LabelFilter from './LabelFilter';
import AssigneeFilter from './AssigneeFilter';
import Button from '../../../Base/Button';

const BoardFilter: FC<{
  projectId: string;
  boardId: string;
}> = ({ projectId, boardId }) => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(state => state.boardFilterSlice)[boardId] || {
    assignees: [],
    labels: [],
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onLabelFilterChange = (labels: string[]) => {
    dispatch(
      setBoardFilter({
        boardId,
        filters: {
          labels,
          assignees: filters.assignees,
        },
      }),
    );
  };
  const onAssigneeFilterChange = (assignees: string[]) => {
    dispatch(
      setBoardFilter({
        boardId,
        filters: {
          assignees,
          labels: filters.labels,
        },
      }),
    );
  };
  return (
    <>
      <Button
        variant={
          filters.labels.length || filters.assignees.length
            ? 'contained'
            : 'outlined'
        }
        size="small"
        startIcon={<FilterAltOutlinedIcon />}
        onClick={handleClick}
      >
        Filter
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          disableRipple
          sx={{
            '&:hover': {
              background: 'none',
            },
          }}
        >
          <LabelFilter
            projectId={projectId}
            onChange={onLabelFilterChange}
            value={filters.labels}
          />
        </MenuItem>
        <MenuItem
          disableRipple
          sx={{
            '&:hover': {
              background: 'none',
            },
          }}
        >
          <AssigneeFilter
            projectId={projectId}
            onChange={onAssigneeFilterChange}
            value={filters.assignees}
          />
        </MenuItem>
      </Menu>
    </>
  );
};
export default BoardFilter;
