import { FC } from 'react';
import { TDocItemUI } from 'data/types/doc.types';
import FolderPreview from 'components/Preview/FolderPreview';
import DocItemCard from '../components/DocItemCard';

export type TFolderCardProps = {
  docItem: TDocItemUI;
  docId: string;
  projectId: string;
  onContentClick: () => void;
};

const FolderCard: FC<TFolderCardProps> = ({
  docItem,
  docId,
  projectId,
  onContentClick,
}) => {
  const { name } = docItem;

  return (
    <DocItemCard
      title={name}
      onActionAreaClick={onContentClick}
      docId={docId}
      docItem={docItem}
      projectId={projectId}
    >
      <FolderPreview
        docId={docId}
        projectId={projectId}
        location={docItem.documentId}
      />
    </DocItemCard>
  );
};

export default FolderCard;
