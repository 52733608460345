import { FC, useEffect, useRef, useState } from 'react';
import ReactQuil from 'react-quill';
import parse from 'html-react-parser';
import 'react-quill/dist/quill.snow.css';
import { Box, Card, Typography } from '@mui/material';
import { useUpdateTaskDescriptionMutation } from 'redux/apiSlices/tasks.slice';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import styled from 'styled-components';

const RichTextEditor = styled(ReactQuil)`
  border: none;
  .ql-toolbar,
  .ql-container {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .ql-editor {
    border: none;
  }
`;

const TaskDescription: FC<{
  taskId: string;
  description?: string;
}> = ({ description: descriptionInitial, taskId }) => {
  const quillRef = useRef(null);

  const [viewMode, setViewMode] = useState(true);
  const [description, setDescription] = useState(descriptionInitial || '');
  const [updateDescription] = useUpdateTaskDescriptionMutation();

  const changeViewMode = () => {
    setViewMode(prevState => !prevState);
  };

  const onBlur = async () => {
    if (description.trim() !== descriptionInitial) {
      await updateDescription({ taskId, description: description.trim() });
    }
    changeViewMode();
  };
  useEffect(() => {
    if (quillRef.current) {
      // @ts-ignore
      quillRef.current.editor.focus();
    }
  });

  return (
    <Box
      height={1}
      maxHeight={1}
      overflow="none"
      display="flex"
      flexDirection="column"
      gap={1}
    >
      <Typography paragraph sx={{ fontWeight: 600, marginBottom: 0 }}>
        Description
      </Typography>
      {viewMode ? (
        <Card
          variant="outlined"
          onClick={changeViewMode}
          sx={{
            cursor: 'pointer',
            height: { xs: 0.75, md: 0.85 },
            overflow: 'auto',
            paddingInline: 2,
            paddingTop: 3,
            ':hover': {
              border: '1px solid black',
            },
          }}
        >
          {parse(description || 'Click to add description')}
        </Card>
      ) : (
        <ClickAwayListener onClickAway={onBlur}>
          <Box height={{ xs: 0.75, md: 0.85 }} autoFocus>
            <RichTextEditor
              ref={quillRef}
              style={{ height: '100%' }}
              theme="snow"
              value={description}
              onChange={setDescription}
              modules={{
                toolbar: [
                  [
                    { header: 1 },
                    { header: 2 },
                    { list: 'ordered' },
                    { list: 'bullet' },
                    { list: 'check' },
                    'bold',
                    'italic',
                    'underline',
                    'strike',
                    'link',
                    'blockquote',
                    'code-block',
                    'image',
                    { script: 'sub' },
                    { script: 'super' },
                    { indent: '-1' },
                    { indent: '+1' },
                  ],
                ],
              }}
              preserveWhitespace={false}
            />
          </Box>
        </ClickAwayListener>
      )}
    </Box>
  );
};

export default TaskDescription;
