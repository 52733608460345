import { TBreadcrumbItem } from 'components/BreadcrumbNavigation/BreadcrumbNavigation.types';
import { useMemo } from 'react';
import { useGetProjectByIdQuery } from 'redux/apiSlices/project.slice';
import { PrivateRoutesPaths } from 'routes/Routes.types';

export type TProjectBreadcrumbProps = {
  projectId: string;
};

const useProjectBreadcrumb = ({
  projectId,
}: TProjectBreadcrumbProps): TBreadcrumbItem[] => {
  const { data: projectData } = useGetProjectByIdQuery(projectId, {
    skip: !projectId,
  });

  const breadcrumbs = useMemo(() => {
    if (projectData?.name) {
      return [
        {
          link: PrivateRoutesPaths.Projects,
          text: 'Projects',
        },
        {
          link: PrivateRoutesPaths.ProjectsDetails.replace(':id', projectId),
          text: projectData?.name,
        },
      ];
    }
    return [];
  }, [projectData?.name]);

  return breadcrumbs;
};

export default useProjectBreadcrumb;
