import { useEffect, useState } from 'react';
import useLoginWithEmailAndPassword from 'firebaseServices/Auth/useLoginWithEmailAndPassword';
import { CircularProgress, Grid, Link, Typography } from '@mui/material';

const PlaygroundPublicPage = () => {
  const { login, error: demoUserError } = useLoginWithEmailAndPassword();
  const [error, setError] = useState(false);

  useEffect(() => {
    const demoUserEmail = process.env.REACT_APP_DEMO_USER_EMAIL;
    const demoUserPassword = process.env.REACT_APP_DEMO_USER_PASSWORD;
    if (!demoUserEmail || !demoUserPassword) {
      setError(true);
      return;
    }
    (async () => {
      await login(demoUserEmail, demoUserPassword);
    })();
  }, []);

  useEffect(() => {
    setError(!!demoUserError);
  }, [demoUserError]);

  if (error) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
        spacing={4}
        direction="column"
      >
        <Grid item>
          <Typography variant="h6">
            Playground mode is currently Unavailable.
          </Typography>
        </Grid>
        <Grid item>
          <Link
            target="_blank"
            href="https://app.workfeed.ai/sign-up"
            variant="h6"
          >
            Check out the full version
          </Link>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center" alignItems="center" height="100vh">
      <CircularProgress />
    </Grid>
  );
};

export default PlaygroundPublicPage;
