import { TBreadcrumbItem } from 'components/BreadcrumbNavigation/BreadcrumbNavigation.types';
import { useMemo } from 'react';
import { PrivateRoutesPaths } from 'routes/Routes.types';
import useProjectBreadcrumb, {
  TProjectBreadcrumbProps,
} from './useProjectBreadcrumb';

type TUseChatBreadcrumbItemsProps = TProjectBreadcrumbProps & {
  chatId: string;
  chatName?: string;
};

const useChatBreadcrumbItems = ({
  projectId,
  chatId,
  chatName,
}: TUseChatBreadcrumbItemsProps): TBreadcrumbItem[] => {
  const projectBreadcrumb = useProjectBreadcrumb({ projectId });

  const chatData = {
    title: chatName || '',
    id: chatId,
  };

  return useMemo(() => {
    if (projectBreadcrumb.length && chatData?.title) {
      return [
        ...projectBreadcrumb,
        {
          link: PrivateRoutesPaths.Chat.replace(':chatId', chatId).replace(
            ':chatId',
            chatId,
          ),
          text: chatData.title,
        },
      ];
    }
    return [];
  }, [projectBreadcrumb.length, chatData?.id, chatId, chatName]);
};

export default useChatBreadcrumbItems;
