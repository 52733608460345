import { FC, useState } from 'react';
import { Button, IconButton, Tooltip } from '@mui/material';
import { IProjectUI } from 'data/types/project.types';
import PeopleIcon from '@mui/icons-material/People';
import { isDemo } from 'utils/utils';
import ManageProjectMemberModal from './ManageProjectMemberModal'; 

type TManageProjectMembersProps = {
  members: IProjectUI['members'];
  projectId: string;
  projectName: string;
  isMobileView: boolean;
};

const ManageProjectMembers: FC<TManageProjectMembersProps> = ({
  members,
  projectId,
  projectName,
  isMobileView,
}) => { 
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    if (isDemo) return;
    setOpen(true);
  };

  return (
    <>
      {isMobileView ? (
        <IconButton
          size="small"
          // sx={{ backgroundColor: theme => theme.palette.secondary.main }}
          onClick={handleClickOpen}
        >
          <PeopleIcon />
        </IconButton>
      ) : (
        <Tooltip title={isDemo ? 'Unavailable for playground mode' : ''}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClickOpen}
            startIcon={<PeopleIcon />}
          >
            Manage people
          </Button>
        </Tooltip>
      )}
      {open && (
        <ManageProjectMemberModal
          currentMembers={members}
          open={open}
          setOpen={setOpen}
          projectId={projectId}
          projectName={projectName}
        />
      )}
    </>
  );
};

export default ManageProjectMembers;
