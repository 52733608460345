// @ts-nocheck
import Embed from '@editorjs/embed';
import Table from '@editorjs/table';
import List from '@editorjs/list';
import Code from '@editorjs/code';
import LinkTool from '@editorjs/link';
// import Image from '@editorjs/image';
// import Raw from '@editorjs/raw';
import Header from '@editorjs/header';
import Paragraph from '@editorjs/paragraph';
import CheckList from '@editorjs/checklist';
import Delimiter from '@editorjs/delimiter';
import SimpleImage from '@editorjs/simple-image';
import Alert from 'editorjs-alert';

import InlineCode from '@editorjs/inline-code';
import Marker from '@editorjs/marker';
import Underline from '@editorjs/underline';
import Strikethrough from '@sotaproject/strikethrough';

import AlignmentTuneTool from 'editorjs-text-alignment-blocktune';
import IndentTune from 'editorjs-indent-tune';

import AIText from '@alkhipce/editorjs-aitext';
import { fetchOpenAISuggestions } from 'firebaseServices/queryFunctions/docsQueryFunctions';
import { Theme } from '@mui/material';

const functionsBaseURL = process.env.REACT_APP_FUNCTIONS_BASE_URL;

export const EDITOR_JS_TOOLS: {
  [toolName: string]: ToolConstructable | ToolSettings<any>;
} = {
  aiText: {
    class: AIText as unknown as ToolConstructable,
    config: {
      callback: fetchOpenAISuggestions,
    },
  },

  paragraph: {
    class: Paragraph,
    tunes: ['textAlignmentTool', 'indentTune'],
  },
  embed: Embed,
  table: {
    class: Table,
    inlineToolbar: true,
  },
  list: {
    class: List,
    inlineToolbar: true,
    tunes: ['textAlignmentTool', 'indentTune'],
  },
  code: Code,
  linkTool: {
    class: LinkTool,
    config: {
      endpoint: `${functionsBaseURL}/docs-fetchLinkMetadata`,
    },
  },
  // image: Image,
  // raw: Raw,
  header: {
    class: Header,
    tunes: ['textAlignmentTool', 'indentTune'],
  },
  checklist: {
    class: CheckList,
    inlineToolbar: true,
    tunes: ['textAlignmentTool', 'indentTune'],
  },
  alert: {
    class: Alert,
    inlineToolbar: true,
    config: {
      alertTypes: ['info', 'success', 'warning', 'danger', 'light', 'dark'],
      defaultType: 'info',
    },
  },

  delimiter: Delimiter,
  simpleImage: SimpleImage,

  // inline tools START
  inlineCode: InlineCode,
  marker: Marker,
  underline: Underline,
  strikethrough: Strikethrough,
  // inline tools END

  // tunes START
  textAlignmentTool: {
    class: AlignmentTuneTool,
    config: {
      blocks: {
        header: 'center',
      },
    },
  },
  indentTune: IndentTune,
  // tunes END
};

export const styleOverides = (theme: Theme) => {
  const hasSuggestion =
    document.querySelector('#ai-suggestions')?.textContent?.length;
  return {
    '.link-tool__image': {
      width: '240px',
      height: '126px',
    },
    '.ce-paragraph.cdx-block': {
      position: 'relative',
    },
    '#ai-suggestions::after': {
      content: '"Accept: ALT, Decline ESC"',
      display: hasSuggestion ? 'block' : 'none',
      fontSize: '12px',
      color: theme.palette.primary.main,
      marginTop: '-7px',
      position: 'absolute',
      left: '0',
    },
  };
};
