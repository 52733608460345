import { FC } from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { Icon } from '../../Base/Icon';

const PinMenuItem: FC<{ inShortcuts: boolean; onClick: () => void }> = ({
  onClick,
  inShortcuts,
}) => {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <Icon
          icon={inShortcuts ? 'pinOff' : 'pin'}
          color="rgba(0, 0, 0, 0.54)"
          size={20}
        />
      </ListItemIcon>
      <ListItemText>{inShortcuts ? 'Unpin' : 'Pin'}</ListItemText>
    </MenuItem>
  );
};

export default PinMenuItem;
