import React from 'react';
import { EProjectCustomTools } from '../../../../../shared/types/generic.types';
import { Icon } from '../../../../../components/Base/Icon';

export const projectToolMapper: Record<
  EProjectCustomTools,
  {
    icon: React.ReactElement;
  }
> = {
  [EProjectCustomTools.JIRA]: { icon: <Icon icon="jira" /> },
  [EProjectCustomTools.DROPBOX]: { icon: <Icon icon="dropbox" /> },
  [EProjectCustomTools.FIGMA]: { icon: <Icon icon="figma" /> },
  [EProjectCustomTools.GITHUB]: { icon: <Icon icon="github" /> },
  [EProjectCustomTools.ZOOM]: { icon: <Icon icon="zoom" /> },
  [EProjectCustomTools.GOOGLE_CALENDAR]: {
    icon: <Icon icon="google_calendar" />,
  },
  [EProjectCustomTools.GOOGLE_DOCS]: { icon: <Icon icon="google_docs" /> },
  [EProjectCustomTools.GOOGLE_DRIVE]: { icon: <Icon icon="google_drive" /> },
  [EProjectCustomTools.GOOGLE_MEET]: { icon: <Icon icon="google_meet" /> },
  [EProjectCustomTools.GOOGLE_SHEETS]: { icon: <Icon icon="google_sheets" /> },
  [EProjectCustomTools.NOTION]: { icon: <Icon icon="notion" /> },
  [EProjectCustomTools.SLACK]: { icon: <Icon icon="slack" /> },
  [EProjectCustomTools.DISCORD]: { icon: <Icon icon="discord" /> },
  [EProjectCustomTools.CUSTOM]: { icon: <Icon icon='link' /> },
};
