import { createApi } from '@reduxjs/toolkit/query/react';
import { fakeBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { queryFnWrapper } from 'firebaseServices/queryFunctions/helper';
import {
  ICreatCustomTool,
  ICustomToolUI,
  IDeleteCustomToolUi,
  IUpdateCustomToolUI,
} from '../../data/types/customTools.type';
import {
  createCustomTool,
  deleteCustomTool,
  getCustomTool,
  getCustomTools,
  updateCustomTool,
} from '../../firebaseServices/queryFunctions/customToolsQueryFunctions';

export const customToolApi = createApi({
  tagTypes: ['customTools', 'tool'],
  reducerPath: 'customTool',
  baseQuery: fakeBaseQuery(),
  endpoints: build => ({
    createCustomTool: build.mutation<void, ICreatCustomTool>({
      queryFn: arg => queryFnWrapper(createCustomTool, arg),
      invalidatesTags: ['customTools'],
    }),
    getCustomTools: build.query<ICustomToolUI[], string>({
      queryFn: arg => queryFnWrapper(getCustomTools, arg),
      providesTags: ['customTools'],
    }),
    deleteCustomTool: build.mutation<void, IDeleteCustomToolUi>({
      queryFn: arg => queryFnWrapper(deleteCustomTool, arg),
      invalidatesTags: ['customTools', 'tool'],
    }),
    updateCustomTool: build.mutation<void, IUpdateCustomToolUI>({
      queryFn: arg => queryFnWrapper(updateCustomTool, arg),
      invalidatesTags: ['customTools', 'tool'],
    }),
    getCustomTool: build.query<
      ICustomToolUI,
      { toolId: string; projectId: string }
    >({
      queryFn: arg => queryFnWrapper(getCustomTool, arg),
      providesTags: ['tool'],
    }),
  }),
});

export const {
  useCreateCustomToolMutation,
  useGetCustomToolsQuery,
  useDeleteCustomToolMutation,
  useUpdateCustomToolMutation,
  useGetCustomToolQuery
} = customToolApi;
