import { TFetchConverter } from '../types/general.types';
import { IChatUi } from '../types/chat.types';
import { IChatDTO } from '../../shared/models/chat.model';

export const getChatConverter: TFetchConverter<
  IChatUi,
  IChatDTO & { documentId: string }
> = {
  fromDb: data => {
    return {
      name: data.name,
      projectId: data.projectId,
      documentId: data.documentId,
    };
  },
};
