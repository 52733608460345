import { FirebaseError } from 'firebase/app';

export const validationMessages = {
  required: 'This field is required.',
  passwordSymbols: 'Use different symbols for password.',
  email: 'Wrong email format.',
  confirmPassword: 'The passwords do not match.',
  invalidUrlChars: 'Contains not allowed symbols'
};

export const errorMessages = {
  emailAlreadyInUse: 'Email already in use',
  weakPassword: 'Password should be at least 6 characters',
  invalidEmail: 'Invalid email',
  invalidLoginCredentials: 'Incorrect username or password.',
  default: 'Something went wrong',
  verifyEmail: 'Please verify your email first.',
  folderNameExists: 'Folder with this name already exists',
};

export const successMessages = {
  ressetPasswordEmailSent:
    'Password reset email sent successfully! Check your inbox.',
  verifyEmail: 'Please verify your email address',
  toast: 'Your request successfully done',
};

export const firebaseErrorMapper = (error: FirebaseError) => {
  const errorCode = error?.code?.replace('functions/', '');
  switch (errorCode) {
    case 'auth/email-already-in-use':
      return errorMessages.emailAlreadyInUse;
    case 'auth/weak-password':
      return errorMessages.weakPassword;
    case 'auth/invalid-email':
      return errorMessages.invalidEmail;
    case 'auth/invalid-login-credentials':
      return errorMessages.invalidLoginCredentials;
    case 'permission-denied':
      return 'You do not have permission for this action';
    default:
      return error?.message?.replace('FirebaseError:', ' ');
  }
};
