import { Circle } from '@mui/icons-material';
import {
  FormControl,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { projectColors } from 'data/static';
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

export type TAddEditNewProjectForm = {
  name: string;
  color: string;
  description?: string | null;
};

type TAddEditNewProjectFormProps = Pick<
  UseFormReturn<TAddEditNewProjectForm>,
  'formState' | 'register' | 'watch' | 'setValue'
> & {
  onSubmit: () => void;
};

const AddEditNewProjectForm: FC<TAddEditNewProjectFormProps> = ({
  formState,
  register,
  watch,
  setValue,
  onSubmit,
}) => {
  const { errors } = formState;

  return (
    <FormControl component="form" fullWidth onSubmit={onSubmit} autoFocus>
      <Stack spacing={2} direction="column">
        <TextField
          autoFocus
          label="Name"
          variant="standard"
          error={!!errors.name}
          helperText={errors.name && errors.name.message}
          required
          {...register('name')}
        />
        <TextField
          id="description-textarea"
          label="Description"
          variant="outlined"
          error={!!errors.description}
          helperText={errors.description && errors.description.message}
          multiline
          inputProps={{
            maxLength: 100,
          }}
          rows={2}
          placeholder="Some useful description here..."
          {...register('description')}
        />
        <ToggleButtonGroup
          value={watch('color')}
          exclusive
          onChange={(event: React.MouseEvent<HTMLElement>, value: string) => {
            if (!value) return;
            setValue('color', value);
          }}
        >
          {projectColors.map(color => (
            <ToggleButton key={color.light} value={color.light}>
              <Circle fontSize="large" style={{ fill: color.dark }} />
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Stack>
    </FormControl>
  );
};

export default AddEditNewProjectForm;
