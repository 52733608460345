import { ReactNode } from 'react';

export type PublicRoutesType = {
  component: ReactNode;
  path: PublicRoutesPaths;
  index?: boolean;
};

export type PrivateRouteType = {
  component: ReactNode;
  path: PrivateRoutesPaths;
  index?: boolean;
  title?: string;
  icon?: ReactNode;
  subRoutes?: PrivateRouteType[];
  notInSidebar?: boolean;
};

export enum PublicRoutesPaths {
  Login = 'login',
  SignUp = 'sign-up',
  ForgotPassword = 'forgot-password',
  Demo = 'demo',
}

// TODO: extract BASE_PATHS (ex. projects,docs,account) and build this routes based on it.
// usecase: sometimes we need BASE_PATHS in components to navigate. ex. navigate(`/${BASE_PATHS.boards}/${boardId}`)

export enum PrivateRoutesPaths {
  MyAccount = 'account',
  Projects = 'projects',
  ProjectsDetails = 'projects/:id',
  pageNotFound = '404',
  BoardDetails = 'projects/:projectId/boards/:boardId',
  DocDetails = 'projects/:projectId/docs/:docId',
  CustomDocDetails = 'projects/:projectId/docs/:docId/:customDocId',
  TaskDetails = 'task/:taskId',
  ProjectChangelog = 'projects/:projectId/changelog',
  Chat = 'projects/:projectId/chats/:chatId'
}
