import { AvatarGroup, Badge, styled } from '@mui/material';
import MemberAvatar from 'components/MemberAvatar';
import { ICustomDocViewerUI } from 'data/types/doc.types';
import { IAuthUserUI } from 'data/types/user.types';
import { FC, useEffect } from 'react';
import {
  useAddViewerToCustomDocMutation,
  useRemoveViewerFromCustomDocMutation,
} from 'redux/apiSlices/doc.slice';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  // '& .MuiAvatar-root': {
  //   marginLeft: '0px !important',
  // },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

type TViewersListProps = {
  isPublished: boolean;
  authData: IAuthUserUI;
  viewers: ICustomDocViewerUI[];
  projectId: string;
  docId: string;
  customDocId: string;
};

const ViewersList: FC<TViewersListProps> = ({
  authData,
  isPublished,
  projectId,
  docId,
  customDocId,
  viewers,
}) => {
  const [addViewer] = useAddViewerToCustomDocMutation();
  const [removeViewer] = useRemoveViewerFromCustomDocMutation();

  useEffect(() => {
    if (isPublished) {
      addViewer({
        projectId,
        docId,
        customDocId,
        viewer: {
          id: authData.id,
          name: authData.name,
          email: authData.email,
        },
      });
    }
    return () => {
      if (isPublished) {
        removeViewer({
          projectId,
          docId,
          customDocId,
          viewerId: authData.id,
        });
      }
    };
  }, [
    authData,
    isPublished,
    projectId,
    docId,
    customDocId,
    addViewer,
    removeViewer,
  ]);

  window.addEventListener('beforeunload', () => {
    removeViewer({
      projectId,
      docId,
      customDocId,
      viewerId: authData.id,
    });
  });

  return (
    <AvatarGroup spacing="medium" max={4}>
      {viewers
        .filter(v => v.userId !== authData?.id)
        .map(viewer => (
          <StyledBadge
            key={viewer.userId}
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
          >
            <MemberAvatar
              {...viewer}
              key={viewer.userId}
              hideBadge
              avatarSize={32}
            />
          </StyledBadge>
        ))}
    </AvatarGroup>
  );
};

export default ViewersList;
