import {
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SvgIcon,
} from '@mui/material';
import { FC, MouseEvent, ReactNode, useMemo, useState } from 'react';
import { EProjectCustomTools, EProjectTools } from 'shared/types/generic.types';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import MessageIcon from '@mui/icons-material/Message';
import { TextSnippet } from '@mui/icons-material';
import DetailsAddButton from 'components/DetailsPageWrapper/components/DetailsAddButton';
import AddBoardModal from './components/AddBoardModal';
import AddDocModal from './components/AddDocModal';
import AddChatModal from './components/AddChatModal';
import AddCustomToolModal from './components/AddCustomToolModal/indesx';
import { projectToolMapper } from '../ActiveTools/ActiveToolsData';

const projectTools: {
  type: EProjectTools | EProjectCustomTools;
  label: string;
  icon?: ReactNode;
}[] = [
  {
    label: 'Docs & Files',
    type: EProjectTools.DOC,
    icon: <TextSnippet />,
  },
  {
    label: 'Card Board',
    type: EProjectTools.BOARD,
    icon: <ViewKanbanIcon />,
  },
  {
    label: 'Group Chat',
    type: EProjectTools.CHAT,
    icon: <MessageIcon />,
  },
  ...Object.entries(EProjectCustomTools).map(([, value]) => ({
    label: value,
    type: value,
    icon: projectToolMapper[value].icon,
  })),
];

const AddTool: FC<{ projectId: string; isMobileView: boolean }> = ({
  projectId,
  isMobileView,
}) => {
  const [openedToolModal, setOpenedToolModal] = useState<
    EProjectTools | EProjectCustomTools | null
  >(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const closeModal = () => {
    setOpenedToolModal(null);
    handleCloseMenu();
  };

  const modalMapper: Record<
    EProjectTools | EProjectCustomTools,
    { ModalComponent: ReactNode }
  > = useMemo(
    () => ({
      [EProjectTools.BOARD]: {
        ModalComponent: (
          <AddBoardModal
            closeModal={closeModal}
            open={openedToolModal === EProjectTools.BOARD}
            projectId={projectId}
          />
        ),
      },
      [EProjectTools.DOC]: {
        ModalComponent: (
          <AddDocModal
            closeModal={closeModal}
            projectId={projectId}
            open={openedToolModal === EProjectTools.DOC}
          />
        ),
      },
      [EProjectTools.CHAT]: {
        ModalComponent: (
          <AddChatModal
            projectId={projectId}
            closeModal={closeModal}
            open={openedToolModal === EProjectTools.CHAT}
          />
        ),
      },
      [EProjectCustomTools.DISCORD]: {
        ModalComponent: (
          <AddCustomToolModal
            open={openedToolModal === EProjectCustomTools.DISCORD}
            closeModal={closeModal}
            projectId={projectId}
            customToolType={EProjectCustomTools.DISCORD}
          />
        ),
      },
      [EProjectCustomTools.DROPBOX]: {
        ModalComponent: (
          <AddCustomToolModal
            open={openedToolModal === EProjectCustomTools.DROPBOX}
            closeModal={closeModal}
            projectId={projectId}
            customToolType={EProjectCustomTools.DROPBOX}
          />
        ),
      },
      [EProjectCustomTools.ZOOM]: {
        ModalComponent: (
          <AddCustomToolModal
            open={openedToolModal === EProjectCustomTools.ZOOM}
            closeModal={closeModal}
            projectId={projectId}
            customToolType={EProjectCustomTools.ZOOM}
          />
        ),
      },
      [EProjectCustomTools.JIRA]: {
        ModalComponent: (
          <AddCustomToolModal
            open={openedToolModal === EProjectCustomTools.JIRA}
            closeModal={closeModal}
            projectId={projectId}
            customToolType={EProjectCustomTools.JIRA}
          />
        ),
      },
      [EProjectCustomTools.GITHUB]: {
        ModalComponent: (
          <AddCustomToolModal
            open={openedToolModal === EProjectCustomTools.GITHUB}
            closeModal={closeModal}
            projectId={projectId}
            customToolType={EProjectCustomTools.GITHUB}
          />
        ),
      },
      [EProjectCustomTools.NOTION]: {
        ModalComponent: (
          <AddCustomToolModal
            open={openedToolModal === EProjectCustomTools.NOTION}
            closeModal={closeModal}
            projectId={projectId}
            customToolType={EProjectCustomTools.NOTION}
          />
        ),
      },
      [EProjectCustomTools.SLACK]: {
        ModalComponent: (
          <AddCustomToolModal
            open={openedToolModal === EProjectCustomTools.SLACK}
            closeModal={closeModal}
            projectId={projectId}
            customToolType={EProjectCustomTools.SLACK}
          />
        ),
      },
      [EProjectCustomTools.GOOGLE_SHEETS]: {
        ModalComponent: (
          <AddCustomToolModal
            open={openedToolModal === EProjectCustomTools.GOOGLE_SHEETS}
            closeModal={closeModal}
            projectId={projectId}
            customToolType={EProjectCustomTools.GOOGLE_SHEETS}
          />
        ),
      },
      [EProjectCustomTools.GOOGLE_MEET]: {
        ModalComponent: (
          <AddCustomToolModal
            open={openedToolModal === EProjectCustomTools.GOOGLE_MEET}
            closeModal={closeModal}
            projectId={projectId}
            customToolType={EProjectCustomTools.GOOGLE_MEET}
          />
        ),
      },
      [EProjectCustomTools.GOOGLE_CALENDAR]: {
        ModalComponent: (
          <AddCustomToolModal
            open={openedToolModal === EProjectCustomTools.GOOGLE_CALENDAR}
            closeModal={closeModal}
            projectId={projectId}
            customToolType={EProjectCustomTools.GOOGLE_CALENDAR}
          />
        ),
      },
      [EProjectCustomTools.GOOGLE_DRIVE]: {
        ModalComponent: (
          <AddCustomToolModal
            open={openedToolModal === EProjectCustomTools.GOOGLE_DRIVE}
            closeModal={closeModal}
            projectId={projectId}
            customToolType={EProjectCustomTools.GOOGLE_DRIVE}
          />
        ),
      },
      [EProjectCustomTools.GOOGLE_DOCS]: {
        ModalComponent: (
          <AddCustomToolModal
            open={openedToolModal === EProjectCustomTools.GOOGLE_DOCS}
            closeModal={closeModal}
            projectId={projectId}
            customToolType={EProjectCustomTools.GOOGLE_DOCS}
          />
        ),
      },
      [EProjectCustomTools.CUSTOM]: {
        ModalComponent: (
          <AddCustomToolModal
            open={openedToolModal === EProjectCustomTools.CUSTOM}
            closeModal={closeModal}
            projectId={projectId}
            customToolType={EProjectCustomTools.CUSTOM}
          />
        ),
      },
      [EProjectCustomTools.FIGMA]: {
        ModalComponent: (
          <AddCustomToolModal
            open={openedToolModal === EProjectCustomTools.FIGMA}
            closeModal={closeModal}
            projectId={projectId}
            customToolType={EProjectCustomTools.FIGMA}
          />
        ),
      },
    }),
    [openedToolModal],
  );

  const onItemClick = (tool: EProjectTools | EProjectCustomTools) => {
    setOpenedToolModal(tool);
    setAnchorEl(null);
  };

  return (
    <>
      <DetailsAddButton
        onClick={handleOpenMenu}
        label={isMobileView ? '' : 'Add New Tool'}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        component="div"
      >
        {projectTools.map(tool => (
          <Box key={tool.label}>
            <MenuItem sx={{ width: 1 }} onClick={() => onItemClick(tool.type)}>
              <ListItemIcon>
                <SvgIcon>{tool.icon}</SvgIcon>
              </ListItemIcon>
              <ListItemText>{tool.label}</ListItemText>
            </MenuItem>
            {tool.type === EProjectTools.CHAT && (
              <Divider textAlign="left">External tools</Divider>
            )}
          </Box>
        ))}
      </Menu>
      {openedToolModal && modalMapper[openedToolModal].ModalComponent}
    </>
  );
};

export default AddTool;
