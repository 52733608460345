import { TreeViewBaseItem } from '@mui/x-tree-view';
import { IFolderUI, TDocItemUI } from 'data/types/doc.types';
import { EDocTypes } from 'shared/models/doc.model';

export const buildFoldersTree = (
  folders: IFolderUI[],
  movedItem: TDocItemUI,
  rootFolderName?: string,
): {
  treeItems: TreeViewBaseItem[];
  allIds: string[];
} => {
  const itemMap = new Map<string, TreeViewBaseItem>();
  const allIds: string[] = [];

  const rootFolder: TreeViewBaseItem = {
    id: 'root',
    label: rootFolderName || 'root',
    children: [],
  };
  itemMap.set('root', rootFolder);
  allIds.push('root');

  // Collect all documentIds to exclude based on movedItem
  const excludeIds = new Set<string>();
  if (movedItem.type === EDocTypes.FOLDER) {
    const collectIds = (currentId: string) => {
      excludeIds.add(currentId);
      folders?.forEach(folder => {
        if (folder.parentFolderId === currentId) {
          collectIds(folder.documentId);
        }
      });
    };
    collectIds(movedItem.documentId);
  }

  // Initialize the map with all folders, excluding movedItem and its children
  folders?.forEach(folder => {
    if (!excludeIds.has(folder.documentId)) {
      itemMap.set(folder.documentId, {
        id: folder.documentId,
        label: folder.name,
        children: [],
      });
      allIds.push(folder.documentId);
    }
  });

  // Build the tree by assigning children, excluding movedItem and its children
  folders?.forEach(folder => {
    if (!excludeIds.has(folder.documentId)) {
      const treeItem = itemMap.get(folder.documentId)!;
      if (
        folder.parentFolderId === 'root' ||
        excludeIds.has(folder.parentFolderId)
      ) {
        rootFolder?.children?.push(treeItem);
      } else {
        const parentItem = itemMap.get(folder.parentFolderId);
        parentItem?.children?.push(treeItem);
      }
    }
  });

  return {
    treeItems: [rootFolder],
    allIds,
  };
};
