// import { OutputData } from '@editorjs/editorjs';

import { IBaseModelDTO } from './general.model';

export enum EDocTypes {
  FILE = 'File',
  FOLDER = 'Folder',
  CUSTOM = 'Custom',
}

export interface IDocItemCommonDTO extends IBaseModelDTO {
  name: string;
  parentFolderId: string;
}

export interface IFileDTO extends IDocItemCommonDTO {
  extension: string;
  url: string;
  storagePath: string;
  type: EDocTypes.FILE;
}

export interface IFolderDTO extends IDocItemCommonDTO {
  type: EDocTypes.FOLDER;
}

export interface ICustomDocViewerDTO {
  [userId: string]: {
    name: string;
    email: string;
  };
}

export interface ICustomDocDTO extends IDocItemCommonDTO {
  type: EDocTypes.CUSTOM;
  // content: OutputData;
  content: any;
  isPublished: boolean;
  creatorId: string;
  viewers: ICustomDocViewerDTO;
}

export type TDocItemDTO = IFileDTO | IFolderDTO | ICustomDocDTO;
export interface IDocDTO extends IBaseModelDTO {
  name: string;
  projectId: string;
}

export interface IDocDeleteDTO {
  id: string;
  projectId: string;
}

export interface IDocItemDeleteDTO {
  id: string;
  docId: string;
  projectId: string;
}
