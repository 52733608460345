import { DropzoneOptions } from 'react-dropzone';

export type TAcceptedFileExtension = keyof typeof EAcceptedFileExtensions;

export enum EAcceptedFileExtensions {
  // Define the supported file types here
  ai = 'ai',
  csv = 'csv',
  docx = 'docx',
  doc = 'doc',
  exe = 'exe',
  gif = 'gif',
  iso = 'iso',
  java = 'java',
  jpg = 'jpg',
  jpeg = 'jpeg',
  pdf = 'pdf',
  png = 'png',
  psd = 'psd',
  rar = 'rar',
  srd = 'srd',
  svg = 'svg',
  text = 'text',
  txt = 'txt',
  xml = 'xml',
  xls = 'xls',
  xlsx = 'xlsx',
  zip = 'zip',
}
export const uploadFileConfig: Pick<
  DropzoneOptions,
  'maxSize' | 'multiple' | 'accept'
> = {
  maxSize: 10000000, // 10MB
  multiple: false,
  accept: {
    'image/jpeg': ['.jpeg', '.jpg'], // JPEG images
    'image/png': ['.png'], // PNG images
    'image/svg+xml': ['.svg'], // SVG images
    'application/pdf': ['.pdf'], // PDF documents
    'application/msword': ['.doc'], // Microsoft Word documents
    'application/vnd.ms-excel': ['.xls'], // Microsoft Excel documents
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
      '.docx',
    ], // Word 2007+ documents
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
      '.xlsx',
    ], // Excel 2007+ spreadsheets
    'text/plain': ['.txt', '.text'], // Plain text files
    'text/csv': ['.csv'], // CSV files
  },
};
