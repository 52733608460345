import { Close } from '@mui/icons-material';
import { LoadingButtonProps } from '@mui/lab';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { FC, ReactNode } from 'react';
import Button from '../Button';

type TModalProps = {
  open: boolean;
  handleClose: () => void;
  title?: ReactNode;
  hasCloseIcon?: boolean;
  children: ReactNode;
  hideActions?: boolean;
  hideCancelButton?: boolean;
  submitButtonProps?: LoadingButtonProps & {
    text?: string;
  };
  contentProps?: DialogContentProps;
  fullWidth?: DialogProps['fullWidth'];
  dialogProps?: Omit<DialogProps, 'open' | 'onClose' | 'fullWidth'>;
};

const Modal: FC<TModalProps> = ({
  open,
  handleClose,
  title,
  hasCloseIcon,
  children,
  hideActions,
  hideCancelButton,
  submitButtonProps,
  contentProps,
  dialogProps,
  fullWidth,
}) => {
  return (
    <Dialog
      fullWidth={fullWidth}
      open={open}
      onClose={handleClose}
      {...dialogProps}
    >
      {(title || hasCloseIcon) && (
        <DialogTitle
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {title}
          {hasCloseIcon && (
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          )}
        </DialogTitle>
      )}
      <DialogContent {...contentProps}>{children}</DialogContent>
      {!hideActions && (
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            {...submitButtonProps}
          >
            {submitButtonProps?.text || 'Ok'}
          </Button>
          {!hideCancelButton && (
            <Button variant="text" color="primary" onClick={handleClose}>
              Cancel
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default Modal;
