import { FC, memo, useRef } from 'react';
import { Box } from '@mui/material';
import NewMessageArea from './components/NewMessageArea';
import MessageGroupsByDate from './components/MessageGroupsByDate';
import Alert from '../Base/Alert';
import useListenChatMessages from '../../firebaseServices/listeners/Chats/useListenChatMessages';
import useListenUnreadMessages from '../../firebaseServices/listeners/Chats/useListenUnreadMessages';

const Chat: FC<{
  projectId: string;
  chatId: string;
  userId: string;
  fullWidth?: boolean;
}> = ({ projectId, chatId, userId, fullWidth }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { messages } = useListenChatMessages({ chatId, projectId });
  const { unreadMessages } = useListenUnreadMessages({
    projectId,
    chatId,
    userId,
  });

  const scrollToTheBottom = () => {
    if (ref.current) {
      ref.current.scroll({ top: ref.current.scrollHeight, behavior: 'smooth' });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginInline: 'auto',
        height: 1,
        flexGrow: 1,
        width: fullWidth ? 1 : { md: 0.7, sm: 1 },
        gap: 3,
        overflow: 'hidden',
        padding: 1,
        position: 'relative',
      }}
    >
      {!!unreadMessages && (
        <Alert
          severity="info"
          variant="filled"
          sx={{ position: 'absolute', width: 1, zIndex: 300 }}
        >
          You have {unreadMessages} unread messages.
        </Alert>
      )}
      <Box
        ref={ref}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          gap: 3,
          width: 1,
          flex: 1,
          padding: 1,
          overflowY: 'auto',
        }}
      >
        {messages && (
          <MessageGroupsByDate
            allMessages={messages}
            projectId={projectId}
            userId={userId}
          />
        )}
      </Box>
      <NewMessageArea
        chatId={chatId}
        projectId={projectId}
        onMessageSend={() => {
          scrollToTheBottom();
        }}
      />
    </Box>
  );
};

export default memo(Chat);
