import { TFetchConverter } from '../types/general.types';
import { IBoardUI, IColumnUI } from '../types/board.type';
import { IBoardDTO, IColumnDTO } from '../../shared/models/board.model';

export const getBoardConverter: TFetchConverter<
  IBoardUI,
  IBoardDTO & { documentId: string }
> = {
  fromDb: data => {
    return {
      name: data.name,
      creationDate: data.creationDate,
      projectId: data.projectId,
      documentId: data.documentId,
    };
  },
};

export const getColumnConverter: TFetchConverter<
  IColumnUI,
  IColumnDTO & { documentId: string }
> = {
  fromDb: data => {
    return {
      color: data.color,
      documentId: data.documentId,
      title: data.title,
      order: data.order,
      tasks: data.tasks,
    };
  },
};
