import { FC } from 'react';
import { IColumnUI } from 'data/types/board.type';
import { Box } from '@mui/material';
import { ITaskUI } from 'data/types/task.type';
import { Droppable } from 'react-beautiful-dnd';
import AddTask from './AddTask';
import TaskCard from './TaskCard';

const ColumnContent: FC<{
  column: IColumnUI;
  projectId: string;
  boardId: string;
  tasks: ITaskUI[];
  color: string;
  setOpenedTask: (taskId: string) => void;
  dragLoading: boolean;
}> = ({
  column,
  boardId,
  projectId,
  tasks,
  color,
  setOpenedTask,
  dragLoading,
}) => {
  return (
    <Droppable
      droppableId={column.documentId}
      type="tasks"
      isDropDisabled={dragLoading}
    >
      {(provided, snapshot) => (
        <Box height={1} {...provided.droppableProps} ref={provided.innerRef}>
          {tasks.map((task, index) => (
            <TaskCard
              task={task}
              key={task.taskId}
              index={index}
              setOpenedTask={setOpenedTask}
            />
          ))}
          {!snapshot.isDraggingOver && (
            <AddTask
              order={tasks.length}
              columnId={column.documentId}
              projectId={projectId}
              boardId={boardId}
              color={color}
            />
          )}
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  );
};

export default ColumnContent;
