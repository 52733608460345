import { createApi } from '@reduxjs/toolkit/query/react';
import { fakeBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { queryFnWrapper } from 'firebaseServices/queryFunctions/helper';
import {
  ICreateLabelUI,
  IGetLabelsByIds,
  ILabelUI,
} from '../../data/types/label.type';
import {
  createLabel,
  getLabelsByIds,
  getProjectLabels,
} from '../../firebaseServices/queryFunctions/labelQueryFunctions';
import { IBoardDTO } from '../../shared/models/board.model';

export const labelsApi = createApi({
  tagTypes: ['labels', 'taskLabels'],
  reducerPath: 'labels',
  baseQuery: fakeBaseQuery(),
  endpoints: build => ({
    getProjectLabels: build.query<ILabelUI[], string>({
      queryFn: arg => queryFnWrapper(getProjectLabels, arg),
      providesTags: ['labels'],
    }),
    addLabel: build.mutation<IBoardDTO, ICreateLabelUI>({
      queryFn: arg => queryFnWrapper(createLabel, arg),
      invalidatesTags: ['labels', 'taskLabels'],
    }),
    getLabelsByIds: build.query<ILabelUI[], IGetLabelsByIds>({
      queryFn: arg => queryFnWrapper(getLabelsByIds, arg),
      providesTags: ['taskLabels'],
    }),
  }),
});

export const { useGetProjectLabelsQuery, useAddLabelMutation,useGetLabelsByIdsQuery } = labelsApi;
