import { CloudUpload } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { DropzoneInputProps } from 'react-dropzone';

type TUploadFileOnDrop = {
  isDragActive: boolean;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
};

const bounceAnimation = {
  '@keyframes bounce': {
    '0%, 100%': {
      transform: 'translateY(0)',
    },
    '50%': {
      transform: 'translateY(7px)',
    },
  },
  animation: 'bounce 0.5s ease-in-out 3',
};

const UploadFileOnDrop: FC<TUploadFileOnDrop> = ({
  isDragActive,
  getInputProps,
}) => {
  return (
    <Box
      textAlign="center"
      sx={theme => ({
        paddingY: theme.spacing(1),
      })}
    >
      <input {...getInputProps()} />
      <CloudUpload
        color={isDragActive ? 'info' : 'disabled'}
        fontSize="large"
        sx={isDragActive ? bounceAnimation : {}}
      />
      <Typography
        variant="body2"
        color={isDragActive ? 'InfoText' : 'textSecondary'}
      >
        {isDragActive ? 'Drop the files here ...' : 'Drag and drop files here.'}
      </Typography>
    </Box>
  );
};

export default UploadFileOnDrop;
