// to escape dependency cycle when importing store in FirestoreOperations class
let store: any;

export const setStore = (reduxStore: any) => {
  store = reduxStore;
};

export const getStore = () => {
  if (!store) {
    throw new Error("Store is not set. Please set the store before using it.");
  }
  return store;
};