import { cloneElement, FC } from 'react';
import { Box } from '@mui/material';
import { ICustomToolUI } from 'data/types/customTools.type';
import {
  useDeleteCustomToolMutation,
  useUpdateCustomToolMutation,
} from 'redux/apiSlices/customTool.slice';
import {
  useGetAuthUserQuery,
  useUpdateUserShortcutsMutation,
} from 'redux/apiSlices/authUser.slice';
import { projectToolMapper } from '../ActiveToolsData';
import ActiveToolCard from '../ActiveToolCard';

const CustomToolCard: FC<{ tool: ICustomToolUI }> = ({ tool }) => {
  const [updateShortcuts] = useUpdateUserShortcutsMutation();
  const { data: userData } = useGetAuthUserQuery();

  const [removeTool, { isLoading: deleteLoading }] =
    useDeleteCustomToolMutation();
  const [updateTool] = useUpdateCustomToolMutation();
  const onCardClick = () => {
    if (!tool.link.startsWith('http://') && !tool.link.startsWith('https://')) {
      window.open(`https://${tool.link}`, '_blank');
    } else window.open(tool.link, '_blank');
  };
  const icon = cloneElement(projectToolMapper[tool.type].icon, {
    size: 150,
  });

  const inShortcuts = !!(userData?.settings?.shortcuts || []).find(
    item => item.id === tool.documentId,
  );

  const onPin = async () => {
    if (inShortcuts && userData?.settings?.shortcuts.length) {
      try {
        await updateShortcuts(
          userData.settings.shortcuts.filter(
            item => !(item.id === tool.documentId),
          ),
        ).unwrap();
      } catch {
        // TODO handle error
      }
    } else if (!inShortcuts) {
      try {
        await updateShortcuts([
          ...(userData?.settings?.shortcuts || []),
          {
            projectId: tool.projectId,
            type: tool.type,
            pinned: false,
            name: tool.name,
            id: tool.documentId,
            open: false,
          },
        ]).unwrap();
      } catch {
        // TODO handle error
      }
    }
  };
  return (
    <ActiveToolCard
      key={tool.documentId}
      onClick={onCardClick}
      name={tool.name}
      link={tool.link}
      pinProps={{
        inShortcuts,
        onClick: onPin,
      }}
      onRenameTool={async newName => {
        await updateTool({
          projectId: tool.projectId,
          toolId: tool.documentId,
          name: newName,
          link: tool.link,
        }).unwrap();
      }}
      onRemoveTool={async () => {
        await removeTool({
          projectId: tool.projectId,
          toolId: tool.documentId,
        }).unwrap();
      }}
      onUpdateLink={async link => {
        await updateTool({
          projectId: tool.projectId,
          toolId: tool.documentId,
          name: tool.name,
          link,
        }).unwrap();
      }}
      removeLoading={deleteLoading}
    >
      <Box
        width={1}
        height={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {icon}
      </Box>
    </ActiveToolCard>
  );
};
export default CustomToolCard;
