import { GridProps } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { FC } from 'react';
import { useGetAuthUserQuery } from 'redux/apiSlices/authUser.slice';
import { EDocTypes } from 'shared/models/doc.model';
import PreviewIcon from 'components/Preview/FilePreview/PreviewIcon';
import { useProjectDocItemsListener } from 'firebaseServices/listeners/Docs/useProjectDocItemsListener';
import DescriptionIcon from '@mui/icons-material/Description';
import ToolEmptyView from 'components/ToolEmptyView';
import { TActiveToolCardChildrenProps } from '../../ActiveToolCard';

type TDocPreviewProps = TActiveToolCardChildrenProps & {
  projectId: string;
  docId: string;
  parentFolderId?: string;
  contentHeight?: number;
};

const GridContainerProps: GridProps = {
  container: true,
  wrap: 'wrap',
  direction: 'row',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
};

const DocPreview: FC<TDocPreviewProps> = ({
  docId,
  projectId,
  parentFolderId,
  contentHeight,
}) => {
  const { data: authData } = useGetAuthUserQuery();
  const { data } = useProjectDocItemsListener({
    projectId,
    docId,
    parentFolderId: parentFolderId || 'root',
    limit: 4,
    currentUserId: authData?.id || '',
  });

  if (!data) {
    return null;
  }

  if (data.length === 0) {
    return (
      <ToolEmptyView
        icon={<DescriptionIcon />}
        description="A centralized place to store docs, spreadsheets, images, and other
          files."
      />
    );
  }

  return (
    <Grid paddingInline={1} paddingBottom={1} {...GridContainerProps}>
      {data.map(item => {
        return (
          <Grid
            key={item.documentId}
            xs={6}
            sx={{ height: '50%' }}
            paddingInline={1}
            paddingBottom={1}
          >
            <PreviewIcon
              extension={
                item.type === EDocTypes.FILE ? item.extension : undefined
              }
              isFolder={item.type === EDocTypes.FOLDER}
              label={item.name}
              height={contentHeight! / 3}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default DocPreview;
