import { AvatarGroup } from '@mui/material';
import { FC } from 'react';
import { IProjectMembersUpdated } from 'shared/models/event.model';
import MemberAvatarInContent from '../../../components/MemberAvatarInContent';
import OneValueContent from '../../../components/OneValueContent';

type TMemberUpdateEventProps = {
  members: IProjectMembersUpdated;
  previewMode?: boolean;
};

const MemberUpdateEvent: FC<TMemberUpdateEventProps> = ({
  members,
  previewMode,
}) => {
  const prefixText = members?.added?.length
    ? 'Added Member(s)'
    : 'Removed Member(s)';
  const membersList = [...(members.added || []), ...(members.removed || [])];

  return (
    <OneValueContent prefixText={prefixText}>
      <AvatarGroup spacing="medium">
        {membersList.map(member => (
          <MemberAvatarInContent previewMode={previewMode} member={member} />
        ))}
      </AvatarGroup>
    </OneValueContent>
  );
};

export default MemberUpdateEvent;
