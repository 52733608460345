import { ListItem, Stack, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import {
  IEventActionType,
  TProjectEventDTO,
} from 'shared/models/event.model';
import dayjs from 'dayjs';
import { timeFormat } from 'data/static';
import LinkRouter from 'components/Base/LinkRouter';
import {
  eventActionTypeMapper,
  eventResourceTypeTextMapper,
  generatePathToNavigateByResourceType,
} from '../mappers.data';

type TChanglogItemWrapperProps = {
  children: ReactNode;
  eventData: TProjectEventDTO;
  previewMode?: boolean;
};

const ChanglogItemWrapper: FC<TChanglogItemWrapperProps> = ({
  children,
  eventData,
  previewMode,
}) => {
  const {
    performedBy,
    eventActionType,
    eventDateTime,
    eventResourceType,
    eventResourceTitle,
  } = eventData;

  if (
    !performedBy ||
    !eventDateTime ||
    !eventResourceType ||
    !eventResourceTitle
  ) {
    return null;
  }
  const typographyVariant = previewMode ? 'body2' : 'body1';

  const pathToNavigate = generatePathToNavigateByResourceType(eventData);
  const showTitleAsLink =
    !!pathToNavigate && eventActionType !== IEventActionType.DELETE;

  return (
    <ListItem
      divider
      sx={theme => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.grey[50],
        },
      })}
    >
      <Stack spacing={previewMode ? 0 : 1} direction="column" width="100%">
        <Stack direction="row" spacing={1} width="100%" flexWrap="wrap">
          <Typography fontWeight={600} variant={typographyVariant}>
            {performedBy.name}
          </Typography>

          <Typography fontWeight={400} variant={typographyVariant}>
            {eventActionTypeMapper[eventActionType]}
          </Typography>

          <Typography fontWeight={400} variant={typographyVariant}>
            the
          </Typography>

          {showTitleAsLink ? (
            <LinkRouter
              to={pathToNavigate}
              fontWeight={600}
              variant={typographyVariant}
            >
              {eventResourceTitle}
            </LinkRouter>
          ) : (
            <Typography fontWeight={400} variant={typographyVariant}>
              {eventResourceTitle}
            </Typography>
          )}

          <Typography fontWeight={400} variant={typographyVariant}>
            {eventResourceTypeTextMapper[eventResourceType]}
          </Typography>

          <Typography fontWeight={400} variant={typographyVariant}>
            {dayjs(eventDateTime).format(timeFormat)}
          </Typography>
        </Stack>

        {children && (
          <Stack direction="column" width="100%" pl={previewMode ? 0 : 2}>
            {children}
          </Stack>
        )}
      </Stack>
    </ListItem>
  );
};

export default ChanglogItemWrapper;
