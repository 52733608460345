import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
// import Header from './components/Header';

const Layout = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ height: '100vh', maxHeight: '100vh', overflowY: 'hidden' }}
    >
      {/* <Header /> */}
      <Outlet />
    </Box>
  );
};
export default Layout;
