import { FC, useState } from 'react';
import { Clear, Search } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { debounce } from 'lodash';
import { isDemo } from 'utils/utils';

type TSearchInputProps = {
  searchText: string;
  onClearSearchText: () => void;
  onSaveSearchText: (value: string) => void;
};

const SearchInput: FC<TSearchInputProps> = ({
  searchText,
  onClearSearchText,
  onSaveSearchText,
}) => {
  const [value, setValue] = useState(searchText);
  if (isDemo) return null;
  const debounceSetSearchText = debounce(onSaveSearchText, 300);
  return (
    <TextField
      variant="outlined"
      label="Search"
      placeholder="Type to search..."
      size="small"
      onChange={e => {
        setValue(e.target.value);
        debounceSetSearchText(e.target.value);
      }}
      value={value}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {value ? (
              <IconButton
                onClick={() => {
                  onClearSearchText();
                  setValue('');
                }}
                onMouseDown={e => e.preventDefault()}
              >
                <Clear />
              </IconButton>
            ) : (
              <Search />
            )}
          </InputAdornment>
        ),
        sx: theme => ({
          backgroundColor: theme.palette.background.default,
        })
      }}
    />
  );
};

export default SearchInput;
