import { TBreadcrumbItem } from 'components/BreadcrumbNavigation/BreadcrumbNavigation.types';
import { useEffect, useMemo } from 'react';
import { PrivateRoutesPaths } from 'routes/Routes.types';
import { useNavigate } from 'react-router-dom';
import { useGetBoardByIdQuery } from '../../redux/apiSlices/board.slice';
import useProjectBreadcrumb, {
  TProjectBreadcrumbProps,
} from './useProjectBreadcrumb';

type TUseDocDetailsBreadcrumbProps = TProjectBreadcrumbProps & {
  boardId: string;
  taskId?: string;
};

const useBoardDetailsBreadcrumb = ({
  boardId,
  projectId,
  taskId,
}: TUseDocDetailsBreadcrumbProps): TBreadcrumbItem[] => {
  const navigate = useNavigate();
  const projectBreadcrumb = useProjectBreadcrumb({ projectId });

  const { data: boardData, isError } = useGetBoardByIdQuery(
    { boardId, projectId },
    {
      skip: !boardId,
    },
  );

  useEffect(() => {
    if (isError) {
      navigate(`/${PrivateRoutesPaths.pageNotFound}`);
    }
  }, [isError]);

  return useMemo(() => {
    if (projectBreadcrumb.length && boardData?.name) {
      const data = [
        ...projectBreadcrumb,
        {
          link: PrivateRoutesPaths.BoardDetails.replace(
            ':boardId',
            boardId,
          ).replace(':projectId', projectId),
          text: boardData.name,
        },
      ];
      if (taskId)
        data.push({
          link: '',
          text: taskId ? 'Task Details' : '',
        });
      return data;
    }
    return [];
  }, [projectBreadcrumb.length, boardData?.name, taskId]);
};

export default useBoardDetailsBreadcrumb;
