import { Chip, MenuItem, TextField } from '@mui/material';
import { FC } from 'react';
import { useGetBoardColumnsQuery } from 'redux/apiSlices/board.slice';
import { ITaskUI } from 'data/types/task.type';
import { useUpdateTaskStatusMutation } from 'redux/apiSlices/tasks.slice';

const TaskStatus: FC<{
  task: ITaskUI;
}> = ({ task }) => {
  const [updateTaskStatus] = useUpdateTaskStatusMutation();
  const { data: columns } = useGetBoardColumnsQuery({
    boardId: task.boardId,
    projectId: task.projectId,
  });
  const onStatusChange = (newColumnId: string) => {
    updateTaskStatus({
      task,
      destinationColumnId: newColumnId,
      destinationIndex: 0,
    });
  };

  return (
    <>
      {!!columns?.length && (
        <TextField
          select
          sx={{
            height: 'fit-content',
          }}
          label="Status"
          size="small"
          placeholder="Status"
          defaultValue={task.columnId}
        >
          {columns.map(column => (
            <MenuItem
              key={column.documentId}
              value={column.documentId}
              onClick={() => {
                onStatusChange(column.documentId);
              }}
            >
              <Chip
                label={column.title}
                sx={{ bgcolor: column.color, width: 1 }}
              />
            </MenuItem>
          ))}
        </TextField>
      )}
    </>
  );
};

export default TaskStatus;
