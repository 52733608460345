import { FC } from 'react';
import { useGetAuthUserQuery } from 'redux/apiSlices/authUser.slice';
import { useProjectDocItemsListener } from 'firebaseServices/listeners/Docs/useProjectDocItemsListener';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { EDocTypes } from 'shared/models/doc.model';
import PreviewIcon from '../FilePreview/PreviewIcon';
import FolderContnentWrapper from './FolderPreviewWrapper';

type TFolderPreviewProps = {
  docId: string;
  projectId: string;
  location: string;
  contentHeight?: number;
};

const FolderPreview: FC<TFolderPreviewProps> = ({
  projectId,
  docId,
  location,
  contentHeight,
}) => {
  const { data: authData } = useGetAuthUserQuery();
  const { data, isLoading } = useProjectDocItemsListener({
    projectId,
    docId,
    parentFolderId: location || 'root',
    limit: 4,
    currentUserId: authData?.id || '',
  });

  if (isLoading) {
    return null;
    // return <Skeleton width="100%" height="100%" />;
  }
  if (!data?.length) {
    return (
      <FolderContnentWrapper>
        <Box height="100%" width="100%" alignContent="start">
          <Typography />
        </Box>
      </FolderContnentWrapper>
    );
  }

  return (
    <FolderContnentWrapper>
      <Grid container justifyContent="center" alignItems="center" height="100%">
        {data.map(item => (
          <Grid xs={6} key={item.documentId} sx={{ height: '50%' }} padding={1}>
            <PreviewIcon
              isFolder={item.type === EDocTypes.FOLDER}
              label={item.name}
              extension={item.type === EDocTypes.FILE ? item.extension : ''}
              height={contentHeight! / 3}
            />
          </Grid>
        ))}
      </Grid>
    </FolderContnentWrapper>
  );
};

export default FolderPreview;
