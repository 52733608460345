import AuthWrapper from 'components/Auth';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SignUpSchema } from 'data/validation/AuthSchemas';
import {
  Divider,
  FormControl,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import PasswordTextField from 'components/Base/Typography/PasswordTextField';
import Button from 'components/Base/Button';
import BackToLogin from 'components/Auth/BackToLogin';
import { useEffect } from 'react';
import ContinueGoogleButton from 'components/Auth/ContinueGoogleButton';
import { useNavigate } from 'react-router-dom';
import { PublicRoutesPaths } from 'routes/Routes.types';
import useCreateUserWithEmailAndPassword from 'firebaseServices/Auth/useCreateUserWithEmailAndPassword';
import { successMessages } from 'data/messages/messages';
import { useSnackbar } from 'notistack';

type TSignUpForm = {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
};

const SignUp = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
  } = useForm<TSignUpForm>({
    defaultValues: {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(SignUpSchema),
  });

  const { createUser, loading, errorMessage, isSuccess } =
    useCreateUserWithEmailAndPassword();

  const onSubmit: SubmitHandler<TSignUpForm> = async values => {
    const { email, password, name } = values;
    await createUser(email, password, {
      name,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(PublicRoutesPaths.Login);
      enqueueSnackbar(successMessages.verifyEmail, {
        variant: 'success',
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (errorMessage) {
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
    }
  }, [errorMessage]);

  return (
    <AuthWrapper title="Sign up" description="Please sign up">
      <FormControl component="form" onSubmit={handleSubmit(onSubmit)} fullWidth>
        <Grid container rowSpacing={3} direction="column">
          <Grid>
            <TextField
              required
              fullWidth
              label="Name"
              type="text"
              id="name"
              error={!!errors.name}
              helperText={errors.name && errors.name.message}
              {...register('name')}
            />
          </Grid>

          <Grid>
            <TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              autoFocus
              variant="outlined"
              error={!!errors.email}
              helperText={errors.email && errors.email.message}
              {...register('email')}
            />
          </Grid>
          <Grid>
            <PasswordTextField
              required
              fullWidth
              label="Password"
              id="password"
              error={!!errors.password}
              helperText={errors.password && errors.password.message}
              {...register('password')}
            />
          </Grid>
          <Grid>
            <PasswordTextField
              required
              fullWidth
              label="Confirm Password"
              id="confirmPassword"
              error={!!errors.confirmPassword}
              helperText={
                errors.confirmPassword && errors.confirmPassword.message
              }
              {...register('confirmPassword')}
            />
          </Grid>
          <Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isSubmitting}
              loading={loading}
            >
              Sign Up
            </Button>
          </Grid>

          <BackToLogin />

          <Grid>
            <Divider>Or</Divider>
          </Grid>
          <Grid>
            <ContinueGoogleButton />
          </Grid>
        </Grid>
      </FormControl>
      <Typography mt={2} variant="body2" align="center">
        By signing up, you agree to our{' '}
        <Link
          underline="hover"
          href="https://workfeed.ai/docs/help/terms-of-service"
        >
          Terms of Services
        </Link>
        <br /> and acknowledge you have read our{' '}
        <Link
          underline="hover"
          href="https://workfeed.ai/docs/help/privacy-policy"
        >
          Privacy Policy
        </Link>
        .
      </Typography>
    </AuthWrapper>
  );
};
export default SignUp;
