import { FC } from 'react';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { Delete, DriveFileRenameOutline } from '@mui/icons-material';

type TProjectCardActionsProps = {
  setOpenRenameProjectModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenRemoveProjectModal: React.Dispatch<React.SetStateAction<boolean>>;
  anchorEl: HTMLElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
};

const ProjectCardActionsMenu: FC<TProjectCardActionsProps> = ({
  setOpenRenameProjectModal,
  setOpenRemoveProjectModal,
  anchorEl,
  setAnchorEl,
}) => {
  const openMenu = Boolean(anchorEl);

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const onClickRenameAction = () => {
    onCloseMenu();
    setOpenRenameProjectModal(true);
  };

  const onClickRemoveAction = () => {
    onCloseMenu();
    setOpenRemoveProjectModal(true);
  };

  return (
    <Menu anchorEl={anchorEl} open={openMenu} onClose={onCloseMenu}>
      <MenuItem
        onClick={e => {
          e.stopPropagation();
          onClickRenameAction();
        }}
      >
        <ListItemIcon>
          <DriveFileRenameOutline fontSize="small" />
        </ListItemIcon>
        <ListItemText>Update</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={e => {
          e.stopPropagation();
          onClickRemoveAction();
        }}
      >
        <ListItemIcon>
          <Delete fontSize="small" />
        </ListItemIcon>
        <ListItemText>Remove</ListItemText>
      </MenuItem>
    </Menu>
  );
};

export default ProjectCardActionsMenu;
