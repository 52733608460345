import React from 'react';
import LoginPage from 'pages/publicPages/LoginPage';
import SignUp from 'pages/publicPages/SignUp';
import Projects from 'pages/privatePages/Projects';
import PageNotFound from 'components/EmptyView/PageNotFound';
import ProjectDetails from 'pages/privatePages/Projects/ProjectDetails'; 
import CustomDocDetailsPage from 'pages/privatePages/CustomDocDetailsPage';
import ChangelogDetailsPage from 'pages/privatePages/ChangelogDetailsPage';
import {
  PrivateRoutesPaths,
  PrivateRouteType,
  PublicRoutesPaths,
  PublicRoutesType,
} from './Routes.types';
import ForgotPassword from '../pages/publicPages/ForgotPassword';
import DocsDetailsPage from '../pages/privatePages/DocsDetailsPage';
import BoardDetailsPage from '../pages/privatePages/BoardDetailsPage';
import TaskDetailPage from '../pages/privatePages/TaskDetailPage';
import Chat from '../pages/privatePages/ChatPage';

// TODO: we don't need notInSidebar here. we can handle it in the sidebar component, or provide inSidebar prop.
export const privateRoutes: PrivateRouteType[] = [
  {
    path: PrivateRoutesPaths.Projects,
    component: <Projects />,
    title: 'Projects',
  },
  {
    path: PrivateRoutesPaths.ProjectsDetails,
    component: <ProjectDetails />,
    notInSidebar: true,
  },
  {
    path: PrivateRoutesPaths.pageNotFound,
    component: <PageNotFound />,
    notInSidebar: true,
  },
  {
    path: PrivateRoutesPaths.BoardDetails,
    component: <BoardDetailsPage />,
    notInSidebar: true,
  },
  {
    path: PrivateRoutesPaths.DocDetails,
    component: <DocsDetailsPage />,
    notInSidebar: true,
  },
  {
    path: PrivateRoutesPaths.CustomDocDetails,
    component: <CustomDocDetailsPage />,
    notInSidebar: true,
  },
  {
    path: PrivateRoutesPaths.TaskDetails,
    component: <TaskDetailPage />,
    notInSidebar: true,
  },
  {
    path: PrivateRoutesPaths.ProjectChangelog,
    component: <ChangelogDetailsPage />,
    notInSidebar: true,
  },
  {
    path: PrivateRoutesPaths.Chat,
    notInSidebar: true,
    component: <Chat />,
  },
];
export const publicRoutes: PublicRoutesType[] = [
  {
    path: PublicRoutesPaths.Login,
    component: <LoginPage />,
    index: true,
  },
  {
    path: PublicRoutesPaths.SignUp,
    component: <SignUp />,
  },
  {
    path: PublicRoutesPaths.ForgotPassword,
    component: <ForgotPassword />,
  },
];
