import { Link } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { PublicRoutesPaths } from 'routes/Routes.types';

const BackToLogin = () => {
  return (
    <Grid container mt={1}>
      <Grid>
        <Link href={PublicRoutesPaths.Login} variant="body2">
          Back to Login
        </Link>
      </Grid>
    </Grid>
  );
};

export default BackToLogin;
