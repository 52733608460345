import * as Yup from 'yup';
import { validationMessages } from '../messages/messages';

const forbiddenCharsRegex = /^[^<>[\]{}|\\^`%/?@&=+$#,"'!*();]+$/;

export const FolderNameSchema = Yup.object().shape({
  name: Yup.string()
    .required(validationMessages.required)
    .matches(forbiddenCharsRegex, validationMessages.invalidUrlChars),
});
