import { createApi } from '@reduxjs/toolkit/query/react';
import { fakeBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { queryFnWrapper } from 'firebaseServices/queryFunctions/helper';
import {
  IBoardUI,
  IColumnUI,
  ICreateNewBoardUI,
  ICreateNewColumnUI,
  IDeleteColumnUI,
  IPutUpdateColumnTasksUI,
  IRemoveBoardUI,
  IUpdateBoardTitleUI,
  IUpdateColumnColorUI,
  IUpdateColumnsOrderUI,
  IUpdateColumnTitleUI,
} from '../../data/types/board.type';
import {
  addBoard,
  createBoardColumn,
  deleteColumn,
  getBoardById,
  getBoardColumns,
  getProjectBoards,
  removeBoard,
  renameBoard,
  updateColumnColor,
  updateColumnsOrder,
  updateColumnTasks,
  updateColumnTitle,
} from '../../firebaseServices/queryFunctions/boardQueryFunctions';
import { eventsApi } from './events.slice';

export const boardApi = createApi({
  tagTypes: ['boards', 'boardColumns', 'board'],
  reducerPath: 'board',
  baseQuery: fakeBaseQuery(),
  endpoints: build => ({
    getProjectBoards: build.query<IBoardUI[] | null, string>({
      queryFn: arg => queryFnWrapper(getProjectBoards, arg),
      providesTags: ['boards'],
    }),
    getBoardById: build.query<
      IBoardUI,
      {
        projectId: string;
        boardId: string;
      }
    >({
      queryFn: arg => queryFnWrapper(getBoardById, arg),
      providesTags: ['board'],
    }),
    addBoard: build.mutation<IBoardUI, ICreateNewBoardUI>({
      queryFn: arg => queryFnWrapper(addBoard, arg),
      invalidatesTags: ['boards'],
    }),
    createNewBoardColumn: build.mutation<void, ICreateNewColumnUI>({
      queryFn: arg => queryFnWrapper(createBoardColumn, arg),
      invalidatesTags: ['boardColumns'],
    }),
    deleteBoardColumn: build.mutation<void, IDeleteColumnUI>({
      queryFn: arg => queryFnWrapper(deleteColumn, arg),
      invalidatesTags: ['boardColumns'],
    }),
    updateColumnTasks: build.mutation<void, IPutUpdateColumnTasksUI>({
      queryFn: arg => queryFnWrapper(updateColumnTasks, arg),
      invalidatesTags: ['boardColumns'],
    }),
    updateColumnsOrder: build.mutation<void, IUpdateColumnsOrderUI>({
      queryFn: arg => queryFnWrapper(updateColumnsOrder, arg),
      invalidatesTags: ['boardColumns'],
    }),
    updateColumnColor: build.mutation<void, IUpdateColumnColorUI>({
      queryFn: arg => queryFnWrapper(updateColumnColor, arg),
      invalidatesTags: ['boardColumns'],
    }),
    updateColumnTitle: build.mutation<void, IUpdateColumnTitleUI>({
      queryFn: arg => queryFnWrapper(updateColumnTitle, arg),
      invalidatesTags: ['boardColumns'],
    }),
    getBoardColumns: build.query<
      IColumnUI[],
      {
        projectId: string;
        boardId: string;
      }
    >({
      queryFn: arg => queryFnWrapper(getBoardColumns, arg),
      providesTags: ['boardColumns'],
    }),
    updateBaordName: build.mutation<void, IUpdateBoardTitleUI>({
      queryFn: arg => queryFnWrapper(renameBoard, arg),
      invalidatesTags: ['boardColumns', 'boards', 'board'],
    }),
    removeBoard: build.mutation<void, IRemoveBoardUI>({
      queryFn: arg => queryFnWrapper(removeBoard, arg),
      invalidatesTags: ['boardColumns', 'boards', 'board'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(eventsApi.util.invalidateTags(['events']));
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
  }),
});

export const {
  useDeleteBoardColumnMutation,
  useGetProjectBoardsQuery,
  useAddBoardMutation,
  useCreateNewBoardColumnMutation,
  useUpdateColumnsOrderMutation,
  useUpdateColumnColorMutation,
  useUpdateColumnTitleMutation,
  useGetBoardColumnsQuery,
  useUpdateColumnTasksMutation,
  useGetBoardByIdQuery,
  useUpdateBaordNameMutation,
  useRemoveBoardMutation,
} = boardApi;
