import MemberAvatar from 'components/MemberAvatar';
import { FC } from 'react';
import { IProjectMember } from 'shared/models/event.model';
import { getFirstLetters, stringToColor } from 'utils/utils';

type TMemberAvatarInContentProps = {
  previewMode?: boolean;
  member: IProjectMember;
};

const MemberAvatarInContent: FC<TMemberAvatarInContentProps> = ({
  previewMode,
  member,
}) => {
  const avatarSiza = previewMode ? 20 : 28;
  return (
    <MemberAvatar
      email={member.email || ''}
      name={member.name || ''}
      userId={member.id || ''}
      firstLetters={getFirstLetters(member.name)}
      avatarColor={stringToColor(member.name || '')}
      key={member.id}
      avatarSize={avatarSiza}
    />
  );
};

export default MemberAvatarInContent;
