import { TFetchConverter } from '../types/general.types';
import { ILabelDTO } from '../../shared/models/label.model';
import { ILabelUI } from '../types/label.type';

export const getLabelConverter: TFetchConverter<
  ILabelUI,
  ILabelDTO & { documentId: string }
> = {
  fromDb: data => {
    return {
      title: data.title,
      id: data.documentId,
    };
  },
};
