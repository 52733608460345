import { useEffect } from 'react';
import { Close } from '@mui/icons-material';
import { IconButton, List, Snackbar } from '@mui/material';
import { useDispatch } from 'react-redux';
import { closeProgressBar } from 'redux/appSlices/uploadFilesInfo.slice';
import { useAppSelector } from 'redux/store';
import InfoItem from './InfoItem';

const UploadFilesInfo = () => {
  const dispatch = useDispatch();
  const uploadFilesInfo = useAppSelector(state => state.uploadFilesInfo);
  const handleClose = () => {
    dispatch(closeProgressBar());
  };

  useEffect(() => {
    return () => {
      dispatch(closeProgressBar());
    };
  }, []);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      sx={theme => ({
        '.MuiSnackbarContent-action': {
          marginRight: theme.spacing(1),
          position: 'absolute',
          top: 0,
          right: 0,
        },
        '.MuiPaper-root': {
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
        },
      })}
      open={uploadFilesInfo.show}
      autoHideDuration={30 * 1000} // 30 second
      onClose={(e, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        handleClose();
      }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      }
      message={
        <List
          sx={theme => ({ width: '400px', marginTop: theme.spacing(1) })}
          disablePadding
        >
          {uploadFilesInfo.items.map(item => {
            const { fileName, progress, errorMessage, id, isDone } = item;
            return (
              <InfoItem
                key={id}
                fileName={fileName}
                progress={progress || 0}
                isDone={!!isDone && !errorMessage}
                isError={errorMessage !== null}
                isLoading={progress !== null && !isDone}
                errorMessage={errorMessage || ''}
              />
            );
          })}
        </List>
      }
    />
  );
};

export default UploadFilesInfo;
