import { FC } from 'react';
import {
  ICustomToolEvent,
  ICustomToolUpdatedData,
  IEventActionType,
} from 'shared/models/event.model';
import OldNewValueContent from '../../components/OldNewValueContent';

type TCustomToolEventsProps = {
  eventData: ICustomToolEvent;
  previewMode?: boolean;
};

const CustomToolEvents: FC<TCustomToolEventsProps> = ({
  eventData,
  previewMode,
}) => {
  if (eventData.eventActionType === IEventActionType.UPDATE) {
    const updateEventData = eventData.data as ICustomToolUpdatedData;
    return (
      <>
        {updateEventData.name && (
          <OldNewValueContent
            prefixText="Name"
            previewMode={previewMode}
            {...updateEventData.name}
          />
        )}
        {updateEventData.link && (
          <OldNewValueContent
            prefixText="Link"
            previewMode={previewMode}
            {...updateEventData.link}
          />
        )}
      </>
    );
  }
  return <></>;
};

export default CustomToolEvents;
