import {
  TreeItem2,
  TreeItem2Props,
  UseTreeItem2ContentSlotOwnProps,
  useTreeItem2Utils,
} from '@mui/x-tree-view';
import { forwardRef } from 'react';

// tree item to expand and collapse only on icon click
const TreeItem = forwardRef(function MyTreeItem(
  props: TreeItem2Props,
  ref: React.Ref<HTMLLIElement>,
) {
  const { interactions } = useTreeItem2Utils({
    itemId: props.itemId,
    children: props.children,
  });

  const handleContentClick: UseTreeItem2ContentSlotOwnProps['onClick'] =
    event => {
      event.defaultMuiPrevented = true;
      interactions.handleSelection(event);
    };

  const handleIconContainerClick = (event: React.MouseEvent) => {
    interactions.handleExpansion(event);
  };

  return (
    <TreeItem2
      {...props}
      ref={ref}
      slotProps={{
        content: { onClick: handleContentClick },
        iconContainer: { onClick: handleIconContainerClick },
      }}
    />
  );
});

export default TreeItem;
