import { useSnackbar } from 'notistack';
import { useAddFileToDocMutation } from 'redux/apiSlices/doc.slice';
import { useDispatch } from 'react-redux';
import { FileRejection } from 'react-dropzone';
import {
  setFailedUploadFileItem,
  setFileSaved,
} from 'redux/appSlices/uploadFilesInfo.slice';
import { TRtkErrorType } from 'data/types/general.types';
import { errorMessages } from 'data/messages/messages';
import useUploadFileToStorage from './useUploadFileToStorage';

type TUseUploadFileInDocOnDropProps = {
  projectId: string;
  docId: string;
  currentLocation: string;
};

const useUploadFileInDocOnDrop = ({
  projectId,
  docId,
  currentLocation,
}: TUseUploadFileInDocOnDropProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [addFileToDoc] = useAddFileToDocMutation();
  const { uploadFile } = useUploadFileToStorage();
  const dispatch = useDispatch();

  const onDrop = async (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ) => {
    if (fileRejections.length) {
      const error = fileRejections[0];
      dispatch(
        setFailedUploadFileItem({
          id: error.file.name,
          errorMessage: error.errors[0].message,
          fileName: error.file.name,
        }),
      );
      return;
    }
    const file = acceptedFiles[0];

    try {
      const { downloadURL: url, storagePath } = await uploadFile(
        file,
        projectId,
      );
      await addFileToDoc({
        projectId,
        docId,
        file,
        location: currentLocation,
        storagePath,
        url,
      }).unwrap();
      dispatch(setFileSaved({ id: storagePath }));
    } catch (err) {
      const error = err as TRtkErrorType;
      enqueueSnackbar(error?.data?.message || errorMessages.default, {
        variant: 'error',
      });
    }
  };

  return { onDrop };
};

export default useUploadFileInDocOnDrop;
