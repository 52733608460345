import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { authUserApi } from './apiSlices/authUser.slice';
import { projectApi } from './apiSlices/project.slice';
import { boardApi } from './apiSlices/board.slice';
import { tasksApi } from './apiSlices/tasks.slice';
import { docApi } from './apiSlices/doc.slice';
import { labelsApi } from './apiSlices/labels.slice';
import uploadFilesInfo from './appSlices/uploadFilesInfo.slice';
import { setStore } from './storeAccessor';
import boardFilterSlice from './appSlices/boardFilter.slice';
import { chatApi } from './apiSlices/chats.slice';
import { eventsApi } from './apiSlices/events.slice';
import { customToolApi } from './apiSlices/customTool.slice';

export const rootReducer = combineReducers({
  uploadFilesInfo: uploadFilesInfo.reducer,
  boardFilterSlice: boardFilterSlice.reducer,
  [authUserApi.reducerPath]: authUserApi.reducer,
  [projectApi.reducerPath]: projectApi.reducer,
  [boardApi.reducerPath]: boardApi.reducer,
  [tasksApi.reducerPath]: tasksApi.reducer,
  [docApi.reducerPath]: docApi.reducer,
  [labelsApi.reducerPath]: labelsApi.reducer,
  [chatApi.reducerPath]: chatApi.reducer,
  [eventsApi.reducerPath]: eventsApi.reducer,
  [customToolApi.reducerPath]: customToolApi.reducer,
});

const setupStore = (initialState: object = {}) => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat(
        authUserApi.middleware,
        projectApi.middleware,
        boardApi.middleware,
        tasksApi.middleware,
        docApi.middleware,
        labelsApi.middleware,
        chatApi.middleware,
        eventsApi.middleware,
        customToolApi.middleware,
      ),
    preloadedState: initialState,
  });

  setStore(store); // Set the store in the accessor

  return store;
};

export const store = setupStore();

export const clearAllCachedData = () => {
  store.dispatch(authUserApi.util.resetApiState());
  store.dispatch(projectApi.util.resetApiState());
  store.dispatch(docApi.util.resetApiState());
  store.dispatch(tasksApi.util.resetApiState());
  store.dispatch(boardApi.util.resetApiState());
  store.dispatch(labelsApi.util.resetApiState());
  store.dispatch(eventsApi.util.resetApiState());
  store.dispatch(chatApi.util.resetApiState());
  store.dispatch(customToolApi.util.resetApiState());
};

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
