import { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetProjectByIdQuery } from 'redux/apiSlices/project.slice';
import { useGetProjectEventsQuery } from 'redux/apiSlices/events.slice';
import useProjectChangelogBreadcrumb from 'hooks/BreadcrumbItems/useProjectChangelogBreadcrumb';
import searchNoResult from 'assets/images/search_no_result.svg';
import DetailsPageWrapper from 'components/DetailsPageWrapper';
import Button from 'components/Base/Button';
import { PrivateRoutesPaths } from 'routes/Routes.types';
import EmptyView from 'components/EmptyView';
import ChangeLogList from './ChangeLogList';
import SearchInput from './SearchInput';

const ChangelogDetailsPage = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams<{ projectId: string }>();
  
  const projectId = params.projectId || '';
  const { data: projectData } = useGetProjectByIdQuery(projectId, {
    skip: !projectId,
  });

  const [eventsDataCount, setEventsDataCount] = useState(10);
  const [searchText, setSearchText] = useState('');

  const {
    data: eventsData,
    isLoading,
    isFetching,
    isError,
  } = useGetProjectEventsQuery(
    { projectId, limitCount: eventsDataCount, searchQuery: searchText },
    {
      skip: !projectId,
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    },
  );

  useEffect(() => {
    if (isError && !searchText) navigate(`/${PrivateRoutesPaths.pageNotFound}`);
  }, [isError]);

  useEffect(() => {
    if (isError && searchText) {
      setSearchText('');
      enqueueSnackbar('Search is not working now.', {
        variant: 'error',
      });
    }
  }, [isError]);

  const breadcrumbItems = useProjectChangelogBreadcrumb({ projectId });

  const showMoreButton =
    !!eventsData && eventsData.totalCount > eventsData.data.length;

  const onSearch = (value: string) => {
    setSearchText(value);
  };
  const onClearSearch = () => {
    setSearchText('');
  };

  return (
    <DetailsPageWrapper
      header={{
        breadcrumbItems,
        rightAction: (
          <SearchInput
            searchText={searchText}
            onSaveSearchText={onSearch}
            onClearSearchText={onClearSearch}
          />
        ),
      }}
      bodyBgColor={projectData?.color}
      loading={isLoading}
      isEmptyView={
        !eventsData?.data.length && !isLoading && !isFetching && !!searchText
      }
      emptyView={<EmptyView imgSrc={searchNoResult} />}
    >
      <Container
        sx={{
          flex: 1,
          paddingBottom: 2,
        }}
      >
        <ChangeLogList eventsData={eventsData?.data} />
        {showMoreButton && (
          <Button
            variant="text"
            color="primary"
            fullWidth
            loading={isLoading || isFetching}
            onClick={() => {
              setEventsDataCount(eventsDataCount + 10);
            }}
          >
            Show More
          </Button>
        )}
      </Container>
    </DetailsPageWrapper>
  );
};

export default ChangelogDetailsPage;
