import {
  Card,
  CardActionArea,
  CardMedia,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Icon } from 'components/Base/Icon';
import { FC } from 'react';

type TAddProjectCardProps = {
  title: string;
  onClick?: () => void;
  imgUrl?: string;
};

const AddProjectCard: FC<TAddProjectCardProps> = ({
  title,
  onClick = () => null,
  imgUrl,
}) => {
  const theme = useTheme();
  const cardSize = 140;
  const addContent = (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      width={cardSize}
      height={cardSize}
    >
      <Grid>
        <Icon icon="plus" size={64} color={theme.palette.primary.main} />
      </Grid>
    </Grid>
  );
  const imgContent = (
    <CardMedia
      component="img"
      height={cardSize}
      width={cardSize}
      image={imgUrl}
      alt={title}
    />
  );
  return (
    <Stack direction="column" spacing={1}>
      <Card>
        <CardActionArea onClick={onClick}>
          {imgUrl ? imgContent : addContent}
        </CardActionArea>
      </Card>
      <Typography variant="subtitle1" align="left">
        {title}
      </Typography>
    </Stack>
  );
};

export default AddProjectCard;
