import { FabOwnProps } from '@mui/material/Fab';
import { FC } from 'react';
import { TextSnippet } from '@mui/icons-material';
import { ICustomDocShortcutUi } from 'data/types/user.types';
import ShortcutItem from './ShortcutItem';

const DocShortcutItem: FC<{
  shortcut: ICustomDocShortcutUi;
  itemSize: FabOwnProps['size'];
  onClick: () => void;
  isOpened: boolean;
}> = ({ shortcut, itemSize, onClick, isOpened }) => {
  return (
    <ShortcutItem
      itemSize={itemSize}
      name={shortcut.name}
      onClick={onClick}
      icon={<TextSnippet />}
      isOpened={isOpened}
      projectId={shortcut.projectId}
    />
  );
};
export default DocShortcutItem;
