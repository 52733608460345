import AlertDialog from 'components/Dialogs/AlertDialog';
import { errorMessages, successMessages } from 'data/messages/messages';
import { TRtkErrorType } from 'data/types/general.types';
import { useSnackbar } from 'notistack';
import { FC } from 'react';
import { useRemoveDocItemMutation } from 'redux/apiSlices/doc.slice';

type TRemoveDocItemModalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  projectId: string;
  docId: string;
  docItemId: string;
};

const RemoveDocItemModal: FC<TRemoveDocItemModalProps> = ({
  setOpen,
  open,
  docId,
  projectId,
  docItemId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [removeDocItem, { isLoading }] = useRemoveDocItemMutation();

  const closeModal = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      await removeDocItem({
        projectId,
        docId,
        id:docItemId,
      }).unwrap();

      enqueueSnackbar(successMessages.toast, {
        variant: 'success',
      });
    } catch (err) {
      const error = err as TRtkErrorType;
      enqueueSnackbar(error?.data.message || errorMessages.default, {
        variant: 'error',
      });
    }
    closeModal();
  };

  if (!open) return null;
  return (
    <AlertDialog
      open={open}
      title="Remove Document"
      text="Are you sure you want to permanently remove this document? This action cannot be undone."
      onCancel={closeModal}
      onConfirm={handleDelete}
      submitLoading={isLoading}
      submitDisabled={isLoading}
    />
  );
};

export default RemoveDocItemModal;
