import { FC } from 'react';
import { MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

type TRemoveColumnActionProps = {
  onDeleteColumn: (moveTasksTo: string | null) => Promise<void>;
  hasTasks: boolean;
  openConfirmModal: () => void;
  deleteLoading: boolean;
};

const RemoveColumnAction: FC<TRemoveColumnActionProps> = ({
  onDeleteColumn,
  hasTasks,
  openConfirmModal,
  deleteLoading,
}) => {
  const onActionClick = async () => {
    if (hasTasks) {
      openConfirmModal();
    } else {
      await onDeleteColumn(null);
    }
  };

  return (
    <MenuItem onClick={onActionClick} disabled={deleteLoading}>
      <DeleteIcon />
      Delete
    </MenuItem>
  );
};

export default RemoveColumnAction;
