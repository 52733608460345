import { FC } from 'react';
import { IFileUI } from 'data/types/doc.types';
import PreviewIcon from 'components/Preview/FilePreview/PreviewIcon';
import DocItemCard from '../components/DocItemCard';
import DownloadMenuItem from './DownloadMenuItem';

export type TFileCardProps = {
  docItem: IFileUI;
  docId: string;
  projectId: string;
};

const FileCard: FC<TFileCardProps> = ({ docItem, docId, projectId }) => {
  const { name } = docItem;

  return (
    <DocItemCard
      title={name}
      docId={docId}
      docItem={docItem}
      projectId={projectId}
      additionalActions={[
        <DownloadMenuItem downloadUrl={docItem.url} fileName={docItem.name} />,
      ]}
      onActionAreaClick={() => window.open(docItem.url, '_blank')}
    >
      <PreviewIcon height={1} extension={docItem.extension} />
    </DocItemCard>
  );
};

export default FileCard;
