import { TBreadcrumbItem } from 'components/BreadcrumbNavigation/BreadcrumbNavigation.types';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useProjectDocItemByIdListener } from 'firebaseServices/listeners/Docs/useProjectDocItemByIdListener';
import { useRenameDocItemMutation } from 'redux/apiSlices/doc.slice';
import useDocDetailsBreadcrumb, {
  TUseDocDetailsBreadcrumbProps,
} from './useDocDetailsBreadcrumb';

type TUseCustomDocDetailsBreadcrumbProps = Omit<
  TUseDocDetailsBreadcrumbProps,
  'foldersLocations'
> & {
  customDocId: string;
};

const useCustomDocDetailsBreadcrumb = ({
  docId,
  projectId,
  customDocId,
}: TUseCustomDocDetailsBreadcrumbProps): TBreadcrumbItem[] => {
  const { data } = useProjectDocItemByIdListener({
    docId,
    projectId,
    docItemId: customDocId,
  });

  const [searchParams] = useSearchParams();

  const foldersLocations = searchParams.get('location') || 'root';

  const docDetailsBreadcrumb = useDocDetailsBreadcrumb({
    docId,
    projectId,
    // foldersLocations,
  });

  const [renameDocItem] = useRenameDocItemMutation();
  const handleRenameDoc = async (newName: string) => {
    await renameDocItem({
      projectId,
      docId,
      docItemId: customDocId,
      newName,
    }).unwrap();
  };

  const breadcrumbs = useMemo(() => {
    if (docDetailsBreadcrumb.length && data?.name && foldersLocations) {
      return [
        ...docDetailsBreadcrumb,
        {
          text: data?.name,
          link: '',
          onUpdateText: handleRenameDoc,
        },
      ];
    }
    return [];
  }, [foldersLocations, data?.name, docDetailsBreadcrumb.length]);

  return breadcrumbs;
};

export default useCustomDocDetailsBreadcrumb;
