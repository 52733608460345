import { Avatar, AvatarProps, Tooltip } from '@mui/material';
import BadgeOwner from 'components/BadgeOwner';
import PopoverWithState from 'components/Base/PopoverWithState';
import { FC } from 'react';
import { EUserRole } from 'shared/types/generic.types';
import MemberPopoverContent from './PopoverContent';
import { getFirstLetters, stringToColor } from '../../utils/utils';

type TMemberAvatarProps = {
  role?: EUserRole;
  userId: string;
  avatarColor?: string;
  firstLetters?: string;
  hideBadge?: boolean;
  name: string;
  email: string;
  avatarSize?: number;
  avatarSx?: AvatarProps['sx'];
};

const MemberAvatar: FC<TMemberAvatarProps> = ({
  role,
  avatarColor,
  firstLetters,
  hideBadge,
  name,
  email,
  avatarSize,
  avatarSx,
}) => {
  return (
    <BadgeOwner
      {...(hideBadge && {
        invisible: true,
      })}
      role={role}
    >
      <PopoverWithState
        popoverContent={
          <MemberPopoverContent
            name={name}
            email={email}
            avatar={{
              bgColor: avatarColor || stringToColor(name),
              firstLetters: firstLetters || getFirstLetters(name),
            }}
            role={role}
          />
        }
      >
        <Tooltip title={name}>
          <Avatar
            sx={{
              bgcolor: avatarColor || stringToColor(name),
              cursor: 'pointer',
              ...(avatarSize && {
                height: avatarSize,
                width: avatarSize,
                fontSize: avatarSize / 2,
                ...avatarSx,
              }),
            }}
          >
            {firstLetters || getFirstLetters(name)}
          </Avatar>
        </Tooltip>
      </PopoverWithState>
    </BadgeOwner>
  );
};

export default MemberAvatar;
