import { Box, styled } from '@mui/material';
import { FC } from 'react';

export const FolderIconWrapper = styled(Box)(() => {
  const borderWith = 4;
  const tabHeight = 20;
  const folderColor = '#f6c141';
  return {
    width: '100%',
    height: '100%',
    margin: '0 auto',
    marginTop: `${tabHeight}px`,
    position: 'relative',
    border: `${borderWith}px solid ${folderColor}`,
    borderRadius: '0 6px 6px 6px',
    boxShadow: `1px 1px 2px ${folderColor}`,
    textAlign: 'center',
    '&:before': {
      content: "''",
      width: '50%',
      height: `${tabHeight}px`,
      borderRadius: '0 20px 0 0',
      backgroundColor: folderColor,
      position: 'absolute',
      top: `-${tabHeight}px`,
      left: `-${borderWith}px`,
      border: `${borderWith}px solid ${folderColor}`,
    },
  };
});

export type TExtendedChildrenProps = {
  parentWith?: number;
  parentHeight?: number;
};

type TFolderContnentWrapperProps = {
  children: JSX.Element;
};

const FolderContnentWrapper: FC<TFolderContnentWrapperProps> = ({
  children,
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        margin: '0 auto',
        textAlign: 'center',
      }}
    >
      {children}
    </Box>
  );
};

export default FolderContnentWrapper;
