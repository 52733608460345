import { FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ICustomDocUI } from 'data/types/doc.types';
import { PrivateRoutesPaths } from 'routes/Routes.types';
import CustomDocPreview from 'components/Preview/CustomDocPreview';
import DocItemCard from '../components/DocItemCard';

export type TCustomDocCardProps = {
  docItem: ICustomDocUI;
  docId: string;
  projectId: string;
};

const CustomDocCard: FC<TCustomDocCardProps> = ({
  docItem,
  docId,
  projectId,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const foldersLocations = searchParams.get('location') || 'root';

  const onContentClick = () => {
    const customDocDetailPagePath = PrivateRoutesPaths.CustomDocDetails.replace(
      ':projectId',
      projectId,
    )
      .replace(':docId', docId)
      .replace(':customDocId', docItem.documentId);
    navigate(`/${customDocDetailPagePath}?location=${foldersLocations}`);
  };

  return (
    <DocItemCard
      docId={docId}
      docItem={docItem}
      projectId={projectId}
      onActionAreaClick={onContentClick}
      title={docItem.name}
    >
      <CustomDocPreview content={docItem.content} />
    </DocItemCard>
  );
};

export default CustomDocCard;
