import { FC } from 'react';
import { TAcceptedFileExtension } from 'components/UploadFiles/uploadFile.config';
import ai from 'assets/fileTypes/ai.svg';
import docx from 'assets/fileTypes/docx.svg';
import csv from 'assets/fileTypes/csv.svg';
import exe from 'assets/fileTypes/exe.svg';
import gif from 'assets/fileTypes/gif.svg';
import iso from 'assets/fileTypes/iso.svg';
import java from 'assets/fileTypes/java.svg';
import jpg from 'assets/fileTypes/jpg.svg';
import pdf from 'assets/fileTypes/pdf.svg';
import png from 'assets/fileTypes/png.svg';
import psd from 'assets/fileTypes/psd.svg';
import rar from 'assets/fileTypes/rar.svg';
import srd from 'assets/fileTypes/srd.svg';
import txt from 'assets/fileTypes/txt.svg';
import xml from 'assets/fileTypes/xml.svg';
import xls from 'assets/fileTypes/xls.svg';
import zip from 'assets/fileTypes/zip.svg';
import FolderIcon from 'assets/fileTypes/folder.svg';
import CustomDocIcon from 'assets/fileTypes/customDoc.svg';
import { Box, Stack, Typography } from '@mui/material';

type TPreviewIconProps = {
  extension?: string;
  isFolder?: boolean;
  label?: string;
  height?: number | string;
};

// TODO: import icons dynamically
const extensionToIconMapper: Record<TAcceptedFileExtension, string> = {
  ai,
  csv,
  docx,
  doc: docx,
  exe,
  gif,
  iso,
  java,
  jpg,
  jpeg: jpg,
  pdf,
  png,
  psd,
  rar,
  srd,
  svg: jpg,
  text: txt,
  txt,
  xml,
  xls,
  xlsx: xls,
  zip,
};

const PreviewIcon: FC<TPreviewIconProps> = ({
  extension,
  label,
  height,
  isFolder,
}) => {
  const icon = isFolder
    ? FolderIcon
    : extensionToIconMapper[extension as TAcceptedFileExtension] ||
      CustomDocIcon;
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      margin="0 auto"
      height={height || 'inherit'}
    >
      <Box
        component="img"
        src={icon}
        alt="file icon"
        height={height || 'inherit'}
      />
      {label && (
        <Typography
          variant="body2"
          lineHeight={1.2}
          sx={{ wordBreak: 'break-all' }}
        >
          {label.substring(0, 15)}
        </Typography>
      )}
    </Stack>
  );
};

export default PreviewIcon;
