import { collection, getDocs } from 'firebase/firestore';
import { doc, firestore, getDoc } from '../firebase.config';
import {
  EFirestoreCollectionPaths,
  EProjectSubCollectionPaths,
} from '../../shared/types/FirestoreCollections';
import FirestoreOperations from '../FirestoreOperations';
import { ICustomToolDTO } from '../../shared/models/customTool.model';
import {
  ICreatCustomTool,
  ICustomToolUI,
  IDeleteCustomToolUi,
  IUpdateCustomToolUI,
} from '../../data/types/customTools.type';
import { getCustomToolConverter } from '../../data/converters/customTool.converter';

export const createCustomTool = async ({
  projectId,
  name,
  type,
  link,
}: ICreatCustomTool) => {
  const customToolsRef = collection(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    projectId,
    EProjectSubCollectionPaths.CUSTOM_TOOLS,
  );
  const newCustomTool: ICustomToolDTO = {
    name,
    type,
    link,
    projectId,
  };
  await FirestoreOperations.addDoc(customToolsRef, newCustomTool);
};

export const getCustomTools = async (
  projectId: string,
): Promise<ICustomToolUI[]> => {
  const collectionRef = collection(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    projectId,
    EProjectSubCollectionPaths.CUSTOM_TOOLS,
  );
  const snapshots = await getDocs(collectionRef);

  if (snapshots.empty || snapshots.docs.every(d => !d.exists())) {
    return [];
  }

  const data: ICustomToolUI[] = [];
  snapshots.forEach(snapshot => {
    const tool = snapshot.data() as ICustomToolDTO;
    data.push(
      getCustomToolConverter.fromDb({ ...tool, documentId: snapshot.id }),
    );
  });
  return data;
};

export const deleteCustomTool = async ({
  projectId,
  toolId,
}: IDeleteCustomToolUi) => {
  const toolRef = doc(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    projectId,
    EProjectSubCollectionPaths.CUSTOM_TOOLS,
    toolId,
  );
  await FirestoreOperations.deleteDoc(toolRef);
};

export const updateCustomTool = async ({
  toolId,
  projectId,
  name,
  link,
}: IUpdateCustomToolUI) => {
  const toolRef = doc(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    projectId,
    EProjectSubCollectionPaths.CUSTOM_TOOLS,
    toolId,
  );
  await FirestoreOperations.updateDoc(toolRef, {
    name,
    link,
  });
};

export const getCustomTool = async ({
  toolId,
  projectId,
}: {
  toolId: string;
  projectId: string;
}) => {
  const toolRef = doc(
    firestore,
    EFirestoreCollectionPaths.PROJECTS,
    projectId,
    EProjectSubCollectionPaths.CUSTOM_TOOLS,
    toolId,
  );
  const snapshot = await getDoc(toolRef);
  if (snapshot.exists()) {
    return getCustomToolConverter.fromDb({
      ...(snapshot.data() as ICustomToolDTO),
      documentId: snapshot.id,
    });
  }
  throw new Error('Tool does not exist');
};
