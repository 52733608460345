import { FC } from 'react';
import { ICustomToolUI } from 'data/types/customTools.type';
import CustomToolCard from './CustomToolCard';

const AllCustomTools: FC<{ customTools: ICustomToolUI[] }> = ({
  customTools,
}) => {
  return (
    <>
      {customTools.map(tool => (
        <CustomToolCard tool={tool} key={tool.documentId} />
      ))}
    </>
  );
};

export default AllCustomTools;
