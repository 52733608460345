import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TUploadFileItem = {
  progress: number | null;
  errorMessage: string | null;
  fileName: string;
  id: string;
  isDone?: boolean;
};

type TUploadFilesInfoState = {
  show: boolean;
  items: TUploadFileItem[];
};

const initialState: TUploadFilesInfoState = {
  show: false,
  items: [],
};

const uploadFilesInfo = createSlice({
  name: 'uploadFilesInfo',
  initialState,
  reducers: {
    setFailedUploadFileItem: (
      state,
      action: PayloadAction<{
        fileName: string;
        errorMessage: string;
        id: string;
      }>,
    ) => {
      state.show = true;
      const itemIndex = state.items.findIndex(
        item => item.id === action.payload.id,
      );
      if (itemIndex !== -1) {
        state.items[itemIndex].errorMessage = action.payload.errorMessage;
        state.items[itemIndex].progress = null;
      } else {
        state.items.unshift({
          fileName: action.payload.fileName,
          errorMessage: action.payload.errorMessage,
          progress: null,
          id: action.payload.id,
        });
      }
    },
    setSuccessUploadFileItem: (
      state,
      action: PayloadAction<{ fileName: string; progress: number; id: string }>,
    ) => {
      state.show = true;
      const itemIndex = state.items.findIndex(
        item => item.id === action.payload.id,
      );
      if (itemIndex !== -1) {
        state.items[itemIndex].progress = action.payload.progress;
      } else {
        state.items.unshift({
          fileName: action.payload.fileName,
          errorMessage: null,
          progress: action.payload.progress,
          id: action.payload.id,
        });
      }
    },
    setFileSaved: (state, action: PayloadAction<{ id: string }>) => {
      const itemIndex = state.items.findIndex(
        item => item.id === action.payload.id,
      );
      if (itemIndex !== -1) {
        state.items[itemIndex].isDone = true;
      }
    },

    closeProgressBar: state => {
      state.show = false;
      state.items = [];
    },
  },
});

export default uploadFilesInfo;

export const {
  closeProgressBar,
  setFailedUploadFileItem,
  setSuccessUploadFileItem,
  setFileSaved,
} = uploadFilesInfo.actions;
