import { FC } from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { Icon } from '../../Base/Icon';

type TUpdateLinkProps = {
  onClick: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
};

const UpdateLinkMenuItem: FC<TUpdateLinkProps> = ({ onClick }) => {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <Icon icon="link" color="rgba(0, 0, 0, 0.54)" size={20} />
      </ListItemIcon>
      <ListItemText>Update link</ListItemText>
    </MenuItem>
  );
};

export default UpdateLinkMenuItem;
