import { IAuthUserDTO } from 'shared/models/user.model';
import { IUserBaseInfoDB } from 'shared/types/user.types';
import { getFirstLetters, stringToColor } from 'utils/utils';
import { TCreateConverter, TFetchConverter } from '../types/general.types';
import {
  IAuthUserUI,
  IUserBaseInfoUI,
  TUpdateAuthUserFS,
  TUpdateAuthUserUI,
} from '../types/user.types';

export const authUserConverter: TFetchConverter<
  IAuthUserUI,
  IAuthUserDTO & { documentId: string }
> = {
  fromDb: data => {
    return {
      id: data.documentId,
      email: data.email,
      name: data.name,
      creationDateTime: data.creationDateTime,
      projects: data.projects,
      settings: data.settings,
    };
  },
};

export const updateAuthUserConverter: TCreateConverter<
  TUpdateAuthUserUI,
  TUpdateAuthUserFS
> = {
  toDb: data => {
    return {
      ...(data.name && { name: data.name }),
    };
  },
};

export const getUserBaseInfoConverter: TFetchConverter<
  IUserBaseInfoUI,
  IUserBaseInfoDB
> = {
  fromDb: data => {
    return {
      id: data.id,
      email: data.email,
      name: data.name,
      firstLetters: getFirstLetters(data.name),
      avatarColor: stringToColor(data.name),
    };
  },
};
