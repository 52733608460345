import { FC } from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { DriveFileRenameOutline } from '@mui/icons-material';

type TRenameMenuItemProps = {
  onClick: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
};

const RenameMenuItem: FC<TRenameMenuItemProps> = ({ onClick }) => {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <DriveFileRenameOutline fontSize="small" />
      </ListItemIcon>
      <ListItemText>Rename</ListItemText>
    </MenuItem>
  );
};

export default RenameMenuItem;
