import { generateUid, storage } from 'firebaseServices/firebase.config';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { useDispatch } from 'react-redux';
import {
  setFailedUploadFileItem,
  setSuccessUploadFileItem,
} from 'redux/appSlices/uploadFilesInfo.slice';

export const useUploadFileToStorage = () => {
  const dispatch = useDispatch();

  const uploadFile = async (
    file: File,
    projectId: string,
  ): Promise<{ downloadURL: string; storagePath: string }> => {
    const uid = generateUid();
    const storagePath = `${projectId}/${uid}`;
    const storageProjectRef = ref(storage, storagePath);

    const uploadTask = uploadBytesResumable(storageProjectRef, file);
    dispatch(
      setSuccessUploadFileItem({
        fileName: file.name,
        progress: 0,
        id: storagePath,
      }),
    );
    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        snapshot => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
          );
          dispatch(
            setSuccessUploadFileItem({
              fileName: file.name,
              progress,
              id: storagePath,
            }),
          );
        },
        error => {
          dispatch(
            setFailedUploadFileItem({
              fileName: file.name,
              errorMessage: error.message,
              id: storagePath,
            }),
          );
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve({ downloadURL, storagePath });
          } catch (error) {
            reject(error);
          }
        },
      );
    });
  };

  return { uploadFile };
};

export default useUploadFileToStorage;
