import { useState } from 'react';
import { useAddNewProjectMutation } from 'redux/apiSlices/project.slice';
import AddProjectCard from '../../components/AddProjectCard';
import AddEditNewProjectModal from '../../components/AddEditNewProjectModal';

const AddNewProject = () => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const [addNewProject, { isLoading }] = useAddNewProjectMutation();
  return (
    <>
      <AddProjectCard title="Blank project" onClick={handleClickOpen} />
      <AddEditNewProjectModal
        submitLoading={isLoading}
        onSubmitClick={values => addNewProject(values).unwrap()}
        open={open}
        setOpen={setOpen}
        modalTitlePrefix="New"
        submitButtonText="Create"
      />
    </>
  );
};

export default AddNewProject;
