import { useNavigate, useParams } from 'react-router-dom';
import { useGetProjectByIdQuery } from 'redux/apiSlices/project.slice';
import { PrivateRoutesPaths } from 'routes/Routes.types';
import DetailsPageWrapper from 'components/DetailsPageWrapper';
import useProjectBreadcrumb from 'hooks/BreadcrumbItems/useProjectBreadcrumb';
import { useState } from 'react';
import AddTool from './AddTool';
import ProjectMembers from './ProjectMembers';
import ActiveTools from './ActiveTools';

const ProjectDetails = () => {
  const params = useParams<{ id: string }>();
  const projectId = params.id || '';
  const navigate = useNavigate();
  const [isMobileView, setIsMobileView] = useState(false);

  const { data, isError, isLoading } = useGetProjectByIdQuery(projectId, {
    skip: !projectId,
  });

  const breadcrumbItems = useProjectBreadcrumb({ projectId });

  if (!projectId || isError) {
    navigate(`/${PrivateRoutesPaths.pageNotFound}`);
    return null;
  }

  return (
    <>
      {!isLoading &&
        <DetailsPageWrapper
          setIsMobileView={setIsMobileView}
          loading={isLoading}
          header={{
            breadcrumbItems,
            leftAction: (
              <AddTool projectId={projectId} isMobileView={isMobileView} />
            ),
            rightAction: (
              <ProjectMembers
                members={data?.members || []}
                projectId={projectId}
                projectName={data?.name || ''}
                isMobileView={isMobileView}
              />
            ),
          }}
          bodyBgColor={data?.color}
        >
          <ActiveTools projectId={projectId} />
        </DetailsPageWrapper>}
    </>
  );
};

export default ProjectDetails;
