import { FC, ReactNode } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Close } from '@mui/icons-material';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import { Icon } from '../../../Base/Icon';

export type TPinnedContentHeaderProps = {
  onUnpin: () => Promise<void>;
  onRemoveShortcut: () => Promise<void>;
  onClose: () => void;
  boardFilter?: ReactNode;
};

const PinnedContentHeader: FC<TPinnedContentHeaderProps> = ({
  onUnpin,
  onRemoveShortcut,
  onClose,
  boardFilter,
}) => {
  return (
    <Box width={1} display="flex" justifyContent="space-between">
      <Box>
        <Tooltip title="Unpin">
          <IconButton onClick={onUnpin}>
            <Icon icon="pinOff" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Remove shortcut">
          <IconButton onClick={onRemoveShortcut}>
            <BookmarkRemoveIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box>
        {boardFilter}
        <Tooltip title="Close">
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default PinnedContentHeader;
