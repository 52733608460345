import { List, ListSubheader } from '@mui/material';
import { FC, useMemo } from 'react';
import dayjs from 'dayjs';
import { dateFormat } from 'data/static';
import { IEventActionType, TProjectEventDTO } from 'shared/models/event.model';
import { TProjectEventUI } from 'data/types/events.types';
import ChanglogItemWrapper from './components/ChanglogItemWrapper';
import EventContent from './EventContent';
import { convertDateToText } from './mappers.data';

type TChangeLogListProps = {
  eventsData?: TProjectEventUI['data'] | null;
  previewMode?: boolean;
};

type TChangeLogListItem =
  | { type: 'subheader'; data: string }
  | { type: 'listItem'; data: TProjectEventDTO };

const ChangeLogList: FC<TChangeLogListProps> = ({
  eventsData,
  previewMode,
}) => {
  const eventsDataWithSubheaders = useMemo(() => {
    if (!eventsData || !eventsData.length) {
      return [];
    }
    const dataWithSubHeaders: TChangeLogListItem[] = [];
    eventsData.forEach((event, index) => {
      if (
        index === 0 ||
        (event.eventDateTime &&
          dayjs(event.eventDateTime).format(dateFormat) !==
            dayjs(eventsData[index - 1].eventDateTime).format(dateFormat))
      ) {
        dataWithSubHeaders.push({
          type: 'subheader',
          data: convertDateToText(event.eventDateTime),
        });
      }
      dataWithSubHeaders.push({ type: 'listItem', data: event });
    });
    return dataWithSubHeaders;
  }, [eventsData]);

  if (!eventsData || !eventsData.length) {
    return null;
  }

  return (
    <List dense={previewMode} disablePadding={previewMode} subheader={<li />}>
      {eventsDataWithSubheaders?.map(listItem => {
        if (listItem.type === 'subheader') {
          return (
            <ListSubheader
              sx={theme => ({
                lineHeight: previewMode ? theme.spacing(3) : '48px',
              })}
              key={listItem.data}
              disableSticky={previewMode}
            >
              {listItem.data}
            </ListSubheader>
          );
        }
        if (listItem.type === 'listItem') {
          return (
            <ChanglogItemWrapper
              previewMode={previewMode}
              eventData={listItem.data}
              key={listItem.data.eventDateTime}
            >
              {listItem.data.eventActionType === IEventActionType.UPDATE && (
                <EventContent
                  eventData={listItem.data}
                  previewMode={previewMode}
                />
              )}
            </ChanglogItemWrapper>
          );
        }

        return null;
      })}
    </List>
  );
};

export default ChangeLogList;
