import { FC, useMemo, useState } from 'react';
import {
  Autocomplete,
  Avatar,
  Chip,
  Grid,
  ListItem,
  TextField,
  createFilterOptions,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import {
  errorMessages,
  successMessages,
  validationMessages,
} from 'data/messages/messages';
import {
  useGetAllUsersBaseInfoQuery,
  useInviteUserMutation,
} from 'redux/apiSlices/authUser.slice';
import { IAddProjectMemberUI, IProjectUI } from 'data/types/project.types';
import { useAddProjectMembersMutation } from 'redux/apiSlices/project.slice';
import { IUserBaseInfoUI } from 'data/types/user.types';
import { inviteProjectMemberSchema } from 'data/validation/ProjectSchemas';
import { TRtkErrorType } from 'data/types/general.types';
import { EmailOutlined } from '@mui/icons-material';
import Button from 'components/Base/Button';
import MemberOptionItem from './MemberOptionItem';

type TAddProjectMemberProps = {
  currentMembers: IProjectUI['members'];
  projectId: string;
  projectName: string;
};

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: IUserBaseInfoUI) => `${option.email} ${option.name}`,
  ignoreCase: true,
  trim: true,
});

const AddProjectMember: FC<TAddProjectMemberProps> = ({
  currentMembers,
  projectId,
  projectName,
}) => {
  const [selectedValues, setSelectedValues] = useState<IUserBaseInfoUI[]>([]);
  const [inputValue, setInputValue] = useState('');

  const hasSelectedValues = useMemo(
    () => !!selectedValues.length,
    [selectedValues],
  );

  const { data, isLoading } = useGetAllUsersBaseInfoQuery();
  const [addProjectMembers, { isLoading: addMemberLoading }] =
    useAddProjectMembersMutation();
  const [inviteUsers, { isLoading: inviteUsersLoading }] =
    useInviteUserMutation();

  const { enqueueSnackbar } = useSnackbar();

  const onAddNewMembers = async () => {
    const membersEmailsToSendIvitation: string[] = [];
    const membersExistsInSystem: IAddProjectMemberUI[] = [];

    selectedValues.forEach(member => {
      if (member.id) {
        membersExistsInSystem.push(member);
      } else {
        membersEmailsToSendIvitation.push(member.email);
      }
    });

    const userEmailsFailedToInvite: string[] = [];

    if (membersEmailsToSendIvitation.length) {
      try {
        const response = await inviteUsers({
          emails: membersEmailsToSendIvitation,
        }).unwrap();
        const usersSuccessfullyInvited: IAddProjectMemberUI[] = [];
        response.forEach(res => {
          if (res.status === 'Success' && res.id) {
            usersSuccessfullyInvited.push({
              email: res.email,
              id: res.id,
              name: res.name || '',
            });
          } else {
            userEmailsFailedToInvite.push(res.email);
          }
        });

        membersExistsInSystem.push(...usersSuccessfullyInvited);
      } catch (error) {
        const e = error as TRtkErrorType;
        enqueueSnackbar(e?.data.message || errorMessages.default, {
          variant: 'error',
        });
      }
    }

    if (!membersExistsInSystem.length) {
      return;
    }

    try {
      await addProjectMembers({
        newMembers: membersExistsInSystem,
        projectId,
      }).unwrap();
      enqueueSnackbar(successMessages.toast, {
        variant: 'success',
      });
    } catch (err) {
      const error = err as TRtkErrorType;
      enqueueSnackbar(error?.data?.message || errorMessages.default, {
        variant: 'error',
      });
    }

    if (userEmailsFailedToInvite.length) {
      enqueueSnackbar(
        `Failed to invite ${userEmailsFailedToInvite.join(', ')}`,
        {
          variant: 'error',
        },
      );
    }

    setSelectedValues([]);
  };

  const onAddNewUserInList = () => {
    const isEmailValid = inviteProjectMemberSchema.isValidSync({
      email: inputValue,
    });
    if (!isEmailValid) {
      enqueueSnackbar(validationMessages.email, {
        variant: 'error',
      });
      return;
    }
    setSelectedValues(prev => [
      ...(prev || []),
      { email: inputValue, id: '', name: '' },
    ]);
  };

  const options = useMemo(() => {
    return (data || [])?.filter(
      user =>
        ![...(selectedValues || []), ...currentMembers].find(
          member => member.email === user.email,
        ),
    );
  }, [data, currentMembers, selectedValues]);

  return (
    <>
      <Autocomplete
        value={selectedValues}
        inputValue={inputValue}
        options={options}
        filterOptions={filterOptions}
        fullWidth
        multiple
        popupIcon={null}
        open={!!inputValue}
        renderInput={params => (
          <TextField
            {...params}
            variant="standard"
            label="Search by Email or Name"
            InputProps={{
              ...params.InputProps,
              placeholder: hasSelectedValues ? 'add more people...' : '',
              multiline: true,
              sx: {
                '& textarea': {
                  width: 'max-content !important',
                },
              },
            }}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const isNewUser = !option.id;
            return (
              <Chip
                label={option.email}
                avatar={
                  isNewUser ? (
                    <EmailOutlined
                      sx={{
                        fill: 'black',
                      }}
                    />
                  ) : (
                    <Avatar
                      sx={{
                        bgcolor: option.avatarColor,
                      }}
                    >
                      {option.firstLetters}
                    </Avatar>
                  )
                }
                size="small"
                variant="filled"
                {...getTagProps({ index })}
              />
            );
          })
        }
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderOption={(props, optionValue) => {
          return (
            <ListItem {...props}>
              <MemberOptionItem
                name={optionValue.name}
                email={optionValue.email}
              />
            </ListItem>
          );
        }}
        loading={isLoading}
        disabled={inviteUsersLoading}
        noOptionsText={
          <ListItem
            onClick={onAddNewUserInList}
            sx={{
              cursor: 'pointer',
              ':hover': {
                backgroundColor: 'grey.100',
              },
            }}
          >
            <MemberOptionItem
              email={inputValue}
              inviteMember={{ projectName }}
            />
          </ListItem>
        }
        onChange={async (event, newValue) => {
          setSelectedValues(newValue);
        }}
        getOptionLabel={option => option.email}
      />
      <Grid container justifyContent="end" mt={1}>
        <Button
          sx={{
            visibility: hasSelectedValues ? 'visible' : 'hidden',
          }}
          variant="contained"
          loading={addMemberLoading}
          onClick={onAddNewMembers}
        >
          Add {selectedValues.length} people
        </Button>
      </Grid>
    </>
  );
};

export default AddProjectMember;
