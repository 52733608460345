import {
  TDocItemUI,
  IDocUI,
  IFileUI,
  IDocItemCommonUI,
  IFolderUI,
  ICustomDocUI,
  ICustomDocViewerUI,
  IAddCustomDocViewerUI,
} from 'data/types/doc.types';
import { TCreateConverter, TFetchConverter } from 'data/types/general.types';
import { parseTableData } from 'pages/privatePages/CustomDocDetailsPage/CustomDoc/helpers';
import {
  ICustomDocDTO,
  ICustomDocViewerDTO,
  IDocDTO,
  IDocItemCommonDTO,
  IFileDTO,
  IFolderDTO,
  TDocItemDTO,
} from 'shared/models/doc.model';
import { getFirstLetters, stringToColor } from 'utils/utils';

export const getDocConverter: TFetchConverter<
  IDocUI,
  IDocDTO & { documentId: string }
> = {
  fromDb: data => {
    return {
      name: data.name,
      projectId: data.projectId,
      documentId: data.documentId,
      creationDate: data.creationDate,
    };
  },
};

export const getDocItemCommonPartConverter: TFetchConverter<
  IDocItemCommonUI,
  IDocItemCommonDTO & { documentId: string }
> = {
  fromDb: data => {
    return {
      name: data.name,
      creationDate: data.creationDate,
      documentId: data.documentId,
      parentFolderId: data.parentFolderId,
    };
  },
};

export const getFileDocConverter: TFetchConverter<
  IFileUI,
  IFileDTO & { documentId: string }
> = {
  fromDb: data => {
    return {
      ...getDocItemCommonPartConverter.fromDb(data),
      extension: data.extension,
      url: data.url,
      storagePath: data.storagePath,
      type: data.type,
    };
  },
};

export const getFolderDocConverter: TFetchConverter<
  IFolderUI,
  IFolderDTO & { documentId: string }
> = {
  fromDb: data => {
    return {
      ...getDocItemCommonPartConverter.fromDb(data),
      type: data.type,
    };
  },
};

export const getCustomDocViewersConverter: TFetchConverter<
  ICustomDocViewerUI[],
  ICustomDocViewerDTO
> = {
  fromDb: data => {
    return Object.entries(data || {}).map(([userId, member]) => ({
      userId,
      name: member.name,
      firstLetters: getFirstLetters(member.name),
      avatarColor: stringToColor(member.name || ''),
      email: member.email,
    }));
  },
};

export const getCustomDocConverter: TFetchConverter<
  ICustomDocUI,
  ICustomDocDTO & { documentId: string }
> = {
  fromDb: data => {
    const parsedContent = parseTableData(data.content);
    return {
      ...getDocItemCommonPartConverter.fromDb(data),
      type: data.type,
      content: parsedContent,
      isPublished: data.isPublished,
      creatorId: data.creatorId,
      viewers: getCustomDocViewersConverter.fromDb(data.viewers),
    };
  },
};

export const getDocItemConverter: TFetchConverter<
  TDocItemUI,
  TDocItemDTO & { documentId: string }
> = {
  fromDb: data => {
    switch (data.type) {
      case 'File':
        return getFileDocConverter.fromDb(
          data as IFileDTO & { documentId: string },
        );
      case 'Folder':
        return getFolderDocConverter.fromDb(
          data as IFolderDTO & { documentId: string },
        );
      case 'Custom':
        return getCustomDocConverter.fromDb(
          data as ICustomDocDTO & { documentId: string },
        );
      default:
        return {} as TDocItemUI;
    }
  },
};

export const addAddCustomDocViewerConverter: TCreateConverter<
  IAddCustomDocViewerUI,
  ICustomDocViewerDTO
> = {
  toDb: data => {
    return {
      [data.id]: {
        name: data.name,
        email: data.email,
      },
    };
  },
};
