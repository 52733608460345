import AuthWrapper from 'components/Auth';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoginSchema } from 'data/validation/AuthSchemas';
import { Divider, FormControl, Link, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import useLoginWithEmailAndPassword from 'firebaseServices/Auth/useLoginWithEmailAndPassword';
import PasswordTextField from 'components/Base/Typography/PasswordTextField';
import Button from 'components/Base/Button';
import { PublicRoutesPaths } from 'routes/Routes.types';
import ContinueGoogleButton from 'components/Auth/ContinueGoogleButton';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';

type TLoginForm = {
  email: string;
  password: string;
};

const LoginPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { login, loading, error } = useLoginWithEmailAndPassword();

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
  } = useForm<TLoginForm>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(LoginSchema),
  });

  const onSubmit: SubmitHandler<TLoginForm> = async values => {
    const { email, password } = values;
    try {
      await login(email, password);
    } catch (e) {
      // do nothing
    }
  };

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  }, [error]);
  return (
    <AuthWrapper title="Login" description="Please login">
      <FormControl component="form" onSubmit={handleSubmit(onSubmit)} fullWidth>
        <Grid container rowSpacing={3} direction="column">
          <Grid>
            <TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              autoComplete="email"
              autoFocus
              variant="outlined"
              error={!!errors.email}
              helperText={errors.email && errors.email.message}
              {...register('email')}
            />
          </Grid>
          <Grid>
            <PasswordTextField
              required
              fullWidth
              label="Password"
              id="password"
              autoComplete="current-password"
              error={!!errors.password}
              helperText={errors.password && errors.password.message}
              {...register('password')}
            />
          </Grid>
          <Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isSubmitting}
              loading={loading}
            >
              Sign In
            </Button>
          </Grid>

          <Grid container mt={1}>
            <Grid xs>
              <Link href={PublicRoutesPaths.ForgotPassword} variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid>
              <Link href={PublicRoutesPaths.SignUp} variant="body2">
                Don`t have an account? Sign Up
              </Link>
            </Grid>
          </Grid>

          <Grid>
            <Divider>Or</Divider>
          </Grid>
          <Grid>
            <ContinueGoogleButton />
          </Grid>
        </Grid>
      </FormControl>
    </AuthWrapper>
  );
};
export default LoginPage;
