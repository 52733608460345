import { FC } from 'react';
import {
  IBoardEvent,
  IColumnUpdatedData,
  IEventActionType,
} from 'shared/models/event.model';
import OldNewValueContent from '../../components/OldNewValueContent';
import ColorCircleInContent from '../../components/ColorCircleInContent';

type TColumnEventsProps = {
  eventData: IBoardEvent;
  previewMode?: boolean;
};

const ColumnEvents: FC<TColumnEventsProps> = ({ eventData, previewMode }) => {
  if (eventData.eventActionType === IEventActionType.UPDATE) {
    const updateColumnData = eventData.data as IColumnUpdatedData;
    const { color, title } = updateColumnData;
    return (
      <>
        {title && (
          <OldNewValueContent
            prefixText="Title"
            previewMode={previewMode}
            {...updateColumnData.title}
          />
        )}
        {color && (
          <OldNewValueContent
            prefixText="Color"
            oldValue={<ColorCircleInContent color={color.oldValue} />}
            newValue={<ColorCircleInContent color={color.newValue} />}
            previewMode={previewMode}
            alignItems="center"
          />
        )}
      </>
    );
  }
  return <></>;
};

export default ColumnEvents;
