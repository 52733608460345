import { FabOwnProps } from '@mui/material/Fab';
import { FC } from 'react';
import MessageIcon from '@mui/icons-material/Message';
import { IChatShortcutUi } from 'data/types/user.types';
import { Box, Typography } from '@mui/material';
import useListenUnreadMessages from 'firebaseServices/listeners/Chats/useListenUnreadMessages';
import { useGetAuthUserQuery } from 'redux/apiSlices/authUser.slice';
import ShortcutItem from './ShortcutItem';

const ChatShortcutItem: FC<{
  shortcut: IChatShortcutUi;
  itemSize: FabOwnProps['size'];
  onClick: () => void;
  isOpened: boolean;
}> = ({ shortcut, itemSize, onClick, isOpened }) => {
  const { data: userData } = useGetAuthUserQuery();
  const { unreadMessages } = useListenUnreadMessages({
    chatId: shortcut.chatId,
    projectId: shortcut.projectId,
    userId: userData?.id || '',
  });

  return (
    <Box sx={{ position: 'relative' }}>
      <ShortcutItem
        itemSize={itemSize}
        name={shortcut.name}
        onClick={onClick}
        icon={<MessageIcon />}
        isOpened={isOpened}
        projectId={shortcut.projectId}
      />
      {!!unreadMessages && (
        <Typography
          variant="h6"
          sx={{
            position: 'absolute',
            top: '-8px',
            right: '-8px',
            zIndex: theme => theme.zIndex.fab + 1,
            cursor: 'default',
          }}
        >
          {unreadMessages > 99 ? '99+' : unreadMessages}
        </Typography>
      )}
    </Box>
  );
};
export default ChatShortcutItem;
