import { useMemo } from 'react';
import { TGetDocItemByIdProps } from 'firebaseServices/queryFunctions/docsQueryFunctions';
import { TDocItemDTO } from 'shared/models/doc.model';
import { TDocItemUI } from 'data/types/doc.types';
import { getDocItemConverter } from 'data/converters/doc.converter';
import { firestoreReference } from 'firebaseServices/FirestoreReferenceManager';
import { useFirebaseDocumentListener } from '../useFirebaseDocumentListener';

export const useProjectDocItemByIdListener = (props: TGetDocItemByIdProps) => {
  const { docId, docItemId, projectId } = props;
  const docRef = useMemo(
    () => firestoreReference.projectDocItem_DocRef(props),
    [docId, docItemId, projectId],
  );

  const { snapshotData, isLoading, error } =
    useFirebaseDocumentListener<TDocItemDTO>(docRef);

  const data: TDocItemUI | null = snapshotData
    ? getDocItemConverter.fromDb({
        ...snapshotData,
      })
    : null;

  return { data, isLoading, error };
};
