import { FC, ReactNode, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, SxProps } from '@mui/material';

const MenuDropdown: FC<{
  menuItems: ReactNode[];
  sx?: SxProps;
}> = ({ menuItems, sx }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <>
      <IconButton
        sx={sx}
        onClick={e => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        {menuItems.map(item => item)}
      </Menu>
    </>
  );
};

export default MenuDropdown;
