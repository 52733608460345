import { auth, sendPasswordResetEmail } from 'firebaseServices/firebase.config';
import AuthWrapper from 'components/Auth';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ForgotPasswordSchema } from 'data/validation/AuthSchemas';
import { FormControl, TextField } from '@mui/material';
import Button from 'components/Base/Button';
import BackToLogin from 'components/Auth/BackToLogin';
import { errorMessages, successMessages } from 'data/messages/messages';
import { useSnackbar } from 'notistack';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

type TForgotPasswordForm = {
  email: string;
};

const ForgotPassword = () => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
  } = useForm<TForgotPasswordForm>({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(ForgotPasswordSchema),
  });

  const onSubmit: SubmitHandler<TForgotPasswordForm> = async values => {
    try {
      const { email } = values;
      await sendPasswordResetEmail(auth, email);
      enqueueSnackbar(successMessages.ressetPasswordEmailSent, {
        variant: 'success',
      });
    } catch (err) {
      enqueueSnackbar(errorMessages.default, {
        variant: 'error',
      });
    }
  };

  return (
    <AuthWrapper title="Forgot password">
      <FormControl component="form" onSubmit={handleSubmit(onSubmit)} fullWidth>
        <Grid container rowSpacing={3} direction="column">
          <Grid>
            <TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              autoComplete="email"
              autoFocus
              variant="outlined"
              error={!!errors.email}
              helperText={errors.email && errors.email.message}
              {...register('email')}
            />
          </Grid>
          <Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isSubmitting}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        <BackToLogin />
      </FormControl>
    </AuthWrapper>
  );
};

export default ForgotPassword;
