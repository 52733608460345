import React, { cloneElement, FC } from 'react';
import IconButton from '@mui/material/IconButton';
import { Box, Typography } from '@mui/material';

const ToolEmptyView: FC<{
  description: string;
  icon: React.ReactElement;
}> = ({ description, icon }) => {
  const styledIcon = cloneElement(icon, {
    sx: { color: 'white', fontSize: 32 },
  });

  return (
    <Box
      sx={{
        height: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 4,
      }}
    >
      <IconButton
        sx={{
          backgroundColor: 'primary.light',
          width: 96,
          height: 96,
        }}
        disableRipple
      >
        {styledIcon}
      </IconButton>
      <Typography variant="body1" paddingInline={2} textAlign="center">
        {description}
      </Typography>
    </Box>
  );
};

export default ToolEmptyView;
