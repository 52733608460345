import { useRef, FC } from 'react';
import { Box, Button, Stack } from '@mui/material';
import useElementResize from 'hooks/useElementResize';

type TEmptyViewProps = {
  backButton?: boolean;
  imgSrc: string;
};

const EmptyView: FC<TEmptyViewProps> = ({ backButton, imgSrc }) => {
  const ref = useRef(null);
  const { width } = useElementResize({
    ref,
  });
  const imgWidth = width > 600 ? 500 : width * 0.8;

  return (
    <Stack
      ref={ref}
      width="100%"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Box width={imgWidth} component="img" alt="Page not found" src={imgSrc} />
      {backButton && (
        <Button
          variant="contained"
          onClick={() => window.location.replace('/')}
          sx={{ marginTop: 2 }}
        >
          Go Home Page
        </Button>
      )}
    </Stack>
  );
};

export default EmptyView;
