import AlertDialog from 'components/Dialogs/AlertDialog';
import { errorMessages, successMessages } from 'data/messages/messages';
import { TRtkErrorType } from 'data/types/general.types';
import { useSnackbar } from 'notistack';
import { FC } from 'react';
import { useRemoveProjectMutation } from 'redux/apiSlices/project.slice';

type TRemoveProjectModalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  projectId: string;
};

const RemoveProjectModal: FC<TRemoveProjectModalProps> = ({
  open,
  setOpen,
  projectId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [removeProject, { isLoading }] = useRemoveProjectMutation();
  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      await removeProject(projectId).unwrap();

      enqueueSnackbar(successMessages.toast, {
        variant: 'success',
      });
    } catch (err) {
      const error = err as TRtkErrorType;
      enqueueSnackbar(error?.data.message || errorMessages.default, {
        variant: 'error',
      });
    }
    handleClose();
  };

  if (!open) return null;
  return (
    <AlertDialog
      open={open}
      title="Remove Project"
      text="Are you sure you want to permanently remove this project? This action cannot be undone."
      onCancel={handleClose}
      onConfirm={handleDelete}
      submitLoading={isLoading}
      submitDisabled={isLoading}
    />
  );
};

export default RemoveProjectModal;
