import { FC } from 'react';
import { Card } from '@mui/material';
import { IBoardColumnShortDataUI, IColumnUI } from 'data/types/board.type';
import { Draggable } from 'react-beautiful-dnd';
import { ITaskUI } from 'data/types/task.type';
import ColumnHeader from './ColumnHeader';
import ColumnContent from './ColumnContent';

const BoardColumn: FC<{
  column: IColumnUI;
  width: number;
  boardId: string;
  projectId: string;
  index: number;
  tasks: ITaskUI[];
  setOpenedTask: (taskId: string) => void;
  dragLoading: boolean;
  allColumnsShortData: IBoardColumnShortDataUI[];
}> = ({
  column,
  width,
  boardId,
  projectId,
  index,
  tasks,
  setOpenedTask,
  dragLoading,
  allColumnsShortData,
}) => {
  return (
    <Draggable draggableId={column.documentId} index={index}>
      {provided => (
        <Card
          {...provided.draggableProps}
          ref={provided.innerRef}
          sx={{
            width,
            minWidth: '200px',
            backgroundColor: column.color,
            padding: 2,
            paddingTop: 1,
          }}
        >
          <ColumnHeader
            dragHandleProps={provided.dragHandleProps}
            column={column}
            boardId={boardId}
            projectId={projectId}
            allColumnsShortData={allColumnsShortData}
          />
          <ColumnContent
            column={column}
            boardId={boardId}
            projectId={projectId}
            tasks={tasks}
            color={column.color}
            setOpenedTask={setOpenedTask}
            dragLoading={dragLoading}
          />
        </Card>
      )}
    </Draggable>
  );
};
export default BoardColumn;
