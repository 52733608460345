import { FC } from 'react';
import { Typography } from '@mui/material';

type TEventContentPrefixProps = {
  prefixText: string;
};

const EventContentPrefix: FC<TEventContentPrefixProps> = ({ prefixText }) => {
  return (
    <Typography variant="caption" fontWeight={600} color="textSecondary">
      {prefixText}:
    </Typography>
  );
};

export default EventContentPrefix;
