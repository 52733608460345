import { useGetAuthUserQuery } from 'redux/apiSlices/authUser.slice';
import { useGetUserProjectsQuery } from 'redux/apiSlices/project.slice';
import CreateProjectSection from './CreateProjectSection';
import AllProjects from './AllProjects';
import ProjectPageWrapper from './components/ProjectPageWrapper';

const Projects = () => {
  const { data: userData } = useGetAuthUserQuery();
  const userProjectIds = Object.keys(userData?.projects || {});

  const { data, isLoading } = useGetUserProjectsQuery(userProjectIds, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <ProjectPageWrapper
      createSection={{
        title: 'Start a new project',
        element: <CreateProjectSection />,
      }}
      loading={isLoading}
      empty={!(data || []).length}
      mainSection={{
        title: 'Recent projects',
      }}
    >
      <AllProjects projectData={data || []} />
    </ProjectPageWrapper>
  );
};

export default Projects;
