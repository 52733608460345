import { firestore } from 'firebaseServices/firebase.config';
import { getTaskConverter } from 'data/converters/task.converter';
import { ITaskUI } from 'data/types/task.type';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { ITaskDTO } from 'shared/models/task.model';
import { EFirestoreCollectionPaths } from 'shared/types/FirestoreCollections';

interface IProps {
  projectId: string;
  boardId: string;
}

type ITasksState = Record<string, ITaskUI>;
const useListenBoardTasks = ({ boardId, projectId }: IProps) => {
  const [tasks, setTasks] = useState<ITasksState>({});

  useEffect(() => {
    const ref = collection(firestore, EFirestoreCollectionPaths.TASKS);
    const q = query(
      ref,
      where('projectId', '==', projectId),
      where('boardId', '==', boardId),
    );
    const unsubscribe = onSnapshot(q, querySnapshot => {
      if (!querySnapshot.empty) {
        setTasks(
          Object.fromEntries(
            querySnapshot.docs.map(snapshot => {
              return [
                snapshot.id,
                getTaskConverter.fromDb({
                  ...(snapshot.data() as ITaskDTO),
                  documentId: snapshot.id,
                }),
              ];
            }),
          ),
        );
      }
    });
    return () => {
      unsubscribe();
    };
  }, [boardId, projectId]);

  return { tasks };
};

export default useListenBoardTasks;
