import { FC, useEffect, useState } from 'react';
import { Autocomplete, createFilterOptions, TextField } from '@mui/material';
import { useGetProjectLabelsQuery } from 'redux/apiSlices/labels.slice';
import { ITaskUI } from 'data/types/task.type';
import {
  useAddAndUpdateTaskLabelsMutation,
  useUpdateTaskLabelsMutation,
} from '../../../../../redux/apiSlices/tasks.slice';

interface IOptionType {
  title: string;
  optionLabel: string;
  id?: string;
}

const filter = createFilterOptions<IOptionType>();

const TaskLabels: FC<{
  task: ITaskUI;
}> = ({ task }) => {
  const [updateTaskLabels] = useUpdateTaskLabelsMutation();
  const [addAndUpdateTaskLabels] = useAddAndUpdateTaskLabelsMutation();

  const { data: labels } = useGetProjectLabelsQuery(task.projectId);
  const [value, setValue] = useState<string[]>(task.labels);

  const [selectOptions, setSelectOptions] = useState<IOptionType[]>([]);
  useEffect(() => {
    if (labels?.length) {
      setSelectOptions(
        labels.map(item => ({ ...item, optionLabel: item.title })),
      );
    } else setSelectOptions([]);
  }, [labels]);
  const onUpdateLabels = (labelIds: string[]) => {
    updateTaskLabels({ labels: labelIds, taskId: task.taskId }).unwrap();
    setValue(labelIds);
  };
  const onAddAndUpdate = async (newLabel: string, labelIds: string[]) => {
    const response = await addAndUpdateTaskLabels({
      taskId: task.taskId,
      projectId: task.projectId,
      labelIds,
      newLabel,
    }).unwrap();
    setSelectOptions(prev => [
      ...prev,
      { optionLabel: response.title, title: response.title, id: response.id },
    ]);
    setValue(prev => [...prev, response.id]);
  };
  return (
    <>
      {labels && (
        <Autocomplete
          fullWidth
          multiple
          popupIcon={null}
          value={
            value.map(id => selectOptions.find(item => item.id === id)) || []
          }
          clearOnBlur
          noOptionsText="Type to create a new label"
          options={selectOptions}
          getOptionLabel={option => option?.optionLabel || ''}
          renderInput={params => (
            <TextField
              {...params}
              label="Labels"
              multiline={false}
              maxRows={1}
            />
          )}
          onChange={async (event, newLabels) => {
            const addedLabel = newLabels.find(item => !item?.id);
            if (!addedLabel) {
              const labelIds = newLabels.map(item => item?.id);
              onUpdateLabels(labelIds as string[]);
            } else {
              const temp: string[] = [];
              newLabels.forEach(item => {
                if (item?.id) temp.push(item.id);
              });
              await onAddAndUpdate(addedLabel.optionLabel, temp);
            }
          }}
          renderOption={(props, option) => (
            <li {...props} key={option?.id}>
              {option?.title}
            </li>
          )}
          filterOptions={(options, params) => {
            const filtered = filter(options as IOptionType[], params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              option => inputValue === option?.title,
            );
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                title: `Add "${inputValue}"`,
                optionLabel: inputValue,
              });
            }
            return filtered;
          }}
        />
      )}
    </>
  );
};

export default TaskLabels;
