import {
  Card,
  CardActionArea,
  CardContent,
  Menu,
} from '@mui/material';
import useElementResize from 'hooks/useElementResize';
import React, { FC, cloneElement, useRef, useState } from 'react';
import RenameMenuItem from 'components/MenuDropdown/RenameMenuItem';
import RemoveMenuItem from 'components/MenuDropdown/RemoveMenuItem';
import NameModal from 'components/Dialogs/NameModal';
import AlertDialog from 'components/Dialogs/AlertDialog';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import CardHeader from 'components/Base/Card/CardHeader';
import UpdateLinkMenuItem from 'components/MenuDropdown/UpdateLinkMenuItem';
import LinkModal from 'components/Dialogs/LinkModal';
import PinMenuItem from 'components/MenuDropdown/PinMenuItem';

type TActiveToolCardProps = {
  onClick: () => void;
  children: React.ReactElement;
  name: string;
  link?: string;
  onRenameTool?: (name: string) => Promise<void>;
  onRemoveTool?: () => Promise<void>;
  removeLoading?: boolean;
  onUpdateLink?: (link: string) => Promise<void>;
  pinProps?: {
    inShortcuts: boolean;
    onClick: () => void;
  };
};

export type TActiveToolCardChildrenProps = {
  contentWidth?: number;
  contentHeight?: number;
};

const ActiveToolCard: FC<TActiveToolCardProps> = ({
  onClick,
  children,
  name,
  link,
  onRenameTool,
  onRemoveTool,
  onUpdateLink,
  removeLoading,
  pinProps,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [openUpdateLinkModal, setOpenUpdateLinkModal] = useState(false);

  const cardContentRef = useRef<HTMLDivElement>(null);
  const { width, height } = useElementResize({
    ref: cardContentRef,
  });
  const childrenWithProps = cloneElement(children, {
    contentWidth: width,
    contentHeight: height,
  } as TActiveToolCardChildrenProps);

  const onOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };

  const showActionsMenu = Boolean(onRenameTool || onRemoveTool);

  return (
    <Grid
      xs={12}
      sm={6}
      md={4}
      lg={3}
      sx={{ height: '50%', minHeight: 210, minWidth: 300 }}
      padding={1}
    >
      <Card sx={{ height: 1 }}>
        <CardActionArea
          onClick={onClick}
          sx={{
            flexGrow: 1,
            height: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <CardHeader
            title={name}
            {...(showActionsMenu && {
              hasaction: { onActionClick: onOpenMenu },
            })}
            headerEllipsis
            border={{
              bottom: true,
            }}
          />
          <CardContent
            sx={{
              width: 1,
              flexGrow: 1,
              overflow: 'hidden',
              padding: 0,
            }}
            ref={cardContentRef}
          >
            {childrenWithProps}
          </CardContent>
        </CardActionArea>
      </Card>
      {showActionsMenu && (
        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={() => setMenuAnchorEl(null)}
          onBlur={() => setMenuAnchorEl(null)}
        >
          {onRenameTool && (
            <RenameMenuItem onClick={() => setOpenRenameModal(true)} />
          )}
          {onUpdateLink && (
            <UpdateLinkMenuItem
              onClick={() => {
                setOpenUpdateLinkModal(true);
              }}
            />
          )}
          {pinProps && (
            <PinMenuItem
              inShortcuts={pinProps.inShortcuts}
              onClick={pinProps.onClick}
            />
          )}
          {onRemoveTool && (
            <RemoveMenuItem onClick={() => setOpenRemoveModal(true)} />
          )}
        </Menu>
      )}
      {openRenameModal && onRenameTool && (
        <NameModal
          modalTitle="Rename"
          open={openRenameModal}
          setOpen={setOpenRenameModal}
          initialName={name}
          onSubmitModal={value => onRenameTool(value)}
        />
      )}
      {openUpdateLinkModal && onUpdateLink && (
        <LinkModal
          modalTitle="Update Link"
          open={openUpdateLinkModal}
          setOpen={setOpenUpdateLinkModal}
          initialLink={link}
          onSubmitModal={value => onUpdateLink(value)}
        />
      )}
      {openRemoveModal && onRemoveTool && (
        <AlertDialog
          open={openRemoveModal}
          title="Remove Tool"
          text="Are you sure you want to permanently remove this tool? This action cannot be undone."
          onCancel={() => {
            setOpenRemoveModal(false);
          }}
          onConfirm={onRemoveTool}
          submitLoading={removeLoading}
          submitDisabled={removeLoading}
        />
      )}
    </Grid>
  );
};

export default ActiveToolCard;
