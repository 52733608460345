import { FC } from 'react'; 
import { IProjectUpdatedData } from 'shared/models/event.model';
import OldNewValueContent from '../../../components/OldNewValueContent';
import ColorCircleInContent from '../../../components/ColorCircleInContent';
import MemberUpdateEvent from './MemberUpdateEvent';

type TProjectUpdateEventProps = {
  updateEventData: IProjectUpdatedData;
  previewMode?: boolean;
};

const ProjectUpdateEvent: FC<TProjectUpdateEventProps> = ({
  updateEventData,
  previewMode,
}) => {
  const { name, description, color, members } = updateEventData;

  return (
    <>
      {name && (
        <OldNewValueContent
          prefixText="Name"
          previewMode={previewMode}
          {...name}
        />
      )}
      {description && (
        <OldNewValueContent
          prefixText="Description"
          previewMode={previewMode}
          {...description}
        />
      )}
      {color && (
        <OldNewValueContent
          prefixText="Color"
          oldValue={<ColorCircleInContent color={color.oldValue} />}
          newValue={<ColorCircleInContent color={color.newValue} />}
          previewMode={previewMode}
          alignItems="center"
        />
      )}
      {members && (
        <MemberUpdateEvent previewMode={previewMode} members={members} />
      )}
    </>
  );
};

export default ProjectUpdateEvent;
