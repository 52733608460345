import { createApi } from '@reduxjs/toolkit/query/react';
import { fakeBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { queryFnWrapper } from 'firebaseServices/queryFunctions/helper';
import { IInviteUserReturnDataDB } from 'shared/types/user.types';
import {
  createUserDocument,
  getAllUsersBaseInfo,
  getAuthUser,
  inviteUserToSystem,
  updateAuthUser,
  updateUserShortcuts,
} from '../../firebaseServices/queryFunctions/authUserQueryFunctions';
import {
  IAuthUserUI,
  ICreateUserDocumentUI,
  IShortcutUi,
  IUserBaseInfoUI,
  TUpdateAuthUserFS,
  TUpdateAuthUserUI,
} from '../../data/types/user.types';

export const authUserApi = createApi({
  tagTypes: ['user', 'all_users'],
  reducerPath: 'authUser',
  baseQuery: fakeBaseQuery(),
  endpoints: build => ({
    getAuthUser: build.query<IAuthUserUI | null, void>({
      queryFn: getAuthUser,
      providesTags: ['user'],
    }),
    updateAuthUserData: build.mutation<TUpdateAuthUserFS, TUpdateAuthUserUI>({
      queryFn: updateAuthUser,
      onQueryStarted: (arg, { queryFulfilled, dispatch }) => {
        queryFulfilled
          .then(res => {
            dispatch(
              authUserApi.util.updateQueryData(
                'getAuthUser',
                undefined,
                data => {
                  return {
                    ...data,
                    ...res.data,
                  } as IAuthUserUI;
                },
              ),
            );
          })
          .catch(error => {
            console.log(error);
          });
      },
    }),
    createUserDocument: build.mutation<void, ICreateUserDocumentUI>({
      queryFn: createUserDocument,
      invalidatesTags: ['user'],
    }),
    getAllUsersBaseInfo: build.query<IUserBaseInfoUI[] | null, void>({
      queryFn: () => queryFnWrapper(getAllUsersBaseInfo),
      providesTags: ['all_users'],
    }),
    updateUserShortcuts: build.mutation<void, IShortcutUi[]>({
      queryFn: arg => queryFnWrapper(updateUserShortcuts, arg),
      onQueryStarted: (arg, { queryFulfilled, dispatch }) => {
        queryFulfilled
          .then(() => {
            dispatch(
              authUserApi.util.updateQueryData(
                'getAuthUser',
                undefined,
                data => {
                  return {
                    ...data,
                    settings: {
                      ...data?.settings,
                      shortcuts: arg,
                    },
                  } as IAuthUserUI;
                },
              ),
            );
          })
          .catch(error => {
            console.log(error);
          });
      },
    }),
    inviteUser: build.mutation<IInviteUserReturnDataDB[], { emails: string[] }>(
      {
        queryFn: arg => queryFnWrapper(inviteUserToSystem, arg),
        invalidatesTags: ['all_users', 'user'],
      },
    ),
  }),
});

export const {
  useGetAuthUserQuery,
  useLazyGetAuthUserQuery,
  useUpdateAuthUserDataMutation,
  useCreateUserDocumentMutation,
  useGetAllUsersBaseInfoQuery,
  useUpdateUserShortcutsMutation,
  useInviteUserMutation,
} = authUserApi;
