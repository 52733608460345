import { RichTreeView } from '@mui/x-tree-view';
import { errorMessages } from 'data/messages/messages';
import { IFolderUI, TDocItemUI } from 'data/types/doc.types';
import { TRtkErrorType } from 'data/types/general.types';
import { useSnackbar } from 'notistack';
import React, { FC, useState } from 'react';
import { useGetAuthUserQuery } from 'redux/apiSlices/authUser.slice';
import {
  useGetAllDocItemsQuery,
  useGetDocByIdQuery,
  useMoveDocItemMutation,
} from 'redux/apiSlices/doc.slice';
import Modal from 'components/Base/Modal';
import { buildFoldersTree } from './helpers';
import TreeItem from './TreeItem';

type TMoveActionModalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  projectId: string;
  docId: string;
  docItem: TDocItemUI;
};

const MoveActionModal: FC<TMoveActionModalProps> = ({
  open,
  setOpen,
  docId,
  projectId,
  docItem,
}) => {
  const initialFolderId = docItem.parentFolderId;
  const [selectedFolderId, setSelectedFolderId] =
    useState<string>(initialFolderId);
  const { enqueueSnackbar } = useSnackbar();
  const [moveDocItem, { isLoading }] = useMoveDocItemMutation();
  const { data: authData } = useGetAuthUserQuery(undefined, {
    skip: !open,
  });
  const { data: docData } = useGetDocByIdQuery(
    {
      docId,
      projectId,
    },
    {
      skip: !docId && !projectId,
    },
  );

  const { data: allFolders } = useGetAllDocItemsQuery(
    {
      docId,
      projectId,
      currentUserId: authData?.id || '',
      onlyFolders: true,
    },
    {
      skip: !open || !authData,
    },
  );

  const { allIds, treeItems } = buildFoldersTree(allFolders as IFolderUI[], docItem, docData?.name);

  const closeModal = () => {
    setOpen(false);
  };

  const hanldeMove = async () => {
    if (selectedFolderId !== initialFolderId) {
      try {
        await moveDocItem({
          projectId,
          docId,
          docItemId: docItem.documentId,
          destinationFolderId: selectedFolderId,
        }).unwrap();
      } catch (err) {
        const error = err as TRtkErrorType;
        enqueueSnackbar(error?.data.message || errorMessages.default, {
          variant: 'error',
        });
      }
    }
    closeModal();
  };

  return (
    <Modal
      open={open}
      handleClose={closeModal}
      title="Move"
      submitButtonProps={{
        disabled: isLoading,
        loading: isLoading,
        onClick: hanldeMove,
        autoFocus: true,
      }}
      contentProps={{ sx: { minWidth: '300px' } }}
    >
      <RichTreeView
        items={treeItems}
        defaultExpandedItems={allIds}
        selectedItems={selectedFolderId}
        slots={{ item: TreeItem }}
        onSelectedItemsChange={(_, id) => {
          if (!id) return;
          setSelectedFolderId(id);
        }}
      />
    </Modal>
  );
};

export default MoveActionModal;
