import { FC, MouseEvent } from 'react';
import { Add } from '@mui/icons-material';
import { Button, IconButton, useTheme } from '@mui/material';

type TDetailsAddButtonProps = {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  label: string;
};

const DetailsAddButton: FC<TDetailsAddButtonProps> = ({ onClick, label }) => {
  const theme = useTheme();

  return label ? (
    <Button
      onClick={onClick}
      variant="contained"
      color="primary"
      size="large"
      startIcon={<Add />}
    >
      {label}
    </Button>
  ) : (
    <IconButton
      sx={{ bgcolor: theme.palette.primary.main, color: 'white' }}
      onClick={onClick}
      color="primary"
      size="small"
    >
      <Add />
    </IconButton>
  );
};

export default DetailsAddButton;
