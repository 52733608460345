import * as Yup from 'yup';
import { errorMessages, validationMessages } from 'data/messages/messages';
import { emailRegexp } from 'shared/constants';

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .matches(emailRegexp, validationMessages.email)
    .required(validationMessages.required),
  password: Yup.string().required(validationMessages.required),
});

export const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .matches(emailRegexp, validationMessages.email)
    .required(validationMessages.required),
});

export const SignUpSchema = Yup.object().shape({
  name: Yup.string().required(validationMessages.required),
  password: Yup.string()
    .required(validationMessages.required)
    .min(6, errorMessages.weakPassword),
  confirmPassword: Yup.string()
    .required(validationMessages.required)
    .oneOf([Yup.ref('password')], validationMessages.confirmPassword),
  email: Yup.string()
    .matches(emailRegexp, validationMessages.email)
    .required(validationMessages.required),
});
