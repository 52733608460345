import { FC } from 'react';
import {
  IEventResourceType,
  TProjectEventDTO,
} from 'shared/models/event.model';
import BoardEvents from './BoardEvents';
import ProjectEvents from './ProjectEvents';
import ColumnEvents from './ColumnEvents';
import TaskEvents from './TaskEvents';
import ChatEvents from './ChatEvents';
import CustomToolEvents from './CustomToolEvents';

export const updateEventComponentMapperByResourseType: Record<
  IEventResourceType,
  React.FC<any> | null
> = {
  Project: ProjectEvents,
  Board: BoardEvents,
  Column: ColumnEvents,
  Task: TaskEvents,
  Chat: ChatEvents,
  'Docs Item': null,
  Docs: null,
  [IEventResourceType.CUSTOM_TOOL]:CustomToolEvents
};

type TEventContentProps = {
  eventData: TProjectEventDTO;
  previewMode?: boolean;
};

const EventContent: FC<TEventContentProps> = ({ eventData, previewMode }) => {
  const Component =
    updateEventComponentMapperByResourseType[eventData.eventResourceType];
  if (!Component) {
    return null;
  }
  return <Component eventData={eventData} previewMode={previewMode} />;
};

export default EventContent;
