import { FC } from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { DriveFileMoveOutlined } from '@mui/icons-material';

type TMoveMenuItemProps = {
  onClick: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
};

const MoveMenuItem: FC<TMoveMenuItemProps> = ({ onClick }) => {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <DriveFileMoveOutlined fontSize="small" />
      </ListItemIcon>
      <ListItemText>Move</ListItemText>
    </MenuItem>
  );
};

export default MoveMenuItem;
