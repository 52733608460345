import { FC } from 'react';
import { Box, Card, Typography } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import MemberAvatar from '../../../MemberAvatar';
import { ITaskUI } from '../../../../data/types/task.type';
import DueDate from './components/DueDate';
import TaskLabels from './components/TaskLabels';
import { useAppSelector } from '../../../../redux/store';

const TaskCard: FC<{
  task: ITaskUI;
  index: number;
  setOpenedTask: (taskId: string) => void;
}> = ({ task, index, setOpenedTask }) => {
  const filters = useAppSelector(state => state.boardFilterSlice)[
    task.boardId
  ] || {
    assignees: [],
    labels: [],
  };
  const show =
    (!filters.labels.length ||
      task.labels.some(label => filters.labels.includes(label))) &&
    (!filters.assignees.length ||
      filters.assignees.includes(task.assignee?.userId || ''));
  const openModal = () => {
    setOpenedTask(task.taskId);
  };

  return (
    <>
      {show && (
        <Draggable draggableId={task.taskId} index={index}>
          {provided => (
            <Card
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              sx={{
                width: 1,
                padding: theme => theme.spacing(1),
                marginBottom: theme => theme.spacing(2),
              }}
              onClick={openModal}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="top"
                maxWidth={1}
              >
                <Typography fontWeight={500} sx={{ overflowWrap: 'anywhere' }}>
                  {task.title}
                </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={theme => theme.spacing()}
                >
                  {task.assignee && (
                    <MemberAvatar
                      {...task.assignee}
                      hideBadge
                      avatarSize={24}
                      avatarSx={{
                        marginLeft: 0.5,
                      }}
                    />
                  )}
                </Box>
              </Box>

              <Box
                display="flex"
                alignItems="start"
                justifyContent="end"
                gap={1}
                marginTop={1}
                flexWrap="wrap"
              >
                <DueDate dueDate={task.dueDate} />
                <TaskLabels labels={task.labels} projectId={task.projectId} />
              </Box>
            </Card>
          )}
        </Draggable>
      )}
    </>
  );
};
export default TaskCard;
