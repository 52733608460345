import { Route, Routes } from 'react-router-dom';
import { useAuthUser } from '@react-query-firebase/auth';
import { CircularProgress } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';
import { useGetAuthUserQuery } from '../redux/apiSlices/authUser.slice';
import { auth } from '../firebaseServices/firebase.config';

const RoutesPage = () => {
  const { data: authUser, isLoading } = useAuthUser('user', auth);

  const { data: user, isLoading: userDataLoading } = useGetAuthUserQuery(
    undefined,
    {
      skip: !authUser || !authUser.emailVerified,
    },
  );

  if (isLoading || userDataLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Routes>
      {user ? (
        <Route path="/*" element={<PrivateRoutes />} />
      ) : (
        <Route path="/*" element={<PublicRoutes />} />
      )}
    </Routes>
  );
};

export default RoutesPage;
