import * as Yup from 'yup';

export const AddCustomToolSchema = Yup.object().shape({
  link: Yup.string()
    .matches(
      /(https?:\/\/)?(www\.)?[a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)?/,
      'Please enter a valid URL',
    )
    .required(),  name: Yup.string().required(),
});

export const LinkSchema = Yup.object().shape({
  link: Yup.string()
    .matches(
      /(https?:\/\/)?(www\.)?[a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)?/,
      'Please enter a valid URL',
    )
    .required(),
});
