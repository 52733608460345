import { Typography } from '@mui/material';
import { FC } from 'react';

const TextMessageContent: FC<{ content: string }> = ({ content }) => {
  return (
    <Typography
      sx={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}
      variant="body2"
    >
      {content}
    </Typography>
  );
};

export default TextMessageContent;
