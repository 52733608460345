import React from 'react';
import styled from 'styled-components';
import { ResizableBox, ResizableBoxProps } from 'react-resizable';

const Resizable = styled(ResizableBox)<{ pined: string }>`
  height: 100% !important;
  position: relative;
  display: ${({ pined }) => (pined === 'true' ? 'block' : 'none')};

  .react-resizable-handle {
    left: 0;
    position: absolute;
    width: 8px;
    height: 100%;
    bottom: 0;
    cursor: col-resize;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: -4px;

    .triangle-left {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 6px 6px 0;
      border-color: transparent #e0e0e0 transparent transparent;
      margin: 6px 0;
    }

    .dot {
      width: 4px;
      height: 4px;
      background-color: #e0e0e0;
      border-radius: 50%;
      margin: 4px 0;
    }

    .triangle-right {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 0 6px 6px;
      border-color: transparent transparent transparent #e0e0e0;
      margin: 6px 0;
    }
  }
`;

type CustomResizableProps = ResizableBoxProps & {
  pined: string;
};

const CustomResizable: React.FC<CustomResizableProps> = props => {
  const { children, ...resizableProps } = props;

  return (
    <Resizable {...resizableProps}>
      {children}
      <div className="react-resizable-handle">
        <div className="triangle-left" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="triangle-right" />
      </div>
    </Resizable>
  );
};

export default CustomResizable;
