import { FC } from 'react';
import {
  IBoardEvent,
  IBoardUpdatedData,
  IEventActionType,
} from 'shared/models/event.model';
import OldNewValueContent from '../../components/OldNewValueContent';

type TBoardEventsProps = {
  eventData: IBoardEvent;
  previewMode?: boolean;
};

const BoardEvents: FC<TBoardEventsProps> = ({ eventData, previewMode }) => {
  if (eventData.eventActionType === IEventActionType.UPDATE) {
    const updateEventData = eventData.data as IBoardUpdatedData;
    return (
      <>
        {updateEventData.name && (
          <OldNewValueContent
            prefixText="Name"
            previewMode={previewMode}
            {...updateEventData.name}
          />
        )}
      </>
    );
  }
  return <></>;
};

export default BoardEvents;
