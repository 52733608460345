import { TBreadcrumbItem } from 'components/BreadcrumbNavigation/BreadcrumbNavigation.types';
import { useEffect, useMemo } from 'react';
import {
  useGetAllDocItemsQuery,
  useGetDocByIdQuery,
} from 'redux/apiSlices/doc.slice';
import { PrivateRoutesPaths } from 'routes/Routes.types';
import { extractPathUpToFolder } from 'utils/utils';
import { useGetAuthUserQuery } from 'redux/apiSlices/authUser.slice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TDocItemUI } from 'data/types/doc.types';
import useProjectBreadcrumb, {
  TProjectBreadcrumbProps,
} from './useProjectBreadcrumb';

export type TUseDocDetailsBreadcrumbProps = TProjectBreadcrumbProps & {
  docId: string;
};

const getFolderBreadcrumbs = (
  foldersLocations: string,
  docItems: TDocItemUI[],
) => {
  const folderIds = foldersLocations.split('/');

  const result = folderIds.reduce((acc, folderId) => {
    const folder = docItems.find(item => item.documentId === folderId);
    if (folder) {
      acc.push({ folderName: folder.name, folderId: folder.documentId });
    }
    return acc;
  }, [] as { folderName: string; folderId: string }[]);

  return result;
};

const useDocDetailsBreadcrumb = ({
  docId,
  projectId,
}: TUseDocDetailsBreadcrumbProps): TBreadcrumbItem[] => {
  const navigate = useNavigate();
  const projectBreadcrumb = useProjectBreadcrumb({ projectId });

  const { data: docData, isError } = useGetDocByIdQuery(
    {
      docId,
      projectId,
    },
    {
      skip: !docId && !projectId,
    },
  );
  useEffect(() => {
    if (isError) navigate(`/${PrivateRoutesPaths.pageNotFound}`);
  }, [isError]);
  const [searchParams] = useSearchParams();
  const foldersLocations = searchParams.get('location') || 'root';

  const { data: authData } = useGetAuthUserQuery();
  const { data: docItems } = useGetAllDocItemsQuery({
    docId,
    projectId,
    currentUserId: authData?.id || '',
    onlyFolders: true,
  });

  const folderBreadcrumbItems = getFolderBreadcrumbs(
    foldersLocations,
    docItems || [],
  );

  const breadcrumbs = useMemo(() => {
    if (projectBreadcrumb.length && docData?.name) {
      return [
        ...projectBreadcrumb,
        {
          link: PrivateRoutesPaths.DocDetails.replace(
            ':projectId',
            projectId,
          ).replace(':docId', docId),
          text: docData?.name,
          search: '?location=root',
        },
        ...folderBreadcrumbItems.map(i => ({
          text: i.folderName,
          link: PrivateRoutesPaths.DocDetails.replace(
            ':projectId',
            projectId,
          ).replace(':docId', docId),
          search: `?location=${extractPathUpToFolder(
            foldersLocations,
            i.folderId,
          )}`,
        })),
      ];
    }
    return [];
  }, [projectBreadcrumb.length, docData?.name, foldersLocations, docItems]);

  return breadcrumbs;
};

export default useDocDetailsBreadcrumb;
