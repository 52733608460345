import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import Button from 'components/Base/Button';
import { IBoardColumnShortDataUI } from 'data/types/board.type';
import { FC, useState } from 'react';

type TRemoveColumnConfirmModalProps = {
  open: boolean;
  onDeleteColumn: (moveTasksTo: string | null) => Promise<void>;
  submitButtonLoading: boolean;
  onCloseModal: () => void;
  columnsToMove: IBoardColumnShortDataUI[];
};

const RemoveColumnConfirmModal: FC<TRemoveColumnConfirmModalProps> = ({
  open,
  onDeleteColumn,
  submitButtonLoading,
  onCloseModal,
  columnsToMove,
}) => {
  const [selectedColumn, setSelectedColumn] = useState<string | null>(null);

  const closeModal = () => {
    onCloseModal();
    setSelectedColumn(null);
  };

  const onConfirm = async () => {
    if (!selectedColumn) {
      closeModal();
      return;
    }
    await onDeleteColumn(selectedColumn);
    closeModal();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Choose column to move tasks</DialogTitle>
      <DialogContent sx={{ minWidth: '300px', minHeight: '100px' }}>
        <ToggleButtonGroup
          value={selectedColumn}
          exclusive
          onChange={async (e, value) => {
            setSelectedColumn(value);
          }}
        >
          {columnsToMove.map(column => (
            <ToggleButton key={column.documentId} value={column.documentId}>
              <Chip
                label={column.title}
                style={{
                  backgroundColor: column.color,
                }}
              />
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="contained"
          color="primary"
          onClick={onConfirm}
          disabled={submitButtonLoading}
          loading={submitButtonLoading}
        >
          Move
        </Button>
        <Button variant="text" color="primary" onClick={closeModal}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveColumnConfirmModal;
