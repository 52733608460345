import { EDocTypes } from './doc.model';
import { ITaskDTO } from './task.model';
import { EProjectCustomTools } from '../types/generic.types';

export type TProjectEventDTO = IProjectEvent | IBoardEvent | IColumnEvent | ITaskEvent | IChatEvent;
export interface IProjectEvent extends IEvent {
  data: IProjectData | IProjectUpdatedData;
}

export interface ICustomToolEvent extends IEvent {
  toolId: string;
  data: ICustomToolData | ICustomToolUpdatedData;
}

export interface ICustomToolUpdatedData {
  name?: IFieldValueChange<string>;
  link?: IFieldValueChange<string>;
}

export interface ICustomToolData {
  name: string;
  link: string;
  type: EProjectCustomTools;
}

export interface IBoardEvent extends IEvent {
  boardId: string;
  data: IBoardData | IBoardUpdatedData;
}

export interface IColumnEvent extends IEvent {
  boardId: string;
  columnId: string;
  data: IColumnData | IColumnUpdatedData;
}

export interface ITaskEvent extends IEvent {
  boardId: string;
  columnId: string;
  taskId: string;
  data: ITaskData | ITaskDTO | ITaskUpdatedData;
}

export interface IDocsEvent extends IEvent {
  docsId: string;
  data: IDocsData | IDocsUpdatedData;
}

export interface IDocsItemEvent extends IEvent {
  docsId: string;
  docsItemId: string;
  data: IDocsItemData | IDocsItemUpdateData;
}

export interface IChatEvent extends IEvent {
  chatId: string;
  data: IChatData | IChatUpdateData;
}

export interface IChatData {
  name: string;
}

export interface IChatUpdateData {
  name: IFieldValueChange<string>;
}

export interface IDocsItemData {
  name: string;
  type: EDocTypes;
  storageUrl: string;
}

export interface IDocsItemUpdateData {
  name: IFieldValueChange<string>;
  data: IFieldValueChange<any>;
}

export interface IDocsData {
  name: string;
}

export interface IDocsUpdatedData {
  name: IFieldValueChange<string>;
}

export interface ITaskData {
  title: string;
}

export interface ITaskUpdatedData {
  title?: IFieldValueChange<string>;
  description?: IFieldValueChange<string>;
  column?: IFieldValueChange<{ columnId: string; columnTitle: string }>;
  assignee?: IFieldValueChange<IProjectMember>;
  labels?: ILabelsUpdated;
  dueDate?: IFieldValueChange<string>;
  estimate?: IFieldValueChange<string>;
}

export interface ILabelsUpdated {
  added: ILabel[];
  removed: ILabel[];
}

export interface ILabel {
  id: string;
  title: string;
}

export interface IColumnData {
  title: string;
}

export interface IColumnUpdatedData {
  title?: IFieldValueChange<string>;
  color?: IFieldValueChange<string>;
}

export interface IBoardUpdatedData {
  name: IFieldValueChange<string>;
}

export interface IBoardData {
  name: string;
}

export interface IProjectUpdatedData {
  name?: IFieldValueChange<string>;
  color?: IFieldValueChange<string>;
  description?: IFieldValueChange<string>;
  members?: IProjectMembersUpdated;
}

export interface IProjectData {
  name: string;
  color: string;
  description: string | null;
}

export interface IEvent {
  performedBy: IProjectMember | null;
  performedByType: IEventPerformedByType;
  eventResourceType: IEventResourceType;
  eventActionType: IEventActionType;
  eventResourceTitle: string;
  eventDateTime?: number;
  projectId: string;
}

export interface IProjectMember {
  id?: string;
  name?: string;
  email?: string;
}

export interface IProjectMembersUpdated {
  added: IProjectMember[];
  removed: IProjectMember[];
}

export interface IFieldValueChange<T> {
  oldValue?: T | null;
  newValue?: T | null;
}

export enum IEventActionType {
  CREATE = 'Create',
  UPDATE = 'Update',
  DELETE = 'Delete',
}

export enum IEventResourceType {
  PROJECT = 'Project',
  BOARD = 'Board',
  COLUMN = 'Column',
  TASK = 'Task',
  DOCS = 'Docs',
  DOCS_ITEM = 'Docs Item',
  CHAT = 'Chat',
  CUSTOM_TOOL = 'Custom Tool',
}

export enum IEventPerformedByType {
  END_USER = 'End User',
  SYSTEM = 'System',
}
