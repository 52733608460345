import { TBreadcrumbItem } from 'components/BreadcrumbNavigation/BreadcrumbNavigation.types';
import { useMemo } from 'react';
import useProjectBreadcrumb, {
  TProjectBreadcrumbProps,
} from './useProjectBreadcrumb';

export type TUseProjectChangelogBreadcrumbProps = TProjectBreadcrumbProps;

const useProjectChangelogBreadcrumb = ({
  projectId,
}: TUseProjectChangelogBreadcrumbProps): TBreadcrumbItem[] => {
  const projectBreadcrumb = useProjectBreadcrumb({ projectId });

  const breadcrumbs = useMemo(() => {
    if (projectBreadcrumb.length) {
      return [
        ...projectBreadcrumb,
        {
          text: 'Activities',
          link: '',
        },
      ];
    }
    return [];
  }, [projectBreadcrumb.length]);

  return breadcrumbs;
};

export default useProjectChangelogBreadcrumb;