import { Box } from '@mui/material';
import { TDocItemUI } from 'data/types/doc.types';
import { FC } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { EDocTypes } from 'shared/models/doc.model';

type TDragDropDocItemProps = {
  docItem: TDocItemUI;
  index: number;
  children: React.ReactNode;
};

const DragDropDocItem: FC<TDragDropDocItemProps> = ({
  docItem,
  index,
  children,
}) => {
  const { documentId, type } = docItem;
  return (
    <Droppable
      droppableId={documentId}
      key={documentId}
      isDropDisabled={type !== EDocTypes.FOLDER}
    >
      {(dropProvided, dropSnapshot) => {
        const isDraggingOverOthers =
          dropSnapshot.isDraggingOver &&
          dropSnapshot.draggingOverWith !== documentId;
        return (
          <Box
            key={documentId}
            ref={dropProvided.innerRef}
            {...dropProvided.droppableProps}
          >
            <Draggable
              draggableId={documentId}
              index={index}
              // isDragDisabled={docItem.type === EDocTypes.FOLDER}
            >
              {(dragProvided, dragSnapshot) => {
                return (
                  <Box
                    ref={dragProvided.innerRef}
                    {...dragProvided.draggableProps}
                    {...dragProvided.dragHandleProps}
                    sx={theme => ({
                      ...dragProvided.draggableProps.style,
                      ...(isDraggingOverOthers && {
                        transform: 'none !important',
                      }),
                      ...(dragSnapshot.isDragging && {
                        scale: '0.80',
                      }),
                      border: `2px dashed ${
                        isDraggingOverOthers
                          ? theme.palette.info.main
                          : 'transparent'
                      }`,
                    })}
                  >
                    {children}
                  </Box>
                );
              }}
            </Draggable>
          </Box>
        );
      }}
    </Droppable>
  );
};

export default DragDropDocItem;
