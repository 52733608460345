import React, { FC } from 'react';
import IcomoonReact from 'icomoon-react';
import { TIconProps } from './types';
import iconSet from './selection.json';

const Icon: FC<TIconProps> = ({
  color,
  size = 24,
  icon,
  className = '',
  onClick,
  ...props
}) => {
  return (
    <IcomoonReact
      className={`icon ${className}`}
      iconSet={iconSet}
      color={color}
      size={size}
      icon={icon}
      onClick={onClick}
      data-testid={`icon-${icon}`}
      {...props}
    />
  );
};

export { Icon };
