export enum EProjectCustomTools {
  DISCORD = 'Discord',
  DROPBOX = 'Dropbox',
  FIGMA = 'Figma',
  GITHUB = 'Github',
  GOOGLE_CALENDAR = 'Google Calendar',
  GOOGLE_DRIVE = 'Google Drive',
  GOOGLE_DOCS = 'Google Docs',
  GOOGLE_MEET = 'Google Meet',
  GOOGLE_SHEETS = 'Google Sheets',
  JIRA = 'Jira',
  NOTION = 'Notion',
  SLACK = 'Slack',
  ZOOM = 'Zoom',
  CUSTOM = 'Custom'
}
export enum EProjectTools {
  DOC = 'Doc',
  BOARD = 'Board',
  CHAT = 'Chat',
}

export enum EUserRole {
  OWNER = 'Owner',
  MEMBER = 'Member',
}

export enum EShortcutTypes {
  BOARD = 'BOARD',
  CUSTOM_DOC = 'CUSTOM_DOC',
  CHAT = 'CHAT',
}
