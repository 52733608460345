import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { privateRoutes } from './RoutesData';
import { PrivateRoutesPaths, PublicRoutesPaths } from './Routes.types';
import Layout from '../components/Layout';
import PinedLayout from '../components/Layout/components/PinedLayout';

const PrivateRoutes = () => {
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route element={<Layout />}>
          <Route element={<PinedLayout />}>
            {privateRoutes.map(route => {
              if (route.subRoutes?.length) {
                return (
                  <Route path={route.path}>
                    {route.subRoutes.map(subRoute => (
                      <Route
                        path={subRoute.path}
                        element={subRoute.component}
                        index={subRoute.index}
                        key={subRoute.path}
                      />
                    ))}
                  </Route>
                );
              }
              return (
                <Route
                  path={route.path}
                  element={route.component}
                  key={route.path}
                />
              );
            })}
            {Object.values(PublicRoutesPaths).map(route => (
              <Route
                path={route}
                element={<Navigate to={`/${PrivateRoutesPaths.Projects}`} />}
                key={route}
              />
            ))}
            <Route
              path="/"
              element={<Navigate to={PrivateRoutesPaths.Projects} />}
            />
            <Route
              path="*"
              element={<Navigate to={PrivateRoutesPaths.pageNotFound} />}
            />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};
export default PrivateRoutes;
