import InfoIcon from '@mui/icons-material/Info';
import {
  Autocomplete,
  IconButton,
  ListItem,
  TextField,
  Typography,
} from '@mui/material';
import { typographyEllipsisStyles } from 'components/Base/Typography/styles';
import { FC, useState } from 'react';
import { useUpdateTaskEstimateMutation } from 'redux/apiSlices/tasks.slice';

type TTaskEstimateProps = {
  estimate?: string;
  taskId: string;
};

type TPomodoroOptionProps = {
  props: React.HTMLAttributes<HTMLLIElement>;
  value: string;
};

const PomodoroOption: FC<TPomodoroOptionProps> = ({ props, value }) => {
  const [hovered, setHovered] = useState(false);
  const infoText = (
    <Typography
      ml={1}
      color="GrayText"
      variant="caption"
      sx={{
        ...typographyEllipsisStyles,
      }}
    >
      x (25min + 5min break)
    </Typography>
  );
  return (
    <ListItem
      {...props}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {value} {hovered && infoText}
    </ListItem>
  );
};

const TaskEstimate: FC<TTaskEstimateProps> = ({ estimate, taskId }) => {
  const [updateEstimate] = useUpdateTaskEstimateMutation();
  const onUpdateEstimate = async (value: string | null) => {
    await updateEstimate({
      taskId,
      estimate: value || '',
    });
  };

  const options = Array.from({ length: 16 }, (_, i) => String(i + 1));

  return (
    <>
      <Autocomplete
        defaultValue={estimate}
        options={options}
        onChange={async (event, newValue) => {
          await onUpdateEstimate(newValue);
        }}
        renderOption={(props, optionValue) => (
          <PomodoroOption props={props} value={optionValue} />
        )}
        clearOnEscape
        fullWidth
        popupIcon={
          <IconButton
            onMouseDown={e => e.preventDefault()}
            component="a"
            href="https://workfeed.ai/blog/what-is-pomodoro-technique-in-time-management"
            target="_blank"
            size="small"
          >
            <InfoIcon color="info" />
          </IconButton>
        }
        sx={{
          '.MuiFormLabel-root': {
            lineHeight: '15px',
            maxWidth: 'calc(100% - 50px)',
          },
          '& .MuiOutlinedInput-root': {
            // padding: '2px',
            '.MuiAutocomplete-input': {
              paddingY: 0.4,
            },
          },
          '.MuiAutocomplete-popupIndicator': {
            transform: 'none',
          },
        }}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label="Estimate (Pomodoro)"
            fullWidth
          />
        )}
      />
    </>
  );
};

export default TaskEstimate;
