import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { dateFormat } from 'data/static';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useUpdateTaskDueDateMutation } from 'redux/apiSlices/tasks.slice';

type TTaskDuedateProps = {
  taskId: string;
  dueDate?: string;
};

const TaskDuedate: FC<TTaskDuedateProps> = ({ dueDate, taskId }) => {
  const [updateDueDate] = useUpdateTaskDueDateMutation();
  const onUpdateDueDate = (date: string) => {
    updateDueDate({
      taskId,
      dueDate: date,
    });
  };

  const defaultDate = dueDate ? dayjs(dueDate) : null;
  return (
    <DatePicker
      label="Due Date"
      defaultValue={defaultDate}
      onChange={value => {
        const dateToSave = value ? dayjs(value).format() : '';
        onUpdateDueDate(dateToSave);
      }}
      slotProps={{
        actionBar: {
          actions: ['clear', 'today'],
        },
      }}
      disablePast
      closeOnSelect
      format={dateFormat}
      sx={{
        '& input': {
          paddingY: 1.5,
        },
        '.MuiFormLabel-root': {
          lineHeight: '15px',
        },
      }}
    />
  );
};

export default TaskDuedate;
