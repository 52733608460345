import { firestore } from 'firebaseServices/firebase.config';
import {
  collection,
  doc,
  CollectionReference,
  DocumentReference,
  Firestore,
} from 'firebase/firestore';
import { IDocDTO, TDocItemDTO } from 'shared/models/doc.model';
import { CollectionPaths } from 'shared/types/FirestoreCollections';
import { IProjectDTO } from 'shared/models/project.model';
import { IProjectEvent } from 'shared/models/event.model';

type TProjectReferenceProps = { projectId: string };
type TDocReferenceProps = TProjectReferenceProps & { docId: string };
type TDocItemReferenceProps = TDocReferenceProps & { docItemId: string };

type TEventsReferenceProps = TProjectReferenceProps;

export class FirestoreReferenceManager {
  private db: Firestore;

  constructor(db: Firestore) {
    this.db = db;
  }

  public projects_CollectionRef(): CollectionReference<IProjectDTO> {
    return collection(this.db, CollectionPaths.Project) as CollectionReference<IProjectDTO>;
  }

  public project_DocRef({
    projectId,
  }: TProjectReferenceProps): DocumentReference<IProjectDTO> {
    return doc(
      this.db,
      CollectionPaths.Project,
      projectId,
    ) as DocumentReference<IProjectDTO>;
  }

  public projectDocs_CollectionRef({
    projectId,
  }: TProjectReferenceProps): CollectionReference<IDocDTO> {
    return collection(
      this.db,
      CollectionPaths.ProjectDocs(projectId),
    ) as CollectionReference<IDocDTO>;
  }

  public projectDoc_DocRef({
    projectId,
    docId,
  }: TDocReferenceProps): DocumentReference<IDocDTO> {
    const docCollectionPath = CollectionPaths.ProjectDocs(projectId).concat(
      `/${docId}`,
    );
    return doc(this.db, docCollectionPath) as DocumentReference<IDocDTO>;
  }

  public projectDocItems_CollectionRef({
    projectId,
    docId,
  }: TDocReferenceProps): CollectionReference<TDocItemDTO> {
    const docItemsCollectionPath = CollectionPaths.ProjectDocItems(
      projectId,
      docId,
    );
    return collection(
      this.db,
      docItemsCollectionPath,
    ) as CollectionReference<TDocItemDTO>;
  }

  public projectDocItem_DocRef({
    projectId,
    docId,
    docItemId,
  }: TDocItemReferenceProps): DocumentReference<TDocItemDTO> {
    const docItemsCollectionPath = CollectionPaths.ProjectDocItems(
      projectId,
      docId,
    ).concat(`/${docItemId}`);
    return doc(
      this.db,
      docItemsCollectionPath,
    ) as DocumentReference<TDocItemDTO>;
  }

  public projectEvents_CollectionRef({
    projectId,
  }: TEventsReferenceProps): CollectionReference<IProjectEvent> {
    const changelogsCollectionPath = CollectionPaths.ProjectEvents(projectId);
    return collection(
      this.db,
      changelogsCollectionPath,
    ) as CollectionReference<IProjectEvent>;
  }
}

export const firestoreReference = new FirestoreReferenceManager(firestore);
