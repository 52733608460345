import { FC } from 'react';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { IProjectUI } from 'data/types/project.types';
import ProjectMembersList from './ProjectMembersList';
import ManageProjectMembers from './ManageProjectMembers';

type TProjectMembersProps = {
  members: IProjectUI['members'];
  projectId: string;
  projectName: string;
  isMobileView: boolean;
};
const ProjectMembers: FC<TProjectMembersProps> = ({
  members,
  projectId,
  projectName,
  isMobileView,
}) => {
  return (
    <Grid
      container
      direction="row"
      alignItems="baseline"
      columnSpacing={2}
      wrap="nowrap"
    >
      {!isMobileView && (
        <Grid>
          <ProjectMembersList members={members} />
        </Grid>
      )}
      <Grid>
        <ManageProjectMembers
          members={members}
          projectId={projectId}
          projectName={projectName}
          isMobileView={isMobileView}
        />
      </Grid>
    </Grid>
  );
};

export default ProjectMembers;
