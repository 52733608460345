import { FC } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { IProjectUI } from 'data/types/project.types';
import Modal from 'components/Base/Modal';
import ManageProjectMembersList from './ManageProjectMembersList';
import AddProjectMember from './AddProjectMember';

type TManageProjectMemberModalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentMembers: IProjectUI['members'];
  projectId: string;
  projectName: string;
};

const ManageProjectMemberModal: FC<TManageProjectMemberModalProps> = ({
  open,
  setOpen,
  currentMembers,
  projectId,
  projectName,
}) => {
  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <Modal
      fullWidth
      open={open}
      handleClose={handleCloseModal}
      title={`Manage access to ${projectName}`}
      hideActions
      hasCloseIcon
    >
      <Grid
        container
        direction="column"
        spacing={3}
        alignItems="start"
        justifyContent="space-between"
        minHeight={300}
      >
        <Grid>
          <ManageProjectMembersList
            projectId={projectId}
            members={currentMembers}
            projectName={projectName}
          />
        </Grid>

        <Grid width="100%">
          <Typography variant="h6" gutterBottom>
            Add people to {projectName}
          </Typography>
          <AddProjectMember
            currentMembers={currentMembers}
            projectId={projectId}
            projectName={projectName}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ManageProjectMemberModal;
