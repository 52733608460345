import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { PrivateRoutesPaths } from 'routes/Routes.types';
import ActiveToolCard from '../ActiveToolCard';
import ChangelogPreview from './ChangelogPreview';

type TChangelogProps = {
  projectId: string;
};

const Changelog: FC<TChangelogProps> = ({ projectId }) => {
  const navigate = useNavigate();

  const onChangelogCardClick = () => {
    const projectChangelogPath = PrivateRoutesPaths.ProjectChangelog.replace(
      ':projectId',
      projectId,
    );
    navigate(`/${projectChangelogPath}`);
  };
  return (
    <ActiveToolCard name="Activities" onClick={onChangelogCardClick}>
      <ChangelogPreview projectId={projectId} />
    </ActiveToolCard>
  );
};

export default Changelog;
