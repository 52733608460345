import React, { FC, ReactNode } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import theme from './theme';

const ThemeWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      autoHideDuration={3000}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </SnackbarProvider>
  );
};

export default ThemeWrapper;
