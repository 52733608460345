import { createApi } from '@reduxjs/toolkit/query/react';
import { fakeBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { queryFnWrapper } from 'firebaseServices/queryFunctions/helper';
import {
  TGetAllEventsQueryProps,
  getAllProjectEventsQuery,
} from 'firebaseServices/queryFunctions/eventQueryFunctions';
import { TProjectEventUI } from 'data/types/events.types';
import { searchAlgoliaProjectEvents } from 'algolia/algoliaServices';

export const eventsApi = createApi({
  tagTypes: ['events'],
  reducerPath: 'events',
  baseQuery: fakeBaseQuery(),
  endpoints: build => ({
    getProjectEvents: build.query<
      TProjectEventUI | null,
      TGetAllEventsQueryProps
    >({
      queryFn: arg =>
        arg.searchQuery
          ? queryFnWrapper(searchAlgoliaProjectEvents, arg)
          : queryFnWrapper(getAllProjectEventsQuery, arg),
      providesTags: ['events'],
    }),
  }),
});

export const { useGetProjectEventsQuery } = eventsApi;
