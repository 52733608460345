import { Box, IconButton, Menu } from '@mui/material';
import { FC, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FirebaseError } from 'firebase/app';
import { useSnackbar } from 'notistack';
import { IBoardColumnShortDataUI, IColumnUI } from 'data/types/board.type';
import { useDeleteBoardColumnMutation } from 'redux/apiSlices/board.slice';
import { errorMessages, successMessages } from 'data/messages/messages';
import { boardColors } from 'data/static';
import ChangeColor from './ChangeColor';
import EditName from './EditName';
import RemoveColumnAction from './RemoveColumn/RemoveColumnAction';
import RemoveColumnConfirmModal from './RemoveColumn/RemoveColumnConfirmModal';

const ColumnHeader: FC<{
  column: IColumnUI;
  boardId: string;
  projectId: string;
  dragHandleProps?: any;
  allColumnsShortData: IBoardColumnShortDataUI[];
}> = ({ column, boardId, projectId, dragHandleProps, allColumnsShortData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [openComfirmModal, setOpenConfirmModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deleteColumn, { isLoading: deleteLoading }] =
    useDeleteBoardColumnMutation();

  const onDeleteColumn = async (moveTasksTo: string | null) => {
    try {
      await deleteColumn({
        columnId: column.documentId,
        boardId,
        projectId,
        moveTasksTo,
      }).unwrap();
      setAnchorEl(null);
      enqueueSnackbar(successMessages.toast, {
        variant: 'success',
      });
    } catch (err) {
      const error = err as FirebaseError;
      enqueueSnackbar(error.message || errorMessages.default, {
        variant: 'error',
      });
    }
  };

  const openConfirmModal = () => {
    setOpenConfirmModal(true);
  };
  const closeConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  const currentColor = boardColors.find(c => c.light === column.color)?.dark;
  const possibleColumnsToMove = allColumnsShortData.filter(
    c => c.documentId !== column.documentId,
  );
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      maxWidth="100%"
      marginBottom={2}
      borderBottom="2px solid"
      borderColor={currentColor}
      {...dragHandleProps}
    >
      <EditName
        boardId={boardId}
        column={column}
        projectId={projectId}
        currentColor={currentColor}
      />
      <IconButton
        onClick={e => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <RemoveColumnAction
          hasTasks={column.tasks.length > 0}
          onDeleteColumn={onDeleteColumn}
          openConfirmModal={openConfirmModal}
          deleteLoading={deleteLoading}
        />
        <ChangeColor boardId={boardId} column={column} projectId={projectId} />
      </Menu>
      <RemoveColumnConfirmModal
        open={openComfirmModal}
        onCloseModal={closeConfirmModal}
        onDeleteColumn={onDeleteColumn}
        submitButtonLoading={deleteLoading}
        columnsToMove={possibleColumnsToMove}
      />
    </Box>
  );
};

export default ColumnHeader;
