import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { IBoardUI } from 'data/types/board.type';
import { useNavigate } from 'react-router-dom';
import {
  useRemoveBoardMutation,
  useUpdateBaordNameMutation,
} from 'redux/apiSlices/board.slice';
import { boardColors } from 'data/static';
import useListenBoardColumns from 'firebaseServices/listeners/Boards/useListenBoardColumns';
import { EShortcutTypes } from 'shared/types/generic.types';
import {
  useGetAuthUserQuery,
  useUpdateUserShortcutsMutation,
} from 'redux/apiSlices/authUser.slice';
import ActiveToolCard from '../../ActiveToolCard';

const BoardCard: FC<{ board: IBoardUI }> = ({ board }) => {
  const [updateShortcuts] = useUpdateUserShortcutsMutation();
  const { data: userData } = useGetAuthUserQuery();
  const navigate = useNavigate();
  const { columns } = useListenBoardColumns({
    boardId: board.documentId,
    projectId: board.projectId,
  });

  const [renameBoard] = useUpdateBaordNameMutation();
  const [removeBoard, { isLoading }] = useRemoveBoardMutation();

  const onBoardItemClick = () => {
    navigate(`boards/${board.documentId}`);
  };
  const inShortcuts = !!(userData?.settings?.shortcuts || []).find(
    item =>
      item.type === EShortcutTypes.BOARD &&
      item.boardGroupId === board.documentId,
  );
  const onPin = async () => {
    if (inShortcuts && userData?.settings?.shortcuts.length) {
      try {
        await updateShortcuts(
          userData.settings.shortcuts.filter(
            item =>
              !(
                item.type === EShortcutTypes.BOARD &&
                item.boardGroupId === board.documentId
              ),
          ),
        ).unwrap();
      } catch {
        // TODO handle error
      }
    } else if (!inShortcuts) {
      try {
        await updateShortcuts([
          ...(userData?.settings?.shortcuts || []),
          {
            projectId: board.projectId,
            type: EShortcutTypes.BOARD,
            pinned: false,
            name: board.name,
            boardGroupId: board.documentId,
            open: false,
            id: board.documentId,
          },
        ]).unwrap();
      } catch {
        // TODO handle error
      }
    }
  };
  return (
    <ActiveToolCard
      key={board.documentId}
      onClick={onBoardItemClick}
      name={board.name}
      pinProps={{
        onClick: onPin,
        inShortcuts,
      }}
      onRenameTool={async newName => {
        await renameBoard({
          projectId: board.projectId,
          boardId: board.documentId,
          name: newName,
        }).unwrap();
      }}
      onRemoveTool={async () => {
        await removeBoard({
          projectId: board.projectId,
          boardId: board.documentId,
        }).unwrap();
      }}
      removeLoading={isLoading}
    >
      <Box display="flex" height={1} gap={1} width="100%">
        {columns?.map(column => (
          <Box
            key={column.documentId}
            display="flex"
            flexDirection="column"
            alignItems="center"
            paddingTop={1}
            gap={1}
            sx={{ bgcolor: column.color, height: 1, flexGrow: 1 }}
          >
            <Typography
              color={boardColors.find(c => c.light === column.color)?.dark}
            >
              ({column.tasks.length})
            </Typography>
            <Typography
              variant="h6"
              sx={{
                writingMode: 'vertical-rl',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textWrap: 'nowrap',
              }}
              color={boardColors.find(c => c.light === column.color)?.dark}
            >
              {column.title}
            </Typography>
          </Box>
        ))}
      </Box>
    </ActiveToolCard>
  );
};
export default BoardCard;
