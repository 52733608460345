import { FC, useState } from 'react';
import { Card, CardActionArea } from '@mui/material';
import CardHeader from 'components/Base/Card/CardHeader';
import { IProjectUI } from 'data/types/project.types';
import ProjectCardActionsMenu from './ProjectCardActionsMenu';
import RenameProject from './RenameProject';
import RemoveProjectModal from './RemoveProjectModal';

type TProjectCardProps = {
  projectData: IProjectUI;
  onClick?: () => void;
};

const ProjectCard: FC<TProjectCardProps> = ({
  projectData,
  onClick = () => null,
}) => {
  const { name, id, color, description } = projectData;
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [openRenameProjectModal, setOpenRenameProjectModal] = useState(false);
  const [openRemoveProjectModal, setOpenRemoveProjectModal] = useState(false);
  const onOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };
  const subheader =
    description === undefined ? 'Some useful description here' : description;
  return (
    <>
      <Card
        sx={{
          height: '100%',
        }}
      >
        <CardActionArea
          sx={{
            height: '100%',
          }}
          onClick={onClick}
        >
          <CardHeader
            title={name}
            titleTypographyProps={{ variant: 'h6' }}
            subheader={subheader}
            sx={{
              backgroundColor: color,
              height: '100%',
              alignItems: 'flex-start',
            }}
            hasaction={{
              onActionClick: onOpenMenu,
            }}
          />
          {/* <CardContent sx={{ backgroundColor: color, height: 120 }}>
            <CircularProgressWithLabel value={random(100)} />
          </CardContent> */}
        </CardActionArea>
      </Card>
      <ProjectCardActionsMenu
        anchorEl={menuAnchorEl}
        setAnchorEl={setMenuAnchorEl}
        setOpenRenameProjectModal={setOpenRenameProjectModal}
        setOpenRemoveProjectModal={setOpenRemoveProjectModal}
      />
      <RenameProject
        projectData={projectData}
        open={openRenameProjectModal}
        setOpen={setOpenRenameProjectModal}
      />
      <RemoveProjectModal
        open={openRemoveProjectModal}
        setOpen={setOpenRemoveProjectModal}
        projectId={id}
      />
    </>
  );
};

export default ProjectCard;
