import { CircularProgress, Container } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { FC, useEffect, useRef } from 'react';
import EditorJS from '@editorjs/editorjs';
import {
  useRenameDocItemMutation,
  useUpdateCustomDocContentMutation,
} from 'redux/apiSlices/doc.slice';
import { EDocTypes } from 'shared/models/doc.model';
import { ICustomDocUI } from 'data/types/doc.types';
import { useSnackbar } from 'notistack';
import { isEqual } from 'lodash';
import { useProjectDocItemByIdListener } from 'firebaseServices/listeners/Docs/useProjectDocItemByIdListener';
import { useGetAuthUserQuery } from 'redux/apiSlices/authUser.slice';
import { defaultTitle, generateDocTitle } from './helpers';
import Editor from './Editor';
import ViewersList from './components/ViewersList';
import DocStatus from './components/DocStatus';
import SaveState from './components/SaveState';

type TCustomDocProps = {
  projectId: string;
  docId: string;
  customDocId: string;
};

const CustomDoc: FC<TCustomDocProps> = ({ customDocId, docId, projectId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data: authData } = useGetAuthUserQuery();
  const { data, isLoading } = useProjectDocItemByIdListener({
    docId,
    projectId,
    docItemId: customDocId,
  });

  const { isPublished } = (data as ICustomDocUI) || {};

  const [updateCustomDocContent, { isLoading: saveLoading, isSuccess }] =
    useUpdateCustomDocContentMutation();
  const [renameDocItem] = useRenameDocItemMutation();

  const editorRef = useRef<EditorJS | null>(null);

  useEffect(() => {
    if (data?.name !== defaultTitle || data.type !== EDocTypes.CUSTOM) {
      return;
    }
    const title = generateDocTitle(data.content);
    if (!title) {
      return;
    }
    renameDocItem({
      projectId,
      docId,
      docItemId: customDocId,
      newName: title,
    }).unwrap();
  }, [data]);

  const onSave = async () => {
    if (!editorRef.current || data?.type !== EDocTypes.CUSTOM) {
      return;
    }
    try {
      const output = await editorRef.current.save();
      if (isEqual(output.blocks, data.content.blocks)) {
        return;
      }

      await updateCustomDocContent({
        projectId,
        docId,
        customDocId,
        content: output,
      }).unwrap();
    } catch (error) {
      console.error(error, 'save custom doc error');
      enqueueSnackbar('Failed to save, Please try again', {
        variant: 'error',
      });
    }
  };

  if (!data || data?.type !== EDocTypes.CUSTOM || !authData) {
    return null;
  }

  if (isLoading) {
    return (
      <CircularProgress
        sx={{
          margin: 'auto',
        }}
      />
    );
  }

  return (
    <Container>
      <Grid
        maxWidth="800px" // aline styles with ReactEditorJS styles + 150px
        margin="0 auto" // aline styles with ReactEditorJS styles
        container
        justifyContent="space-between"
        alignItems="baseline"
        marginBottom={2}
      >
        <Grid minWidth="70px">
          <SaveState isLoading={saveLoading} isSuccess={isSuccess} />
        </Grid>
        <Grid>
          <ViewersList
            authData={authData}
            isPublished={isPublished}
            projectId={projectId}
            docId={docId}
            customDocId={customDocId}
            viewers={data.viewers}
          />
        </Grid>
        <Grid>
          <DocStatus
            currentUserId={authData.id}
            creatorId={data.creatorId}
            onSave={onSave}
            projectId={projectId}
            docId={docId}
            customDocId={customDocId}
            isPublished={isPublished}
          />
        </Grid>
      </Grid>
      <Editor
        onSave={onSave}
        editorRef={editorRef}
        initialData={data.content}
      />
    </Container>
  );
};

export default CustomDoc;
