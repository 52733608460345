import { FC, ReactNode } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import { Close } from '@mui/icons-material';

export type TDrawerHeaderProps = {
  onPin: () => Promise<void>;
  onRemoveShortcut: () => Promise<void>;
  onClose: () => void;
  boardFilter?: ReactNode;
};

const DrawerHeader: FC<TDrawerHeaderProps> = ({
  onClose,
  onPin,
  onRemoveShortcut,
  boardFilter,
}) => {
  return (
    <Box display="flex" justifyContent="space-between" width={1}>
      <Box gap={1}>
        <Tooltip title="Pin" placement="top">
          <IconButton onClick={onPin}>
            <PushPinIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Remove shortcut" placement="top">
          <IconButton onClick={onRemoveShortcut}>
            <BookmarkRemoveIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box>
        {boardFilter}
        <Tooltip title="Close" placement="top">
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};
export default DrawerHeader;
