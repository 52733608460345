import { FC } from 'react';
import Modal from 'components/Base/Modal';

export type TAlertDialogProps = {
  open: boolean;
  title: string;
  text: string;
  submitLoading?: boolean;
  submitDisabled?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

const AlertDialog: FC<TAlertDialogProps> = ({
  open,
  title,
  text,
  submitDisabled,
  submitLoading,
  onConfirm,
  onCancel,
}) => {
  if (!open) return null;
  return (
    <Modal
      handleClose={onCancel}
      open={open}
      title={title}
      submitButtonProps={{
        autoFocus: true,
        disabled: submitDisabled,
        loading: submitLoading,
        onClick: onConfirm,
        text: 'Yes',
      }}
    >
      {text}
    </Modal>
  );
};

export default AlertDialog;
