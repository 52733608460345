import { Navigate, Route, Routes } from 'react-router-dom';
import PlaygroundPublicPage from 'pages/publicPages/PlaygroundPublicPage';
import { publicRoutes } from './RoutesData';
import { PublicLayout } from '../components/Layout/PublicLayout';
import { PublicRoutesPaths } from './Routes.types';

const PublicRoutes = () => {
  if (process.env.REACT_APP_ENVIROMENT === 'demo') {
    return (
      <Routes>
        <Route element={<PublicLayout />}>
          <Route
            path={PublicRoutesPaths.Demo}
            element={<PlaygroundPublicPage />}
            key={PublicRoutesPaths.Demo}
          />
        </Route>
        <Route
          path="*"
          element={<Navigate replace to={PublicRoutesPaths.Demo} />}
        />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route element={<PublicLayout />}>
        {publicRoutes.map(route => {
          return (
            <Route
              path={route.path}
              element={route.component}
              key={route.path}
            />
          );
        })}
        <Route
          path="*"
          element={<Navigate replace to={publicRoutes[0].path} />}
        />
      </Route>
    </Routes>
  );
};

export default PublicRoutes;
