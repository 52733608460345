import { Chip } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { FC } from 'react';
import dayjs from 'dayjs';

type TDueDateProps = {
  dueDate?: string;
};

const DueDate: FC<TDueDateProps> = ({ dueDate }) => {
  if (!dueDate) {
    return null;
  }
  const dueDatePassed = dueDate && dayjs(dueDate).isBefore(dayjs(), 'day');
  return (
    <Chip
      size="small"
      color={dueDatePassed ? 'error' : 'default'}
      avatar={<AccessTimeIcon color="info" />}
      label={dueDate}
      sx={theme => {
        return {
          marginRight:'auto',
          color: theme.palette.text.primary,
          '.MuiSvgIcon-root': {
            color: 'inherit',
          },
          '.MuiChip-label': {
            color: 'inherit',
          },
        };
      }}
    />
  );
};

export default DueDate;
