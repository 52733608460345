import React, { FC, useState } from 'react';
import { Box, Card } from '@mui/material';
import { IShortcutUi } from 'data/types/user.types';
import { EShortcutTypes } from 'shared/types/generic.types';
import { useUpdateUserShortcutsMutation } from 'redux/apiSlices/authUser.slice';
import CustomDoc from 'pages/privatePages/CustomDocDetailsPage/CustomDoc';
import Resizable from './Resizeble';
import BoardsDetail from '../../../BoardsDetail';
import PinnedContentHeader from './PinnedContentHeader';
import BoardFilter from '../../../BoardsDetail/components/BoardFilter';
import Chat from '../../../Chat';

const PinnedContent: FC<{
  shortcuts: IShortcutUi[];
  userId: string;
}> = ({ shortcuts, userId }) => {
  const [width, setWidth] = useState(400);
  const [updateShortcuts] = useUpdateUserShortcutsMutation();
  const pinnedItem = shortcuts.find(item => item.open);

  const onUnpin = async () => {
    await updateShortcuts(
      shortcuts.map(item => {
        if (item.open) {
          return {
            ...item,
            pinned: false,
            open: false,
          };
        }
        return item;
      }),
    );
  };
  const onClose = async () => {
    await updateShortcuts(
      shortcuts.map(item => {
        if (item.open) {
          return {
            ...item,
            open: false,
          };
        }
        return item;
      }),
    );
  };
  const onRemoveShortcut = async () => {
    await updateShortcuts(shortcuts.filter(item => item.id !== pinnedItem?.id));
    // setOpenedShortcutId('');
  };
  return (
    <Resizable
      axis="x"
      resizeHandles={['nw']}
      maxConstraints={[800, 1000]}
      minConstraints={[300, 1000]}
      pined={(!!pinnedItem).toString()}
      width={width}
      onResize={(event, { size }) => {
        setWidth(size.width);
      }}
    >
      <Card
        className="box"
        sx={{
          height: 'calc(100vh - 32px)',
          overflowY: 'hidden',
          padding: 1,
          marginX: 1,
          marginY: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
        }}
      >
        <PinnedContentHeader
          onClose={onClose}
          onUnpin={onUnpin}
          onRemoveShortcut={onRemoveShortcut}
          boardFilter={
            pinnedItem?.type === EShortcutTypes.BOARD && (
              <BoardFilter
                projectId={pinnedItem.projectId}
                boardId={pinnedItem.boardGroupId}
              />
            )
          }
        />
        <Box flexGrow={1} overflow="auto">
          {pinnedItem?.type === EShortcutTypes.BOARD && (
            <BoardsDetail
              projectId={pinnedItem.projectId}
              boardId={pinnedItem.boardGroupId}
              headerActions
            />
          )}
          {pinnedItem?.type === EShortcutTypes.CUSTOM_DOC && (
            <CustomDoc
              projectId={pinnedItem.projectId}
              docId={pinnedItem.docId}
              customDocId={pinnedItem.customDocId}
            />
          )}
          {pinnedItem?.type === EShortcutTypes.CHAT && (
            <Chat
              projectId={pinnedItem.projectId}
              chatId={pinnedItem.chatId}
              userId={userId}
              fullWidth
            />
          )}
        </Box>
      </Card>
    </Resizable>
  );
};

export default PinnedContent;
