import Button from 'components/Base/Button';
import { Icon } from 'components/Base/Icon';
import { auth } from 'firebaseServices/firebase.config';
import useSignInWithGoogle from 'firebaseServices/Auth/useSignInWithGoogle';

const ContinueGoogleButton = () => {
  const { signInWithGoogle } = useSignInWithGoogle();
  const handleSingUpWithGoogle = async () => {
    try {
      await auth.signOut();
      await signInWithGoogle();
    } catch (err) {
      // do nothing
    }
  };
  return (
    <Button
      fullWidth
      variant="outlined"
      startIcon={<Icon icon="google-icon" />}
      onClick={handleSingUpWithGoogle}
    >
      Continue with Google
    </Button>
  );
};

export default ContinueGoogleButton;
