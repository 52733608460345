import { useEffect, useState } from 'react';
import {
  onSnapshot,
  DocumentReference,
  FirestoreError,
} from 'firebase/firestore';

type WithDocumentId<T> = T & { documentId: string };

type FirebaseDocumentHook<T> = {
  snapshotData: WithDocumentId<T> | null;
  isLoading: boolean;
  error: Error | null;
  setSnapshotData: (data: WithDocumentId<T>) => void;
};

export const useFirebaseDocumentListener = <T>(
  docRef: DocumentReference<T>,
): FirebaseDocumentHook<T> => {
  const [snapshotData, setSnapshotData] = useState<WithDocumentId<T> | null>(
    null,
  );
  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      docRef,
      snapshot => {
        if (snapshot.exists()) {
          setSnapshotData(snapshot.data() as WithDocumentId<T>);
          setError(null);
        } else {
          setSnapshotData(null);
          setError(new Error('Document not found'));
        }
        setLoading(false);
      },
      (err: FirestoreError) => {
        setError(new Error(err.message));
        setLoading(false);
      },
    );

    return () => unsubscribe();
  }, []);

  return { snapshotData, isLoading, error, setSnapshotData };
};
