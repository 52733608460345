import { createApi } from '@reduxjs/toolkit/query/react';
import { fakeBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { queryFnWrapper } from 'firebaseServices/queryFunctions/helper';
import {
  TAddFileDocProps,
  TAddFolderDocProps,
  TGetDocByIdProps,
  TGetDocItemsProps,
  TRemoveDocItemProps,
  TRenameDocItemProps,
  addFileDoc,
  addFolderToDoc,
  addNewDoc,
  getDocById,
  getAllDocItems,
  getProjectAllDocs,
  removeDocItem,
  renameDocItem,
  getDocItemById,
  TGetDocItemByIdProps,
  TAddBlankCustomDocProps,
  addBlankCustomDoc,
  TUpdateCustomDocContentProps,
  updateCustomDocContent,
  updateCustomDocPublishFlag,
  TUpdateCustomDocPublishFlagProps,
  TMoveDocItemProps,
  moveDocItem,
  TRenameDocProps,
  renameDoc,
  TRemoveDocProps,
  removeDoc,
  addViewerToCustomDoc,
  TAddViewerToCustomDocProps,
  removeViewerFromCustomDoc,
  TRemoveViewerFromCustomDocProps,
} from 'firebaseServices/queryFunctions/docsQueryFunctions';
import { ICreateNewDocUI, TDocItemUI, IDocUI } from 'data/types/doc.types';
import { eventsApi } from './events.slice';

export const docApi = createApi({
  tagTypes: ['docs', 'doc', 'docItems', 'docItem'],
  reducerPath: 'docs',
  baseQuery: fakeBaseQuery(),
  endpoints: build => ({
    getProjectDocs: build.query<IDocUI[] | null, string>({
      queryFn: arg => queryFnWrapper(getProjectAllDocs, arg),
      providesTags: ['docs'],
    }),
    getDocById: build.query<IDocUI | null, TGetDocByIdProps>({
      queryFn: arg => queryFnWrapper(getDocById, arg),
      providesTags: ['doc'],
    }),
    addNewDoc: build.mutation<void, ICreateNewDocUI>({
      queryFn: arg => queryFnWrapper(addNewDoc, arg),
      invalidatesTags: ['docs'],
    }),
    addFileToDoc: build.mutation<void, TAddFileDocProps>({
      queryFn: arg => queryFnWrapper(addFileDoc, arg),
      invalidatesTags: ['docs', 'doc', 'docItems'],
    }),
    addFolderToDoc: build.mutation<void, TAddFolderDocProps>({
      queryFn: arg => queryFnWrapper(addFolderToDoc, arg),
      invalidatesTags: ['docs', 'doc', 'docItems'],
    }),
    addBlankCustomDoc: build.mutation<string, TAddBlankCustomDocProps>({
      queryFn: arg => queryFnWrapper(addBlankCustomDoc, arg),
      invalidatesTags: ['docItems', 'doc', 'docs', 'docItem'],
    }),
    getAllDocItems: build.query<TDocItemUI[] | null, TGetDocItemsProps>({
      queryFn: arg => queryFnWrapper(getAllDocItems, arg),
      providesTags: ['docItems'],
    }),
    getDocItemById: build.query<TDocItemUI | null, TGetDocItemByIdProps>({
      queryFn: arg => queryFnWrapper(getDocItemById, arg),
      providesTags: ['docItem'],
    }),
    renameDocItem: build.mutation<void, TRenameDocItemProps>({
      queryFn: arg => queryFnWrapper(renameDocItem, arg),
      invalidatesTags: ['docItems', 'doc', 'docs'],
    }),
    removeDocItem: build.mutation<void, TRemoveDocItemProps>({
      queryFn: arg => queryFnWrapper(removeDocItem, arg),
      invalidatesTags: ['docItems', 'doc', 'docs'],
    }),
    updateCustomDocContent: build.mutation<void, TUpdateCustomDocContentProps>({
      queryFn: arg => queryFnWrapper(updateCustomDocContent, arg),
      invalidatesTags: ['docItem', 'docItems', 'doc'],
    }),
    updateCustomDocPublishFlag: build.mutation<
      void,
      TUpdateCustomDocPublishFlagProps
    >({
      queryFn: arg => queryFnWrapper(updateCustomDocPublishFlag, arg),
      invalidatesTags: ['docItem', 'docItems', 'doc'],
    }),
    moveDocItem: build.mutation<void, TMoveDocItemProps>({
      queryFn: arg => queryFnWrapper(moveDocItem, arg),
      invalidatesTags: ['docItems', 'doc', 'docs'],
    }),
    renameDoc: build.mutation<void, TRenameDocProps>({
      queryFn: arg => queryFnWrapper(renameDoc, arg),
      invalidatesTags: ['docItems', 'doc', 'docs'],
    }),
    removeDoc: build.mutation<void, TRemoveDocProps>({
      queryFn: arg => queryFnWrapper(removeDoc, arg),
      invalidatesTags: ['docItems', 'doc', 'docs'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(eventsApi.util.invalidateTags(['events']));
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    addViewerToCustomDoc: build.mutation<void, TAddViewerToCustomDocProps>({
      queryFn: arg => queryFnWrapper(addViewerToCustomDoc, arg),
      invalidatesTags: ['docItem'],
    }),
    removeViewerFromCustomDoc: build.mutation<
      void,
      TRemoveViewerFromCustomDocProps
    >({
      queryFn: arg => queryFnWrapper(removeViewerFromCustomDoc, arg),
      invalidatesTags: ['docItem'],
    }),
  }),
});

export const {
  useGetProjectDocsQuery,
  useAddNewDocMutation,
  useGetDocByIdQuery,
  useAddFileToDocMutation,
  useAddFolderToDocMutation,
  useGetAllDocItemsQuery,
  useRenameDocItemMutation,
  useRemoveDocItemMutation,
  useGetDocItemByIdQuery,
  useAddBlankCustomDocMutation,
  useUpdateCustomDocContentMutation,
  useUpdateCustomDocPublishFlagMutation,
  useMoveDocItemMutation,
  useRenameDocMutation,
  useRemoveDocMutation,
  useAddViewerToCustomDocMutation,
  useRemoveViewerFromCustomDocMutation,
} = docApi;
