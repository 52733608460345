import { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useRemoveDocMutation,
  useRenameDocMutation,
} from 'redux/apiSlices/doc.slice';
import { IDocUI } from 'data/types/doc.types';
import { PrivateRoutesPaths } from 'routes/Routes.types';
import ActiveToolCard from '../ActiveToolCard';
import DocPreview from './DocPreview';

const AllDocs: FC<{ projectId: string; docs: IDocUI[] }> = memo(
  ({ projectId, docs }) => {
    const navigate = useNavigate();
    const [renameDoc] = useRenameDocMutation();
    const [removeDoc, { isLoading }] = useRemoveDocMutation();

    const onDocsItemClick = (id: string) => {
      const fullDocDetailsPath = PrivateRoutesPaths.DocDetails.replace(
        ':projectId',
        projectId,
      )
        .replace(':docId', id)
        .concat('?location=root');
      navigate(`/${fullDocDetailsPath}`);
    };

    return (
      <>
        {docs.map(doc => {
          return (
            <ActiveToolCard
              key={doc.documentId}
              onClick={() => onDocsItemClick(doc.documentId)}
              name={doc.name}
              onRenameTool={async newName => {
                await renameDoc({
                  projectId,
                  docId: doc.documentId,
                  newName,
                }).unwrap();
              }}
              onRemoveTool={async () => {
                await removeDoc({
                  projectId,
                  id: doc.documentId,
                }).unwrap();
              }}
              removeLoading={isLoading}
            >
              <DocPreview projectId={doc.projectId} docId={doc.documentId} />
            </ActiveToolCard>
          );
        })}
      </>
    );
  },
);

export default AllDocs;
