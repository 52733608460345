import * as Yup from 'yup';
import { validationMessages } from 'data/messages/messages';
import { emailRegexp } from 'shared/constants';

export const AddProjectSchema = Yup.object().shape({
  name: Yup.string().required(validationMessages.required),
  color: Yup.string().required(),
  description: Yup.string().nullable().optional(),
});

export const inviteProjectMemberSchema = Yup.object().shape({
  email: Yup.string()
    .matches(emailRegexp, validationMessages.email)
    .required(validationMessages.required),
});
