import { FC } from 'react';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useNavigate } from 'react-router-dom';
import { PrivateRoutesPaths } from 'routes/Routes.types';
import { IProjectUI } from 'data/types/project.types';
import ProjectCard from './ProjectCard';

type TAllProjectsProps = {
  projectData: IProjectUI[];
};

const AllProjects: FC<TAllProjectsProps> = ({ projectData }) => {
  const navigate = useNavigate();
  return (
    <Grid container direction="row" spacing={4} justifyContent="center">
      {projectData.map(project => (
        <Grid
          minWidth={220}
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={3}
          key={project.id}
        >
          <ProjectCard
            projectData={project}
            key={project.id}
            onClick={() => {
              navigate(
                `/${PrivateRoutesPaths.ProjectsDetails.replace(
                  ':id',
                  project.id,
                )}`,
              );
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default AllProjects;
