import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { FC } from 'react';
import { FileUploadOutlined } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import { uploadFileConfig } from 'components/UploadFiles/uploadFile.config';
import useUploadFileInDocOnDrop from 'components/UploadFiles/useUploadFileInDocOnDrop';

type TAddFileProps = {
  projectId: string;
  docId: string;
  currentLocation: string;
  onCloseMenu: () => void;
};

const AddFile: FC<TAddFileProps> = ({
  projectId,
  docId,
  currentLocation,
  onCloseMenu,
}) => {
  const { onDrop } = useUploadFileInDocOnDrop({
    projectId,
    docId,
    currentLocation,
  });

  const { open: openFileUploadWindow } = useDropzone({
    noClick: true,
    noKeyboard: true,
    ...uploadFileConfig,
    onDrop,
  });

  return (
    <MenuItem
      onClick={() => {
        onCloseMenu();
        openFileUploadWindow();
      }}
    >
      <ListItemIcon>
        <FileUploadOutlined fontSize="small" />
      </ListItemIcon>
      <ListItemText> Upload files</ListItemText>
    </MenuItem>
  );
};

export default AddFile;
