import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import AddNewProject from './AddNewProject';

const CreateProjectSection = () => {
  return (
    <Grid container direction="row" columnSpacing={4}>
      <Grid>
        <AddNewProject />
      </Grid>
    </Grid>
  );
};

export default CreateProjectSection;
