import { FC, useState } from 'react';
import { TextField, Typography } from '@mui/material';
import { useUpdateTaskTitleMutation } from 'redux/apiSlices/tasks.slice';

const TaskTitle: FC<{
  title: string;
  taskId: string;
  wrap?:boolean
}> = ({ title, taskId,wrap }) => {
  const [editMode, setEditMode] = useState(false);
  const [inputValue, setInputValue] = useState(title);
  const [updateTitle] = useUpdateTaskTitleMutation();

  const openEditMode = () => {
    setEditMode(true);
  };
  const closeEditMode = () => {
    setEditMode(false);
  };

  const onUpdateTitle = async () => {
    const newTitle = inputValue.trim()
    if (newTitle === title || !newTitle) {
      closeEditMode();
      setInputValue(title);
    } else {
      await updateTitle({
        title: newTitle,
        taskId,
      });
      closeEditMode();
    }
  };

  return editMode ? (
    <TextField
      size="small"
      fullWidth
      value={inputValue}
      onChange={e => {
        setInputValue(e.target.value);
      }}
      autoFocus
      onBlur={onUpdateTitle}
      onKeyDown={async event => {
        if (event.key === 'Enter') {
          await onUpdateTitle();
        }
      }}
    />
  ) : (
    <Typography sx={{textOverflow:wrap ?"unset":'ellipsis ',textWrap:'nowrap',overflow:'hidden',margin:0}}  variant='h5'   paragraph onClick={openEditMode}>
      {title}
    </Typography>
  );
};

export default TaskTitle;
