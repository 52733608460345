import { FC, memo, useState } from 'react';
import { Box } from '@mui/material';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import BoardColumn from 'components/BoardsDetail/components/BoardColumn';
import useListenBoardColumns from 'firebaseServices/listeners/Boards/useListenBoardColumns';
import useListenBoardTasks from 'firebaseServices/listeners/Boards/useListenBoardTasks';
import { ITaskUI } from 'data/types/task.type';
import AddColumn from './components/ColumnHeader/AddColumn';
import TaskInfoModal from './components/ColumnContent/TaskInfoModal';
import { updateColumnsOrder } from '../../firebaseServices/queryFunctions/boardQueryFunctions';
import {
  updateTaskOrder,
  updateTaskStatus,
} from '../../firebaseServices/queryFunctions/taskQueryFunctions';

const BoardsDetail: FC<{
  projectId: string;
  boardId: string;
  headerActions?: boolean;
}> = ({ projectId, boardId, headerActions }) => {
  const { columns, setColumns } = useListenBoardColumns({ boardId, projectId });
  const { tasks } = useListenBoardTasks({ boardId, projectId });
  const [openedTaskId, setOpenedTaskId] = useState<string>('');
  const [dragLoading, setDragLoading] = useState(false);
  const openedTask = tasks[openedTaskId];

  const onDragEnd = async (result: DropResult) => {
    const { source, destination, type, draggableId } = result;
    if (!destination) {
      return;
    }
    if (type === 'columns') {
      if (source.droppableId === destination.droppableId) {
        if (source.index === destination.index) {
          return;
        }
        const newColumns = columns.map(item => ({ ...item }));
        const [removed] = newColumns.splice(source.index, 1);
        newColumns.splice(destination.index, 0, removed);
        newColumns.forEach((board, index) => {
          board.order = index;
        });
        try {
          setColumns(newColumns);
          await updateColumnsOrder({
            projectId,
            boardId,
            columns: newColumns.map(item => ({
              documentId: item.documentId,
              order: item.order,
            })),
          });
        } catch {
          setColumns(columns);
        }
      }
    }
    if (type === 'tasks') {
      // reorder in his column
      if (destination.droppableId === source.droppableId) {
        try {
          setDragLoading(true);
          setColumns(cols =>
            cols.map(col => {
              if (col.documentId === destination.droppableId) {
                const newTasks = [...col.tasks];
                newTasks.splice(source.index, 1);
                newTasks.splice(destination.index, 0, draggableId);
                return {
                  ...col,
                  tasks: newTasks,
                };
              }
              return col;
            }),
          );
          await updateTaskOrder({
            task: tasks[draggableId],
            destinationIndex: destination.index,
          });
        } catch {
          // handle backup
          setColumns(columns);
        } finally {
          setDragLoading(false);
        }
      }
      // move to another column
      else {
        try {
          setDragLoading(true);
          setColumns(cols =>
            cols.map(col => {
              if (col.documentId === destination.droppableId) {
                const newTasks = col.tasks;
                newTasks.splice(destination.index, 0, draggableId);
                return {
                  ...col,
                  tasks: newTasks,
                };
              }
              if (col.documentId === source.droppableId) {
                const newTasks = col.tasks;
                newTasks.splice(source.index, 1);
                return {
                  ...col,
                  tasks: newTasks,
                };
              }
              return col;
            }),
          );
          await updateTaskStatus({
            task: tasks[draggableId],
            destinationIndex: destination.index,
            destinationColumnId: destination.droppableId,
          });
        } catch {
          setColumns(columns);
        } finally {
          setDragLoading(false);
        }
      }
    }
  };

  return (
    <Box
      flexGrow={1}
      display="flex"
      flexDirection="column"
      paddingTop={1}
      sx={{ minHeight: headerActions ? 'calc(100% - 40px)' : '100%' }}
    >
      <Box
        display="flex"
        alignItems="top"
        sx={{ overflowY: 'auto' }}
        flexGrow={1}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId="all-columns"
            direction="horizontal"
            type="columns"
          >
            {provided => (
              <Box
                display="flex"
                sx={{ flexGrow: 1 }}
                gap={2}
                paddingLeft={1}
                paddingBottom={1}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {columns.map((column, index, allColumns) => {
                  const columnTasks: ITaskUI[] = Object.values(tasks).length
                    ? column.tasks.map(id => tasks[id])
                    : [];
                  return (
                    <BoardColumn
                      width={1 / columns.length}
                      column={column}
                      boardId={boardId}
                      projectId={projectId}
                      key={column.documentId}
                      index={index}
                      tasks={columnTasks}
                      setOpenedTask={setOpenedTaskId}
                      dragLoading={dragLoading}
                      allColumnsShortData={allColumns.map(col => ({
                        documentId: col.documentId,
                        title: col.title,
                        color: col.color,
                      }))}
                    />
                  );
                })}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
        <AddColumn
          boardId={boardId}
          projectId={projectId}
          order={columns.length}
        />
        {openedTask && (
          <TaskInfoModal
            open={!!openedTaskId}
            task={openedTask}
            closeModal={() => setOpenedTaskId('')}
          />
        )}
      </Box>
    </Box>
  );
};

export default memo(BoardsDetail);
