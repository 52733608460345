import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { isEqual } from 'lodash';
import { errorMessages, successMessages } from 'data/messages/messages';
import { yupResolver } from '@hookform/resolvers/yup';
import { projectColors } from 'data/static';
import { AddProjectSchema } from 'data/validation/ProjectSchemas';
import { TRtkErrorType } from 'data/types/general.types';
import Modal from 'components/Base/Modal';
import AddEditNewProjectForm, {
  TAddEditNewProjectForm,
} from './AddEditNewProjectForm';

type TAddEditNewProjectModalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  initialData?: TAddEditNewProjectForm;
  onSubmitClick: (values: TAddEditNewProjectForm) => Promise<void>;
  submitLoading: boolean;
  modalTitlePrefix: string;
  submitButtonText: string;
};

const AddEditNewProjectModal: FC<TAddEditNewProjectModalProps> = ({
  open,
  setOpen,
  initialData,
  onSubmitClick,
  submitLoading,
  modalTitlePrefix,
  submitButtonText,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, formState, register, reset, setValue, watch } =
    useForm<TAddEditNewProjectForm>({
      defaultValues: {
        name: initialData?.name || '',
        color: initialData?.color || projectColors[0].light,
        description: initialData?.description,
      },
      resolver: yupResolver(AddProjectSchema),
    });

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit: SubmitHandler<TAddEditNewProjectForm> = async values => {
    if (isEqual(values, initialData)) {
      handleClose();
      return;
    }

    try {
      const trimDescription = values.description?.trim();
      await onSubmitClick({
        ...values,
        description: trimDescription || null,
      });

      enqueueSnackbar(successMessages.toast, {
        variant: 'success',
      });
      handleClose();
    } catch (err) {
      const error = err as TRtkErrorType;
      enqueueSnackbar(error?.data.message || errorMessages.default, {
        variant: 'error',
      });
    }
  };

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title={`${modalTitlePrefix} Project`}
      submitButtonProps={{
        text: submitButtonText,
        disabled: formState.isSubmitting,
        loading: submitLoading,
        onClick: handleSubmit(onSubmit),
      }}
    >
      <AddEditNewProjectForm
        formState={formState}
        register={register}
        watch={watch}
        setValue={setValue}
        onSubmit={handleSubmit(onSubmit)}
      />
    </Modal>
  );
};

export default AddEditNewProjectModal;
