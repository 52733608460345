import {
  orderBy,
  query,
  limit,
  getDocs,
  getCountFromServer,
} from 'firebase/firestore';
import { firestoreReference } from 'firebaseServices/FirestoreReferenceManager';

export type TGetAllEventsQueryProps = {
  projectId: string;
  limitCount?: number;
  searchQuery?: string;
};

export const ProjectEventsCollectionQuery = ({
  projectId,
  limitCount,
}: TGetAllEventsQueryProps) => {
  const collectionRef = firestoreReference.projectEvents_CollectionRef({
    projectId,
  });

  let eventQuery = query(collectionRef, orderBy('eventDateTime', 'desc'));

  if (limitCount) {
    eventQuery = query(eventQuery, limit(limitCount));
  }
  return eventQuery;
};

export const getCountOfProjectEvents = async (projectId: string) => {
  const collectionRef = firestoreReference.projectEvents_CollectionRef({
    projectId,
  });
  const snapshot = await getCountFromServer(collectionRef);
  return snapshot.data().count;
};

export const getAllProjectEventsQuery = async (
  props: TGetAllEventsQueryProps,
) => {
  const eventQuery = ProjectEventsCollectionQuery(props);

  const collectionSnapshot = await getDocs(eventQuery);

  if (
    collectionSnapshot.empty ||
    collectionSnapshot.docs.every(d => !d.exists)
  ) {
    return null;
  }

  const docItems = collectionSnapshot.docs.map(document => {
    return {
      ...document.data(),
      documentId: document.id,
    };
  });
  const totalCount = await getCountOfProjectEvents(props.projectId);

  return {
    data: docItems,
    totalCount,
  };
};
