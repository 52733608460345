import Stack from '@mui/material/Stack';
import { FabOwnProps } from '@mui/material';
import { FC } from 'react';
import { IShortcutUi } from 'data/types/user.types';
import { useUpdateUserShortcutsMutation } from '../../../../redux/apiSlices/authUser.slice';
import {
  EProjectCustomTools,
  EShortcutTypes,
} from '../../../../shared/types/generic.types';
import ChatShortcutItem from './ChatShortcutItem';
import BoardShortcutItem from './BoardShortcutItem';
import DocShortcutItem from './DocShortcutItem';
import CustomToolShortcutItem from './CustomToolShortcutItem';

const Shortcuts: FC<{
  setOpenedShortcut: (id: string) => void;
  shortcuts: IShortcutUi[];
  openedShortcutId: string;
  itemSize: FabOwnProps['size'];
}> = ({ setOpenedShortcut, shortcuts, openedShortcutId, itemSize }) => {
  const [updateShortcuts] = useUpdateUserShortcutsMutation();

  const onOpen = async (id: string) => {
    await updateShortcuts(
      shortcuts.map(item => {
        if (item.id === id) {
          return {
            ...item,
            pinned: true,
            open: true,
          };
        }
        return { ...item, open: false };
      }),
    );
  };
  // TODO: this part should be refactored to be more generic and reusable depending on the type of the shortcut
  // and also in drawer content, make one mapper for the type of the shortcut

  const onToolItemClick = async (item: IShortcutUi) => {
    if (item.pinned) {
      await onOpen(item.id);
    } else if (openedShortcutId === item.id) {
      setOpenedShortcut('');
    } else {
      setOpenedShortcut(item.id);
    }
  };
  return (
    <Stack
      spacing={2}
      direction="column-reverse"
      justifyContent="center"
      sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
    >
      {shortcuts.map(item => {
        if (item.type === EShortcutTypes.CHAT)
          return (
            <ChatShortcutItem
              key={item.id}
              shortcut={item}
              itemSize={itemSize}
              onClick={async () => {
                await onToolItemClick(item);
              }}
              isOpened={openedShortcutId === item.id}
            />
          );
        if (item.type === EShortcutTypes.BOARD)
          return (
            <BoardShortcutItem
              key={item.id}
              shortcut={item}
              itemSize={itemSize}
              onClick={async () => {
                await onToolItemClick(item);
              }}
              isOpened={openedShortcutId === item.id}
            />
          );
        if (item.type === EShortcutTypes.CUSTOM_DOC)
          return (
            <DocShortcutItem
              key={item.id}
              shortcut={item}
              itemSize={itemSize}
              onClick={async () => {
                await onToolItemClick(item);
              }}
              isOpened={openedShortcutId === item.id}
            />
          );
        if (Object.values(EProjectCustomTools).includes(item.type))
          return (
            <CustomToolShortcutItem
              key={item.id}
              shortcut={item}
              itemSize={itemSize}
            />
          );
        return null;
      })}
    </Stack>
  );
};

export default Shortcuts;
