import { FC, Fragment, useEffect, useMemo } from 'react';
import { Divider } from '@mui/material';
import dayjs from 'dayjs';
import { IMessageUi } from 'data/types/message.types';
import { dateFormat } from 'data/static';
import Messages from '../Messages';

const MessageGroupsByDate: FC<{
  allMessages: IMessageUi[];
  userId: string;
  projectId: string;
}> = ({ allMessages, userId, projectId }) => {
  const groupedMessages = useMemo(() => {
    // @ts-ignore
    return Object.groupBy(allMessages, message => {
      return dayjs(message.creationDate).format(dateFormat);
    });
  }, [allMessages]);
  useEffect(() => {
    // @ts-ignore
    const lastSeenMessage = allMessages.findLast(message =>
      message.seenBy.includes(userId),
    );
    const lastSeenMessageElement = document.getElementById(
      lastSeenMessage?.documentId || '',
    );
    if (lastSeenMessageElement) {
      lastSeenMessageElement.scrollIntoView({ block: 'end' });
    }
  }, []);

  return (
    <>
      {Object.keys(groupedMessages).map(date => {
        return (
          <Fragment key={date}>
            <Divider textAlign="center">{date}</Divider>
            <Messages
              messages={groupedMessages[date] || []}
              projectId={projectId}
              userId={userId}
            />
          </Fragment>
        );
      })}
    </>
  );
};

export default MessageGroupsByDate;
