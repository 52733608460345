import { TextField, Typography } from '@mui/material';
import { IColumnUI } from 'data/types/board.type';
import { FC, useRef, useState } from 'react';
import { useUpdateColumnTitleMutation } from 'redux/apiSlices/board.slice';

type TEditNameProps = {
  column: IColumnUI;
  boardId: string;
  projectId: string;
  currentColor?: string;
};

const EditName: FC<TEditNameProps> = ({
  column,
  boardId,
  projectId,
  currentColor,
}) => {
  const [editMode, setEditMode] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const [updateTitle] = useUpdateColumnTitleMutation();

  const onNameClick = () => {
    setEditMode(true);
  };
  const onNameChange = async () => {
    const newTitle = (inputRef?.current?.value || '').trim();
    if (!newTitle || newTitle === column.title) {
      setEditMode(false);
    } else {
      await updateTitle({
        boardId,
        projectId,
        columnId: column.documentId,
        title: newTitle,
      });
      setEditMode(false);
    }
  };

  if (editMode) {
    return (
      <TextField
        inputRef={inputRef}
        size="small"
        aria-disabled
        defaultValue={column.title}
        onBlur={onNameChange}
        autoFocus
        onKeyDown={async event => {
          if (event.key === 'Enter') {
            await onNameChange();
          }
        }}
      />
    );
  }
  return (
    <Typography
      maxWidth="90%"
      variant="h6"
      noWrap
      textOverflow="ellipsis"
      onClick={onNameClick}
      color={currentColor}
      sx={{ marginBottom: 0 }}
    >
      {column.title.toUpperCase()} ({column.tasks.length})
    </Typography>
  );
};

export default EditName;
