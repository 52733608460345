import { FabOwnProps } from '@mui/material/Fab';
import { FC } from 'react';
import { ICustomToolShortcutUI } from 'data/types/user.types';
import { projectToolMapper } from 'pages/privatePages/Projects/ProjectDetails/ActiveTools/ActiveToolsData';
import { useGetCustomToolQuery } from 'redux/apiSlices/customTool.slice';
import ShortcutItem from './ShortcutItem';

const CustomToolShortcutItem: FC<{
  shortcut: ICustomToolShortcutUI;
  itemSize: FabOwnProps['size'];
}> = ({ shortcut, itemSize }) => {
  const { data: tool, isError } = useGetCustomToolQuery({
    toolId: shortcut.id,
    projectId: shortcut.projectId,
  });

  const onClick = () => {
    if (tool) {
      if (
        !tool.link.startsWith('http://') &&
        !tool.link.startsWith('https://')
      ) {
        window.open(`https://${tool.link}`, '_blank');
      } else window.open(tool.link, '_blank');
    }
  };
  if (isError) {
    return null;
  }
  return (
    <ShortcutItem
      itemSize={itemSize}
      name={tool?.name || shortcut.name}
      onClick={onClick}
      icon={projectToolMapper[shortcut.type].icon}
      isOpened={false}
      projectId={shortcut.projectId}
    />
  );
};
export default CustomToolShortcutItem;
